import React from 'react';

import TitlePages from 'components/TitlePages';

import Form from './partials/Form';
import ErrorModal from './partials/ErrorModal';

import { Container } from './styles';

const SignIn: React.FC = () => {
  return (
    <Container>
      <TitlePages title="Login" />
      <Form />
      <ErrorModal />
    </Container>
  );
};

export default SignIn;
