import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { useSpecSheets } from 'containers/spec-sheets';
import { useSpecSheet } from 'containers/spec-sheet';
import { MAP_STEPS } from 'containers/spec-sheet/constants';

import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import { LoadingEnum } from 'containers/spec-sheet/dtos';
import AddNewStyle from './AddNewStyle';
import CreateSketch from './CreateSketch';
import Prototype from './Prototype';
import SkuDetails from './SkuDetails';
import SkuComponent from './SkuComponent';
import FinishSpecSheet from './FinishSpecSheet';

import { Container } from './styles';

const stepsComponents: any = {
  [MAP_STEPS.ADD_NEW_STYLE]: AddNewStyle,
  [MAP_STEPS.CREATE_SKETCH]: CreateSketch,
  [MAP_STEPS.PROTOTYPE]: Prototype,
  [MAP_STEPS.SKU_DETAILS]: SkuDetails,
  [MAP_STEPS.SKU_COMPONENT]: SkuComponent,
  [MAP_STEPS.FINISH_SPEC]: FinishSpecSheet,
};

const Steps: React.FC = () => {
  const {
    data: { stepActive },
    actions: actionsSpecSheets,
  } = useSpecSheets();
  const {
    data: { loading },
    actions,
  } = useSpecSheet();

  const { id }: any = useParams();

  useEffect(() => {
    actions.getDetails(Number(id));
    actionsSpecSheets.changeStep(MAP_STEPS.ADD_NEW_STYLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StepActive = stepsComponents[stepActive];

  const isLoading = loading === LoadingEnum.DEFAULT;

  return useMemo(
    () => (
      <Container>
        {isLoading ? (
          <GridFlex
            alignItems="center"
            justifyContent="center"
            margin="100px 0"
          >
            <Spinner size="big" />
          </GridFlex>
        ) : (
          <StepActive />
        )}
      </Container>
    ),
    [StepActive, isLoading],
  );
};

export default Steps;
