/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ChangeEvent, useEffect, useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import { Input } from 'components/Fields';

import { useDebounce } from 'hooks/useDebounce';

const StyleName: React.FC = () => {
  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const description = data?.description || '';

  const [value, setValue] = useState(description);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  const autoSave = useDebounce(value);

  const saveChange = async () => {
    if (description !== value) {
      actions.changeSpecSheetData({ description: value.trim() });
    }
  };

  useEffect(() => {
    if (description !== value) saveChange();
  }, [autoSave]);

  const isCompleted = !!value;

  return (
    <Input
      label="Style name"
      value={value}
      name="style-name"
      onChange={handleChange}
      dataTest="style-name"
      isCompleted={isCompleted}
      handleBlur={saveChange}
      placeholder="Click to add"
    />
  );
};

export default StyleName;
