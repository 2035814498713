import React from 'react';
import Icon from 'components/Icon';

import { BoxChip, BoxDelete } from './styles';

export interface IProps {
  id: number;
  description: string;
  onDelete: (id: number) => void;
  isCompleted?: boolean;
}

const Chip: React.FC<IProps> = ({ id, description, onDelete, isCompleted }) => {
  return (
    <BoxChip isCompleted={isCompleted}>
      {description}
      <BoxDelete onClick={() => onDelete(id)}>
        <Icon icon="close" width="7px" color="white" />
      </BoxDelete>
    </BoxChip>
  );
};

export default Chip;
