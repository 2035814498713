import { IPoint } from 'components/ModalLettersAndComments';
import { ISkuDetail } from 'containers/spec-sheet-sku-detail/dtos';
import { IData as ISpecSheet, IMasterImage } from 'containers/spec-sheet/dtos';
import { ISkuComponent } from 'pages/SpecSheet/partials/Steps/SkuComponent/types';

export enum TypeEnum {
  DEFAULT = 'default',
  PRINT = 'print',
  DOWNLOAD = 'download',
}

export interface IActions {
  openModalAndGetSpecSheetComplete(
    idSpecSheet: number,
    type: TypeEnum,
  ): Promise<void>;
  closeModal(): void;
}

interface INote {
  id: number;
  idSpecSheet: number;
  description: string;
  type: string;
}

interface INotes {
  addNewStyle: INote | null;
  prototype: INote | null;
}

interface IPointWithValue extends IPoint {
  value: string;
}

export interface IMasterImageWithPoints extends IMasterImage {
  points: IPointWithValue[];
  comments: {
    id: string;
    idSpecSheetImage: number;
    type: string;
    value: string;
    x: number;
    y: number;
  }[];
}

export interface IState {
  specSheet?: ISpecSheet | null;
  skuDetails?: ISkuDetail[] | null;
  skuComponents?: ISkuComponent[] | null;
  notes?: INotes;
  image?: IMasterImageWithPoints | null;
  imageWithPoints?: IMasterImageWithPoints | null;
  type: TypeEnum | null;
  loading: boolean;
}
