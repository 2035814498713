import { css } from 'styled-components';

import { takeColor } from '.';

export default css`
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${takeColor('grayScrollBar')};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${takeColor('grayMedium')};
  }
`;
