import React from 'react';

import Text from 'components/Text';

import Icon from 'components/Icon';
import { LabelWrapper, CheckBox, CheckBoxContent } from './styles';

export interface IProps {
  onClick: () => void;
  label: string;
  isChecked: boolean;
  name: string;
  width?: string;
  widthIcon?: string;
  marginRightIcon?: string;
  textFontSize?: number;
}

const Checkbox: React.FC<IProps> = props => {
  const {
    onClick,
    label,
    isChecked,
    name,
    width,
    widthIcon,
    marginRightIcon,
    textFontSize,
  } = props;

  return (
    <LabelWrapper
      onClick={onClick}
      data-testid={`checkbox-${name}`}
      data-checked={isChecked ? 'active' : 'disabled'}
    >
      <CheckBox
        isChecked={isChecked}
        width={width}
        marginRightIcon={marginRightIcon}
      >
        <CheckBoxContent>
          <Icon icon="check" color="white" width={widthIcon || '13px'} />
        </CheckBoxContent>
      </CheckBox>
      <Text color="grayMedium" fontSize={textFontSize || 16}>
        {label}
      </Text>
    </LabelWrapper>
  );
};

export default Checkbox;
