import React, { memo } from 'react';

import Modal from 'components/Modal';
import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import Button from 'components/Button';

interface IProps {
  isOpen: boolean;
  hadleClose(): void;
}

const ModalFileError: React.FC<IProps> = ({ isOpen, hadleClose }) => (
  <Modal
    isOpen={isOpen}
    onClose={hadleClose}
    minWidth="690px"
    noButtonClose
    data-testid="modal-error-upload"
  >
    <GridFlex alignItems="center" justifyContent="center" direction="column">
      <Text fontSize={24} color="black" data-testid="modal-error-upload-title">
        Invalid file format. System accepts only:
      </Text>
      <br />
      <Text
        fontSize={16}
        color="black"
        fontWeight="medium"
        data-testid="modal-error-upload-subtitle"
      >
        .XLS or .XLSX
      </Text>

      <GridFlex margin="100px 0 0">
        <Button
          width="265px"
          onClick={hadleClose}
          data-testid="modal-error-upload-button"
        >
          Ok
        </Button>
      </GridFlex>
    </GridFlex>
  </Modal>
);

export default memo(ModalFileError);
