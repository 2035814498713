import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import api from 'services/api';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
import { InputFile, Select, SelectItem } from 'components/Fields';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { useDebounce } from 'hooks/useDebounce';

import ModalFileError from './partials/ModalFileError';

import {
  Container,
  FieldContainer,
  ButtonsContainer,
  ContainerSuccess,
  ButtonsWrapp,
  ContainerLoader,
  ContainerLoaderBrands,
} from './styles';

interface IList {
  id: number;
  description: string;
  isActive?: boolean;
}

enum ResponseType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const UploadExcel: React.FC<IProps> = ({ isOpen, onClose }) => {
  const [responseType, setResponseType] = useState<ResponseType | boolean>(
    false,
  );
  const [brand, setBrand] = useState<IList | null>(null);
  const [brands, setBrands] = useState<IList[]>([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const [fileXlxsSelected, setFileXlxsSelected] = useState<File | null>(null);
  const [errorFileModal, setErrorFileModal] = useState(false);

  const autoSave = useDebounce(searchBrand);

  const upload = async () => {
    setLoadingUpload(true);
    try {
      const formData = new FormData();

      formData.append('idBrand', brand?.id.toString() as string);

      formData.append('file', fileXlxsSelected as Blob, fileXlxsSelected?.name);

      await api.post(`/customer-order/import`, formData);

      setLoadingUpload(false);
      setResponseType(ResponseType.SUCCESS);
    } catch (err) {
      setResponseType(ResponseType.ERROR);
      setLoadingUpload(false);
    }
  };

  const closeAndFinish = () => {
    onClose();
  };

  const getBrands = async () => {
    setLoadingBrands(true);

    const list: IList[] = await api.get(`/brands?description=${searchBrand}`);

    setBrands(list);
    setLoadingBrands(false);
  };

  const onFileChange = (e: any) => {
    const selectedFile = e?.target.files[0];

    const contains =
      selectedFile?.name?.includes('.xlsx') ||
      selectedFile?.name?.includes('.xls');

    if (!contains) {
      setErrorFileModal(true);
      return;
    }

    setFileXlxsSelected(selectedFile);
  };

  useEffect(() => {
    setBrand(null);
    setFileXlxsSelected(null);
    setResponseType(false);
  }, [isOpen]);

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  const isCompleted = brand && fileXlxsSelected;

  return (
    <>
      <ModalFileError
        isOpen={errorFileModal}
        hadleClose={() => setErrorFileModal(false)}
      />
      <Modal
        minWidth="1280px"
        minHeight="500px"
        isOpen={!errorFileModal && isOpen}
        onClose={onClose}
        noButtonClose
      >
        <>
          {!loadingUpload ? (
            <Container data-testid="modal-upload-excel">
              {!responseType ? (
                <>
                  <Text fontSize={24} color="black">
                    Upload excel
                  </Text>
                  <FieldContainer>
                    <Grid item md={6}>
                      <InputFile
                        width="451px"
                        name="upload-excel"
                        onChange={onFileChange}
                        value={fileXlxsSelected?.name}
                        placeholder="Upload file"
                        label="Upload excel"
                        accept=".xlsx, .xls"
                        dataTest="upload-file"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Select
                        label="Select brand"
                        value={brand?.description}
                        withInputSearch
                        inputSearchPlaceholder="Search brand"
                        inputSearchOnChange={setSearchBrand}
                        inputSearchvalue={searchBrand}
                        dataTest="brand"
                        width="451px"
                      >
                        {loadingBrands ? (
                          <ContainerLoaderBrands>
                            <Spinner />
                          </ContainerLoaderBrands>
                        ) : (
                          <>
                            {brands.map(brandItem => (
                              <SelectItem
                                key={`brands-${brandItem.id}`}
                                onClick={() => setBrand(brandItem)}
                                isSelected={brandItem.id === brand?.id}
                                data-testid={`select-item-option-brand-${brandItem.id}`}
                              >
                                {brandItem.description}
                              </SelectItem>
                            ))}
                          </>
                        )}
                      </Select>
                    </Grid>
                  </FieldContainer>
                  <ButtonsContainer>
                    <Button
                      onClick={() => isCompleted && upload()}
                      width="266px"
                      data-testid="button-create"
                      scheme={!isCompleted ? 'disabled' : 'default'}
                    >
                      Save
                    </Button>
                    <Button
                      data-testid="button-cancel"
                      width="266px"
                      scheme="nobg"
                      onClick={closeAndFinish}
                    >
                      Cancel
                    </Button>
                  </ButtonsContainer>
                </>
              ) : (
                <>
                  {responseType === ResponseType.ERROR && (
                    <ContainerSuccess>
                      <Icon icon="close-error" color="none" width="68px" />
                      <Text color="black" fontSize={30}>
                        Could not upload the file at the moment
                      </Text>
                      <Text
                        color="black"
                        fontSize={18}
                        fontWeight="normal"
                        margin="15px 0 0"
                      >
                        We&rsquo;re sorry for the inconvenience. Please, try
                        again later
                      </Text>

                      <ButtonsWrapp>
                        <Button width="250px" onClick={() => upload()}>
                          Try again
                        </Button>
                        <Button
                          width="250px"
                          onClick={() => closeAndFinish()}
                          scheme="nobg"
                        >
                          Back
                        </Button>
                      </ButtonsWrapp>
                    </ContainerSuccess>
                  )}

                  {responseType === ResponseType.SUCCESS && (
                    <ContainerSuccess>
                      <Icon icon="waiting-circle" color="none" width="68px" />
                      <Text
                        fontSize={24}
                        fontWeight="normal-bold"
                        color="black"
                      >
                        Upload done!
                      </Text>

                      <Text
                        fontSize={18}
                        fontWeight="medium"
                        color="black"
                        margin="15px 0 0"
                      >
                        The file <strong>{fileXlxsSelected?.name}</strong> is
                        still processing and will be ready in a moment.
                      </Text>

                      <Button
                        data-testid="button-ok"
                        width="250px"
                        onClick={() => closeAndFinish()}
                      >
                        Ok
                      </Button>
                    </ContainerSuccess>
                  )}
                </>
              )}
            </Container>
          ) : (
            <ContainerLoader>
              <Text fontSize={24} color="black">
                Uploading file
                <Spinner />
              </Text>
            </ContainerLoader>
          )}
        </>
      </Modal>
    </>
  );
};

export default memo(UploadExcel);
