import styled, { css } from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const MasterImageZoomInSizes = {
  width: 350,
  height: 365,
};

export const Container = styled.div<{ isZoomIn: boolean }>`
  position: relative;
  width: fit-content;
  border-radius: 13px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  width: 261px;

  ${simpleFlex}
  justify-content: center;

  ${({ isZoomIn }) =>
    isZoomIn &&
    css`
      width: 652px;
      height: 365px;
    `}

  > div:first-child {
    position: relative;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 13px;
  transition: all 0.5s;
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.2);

  svg {
    margin-right: 12px;
  }

  button:last-child {
    margin-top: 15px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const ImagePrototype = styled.img<{ isZoomIn: boolean }>`
  max-height: 146px;
  max-width: 261px;

  ${({ isZoomIn }) =>
    isZoomIn &&
    css`
      max-width: 652px;
      max-height: 365px;
    `}
`;
