import React, { useState } from 'react';

import { MAP_STEPS } from 'containers/dashboard/constants';
import { useCustomerOrderHistory } from 'containers/customer-order-history';
import { useDashboard } from 'containers/dashboard';
import { LoadingEnum } from 'containers/customer-order-history/dtos';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';

import UploadExcel from '../UploadExcel';

import { Container, BackButton, Actions } from './styles';

const Header: React.FC = () => {
  const [uploadExcelIsOpen, setUploadExcelIsOpen] = useState(false);
  const {
    actions: { changeStep },
  } = useDashboard();

  const { data, actions } = useCustomerOrderHistory();

  const { loading } = data;

  const isLoading = loading === LoadingEnum.DEFAULT;

  return (
    <Container>
      <UploadExcel
        isOpen={uploadExcelIsOpen}
        onClose={() => setUploadExcelIsOpen(false)}
      />

      <BackButton onClick={() => changeStep(MAP_STEPS.COSTUME_ORDER)}>
        <Button padding="14px 0px" width="39px" scheme="defaultWithoutShadow">
          <Icon icon="arrow-left" width="12px" />
        </Button>

        <Text color="grayMedium" fontSize={18} margin="0 0 0 18px">
          Go back to Dashboard
        </Text>
      </BackButton>

      <Actions>
        <Button
          padding="12px 0px"
          width="55px"
          onClick={isLoading ? undefined : actions.listCustomerOrderHistory}
          data-testid="refresh-button"
          scheme={isLoading ? 'disabled' : 'default'}
        >
          <Icon icon="refresh" width="28px" />
        </Button>

        <Button
          width="266px"
          padding="15.5px 20px"
          onClick={() => setUploadExcelIsOpen(true)}
          data-testid="open-upload-excel"
        >
          <Icon icon="import" marginRight={10} width="19px" />
          New upload
        </Button>
      </Actions>
    </Container>
  );
};

export default Header;
