/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import history from 'routes/history';

import GridFlex from 'components/GridFlex';
import Button from 'components/Button';
import Text from 'components/Text';
import ModalCreateMaterial from 'components/ModalCreateMaterial';
import ModalCreateColor from 'components/ModalCreateColor';
import ModalCreateBrand from 'components/ModalCreateBrand';
import ModalCreateSeason from 'components/ModalCreateSeason';
import ModalCreateFactory from 'components/ModalCreateFactory';

import { Container, Item } from './styles';

enum TModalCreate {
  INITIAL,
  CREATE_COLOR,
  CREATE_MATERIAL,
  CREATE_BRAND,
  CREATE_FACTORY,
  CREATE_SEASON,
}

const list = [
  {
    label: 'Brand',
    disabled: false,
    path: 'brand',
    createType: TModalCreate.CREATE_BRAND,
  },
  {
    label: 'Color',
    disabled: false,
    path: 'color',
    createType: TModalCreate.CREATE_COLOR,
  },
  { label: 'Country', disabled: true },
  {
    label: 'Factory',
    disabled: false,
    path: 'factory',
    createType: TModalCreate.CREATE_FACTORY,
  },
  {
    label: 'Material',
    disabled: false,
    path: 'material',
    createType: TModalCreate.CREATE_MATERIAL,
  },
  {
    label: 'Season',
    path: 'season',
    disabled: false,
    createType: TModalCreate.CREATE_SEASON,
  },
  { label: 'Style group', disabled: true },
  { label: 'Style type', disabled: true },
];

const List: React.FC = () => {
  const [modalCreate, setModalCreate] = useState<TModalCreate>(
    TModalCreate.INITIAL,
  );

  const closeModalCreate = () => {
    setModalCreate(TModalCreate.INITIAL);
  };

  const openModalCreate = (type: TModalCreate | undefined) => {
    if (type) setModalCreate(type);
  };

  return (
    <Container>
      {list.map(({ label, disabled, path, createType }) => (
        <Item width="100%" height="85px" key={label} isDisable={disabled}>
          <GridFlex
            justifyContent="space-between"
            alignItems="center"
            padding="0 40px 0"
            height="100%"
          >
            <Text fontSize={16} color="black" fontWeight="normal-bold">
              {label}
            </Text>

            <GridFlex flex="initial" alignItems="center">
              <Button
                margin="0 15px 0 0"
                scheme="nobg"
                width="125px"
                padding="7.5px 0px"
                fontSize="13px"
                data-testid={`data-management-${path}`}
                onClick={() =>
                  !disabled && history.push(`/data-management/${path}`)
                }
              >
                View
              </Button>

              <Button
                width="125px"
                padding="7.5px 0px"
                fontSize="13px"
                onClick={() => openModalCreate(createType)}
              >
                New
              </Button>
            </GridFlex>
          </GridFlex>
        </Item>
      ))}
      <ModalCreateColor
        isOpen={modalCreate === TModalCreate.CREATE_COLOR}
        onClose={closeModalCreate}
        onSaved={() => {}}
      />
      <ModalCreateMaterial
        isOpen={modalCreate === TModalCreate.CREATE_MATERIAL}
        onClose={closeModalCreate}
        onSaved={() => {}}
      />
      <ModalCreateBrand
        isOpen={modalCreate === TModalCreate.CREATE_BRAND}
        onClose={closeModalCreate}
        onSaved={() => {}}
      />
      <ModalCreateFactory
        isOpen={modalCreate === TModalCreate.CREATE_FACTORY}
        onClose={closeModalCreate}
        onSaved={() => {}}
      />
      <ModalCreateSeason
        isOpen={modalCreate === TModalCreate.CREATE_SEASON}
        onClose={closeModalCreate}
        onSaved={() => {}}
      />
    </Container>
  );
};

export default List;
