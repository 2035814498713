import React from 'react';

import Text from 'components/Text';
import GridFlex from 'components/GridFlex';

import { ISkuComponent } from '../../types';

import Row from '../Row';

import { Container } from './styles';
import { SimpleSpace } from '../../styles';

interface IProps {
  items: ISkuComponent[];
}

const Content: React.FC<IProps> = ({ items }) => {
  return (
    <Container>
      <GridFlex justifyContent="space-between" margin="0 0 15px">
        <Text
          fontWeight="normal-bold"
          fontSize={18}
          color="black"
          data-testid="component-label"
        >
          Component
        </Text>

        <SimpleSpace />

        <Text
          fontWeight="normal-bold"
          fontSize={18}
          color="black"
          data-testid="attribute-label"
        >
          Attribute
        </Text>

        <SimpleSpace />

        <Text
          fontWeight="normal-bold"
          fontSize={18}
          color="black"
          data-testid="description-label"
        >
          Description
        </Text>
      </GridFlex>

      {items.map(item => (
        <Row item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Content;
