/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useCallback, useContext, memo } from 'react';

import initialActions from './actions';

import { IState, IActions } from './dtos';

import { initialState } from './constants';

interface IData {
  data: IState;
  actions: IActions;
}

export const CustomerOrderHistoryContext = React.createContext<IData>({
  data: initialState,
  actions: initialActions({ data: initialState, setData: () => {} }),
});

export const useCustomerOrderHistory = (): IData =>
  useContext(CustomerOrderHistoryContext);

export default function withCustomerOrderHistoryProvider(
  WrappedComponent: React.FC,
  state: IState = initialState,
  actions = initialActions,
): React.FC {
  const WithCustomerOrderHistory = (props: any) => {
    const [data, setData] = useState(state);

    const value = useCallback(
      () => ({
        data,
        actions: actions({ data, setData }),
      }),
      [data],
    );

    const dataValue: IData = value();

    return (
      <CustomerOrderHistoryContext.Provider value={dataValue}>
        <WrappedComponent {...props} />
      </CustomerOrderHistoryContext.Provider>
    );
  };

  return memo(WithCustomerOrderHistory);
}
