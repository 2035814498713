import styled, { css } from 'styled-components';

import { takeColor } from 'theme/tools';

import { Container as ContainerBox } from 'components/Box/styles';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
`;

export const BoxSuggest = styled(ContainerBox)<{ isOpen?: boolean }>`
  top: 95px;
  left: 0;
  position: absolute;
  width: 100%;
  max-width: 450px;
  height: fit-content;
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition: 0.2s all;
  opacity: 0;
  visibility: hidden;
  cursor: initial;
  background-color: ${takeColor('white')};
  max-height: 235px;
  z-index: 9;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      top: 105px;
    `}

  > p:first-child {
    margin-bottom: 14px;
  }

  > p {
    margin-bottom: 9px;
    display: flex;

    > div {
      margin-left: 6px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
