import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

import bAreaBg from './barea.png';

export const Container = styled.div`
  background: url(${bAreaBg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: -30px 0px 0px 0px #3a3a3a;
  flex: 1;
  padding: 0 70px;
  padding-bottom: 50px;
`;

export const Header = styled.div`
  margin-top: 60px;
  margin-bottom: 35px;
`;

export const BoxesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px 40px;
  justify-content: space-between;
`;

export const BoxWrapper = styled.div<{ disabled?: boolean }>`
  background-color: ${takeColor('black')};
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  ${simpleFlex}
  justify-content: center;

  > div {
    min-height: 190px;
    ${simpleFlex}
    justify-content: center;
    flex-direction: column;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      > div {
        background-color: ${takeColor('black')};
        opacity: 0.6;
      }
    `}
`;

export const RoundedIcon = styled.div`
  background-color: ${takeColor('white')};
  padding: 18px 19px;
  border-radius: 100%;
  margin-bottom: 15px;
`;
