import React, { useEffect, useState } from 'react';

import api from 'services/api';
import { useSpecSheet } from 'containers/spec-sheet';
import searchTextHook, { IItems } from 'helpers/searchTextHook';

import { Select, SelectItem } from 'components/Fields';
import { IData } from 'containers/spec-sheet/dtos';
import Loading from '../partials/Loading';

const Season: React.FC = () => {
  const [seasons, setSeasons] = useState<IData['season'][]>([]);
  const [loading, setLoading] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: seasons as IItems[],
    },
  );

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const seasonSelected = data?.season;

  const value = seasonSelected
    ? `${seasonSelected.description} ${seasonSelected.year || ''}`
    : '';

  const getSeasons = async () => {
    setLoading(true);

    try {
      const list: IData['season'][] = await api.get('/seasons?isActive=1');

      setSeasons(list);
      setFilteredItems(list as IItems[]);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newSeason: IData['season']) => {
    if (seasonSelected?.id !== newSeason?.id) {
      setSearch('');
      actions.changeSpecSheetData({ season: newSeason });
    }
  };

  return (
    <Select
      label="Season"
      value={value}
      withInputSearch
      inputSearchPlaceholder="Search season"
      inputSearchOnChange={setSearch}
      inputSearchvalue={search}
      dataTest="season"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {filteredItems.map(season => (
            <SelectItem
              key={`season-${season.id}`}
              onClick={() => handleChange(season as IData['season'])}
              isSelected={season.id === seasonSelected?.id}
              data-test={`select-item-option-season-${season.id}`}
            >
              {season.description} {season.year || ''}
            </SelectItem>
          ))}
        </>
      )}
    </Select>
  );
};

export default Season;
