/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import { Input } from 'components/Fields';

import { dateDefault } from 'helpers/date';

const Date: React.FC = () => {
  const {
    data: { data },
  } = useSpecSheet();

  const createdAt = dateDefault(data?.createdAt);

  return (
    <Input
      label="Date"
      value={createdAt}
      name="date"
      onChange={() => {}}
      disabled
      isCompleted
      dataTest="date"
    />
  );
};

export default Date;
