import React from 'react';

import { useNav } from 'containers/nav';
import Perfil from './Perfil';
import Menu from './Menu';
import LittleMenu from './LittleMenu';

import { Container } from './styles';

const Logged: React.FC = () => {
  const {
    data: { openMenu },
  } = useNav();

  return (
    <Container>
      {openMenu && <Perfil />}
      <Menu />
      <LittleMenu />
    </Container>
  );
};

export default Logged;
