import React from 'react';

import { ReactComponent as Loading } from './loading.svg';

import { Container } from './styles';

enum SPINNER_MAP_SIZE {
  'very-small' = '16px',
  'small' = '24px',
  'medium' = '41px',
  'big' = '74px',
}

export interface IProps {
  size?: keyof typeof SPINNER_MAP_SIZE;
}

const Spinner: React.FC<IProps> = ({ size = 'medium' }) => (
  <Container size={SPINNER_MAP_SIZE[size]} data-testid="spinner">
    <Loading width={SPINNER_MAP_SIZE[size]} />
  </Container>
);

export default Spinner;
