import React from 'react';

import Button from 'components/Button';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';
import captalize from 'helpers/captalize';

import { Container, SortByWrapp, FiltersWrapp } from './styles';

const HeaderInfos: React.FC = () => {
  const {
    data: { filters, sortBy },
  } = useSpecSheetSearch();

  const getKey = key => {
    const adjustKeys = {
      Styletype: 'Style type',
      Sampletype: 'Sample type',
    };

    return adjustKeys[key] || key;
  };

  return (
    <Container>
      <FiltersWrapp>
        {Object.keys(filters).map(item => (
          <>
            {filters[item].length > 0 && (
              <Button padding="6px 12px">
                <strong>{getKey(captalize(item))}: </strong>
                {filters[item].map((i, index) => (
                  <span key={`${i.name}`}>
                    {`  ${i.description || i.name}${
                      filters[item].length - 1 !== index ? ', ' : ''
                    }`}
                  </span>
                ))}
              </Button>
            )}
          </>
        ))}
      </FiltersWrapp>
      <SortByWrapp>
        <strong>Sorted by: </strong>
        {sortBy.filter(Boolean).join(' / ')}
      </SortByWrapp>
    </Container>
  );
};

export default HeaderInfos;
