import React from 'react';

import GridFlex from 'components/GridFlex';
import { Table, TableColumn, TableRow } from 'components/Table';
import { useSpecSheetModal } from 'containers/spec-sheet-modal';

const TableSkuComponents: React.FC = () => {
  const {
    data: { skuComponents },
  } = useSpecSheetModal();

  return (
    <GridFlex direction="column" margin="0 14px 0 0" flex={1}>
      <Table width="100%">
        <TableRow isLabel minHeight="17px">
          <TableColumn>Component</TableColumn>
          <TableColumn>Attribute</TableColumn>
          <TableColumn>Description</TableColumn>
        </TableRow>

        {skuComponents?.map(({ attribute, component, description, id }) => (
          <TableRow key={`sku-components-${id}`} minHeight="17px">
            <TableColumn>{component}</TableColumn>
            <TableColumn>{attribute}</TableColumn>
            <TableColumn>{description}</TableColumn>
          </TableRow>
        ))}
      </Table>
    </GridFlex>
  );
};

export default TableSkuComponents;
