import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';
import searchTextHook from 'helpers/searchTextHook';

import { SelectItem, SelectInput } from 'components/Fields';
import Loading from '../partials/Loading';

interface IGroup {
  id: number;
  description: string;
}

const Group: React.FC = () => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasClickOut, setHasClickOut] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: groups as any,
    },
  );

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const groupSelected = data?.group;

  const setSelectedItem = () => {
    if (groupSelected) setSearch(groupSelected.description);
  };

  const getGroups = async () => {
    setLoading(true);

    try {
      const list: IGroup[] = await api.get('/groups');

      setGroups(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const handleChange = useCallback(
    (newGroup: any) => {
      if (groupSelected?.id !== newGroup?.id) {
        actions.changeSpecSheetData({ group: newGroup });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, groups],
  );

  const findGroup = useCallback(
    (search: string) =>
      groups?.find(({ description }) => description === search),
    [groups],
  );

  const createGroup = useCallback(
    async (description: string) => {
      setLoading(true);
      const newGroup: IGroup = await api.post('/groups?isActive=1', {
        description,
      });

      const newGroupList = [...groups, newGroup];
      setGroups(newGroupList);
      setLoading(false);

      return newGroup;
    },
    [groups],
  );

  const handleChangeInput = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSubmit = useCallback(
    async (event?: any) => {
      if (event) event.preventDefault();

      if (!search?.trim()) return;

      const groupExists: any = findGroup(search);

      if (!groupExists) {
        const { id, description }: IGroup = await createGroup(search);
        handleChange({ id, description });
      } else {
        handleChange(groupExists);
      }
    },
    [createGroup, findGroup, handleChange, search],
  );

  const clickOutAction = useCallback(async () => {
    setHasClickOut(false);
    await handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredItems(groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (hasClickOut) clickOutAction();
  }, [hasClickOut, clickOutAction]);

  useEffect(() => {
    setSelectedItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupSelected]);

  return (
    <form onSubmit={handleSubmit}>
      <SelectInput
        name="group"
        value={search}
        onChange={handleChangeInput}
        label="Group"
        dataTest="group"
        callBackClickout={() => setHasClickOut(true)}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            {filteredItems.map(({ id, description }) => (
              <SelectItem
                key={`groups-${id}`}
                onClick={() => handleChange({ id, description })}
                isSelected={id === groupSelected?.id}
                data-test={`select-item-option-group-${id}`}
              >
                {description}
              </SelectItem>
            ))}
          </>
        )}
      </SelectInput>
    </form>
  );
};

export default Group;
