import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { useSpecSheets } from 'containers/spec-sheets';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Notes, { Type as TypeNote } from 'components/Notes';
import {
  OutsoleDesignNo,
  SockDesignNo,
  Construction,
  Last,
  OriginalBaseRef,
  StyleGroup,
  StyleType,
  Category,
  Subcategory,
} from './Fields';

import {
  Container,
  ContentFields,
  ContentActions,
  ContentButtonAddNotes,
} from './styles';

const Prototype: React.FC = () => {
  const {
    actions: { changeStep },
    data: { stepActive },
  } = useSpecSheets();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const goBack = () => {
    changeStep(stepActive - 1);
  };

  const goToSku = () => {
    changeStep(stepActive + 1);
  };

  return (
    <Container>
      <Notes
        open={open}
        onClose={() => handleOpen()}
        title="Add notes"
        type={TypeNote.prototype}
      />

      <ContentFields>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <OutsoleDesignNo />
          </Grid>
          <Grid item xs={12} md={6}>
            <SockDesignNo />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Construction />
          </Grid>
          <Grid item xs={12} md={6}>
            <Last />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <OriginalBaseRef />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyleGroup />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Category />
          </Grid>
          <Grid item xs={12} md={6}>
            <Subcategory />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <StyleType />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentButtonAddNotes>
              <Button
                onClick={() => handleOpen()}
                width="100%"
                scheme="gray"
                data-test="button-add-notes"
                data-testid="button-add-notes"
              >
                <Text fontSize={15} fontWeight="normal-bold" color="white">
                  Add notes
                </Text>
              </Button>
            </ContentButtonAddNotes>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={12}>
            <ContentActions justifyContent="center">
              <Button
                scheme="nobg"
                onClick={goBack}
                width="265px"
                data-testid="button-go-back"
              >
                <Icon
                  icon="arrow-left"
                  width="15px"
                  color="black"
                  marginRight={15}
                />
                Go back
              </Button>

              <Button
                onClick={goToSku}
                width="265px"
                data-testid="button-go-sku-components"
              >
                Go to Sku components
                <Icon
                  icon="arrow-right"
                  width="15px"
                  color="white"
                  marginLeft={15}
                />
              </Button>
            </ContentActions>
          </Grid>
        </Grid>
      </ContentFields>
    </Container>
  );
};

export default Prototype;
