/* eslint-disable no-console */
import api from 'services/api';

import { setSession, removeSession, isAuthenticated } from 'helpers/auth';
import factoryGetError from 'helpers/factoryGetError';
import { setItem, removeItem } from 'helpers/storage';

import history from 'routes/history';

import { IActions, IState, IMakeLoginUser, IUser } from './dtos';
import { MAP_STATUS_ERROR, initialState } from './constants';

interface IParams {
  data: IState;
  setData(data: IState): void;
  resetDashboardData: () => void;
}

const getError = factoryGetError(MAP_STATUS_ERROR, MAP_STATUS_ERROR[400]);

interface IUserResponse extends IUser {
  permissions: string[];
}

interface IMakeLoginResponse {
  access_token: string;
  refresh_token: string;
  user: IUserResponse;
}

export default (params: IParams): IActions => {
  const { setData, data, resetDashboardData } = params;

  async function makeLogin(dataBody: IMakeLoginUser, remember: boolean) {
    setData({ ...data, loading: true });

    try {
      const { access_token, refresh_token, user }: IMakeLoginResponse =
        await api.post('/auth/login', dataBody);

      history.push('/');
      setSession(access_token, refresh_token);

      const { permissions, ...detail } = user;

      setData({
        ...initialState,
        detail,
        permissions,
        signedIn: true,
      });
      resetDashboardData();
      if (remember) {
        setItem('@loginRemember', user.login);
      } else {
        removeItem('@loginRemember');
      }
    } catch (err: any) {
      if (err.status === 400) {
        setData({
          ...data,
          error: null,
          differentError: err?.data?.message,
          loading: false,
          signedIn: false,
        });
      } else {
        const error = err?.data?.message || getError(err.status);
        setData({ ...data, differentError: null, error, loading: false });
      }
    }
  }

  function makeLogout() {
    setData({ ...initialState });
    removeSession();
    history.push('/login');
  }

  async function verifySession() {
    const signedIn = !!isAuthenticated();
    setData({ ...data, loading: true, signedIn });

    try {
      const user: IUserResponse = await api.get('/auth/profile');

      const { permissions, ...detail } = user;

      setData({ ...initialState, detail, permissions, signedIn: true });
    } catch (err: any) {
      const error: string = err?.data?.message || getError(err?.status);
      setData({ ...data, error, loading: false, signedIn: false });
    }
  }

  function clearErrors() {
    setData({ ...data, error: null, differentError: null });
  }

  return {
    makeLogin,
    makeLogout,
    verifySession,
    clearErrors,
  };
};
