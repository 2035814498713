import styled from 'styled-components';

import { StatusEnum } from 'containers/customer-order-history/dtos';

import { simpleFlex, takeColor } from 'theme/tools';

import Box from 'components/Box';

export const Container = styled.div`
  margin: 0 0 40px;
`;

export const ListContainer = styled.div`
  margin: 80px 0 90px;
`;

export const WrapperItem = styled(Box)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns:
    minmax(234px, 2fr) minmax(124px, 0.75fr) minmax(86px, 0.5fr)
    minmax(148px, 1fr) minmax(87px, 1fr) minmax(auto, 1fr);
  align-items: center;
  height: 108px;

  width: 100%;
  padding: 24px;

  & + & {
    margin-top: 30px;
  }

  & > button {
    max-width: 123px;
    margin-left: auto;
  }
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > p {
    &:first-child {
      margin-bottom: 5px;
    }
  }
`;

export const LoadingWrapper = styled.div`
  ${simpleFlex};
  justify-content: center;
  height: 60vh;
`;

interface IStatusWrapper {
  status: StatusEnum;
}

const MAP_STATUS_COLOR: any = {
  [StatusEnum.SUCCESS]: 'success',
  [StatusEnum.ERROR]: 'error',
  [StatusEnum.PROCESSING]: 'processing',
};

export const StatusWrapper = styled.div<IStatusWrapper>`
  ${simpleFlex};

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ status }) => takeColor(MAP_STATUS_COLOR[status])};
    margin-right: 7px;
  }
`;

export const ReasonWrapper = styled.div`
  ${simpleFlex};
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

export const ButtonsModal = styled.div`
  ${simpleFlex};

  margin-top: 64px;

  button:first-child {
    margin-right: 40px;
  }
`;

export const ReasonTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0px 65px;
`;

export const ReasonText = styled.div`
  white-space: break-spaces;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 16px 0 0;
`;
