import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { mixinRespondTo, simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px 10px;
  justify-content: space-between;

  ${mixinRespondTo.xs`
    grid-template-columns: repeat(2,165px);
    gap: 30px 10px;
 `}

  ${mixinRespondTo.md`
    grid-template-columns: repeat(3, 165px);
 `}

  ${mixinRespondTo.lg`
    grid-template-columns: repeat(5, 165px);
    gap: 45px 45px;
 `}

 ${mixinRespondTo.xg`
    grid-template-columns: repeat(6, 165px);
  `}
`;

const itemStyle = css`
  ${simpleFlex};
  flex-direction: column;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    p,
    svg {
      opacity: 0.8;
    }
  }
`;

export const ListItem = styled.div`
  ${itemStyle};
`;

export const ListItemLink = styled(Link)`
  ${itemStyle};
`;

export const WrapperText = styled.div`
  text-align: center;
  margin-top: 15px;
  position: relative;

  &:hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const WrapperPlus = styled.div`
  ${simpleFlex};
  border-radius: 13px;
  justify-content: center;
  background-color: ${takeColor('black')};
  width: 100%;
  height: 100%;

  .has-border {
    border: 3px solid ${takeColor('white')};
    padding: 15px;
    border-radius: 50%;
    height: fit-content;
  }
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
`;

export const ContainerLoadMore = styled.div`
  margin: 40px 0px;
`;
