export interface IActions {
  setSpecSheet(data: IData): void;
  changeSpecSheetData(data: IData): Promise<void>;
  getFactories(data: any): void;
  uploadMasterImage(id: any, file: any, withPoints?: boolean): void;
  updateMasterImage(id: any, file: any, withPoints?: boolean): void;
  getDetails(id: number): Promise<void>;
  getImage(): Promise<void>;
  getImageDetails(id: number): Promise<void>;
  deleteImagePoint(id: number): Promise<void>;
  getStyleGroups(): Promise<void>;
  getStyleTypes(): Promise<void>;
  updateSkuComponent(body: IUpdateSkuComponent): Promise<void>;
  addCategory(idCategory: number): void;
  removeCategory(idCategory: number): void;
  addSubCategory(idSubCategory: number): void;
  removeSubCategory(idSubCategory: number): void;
  getNote(id: any): Promise<void>;
  handleNote(id: any, description: any, type: any): Promise<void>;
  setLoading(loading?: LoadingEnum): void;
  removeLoading(): void;
  deleteSpecSheet(id: number, data?: IData): void;
}

type IdType = number | null;

export interface IData {
  id?: number;
  refNo?: number;
  description?: string | null;
  note?: string | null;
  sampleType?: {
    id: number;
    description: string;
  };
  season?: {
    id: number;
    description: string;
    year: number;
  };
  country?: {
    id: number;
    description: string;
  };
  group?: {
    id: number;
    description: string;
    isActive: boolean;
  };
  factory?: {
    id: number;
    description: string;
  } | null;
  brand?: {
    id: number;
    description: string;
  };
  styleType?: {
    id: number;
    description: string;
    isActive: boolean;
  };
  styleGroup?: {
    id: number;
    description: string;
    isActive: boolean;
  };
  status?: {
    id: number;
    description: string;
    key: string;
  };
  createdAt?: Date;
  outsoleDesignNo?: string | null;
  sockDesignNo?: string | null;
  construction?: string | null;
  last?: string | null;
  originalBaseRef?: string | null;
  categories?: number[];
  subCategories?: number[];
}

export interface IParamsSpec {
  limit: number;
  page: number;
}

export interface IMasterImage {
  url: string;
  isMandatory: boolean;
  idSpecSheet: number;
  id: any;
}

export interface ISkuDetail {
  id?: number;
  idSpecSheet?: number;
  variable?: string | null;
  hardware?: string | null;
  pair?: string | null;
  size?: string | null;
  width?: string | null;
  insoleBinding?: string | null;
}

export enum LoadingEnum {
  DEFAULT = 'default',
  SAVING = 'saving',
  IMAGE = 'image',
  DUPLICATE_SKU_DETAILS = 'DUPLICATE_SKU_DETAILS',
  DELETE_SKU_DETAILS = 'DELETE_SKU_DETAILS',
  DELETE_SPEC_SHEET = 'DELETE_SPEC_SHEET',
}

export interface IState {
  data: IData | null;
  stepActive: number;
  stepsCompleted: number[];
  loading: LoadingEnum | null;
  masterImage: IMasterImage | null;
  masterImageWithPoints: IMasterImage | null;
  factories: any[] | null;
  imageDetails: any[] | null;
  styleGroups: any[] | null;
  styleTypes: any[] | null;
  errorSpecSheet?: boolean;
}

export interface IUpdateSkuComponent {
  id: number;
  description: string;
  attribute: string;
}
