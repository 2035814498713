import React, { useState, ChangeEvent, useEffect } from 'react';

import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';
import { useSpecSheet } from 'containers/spec-sheet';

import { useDebounce } from 'hooks/useDebounce';

import { Input } from 'components/Fields';

interface IProps {
  name: string;
  label: string;
}

const Field: React.FC<IProps> = ({ name, label }) => {
  const {
    data: { skuDetails, skuDetailActive },
    actions,
  } = useSpecSheetSkuDetail();
  const { actions: actionsSpecSheet } = useSpecSheet();

  const skuDetail = skuDetails[skuDetailActive];

  const description = skuDetail[name] || '';

  const oldValue = description;

  const [value, setValue] = useState(description);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  const saveChange = async () => {
    if (oldValue !== value) {
      actionsSpecSheet.setLoading();
      await actions.updateSkuDetail({ [name]: value.trim() });
      actionsSpecSheet.removeLoading();
    }
  };

  const autoSave = useDebounce(value);

  useEffect(() => {
    if (description !== value) saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  useEffect(() => {
    const description = skuDetail[name] || '';
    setValue(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuDetail]);

  const isCompleted = !!value;

  return (
    <Input
      label={label}
      name={name}
      placeholder="Click to add"
      value={value}
      onChange={handleChange}
      dataTest={name}
      isCompleted={isCompleted}
      handleBlur={saveChange}
    />
  );
};

export default Field;
