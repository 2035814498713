import { IPaginationResponse } from 'components/Pagination/dtos';

export interface IActions {
  listCustomerOrderHistory(): Promise<void>;
  setPage(page: number): void;
  downloadSpreadsheet(customerOrderHistoryId: number): Promise<void>;
}

export enum StatusEnum {
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface ICustomerOrderHistoryData {
  id: number;
  name: string;
  url: string;
  status: StatusEnum;
  createdAt: string;
  userName: string;
  reason: string;
  brand: string;
}

export enum LoadingEnum {
  DEFAULT = 'default',
}

interface IPagination {
  page: number;
  pages: number;
}

export interface IState {
  list: ICustomerOrderHistoryData[];
  loading: LoadingEnum | null;
  pagination: IPagination | null;
}

export type TResponselistCustomerOrderHistory =
  IPaginationResponse<ICustomerOrderHistoryData>;
