import { IState } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams) => {
  const { setData, data: state } = params;

  function openNav(isOpen: boolean) {
    setData({ ...state, openMenu: isOpen });
  }

  return {
    openNav,
  };
};
