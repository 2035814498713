/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';

function outsideClick({ ref, callback }: any) {
  async function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      await callback();
      removeEventClickOut();
    }
  }

  function addEventClickOut() {
    document.addEventListener('mousedown', handleClickOutside);
  }

  function removeEventClickOut() {
    document.removeEventListener('mousedown', handleClickOutside);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    return () => {
      removeEventClickOut();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { addEventClickOut, removeEventClickOut };
}

export default outsideClick;
