/* eslint-disable func-names */
export default (string: string) =>
  string[0]?.toUpperCase() + string.slice(1).toLowerCase();

export const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
