import React from 'react';
import history from 'routes/history';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { getPermissions } from './boxes';

import {
  Container,
  Header,
  BoxesContainer,
  BoxWrapper,
  RoundedIcon,
} from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Header>
        <Text margin="3px 0px" fontSize={30} color="white">
          Business areas
        </Text>
        <Text fontSize={18} color="grayChangeText">
          Below you can see all the areas from the system
        </Text>
      </Header>
      <BoxesContainer>
        {getPermissions().map(({ dataTest, icon, link, text, disabled }) => (
          <BoxWrapper
            key={text}
            onClick={() => history.push(link)}
            data-testid={`${dataTest}-button`}
            disabled={disabled}
          >
            <div>
              <RoundedIcon>
                <Icon icon={icon} color="black" />
              </RoundedIcon>
              <Text fontSize={18} color="white">
                {text}
              </Text>
            </div>
          </BoxWrapper>
        ))}
      </BoxesContainer>
    </Container>
  );
};

export default Home;
