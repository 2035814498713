import styled from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

export const WrapperSpecSheet = styled.div`
  position: relative;
  ${simpleFlex};
  justify-content: center;
  height: 100%;
  border-radius: 13px;
  overflow: hidden;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const WrapperSpecSheetOptions = styled.div`
  position: absolute;
  top: 17px;
  right: 19px;
  width: 30px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const ToolTip = styled.span`
  background-color: ${takeColor('black')};
  padding: 7px 13px;
  border-radius: 6px;
  font-size: 15px;
  color: ${takeColor('white')};
  font-weight: 600;
  left: 50%;
  bottom: -6px;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: visibility 0s, opacity 0.2s linear;
  opacity: 0;
  width: max-content;
  visibility: hidden;
  z-index: 9;
`;

export const BoxIcon = styled.div``;
