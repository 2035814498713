import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

import Box from 'components/Box';

export const Container = styled.div`
  margin-top: 50px;
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapList = styled.div`
  margin-top: 45px;
`;

export const Item = styled(Box)`
  ${simpleFlex}
  padding: 30px 40px;
  margin-bottom: 30px;

  > div:first-child {
    flex: 3;
  }

  > div {
    flex: 1;
  }

  > div p:first-child {
    font-size: 12px;
    color: ${takeColor('grayMedium')};
    margin-bottom: 4px;
  }

  > div p:last-child {
    min-height: 19px;

    > div {
      margin-right: 7px;
    }
  }

  > button {
    font-size: 13.25px;
  }
`;
