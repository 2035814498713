import React from 'react';

import { useDashboard } from 'containers/dashboard';

import { SmallSelect, Checkbox } from 'components/Fields';
import Button from 'components/Button';

import { WrapperSelect, WrappCheckbox, WrapperButtonSelect } from '../styles';

const filterType = 'customer';

const list = [`Dillard's`];

const Customer: React.FC = () => {
  const {
    data: {
      filters: { customer },
    },
    actions: { addToNormalFilters, resetNormalFilter },
  } = useDashboard();

  return (
    <WrapperSelect>
      <SmallSelect
        dataTest="select-test"
        placeholder="Customer"
        maxHeight="270px"
        numberSelected={customer ? 1 : null}
        auxiliaryButton={
          <WrapperButtonSelect>
            <Button
              data-testid="reset-filter"
              scheme="nobg"
              onClick={() => resetNormalFilter(filterType)}
              padding="9px 20px"
            >
              Reset filters
            </Button>
          </WrapperButtonSelect>
        }
      >
        <>
          <WrappCheckbox>
            {list.map((item, idx) => (
              <Checkbox
                key={`${filterType}-${item}-${idx as number}`}
                isChecked={customer === item}
                label={item}
                name={item}
                onClick={() => addToNormalFilters(filterType, item)}
                width="11px"
                widthIcon="5px"
                marginRightIcon="7px"
                textFontSize={11}
              />
            ))}
          </WrappCheckbox>
        </>
      </SmallSelect>
    </WrapperSelect>
  );
};

export default Customer;
