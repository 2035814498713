import iconsName from 'components/Icon/icons';
import { Roles } from 'containers/user/dtos';
import { getRole } from 'helpers/verifyRoles';

export interface Box {
  dataTest: string;
  icon: keyof typeof iconsName;
  text: string;
  link: string;
  disabled?: boolean;
}

export const specBox: Box = {
  dataTest: 'spec-sheet',
  icon: 'spec-sheet',
  link: '/spec-sheet',
  text: 'Spec sheet',
};

export const dashboardBox: Box = {
  dataTest: 'dashboard',
  icon: 'dashboard',
  link: '/dashboard',
  text: 'Order and production',
};

export const priceManagementBox: Box = {
  dataTest: 'price-management',
  icon: 'money',
  link: '',
  text: 'Price management',
  disabled: true,
};

export const financialManagementBox: Box = {
  dataTest: 'financial-management',
  icon: 'money-pc',
  link: '',
  text: 'Financial management',
  disabled: true,
};

export const technicalBox: Box = {
  dataTest: 'technical',
  icon: 'technical',
  link: '',
  text: 'Technical',
  disabled: true,
};

export const qualityControlBox: Box = {
  dataTest: 'quality-control',
  icon: 'check-page',
  link: '',
  text: 'Quality control',
  disabled: true,
};

export const libraryBox: Box = {
  dataTest: 'library',
  icon: 'book',
  link: '',
  text: 'Library',
  disabled: true,
};

export const dataManagementBox: Box = {
  dataTest: 'data-management',
  icon: 'settings',
  link: '/data-management',
  text: 'Data management',
};

export const userManageent: Box = {
  dataTest: 'user-management',
  icon: 'settings',
  link: '/users-management',
  text: 'User management',
};

const allBoxes = [
  specBox,
  dashboardBox,
  priceManagementBox,
  financialManagementBox,
  technicalBox,
  qualityControlBox,
  libraryBox,
  dataManagementBox,
  userManageent,
] as Box[];

export const getPermissions = () =>
  ({
    [Roles.manager]: allBoxes,
    [Roles.designer]: [specBox, libraryBox, technicalBox],
    [Roles.factory]: [specBox],
    [Roles.viewer]: [specBox],
    [Roles['sourcing-and-operations']]: [
      specBox,
      dashboardBox,
      priceManagementBox,
      libraryBox,
    ],
  }[getRole()]);

export default allBoxes;
