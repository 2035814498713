/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

import { Input } from 'components/Fields';
import Icon from 'components/Icon';
import Text from 'components/Text';

import { hasUpperCase, hasSpecialCharacter } from 'helpers/verifyPasswordUtils';

import { ContainerPointer } from '../styles';
import { BoxSuggest, Container } from './styles';

interface IProps {
  userPass: string;
  setUserPass: (value: string) => void;
  onCheckPass: (value: boolean) => void;
  label: string;
  error?: string;
  dataTest?: string;
}

const PasswordFields: React.FC<IProps> = ({
  userPass,
  onCheckPass,
  setUserPass,
  label,
  error,
  dataTest,
}) => {
  const [typeText, setTypeText] = useState(false);
  const [openSuggest, setOpenSuggest] = useState(false);

  const hasUpperCaseString = hasUpperCase(userPass);
  const hasSpecialCharacterString = hasSpecialCharacter(userPass);
  const hasMoreThanSix = userPass.length > 6;

  const isCompleted =
    userPass &&
    hasUpperCaseString &&
    hasSpecialCharacterString &&
    hasMoreThanSix;

  const isSuccessOrErrorIcon = (verify, res) => {
    if (verify) return <></>;
    if (res)
      return (
        <Icon icon="check-circle" width="14px" height="14px" color="none" />
      );

    return <Icon icon="close-error" width="14px" height="14px" />;
  };

  const isSuccessOrErrorColorText = (verify, res) => {
    if (verify) return 'grayMedium';
    if (res) return 'success';

    return 'error';
  };

  const isBold = verify => (verify ? 'medium' : 'normal-bold');

  useEffect(() => {
    onCheckPass(!isCompleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted]);

  return (
    <Container>
      <Input
        dataTest={dataTest}
        data-testid={dataTest}
        name="password"
        type={typeText ? `text` : 'password'}
        onChange={e => setUserPass(e.target.value)}
        handleFocus={() => setOpenSuggest(true)}
        handleBlur={() => setOpenSuggest(false)}
        label={label}
        width="450px"
        value={userPass}
        error={error}
        rightContent={
          userPass && (
            <ContainerPointer
              data-testid="password-eye"
              onClick={() => setTypeText(!typeText)}
            >
              <Icon icon="eye-circle" color="none" width="30px" />
            </ContainerPointer>
          )
        }
      />
      <BoxSuggest isOpen={openSuggest}>
        <Text color="black" fontWeight="normal-bold" fontSize={13}>
          Password must contain the following
        </Text>
        <Text
          color={isSuccessOrErrorColorText(!userPass, hasUpperCaseString)}
          fontWeight={isBold(!userPass)}
          fontSize={13}
        >
          Uppercase letter {isSuccessOrErrorIcon(!userPass, hasUpperCaseString)}
        </Text>
        <Text
          color={isSuccessOrErrorColorText(
            !userPass,
            hasSpecialCharacterString,
          )}
          fontWeight={isBold(!userPass)}
          fontSize={13}
        >
          1 Special character - !*$%^@{' '}
          {isSuccessOrErrorIcon(!userPass, hasSpecialCharacterString)}
        </Text>
        <Text
          color={isSuccessOrErrorColorText(!userPass, hasMoreThanSix)}
          fontWeight={isBold(!userPass)}
          fontSize={13}
        >
          Must be longer than 6 characters{' '}
          {isSuccessOrErrorIcon(!userPass, hasMoreThanSix)}
        </Text>
      </BoxSuggest>
    </Container>
  );
};

export default PasswordFields;
