/* eslint-disable consistent-return */
/* eslint-disable no-console */
import api from 'services/api';

import { IActions, ISkuDetail, IState } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

const specSheetPrefix = '/spec-sheets';

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  const { skuDetails, skuDetailActive, idSpecSheet, skuFieldSuggestions } =
    state;

  async function getSkuDetailsAndCreate(id: number) {
    if (skuDetails.length) return;

    const getSkuDetails: any = async () =>
      api.get(`${specSheetPrefix}/${id}/sku-details`);

    try {
      let items: ISkuDetail[] = [];

      items = await getSkuDetails();

      const suggestions: string[] = await api.get(
        `/spec-sheets-sku-details-field/suggestions`,
      );

      const newData = {
        ...state,
        skuDetails: items,
        skuFieldSuggestions: suggestions,
        idSpecSheet: id,
      };

      if (items.length) {
        setData({ ...newData });
        return;
      }

      await api.post(`${specSheetPrefix}/${id}/sku-details`);

      items = await getSkuDetails();

      setData({ ...newData, skuDetails: items });
    } catch (err) {
      console.log(err);
    }
  }

  function setSkuDetail(skuDetailActive: number) {
    setData({
      ...state,
      skuDetailActive,
    });
  }

  async function updateSkuDetail(data: any) {
    const activeSkuDetail = skuDetails[skuDetailActive];

    try {
      await api.put(`${specSheetPrefix}/${idSpecSheet}/sku-details`, {
        id: activeSkuDetail.id,
        ...data,
      });

      skuDetails[skuDetailActive] = {
        ...activeSkuDetail,
        ...data,
      };

      setData({
        ...state,
        skuDetails,
      });
    } catch (err) {
      console.log('err:', err);

      setData({
        ...state,
      });
    }
  }

  async function duplicateSkuDetail() {
    const activeSkuDetail = skuDetails[skuDetailActive];

    try {
      const newSkuDetail: ISkuDetail = await api.post(
        `${specSheetPrefix}/sku-details/duplicate`,
        {
          idSkuDetail: activeSkuDetail.id,
        },
      );

      skuDetails.push(newSkuDetail);

      setData({
        ...state,
        skuDetails,
        skuDetailActive: skuDetails.length - 1,
      });
    } catch (err) {
      console.log('err:', err);

      setData({
        ...state,
      });
    }
  }

  function addSuggestion(text: string) {
    if (!skuFieldSuggestions.includes(text)) {
      setData({
        ...state,
        skuFieldSuggestions: [...skuFieldSuggestions, text],
      });
    }
  }

  async function deleteVariable() {
    const activeSkuDetail = skuDetails[skuDetailActive];

    try {
      await api.delete(`${specSheetPrefix}/sku-details/delete`, {
        data: {
          idSkuDetail: activeSkuDetail.id,
        },
      });

      skuDetails.splice(skuDetailActive, 1);

      setData({
        ...state,
        skuDetails,
        skuDetailActive:
          skuDetailActive === 0 ? skuDetailActive : skuDetailActive - 1,
      });

      if (skuDetails.length === 0) {
        if (!idSpecSheet) {
          throw new Error('No idSpecSheet');
        }

        await getSkuDetailsAndCreate(idSpecSheet);
      }
    } catch (err) {
      console.log(err);
      setData({
        ...state,
      });
    }
  }

  return {
    getSkuDetailsAndCreate,
    setSkuDetail,
    updateSkuDetail,
    duplicateSkuDetail,
    addSuggestion,
    deleteVariable,
  };
};
