/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';

import { Input } from 'components/Fields';

import { useSpecSheet } from 'containers/spec-sheet';

import { useDebounce } from 'hooks/useDebounce';

import { ISkuComponent } from '../../types';

import { Container, WrapperLabel } from './styles';
import { SimpleSpace } from '../../styles';

interface IProps {
  item: ISkuComponent;
}

const Row: React.FC<IProps> = ({
  item: { component, attribute, description, id },
}) => {
  const { actions } = useSpecSheet();

  const [attributeValue, setAttributeValue] = useState(attribute || '');
  const [descriptionValue, setDescriptionValue] = useState(description || '');

  let oldAttributeValue = attribute || '';
  let oldDescribeValue = description || '';

  const handleChangeAttribute = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setAttributeValue(value);

  const handleChangeDescription = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setDescriptionValue(value);

  const autoSave = useDebounce(`${attributeValue}-${descriptionValue}`);

  const saveChange = useCallback(() => {
    if (
      oldAttributeValue !== attributeValue ||
      oldDescribeValue !== descriptionValue
    ) {
      actions.updateSkuComponent({
        id,
        attribute: attributeValue,
        description: descriptionValue,
      });

      oldAttributeValue = attributeValue;
      oldDescribeValue = descriptionValue;
    }
  }, [attributeValue, descriptionValue]);

  useEffect(() => {
    saveChange();
  }, [autoSave]);

  return (
    <>
      <Container justifyContent="space-between">
        <WrapperLabel>
          <Input
            name={`component-${id}`}
            value={component}
            dataTest={`component-${id}`}
            onChange={() => {}}
            disabled
          />
        </WrapperLabel>

        <SimpleSpace />

        <Input
          name={`attribute-${id}`}
          value={attributeValue}
          dataTest={`attribute-${id}`}
          onChange={handleChangeAttribute}
          placeholder="Click to add"
          isCompleted={!!attributeValue}
          handleBlur={saveChange}
        />

        <SimpleSpace />

        <Input
          name={`description-${id}`}
          value={descriptionValue}
          dataTest={`description-${id}`}
          onChange={handleChangeDescription}
          placeholder="Click to add"
          isCompleted={!!descriptionValue}
          handleBlur={saveChange}
        />
      </Container>

      <br />
      <br />
    </>
  );
};

export default Row;
