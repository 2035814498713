import { LoadingEnum } from './dtos';

export const initialState = {
  stepActive: 0,
  stepsCompleted: [],
  pagination: null,
  list: [],
  loading: LoadingEnum.DEFAULT,
  filters: {
    multiples: {
      brands: [],
      styleNames: [],
      refNumbers: [],
      factories: [],
      poNumbers: [],
      poIDs: [],
      colors: [],
      materials: [],
      seasons: [],
      countries: [],
      colMatCode: [],
      orderType: [],
      orderStatus: [],
      status: [],
    },
    customer: '',
    units: null,
    fobCost: null,
    retail: null,
    status: null,
    originalPoStart: null,
    originalPoCancel: null,
    currentPoStart: null,
    currentPoCancel: null,
    currentXfDate: null,
    fcrDate: null,
  },
};

export const steps = [
  { name: 'Customer order' },
  { name: 'Production' },
  { name: 'Style' },
];

export enum MAP_STEPS {
  'COSTUME_ORDER' = 0,
  'PRODUCTION' = 1,
  'STYLE' = 2,
  'UPLOAD_MANAGEMENT' = 3,
}
