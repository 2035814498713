import React from 'react';
import { Grid } from '@material-ui/core';

import TitlePages from 'components/TitlePages';
import List from './partials/List';

import { Container } from './styles';

const Content: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <TitlePages title="Spec sheet" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <List />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Content;
