import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const ContainerTitleAndButtonUpload = styled.div`
  ${simpleFlex}
  justify-content: space-between;
  padding-right: 70px;

  button {
    > div {
      margin-right: 10px;
    }
  }

  margin-bottom: 45px;
`;
