import styled from 'styled-components';

interface IContainer {
  size: string;
}

export const Container = styled.div<IContainer>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  overflow: hidden;

  svg {
    animation: spin 1s infinite linear;
    margin: 0px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
      opacity: 0.6;
    }
    to {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
`;
