import styled from 'styled-components';

import { mixinRespondTo } from 'theme/tools';

export const Container = styled.div``;

export const SimpleSpace = styled.div`
  ${mixinRespondTo.xs`
    width: 40px;
  `};

  ${mixinRespondTo.lg`
    width: 200px;
  `};
`;
