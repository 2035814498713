import styled from 'styled-components';

export const WrapperInputFile = styled.div`
  flex: 1;

  input {
    display: none;
  }

  > div {
    padding: 8.5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
