/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useRef, ReactNode } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import ScrollBar from 'components/ScrollBar';

import outsideClick from 'helpers/outsideClick';
import InputSearch from './InputSearch';

import { LabelWrapper } from '../styles';

import {
  SelectWrapper,
  WrapperOptions,
  SelectItem,
  InsivibleDivClick,
  Field,
  NumberSelected,
} from './styles';

export interface IProps {
  placeholder?: string;
  withInputSearch?: boolean;
  inputSearchPlaceholder?: string;
  inputSearchOnChange?: (value: string) => void;
  inputSearchvalue?: string;
  label?: string;
  numberSelected?: number | null;
  width?: string;
  dataTest: string;
  disabled?: boolean;
  auxiliaryButton?: ReactNode;
  maxHeight?: string;
  onOpen?: () => void;
  closeOnSimpleClick?: boolean;
}

const prefixDataTest = 'select';

const SmallSelect: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);

  const {
    numberSelected,
    label,
    placeholder,
    width,
    withInputSearch,
    inputSearchPlaceholder,
    inputSearchOnChange,
    inputSearchvalue,
    dataTest,
    disabled,
    auxiliaryButton,
    maxHeight,
    onOpen,
    closeOnSimpleClick,
    children,
  } = props;

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: () => setIsFocus(false),
  });

  const handleOpenSelect = () => {
    addEventClickOut();
    if (onOpen) onOpen();
    setIsFocus(true);
  };

  const handleCloseSelect = (event: ChangeEvent<any>) => {
    event.stopPropagation();
    removeEventClickOut();
    setIsFocus(false);
  };

  const simpleClose = (event: ChangeEvent<any>) => {
    if (closeOnSimpleClick) handleCloseSelect(event);
  };

  const isCompleted = !!numberSelected;

  return (
    <LabelWrapper
      as="div"
      onClick={handleOpenSelect}
      width={width}
      disabled={disabled}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
      data-testid={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      <SelectWrapper
        isCompleted={isCompleted || isFocus}
        isFocus={isFocus}
        data-test={`${prefixDataTest}-field-${dataTest}`}
        data-testid={`${prefixDataTest}-field-${dataTest}`}
        isNumberSelected={!!numberSelected}
      >
        <Field width={width} isCompleted={isCompleted} isFocus={isFocus}>
          <Text
            tag="strong"
            fontSize={13}
            data-test={`${prefixDataTest}-value-${dataTest}`}
            data-testid={`${prefixDataTest}-value-${dataTest}`}
          >
            {placeholder}
          </Text>

          {isCompleted && numberSelected > 0 && (
            <NumberSelected className="numberSelected">
              {numberSelected}
            </NumberSelected>
          )}

          <Icon icon="arrow" color="grayLightText" width="10px" />

          {isFocus && <InsivibleDivClick onClick={handleCloseSelect} />}

          <WrapperOptions
            ref={wrapperRef}
            data-test={`${prefixDataTest}-options-${dataTest}`}
            data-testid={`${prefixDataTest}-options-${dataTest}`}
            maxHeight={maxHeight}
          >
            {isFocus && withInputSearch && (
              <InputSearch
                name={`select-${label}`}
                placeholder={inputSearchPlaceholder!}
                onChange={inputSearchOnChange!}
                value={inputSearchvalue}
                data-test={`${prefixDataTest}-input-search-${dataTest}`}
                data-testid={`${prefixDataTest}-input-search-${dataTest}`}
              />
            )}
            <ScrollBar
              onClick={simpleClose}
              data-test={`${prefixDataTest}-scrollbar-${dataTest}`}
              data-testid={`${prefixDataTest}-scrollbar-${dataTest}`}
            >
              {children}
            </ScrollBar>
            <div onClick={simpleClose}>
              {auxiliaryButton && auxiliaryButton}
            </div>
          </WrapperOptions>
        </Field>
      </SelectWrapper>
    </LabelWrapper>
  );
};

SmallSelect.defaultProps = {
  placeholder: 'Click to select',
  width: '100%',
  disabled: false,
};

export { SelectItem };

export default SmallSelect;
