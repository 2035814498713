import React, { useEffect, useState } from 'react';

import api from 'services/api';

import { useDashboard } from 'containers/dashboard';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import { SmallSelect, Checkbox } from 'components/Fields';

import { useDebounce } from 'hooks/useDebounce';
import { WrapperSelect, WrapperButtonSelect, WrappCheckbox } from '../styles';

const filterType = 'brands';

interface IList {
  id: number;
  description: string;
}

const BrandFilter: React.FC = () => {
  const [search, setSearch] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState<IList[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    data: {
      filters: {
        multiples: { brands },
      },
    },
    actions: { addToFilter, hasOnFilter, removeFilter, resetFilter },
  } = useDashboard();

  const getBrands = async () => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    setLoading(true);

    const newList: IList[] = await api.get(
      `/brands?isActive=1&description=${search}`,
    );
    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getBrands();
  };

  useEffect(() => {
    saveChange();
  }, [autoSave]);

  return (
    <WrapperSelect>
      <SmallSelect
        withInputSearch
        dataTest="select-test"
        inputSearchPlaceholder="Search"
        inputSearchOnChange={setSearch}
        inputSearchvalue={search}
        placeholder="Brand"
        numberSelected={Number(brands?.length)}
        maxHeight="270px"
        onOpen={() => !list.length && getBrands()}
        auxiliaryButton={
          <WrapperButtonSelect>
            <Button
              scheme="nobg"
              data-testid="reset-filter"
              onClick={() => {
                setSearch('');
                resetFilter(filterType);
              }}
              padding="9px 20px"
            >
              Reset filters
            </Button>
          </WrapperButtonSelect>
        }
      >
        <>
          {loading ? (
            <GridFlex alignItems="center" justifyContent="center">
              <Spinner />
            </GridFlex>
          ) : (
            <WrappCheckbox>
              {list.map(item => (
                <Checkbox
                  key={`${filterType}-${item.id}-${item.description}`}
                  isChecked={hasOnFilter(filterType, item)}
                  label={item.description}
                  name={item.description}
                  onClick={() =>
                    !hasOnFilter(filterType, item)
                      ? addToFilter(filterType, item)
                      : removeFilter(filterType, item)
                  }
                  width="11px"
                  widthIcon="5px"
                  marginRightIcon="7px"
                  textFontSize={11}
                />
              ))}
            </WrappCheckbox>
          )}
        </>
      </SmallSelect>
    </WrapperSelect>
  );
};

export default BrandFilter;
