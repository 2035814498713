import React from 'react';

import { IProps, TextStyles } from './styles';

const Text: React.FC<IProps> = ({ children, tag, ...props }) => (
  <TextStyles {...props} as={tag}>
    {children}
  </TextStyles>
);

export default Text;
