import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { simpleFlex, takeColor } from 'theme/tools';

interface IMenuCollapsed {
  $isCollapsedMenu?: boolean;
}

const MenuItem = css`
  ${simpleFlex};
  border-radius: 60px;
  width: 240px;
  padding: 0 30px;
  border: 2px solid ${takeColor('grayMedium2Menu')};
  transition: 0.2s background-color;
  height: 55px;

  @media (max-height: 700px) {
    height: 40px;

    & + & {
      margin-top: 10px;
    }
  }

  & + & {
    margin-top: 15px;
  }

  svg {
    fill: ${takeColor('grayLightMenu')};

    * {
      fill: ${takeColor('grayLightMenu')};
    }
  }

  strong {
    margin-left: 20px;
    line-height: 18px;
    color: ${takeColor('grayLightMenu')};
  }

  &:hover,
  &.active {
    background-color: ${takeColor('grayMediumMenu')};
    border-color: transparent;

    svg {
      fill: ${takeColor('white')};

      * {
        fill: ${takeColor('white')};
      }
    }

    strong {
      color: ${takeColor('white')};
    }
  }
`;

export const MenuContainer = styled.nav<IMenuCollapsed>`
  ${simpleFlex};
  flex-direction: column;

  ${({ $isCollapsedMenu }) =>
    $isCollapsedMenu &&
    css`
      margin-top: 60px;
      justify-content: center;
      flex: 1;
    `}

  @media (min-height: 720px) {
    min-height: 370px;
  }
`;

export const ItemMenu = styled(NavLink)<IMenuCollapsed>`
  ${MenuItem}
  transition: 0.2s width;

  ${({ $isCollapsedMenu }) =>
    $isCollapsedMenu &&
    css`
      width: 53px;
      height: 53px;
      padding: 0 16px;
      justify-content: center;

      strong {
        display: none;
      }
    `}
`;

export const ItemMenuSimple = styled.a<IMenuCollapsed>`
  ${MenuItem}
  transition: 0.2s width;

  ${({ $isCollapsedMenu }) =>
    $isCollapsedMenu &&
    css`
      width: 53px;
      height: 53px;
      padding: 0 16px;
      justify-content: center;
      margin-top: 15px;

      strong {
        display: none;
      }
    `}
`;
