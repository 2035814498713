import * as yup from 'yup';

export default yup.object().shape({
  login: yup
    .string()
    .email('Must be a valid email')
    .required('Is a required field'),
  password: yup
    .string()
    .min(8, 'Must be at least 8 characters')
    .required('Is a required field'),
});
