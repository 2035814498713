export interface IActions {
  createPoint(data: ICreatePoint): void;
  updatePoint(
    id: number,
    value: string,
    { letterY, letterX }: { letterY: number; letterX: number },
  ): void;
}

export interface ICreatePoint {
  x: number;
  y: number;
  value: string;
  type: 'COMMENT' | 'POINT';
  idSpecSheetImage: number;
}

export type Selected =
  | 'letters'
  | 'comments'
  | 'change'
  | 'changeLetterPosition'
  | '';

export enum Type {
  Add = 'add',
  View = 'view',
  None = 'none',
}

export interface IState {
  loading: boolean;
  points: any;
}
