import React, { useEffect } from 'react';

import TitlePages from 'components/TitlePages';
import Spinner from 'components/Spinner';
import ProgressBar from 'components/ProgressBar';
import BoxTopSpec from 'components/BoxTopSpec';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { useSpecSheet } from 'containers/spec-sheet';
import { useSpecSheets } from 'containers/spec-sheets';
import { MAP_STEPS, steps } from 'containers/spec-sheet/constants';
import { LoadingEnum } from 'containers/spec-sheet/dtos';
import { TypeEnum } from 'containers/spec-sheet-modal/dtos';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';

import {
  Container,
  WrapperLoading,
  ContentRight,
  ContentProgressBar,
} from './styles';

const IsSaving = () => (
  <WrapperLoading>
    SAVING{' '}
    <div>
      <Spinner size="small" />
    </div>
  </WrapperLoading>
);

const Header: React.FC = () => {
  const {
    data: { loading, data },
  } = useSpecSheet();
  const {
    data: { stepActive },
  } = useSpecSheets();
  const { actions } = useSpecSheetModal();

  const title =
    stepActive === MAP_STEPS.PROTOTYPE ? '' : steps[stepActive].name;

  const isLoading = loading === LoadingEnum.SAVING;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepActive]);

  const isDiferrentFirstStep = stepActive !== MAP_STEPS.ADD_NEW_STYLE;

  const openModal = () => {
    if (data?.id)
      actions.openModalAndGetSpecSheetComplete(data.id, TypeEnum.DEFAULT);
  };

  return stepActive !== MAP_STEPS.FINISH_SPEC ? (
    <>
      <Container>
        <TitlePages
          title={title}
          topContent={
            isLoading ? (
              <>
                <IsSaving />
              </>
            ) : (
              'STYLE MANAGER'
            )
          }
        />

        <ContentRight>
          <Button padding="9px 20px" onClick={openModal}>
            <Icon icon="eye" marginRight={9} />
            Visualize summary
          </Button>
          {isDiferrentFirstStep && (
            <ContentProgressBar>
              <ProgressBar percentage={stepActive * 20} />
            </ContentProgressBar>
          )}
        </ContentRight>
      </Container>

      {isDiferrentFirstStep && <BoxTopSpec />}
    </>
  ) : (
    <></>
  );
};

export default Header;
