import styled from 'styled-components';

import ShadowBlur from 'components/ShadowBlur';

import { simpleFlex } from 'theme/tools';

interface IContainer {
  isOpen: boolean;
}

export const Container = styled(ShadowBlur)<IContainer>`
  ${simpleFlex};
  justify-content: center;

  > div {
    max-width: 1065px;
    max-height: 755px;
    min-width: 500px;
  }
`;
