/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { useSpecSheets } from 'containers/spec-sheets';
import { useSpecSheet } from 'containers/spec-sheet';

import Button from 'components/Button';
import Text from 'components/Text';
import Icon from 'components/Icon';
import GridFlex from 'components/GridFlex';
import { Input } from 'components/Fields';
import Notes, { Type as TypeNote } from 'components/Notes';
import { ContentButtonAddNotes } from './styles';

import SampleTypeSelect from './Fields/SampleTypeSelect';
import RefNo from './Fields/RefNo';
import StyleName from './Fields/StyleName';
import Season from './Fields/Season';
import Brands from './Fields/Brands';
import Factory from './Fields/Factory';
import Country from './Fields/Country';
import Date from './Fields/Date';
import Group from './Fields/Group';
import SpecSheetStatus from './Fields/SpecSheetStatus';

const AddNewStyle: React.FC = () => {
  const {
    data: { stepActive },
    actions: { changeStep },
  } = useSpecSheets();

  const {
    data: { data },
  } = useSpecSheet();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return useMemo(
    () => (
      <Grid>
        <Notes
          open={open}
          onClose={() => handleOpen()}
          title="Add notes"
          type={TypeNote.addNewStyle}
        />

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <SampleTypeSelect />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              name="id-spec"
              onChange={() => {}}
              label="ID"
              value={data?.id}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <RefNo />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyleName />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Season />
          </Grid>
          <Grid item xs={12} md={6}>
            <Country />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Factory />
          </Grid>
          <Grid item xs={12} md={6}>
            <Brands />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Date />
          </Grid>
          <Grid item xs={12} md={6}>
            <Group />
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <SpecSheetStatus />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentButtonAddNotes>
              <Button
                onClick={() => handleOpen()}
                width="100%"
                scheme="gray"
                data-test="button-add-notes"
                data-testid="button-add-notes"
              >
                <Text fontSize={15} fontWeight="normal-bold" color="white">
                  Add notes
                </Text>
              </Button>
            </ContentButtonAddNotes>
          </Grid>
        </Grid>

        <GridFlex
          alignItems="center"
          justifyContent="center"
          margin="100px 0 70px"
        >
          <Button
            onClick={() => changeStep(stepActive + 1)}
            width="265px"
            data-test="button-go-to-sketch"
          >
            <Text fontSize={15} fontWeight="normal-bold" color="white">
              Go to sketch
            </Text>
            <Icon
              icon="arrow-right"
              width="15px"
              color="white"
              marginLeft={15}
            />
          </Button>
        </GridFlex>
      </Grid>
    ),
    [changeStep, handleOpen, open, stepActive],
  );
};

export default AddNewStyle;
