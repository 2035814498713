import React from 'react';

import Header from './partials/Header';
import List from './partials/List';

import { Container } from './styles';

const DataManagement: React.FC = () => {
  return (
    <Container>
      <Header />
      <List />
    </Container>
  );
};

export default DataManagement;
