/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React from 'react';

import { takeColor } from 'theme/tools';

import { Container } from './styles';

interface IProps {
  show: boolean;
  url?: string;
  handleClose: () => void;
  editFile: (data: any) => void;
}

const EditImage: React.FC<IProps> = ({ show, handleClose, editFile, url }) => {
  const isTest = process.env.NODE_ENV === 'test';

  let FilerobotImageEditorComponent: any = () => <></>;

  if (!isTest)
    FilerobotImageEditorComponent = require('filerobot-image-editor').default;

  return (
    <Container isOpen={show}>
      <div>
        <FilerobotImageEditorComponent
          show={show}
          onClose={handleClose}
          src={url}
          config={{
            tools: ['rotate', 'crop', 'resize'],
            finishButtonLabel: 'Save',
            theme: {
              colors: {
                primaryBg: takeColor('black'),
                secondaryBg: takeColor('black'),
                secondaryBgOpacity: 'rgba(0,0,0, 0.75)',
                text: takeColor('white'),
                textHover: '#5D6D7E',
                textMute: '#aaa',
                textWarn: '#f7931e',
                accent: '#D5D8DC',
              },
            },
            showInModal: false,
          }}
          onBeforeComplete={editFile}
        />
      </div>
    </Container>
  );
};

EditImage.defaultProps = { url: '' };

export default EditImage;
