/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import { useSpecSheetModal } from 'containers/spec-sheet-modal';
import { TypeEnum } from 'containers/spec-sheet-modal/dtos';

import ModalSpecSheet from 'components/ModalSpecSheet';
import ModalDeleteOrCancel, { Types } from 'components/ModalDeleteOrCancel';
import ModalDuplicate from 'components/ModalDuplicate';

import Button from 'components/Button';

import { Container, Wrapp } from './styles';

interface IProps {
  refNo?: number;
  styleName?: string;
  status?: string;
  id: number;
  onClose?: () => void;
  onChangeStatus: (status: string) => void;
}

const QuickMenu: React.FC<IProps> = ({
  id,
  refNo,
  styleName,
  status,
  onClose,
  onChangeStatus,
}) => {
  const { actions: actionsModal } = useSpecSheetModal();
  const { actions: actionsSpec } = useSpecSheet();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);

  const [openModal, setOpenModal] = useState<boolean | Types>(false);

  const handleSpec = async () => {
    setLoading(true);
    try {
      await actionsSpec.deleteSpecSheet(id, {
        status: {
          id: openModal === Types.DELETE ? 6 : 3,
          description: openModal === Types.DELETE ? 'Deleted' : 'Cancelled',
          key: openModal === Types.DELETE ? 'DELETED' : 'CANCELLED',
        },
      });
      setSuccess(true);
      onChangeStatus(openModal === Types.CANCEL ? 'Cancelled' : 'Deleted');
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalSpecSheet />

      <ModalDeleteOrCancel
        id={styleName || refNo || id}
        onConfirm={() => handleSpec()}
        onCancel={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        onClick={() => setOpenModal(false)}
        loading={loading}
        error={error}
        success={success}
        open={!!openModal}
        type={openModal as Types}
      />

      <ModalDuplicate
        isOpen={isOpenDuplicateModal}
        onClose={() => setIsOpenDuplicateModal(false)}
        id={id}
        refNo={refNo as number}
        styleName={styleName}
        goBackText="Go back"
      />

      <Container>
        <Wrapp>
          <p>{styleName || refNo || id}</p>
          <Button
            width="93px"
            padding="6px 20px"
            onClick={() => setIsOpenDuplicateModal(true)}
          >
            Duplicate
          </Button>
          <Button
            width="93px"
            padding="6px 20px"
            data-testid="actions-delete"
            scheme={status === 'Deleted' ? 'disabled' : 'default'}
            onClick={() => {
              if (status !== 'Deleted') {
                setSuccess(false);
                setOpenModal(Types.DELETE);
              }
            }}
          >
            Delete
          </Button>
          <Button
            width="93px"
            padding="6px 20px"
            data-testid="actions-cancel"
            scheme={status === 'Cancelled' ? 'disabled' : 'default'}
            onClick={() => {
              if (status !== 'Cancelled') {
                setSuccess(false);
                setOpenModal(Types.CANCEL);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            width="93px"
            padding="6px 20px"
            onClick={() =>
              actionsModal.openModalAndGetSpecSheetComplete(id, TypeEnum.PRINT)
            }
          >
            Print
          </Button>
          <Button
            width="110px"
            padding="6px 20px"
            scheme="nobg"
            data-testid="click-close"
            onClick={onClose}
          >
            Go back
          </Button>
        </Wrapp>
      </Container>
    </>
  );
};

export default QuickMenu;
