/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { InputChip } from 'components/Fields';

const SoleEdgeColor = () => (
  <InputChip
    type="SOLO_EDGE_COLOR"
    name="SoloEdgeColor"
    label="Sole edge color"
  />
);

export default SoleEdgeColor;
