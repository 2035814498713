import React from 'react';

import { Container } from './styles';

interface IProps {
  width?: string;
  height?: string;
  notBorder?: boolean;
}

const Box: React.FC<IProps> = ({
  children,
  width,
  height,
  notBorder,
  ...props
}) => {
  return (
    <Container width={width} height={height} notBorder={notBorder} {...props}>
      {children}
    </Container>
  );
};

Box.defaultProps = {
  width: '165px',
  height: '165px',
  notBorder: false,
};

export default Box;
