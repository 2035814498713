/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
} from 'react';

import { isAuthenticated } from 'helpers/auth';
import { useDashboard } from 'containers/dashboard';
import initialActions from './actions';

import { IState, IActions } from './dtos';

import { initialState } from './constants';

interface IData {
  data: IState;
  actions: IActions;
}

export const UserContext = React.createContext<IData>({
  data: initialState,
  actions: initialActions({
    data: initialState,
    setData: () => {},
    resetDashboardData: () => {},
  }),
});

export const useUser = (): IData => useContext(UserContext);

export default function withUserProvider(
  WrappedComponent: React.FC,
  state: IState = initialState,
  actions = initialActions,
): React.FC {
  const WithUser = (props: any) => {
    const [data, setData] = useState(state);
    const {
      actions: { resetDashboardData },
    } = useDashboard();

    const value = useCallback(
      () => ({
        data,
        actions: actions({
          data,
          setData,
          resetDashboardData,
        }),
      }),
      [data],
    );

    const dataValue: IData = value();

    useEffect(() => {
      if (isAuthenticated()) {
        dataValue.actions.verifySession();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <UserContext.Provider value={dataValue}>
        <WrappedComponent {...props} />
      </UserContext.Provider>
    );
  };

  return memo(WithUser);
}
