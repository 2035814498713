export const optionsSortBy = [
  'Brand',
  'Category',
  'Color name',
  'Construction',
  'Country',
  'Factory',
  'Heel height',
  'Material name',
  'Proto number',
  'Sample Type',
  'Season',
  'Status',
  'Style name',
  'Style type',
  'Sub Category',
];

export const initialState = {
  filters: {
    brands: [],
    styleNames: [],
    refNumbers: [],
    factories: [],
    poNumbers: [],
    poIDs: [],
    colors: [],
    materials: [],
    seasons: [],
    countries: [],
    colMatCode: [],
    orderStatus: [],
    construction: [],
    styleName: [],
    factory: [],
    brand: [],
    country: [],
    sampleType: [],
    refNo: [],
    season: [],
    styleType: [],
    material: [],
    status: [],
    category: [],
    subCategory: [],
  },
  sortBy: [],
  loading: false,
  list: [],
  resultReport: [],
};
