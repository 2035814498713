import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 15px;
  }
`;
