/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { optionsSortBy } from 'containers/spec-sheet-search/constants';

import Button from 'components/Button';
import { SmallSelect } from 'components/Fields';
import { SelectItem } from 'components/Fields/Select';

import { WrapperSelect, WrapperButtonSelect } from '../styles';

import { Container, WrapperOptions } from './styles';

interface IProps {
  value: string;
  onClick(value: string | undefined): void;
}

const SortBy: React.FC<IProps> = ({ onClick, value }) => {
  return (
    <WrapperSelect>
      <Container>
        <SmallSelect
          dataTest="select-test"
          placeholder={!value ? `Click to select` : value}
          width="162px"
          maxHeight="350px"
          numberSelected={value ? 1 : null}
          closeOnSimpleClick
          auxiliaryButton={
            <WrapperButtonSelect>
              <Button
                onClick={() => onClick(undefined)}
                scheme="nobg"
                data-testid="reset-filter"
                padding="9px 20px"
              >
                Reset field
              </Button>
            </WrapperButtonSelect>
          }
        >
          <>
            <WrapperOptions>
              {optionsSortBy.map(option => (
                <SelectItem
                  data-testid={`sortBy-${option}`}
                  onClick={() => onClick(option)}
                >
                  {option}
                </SelectItem>
              ))}
            </WrapperOptions>
          </>
        </SmallSelect>
      </Container>
    </WrapperSelect>
  );
};

export default SortBy;
