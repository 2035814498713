import React from 'react';
import { useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { useNav } from 'containers/nav';
import DashboardArea from './DashboardArea';
import SpecSheetArea from './SpecSheetArea';

import { MenuContainer, ItemMenu } from './styles';

const Menu: React.FC = () => {
  const {
    data: { openMenu },
  } = useNav();

  const { pathname } = useLocation();

  const showOnlyDashboard = pathname.includes('dashboard');

  const showOnlySpecSheet = pathname.includes('spec-sheet');

  return (
    <MenuContainer $isCollapsedMenu={!openMenu}>
      <ItemMenu to="/" exact $isCollapsedMenu={!openMenu}>
        <Icon icon="house" />
        <Text fontWeight="normal-bold" fontSize={15} tag="strong" color="white">
          Business areas
        </Text>
      </ItemMenu>

      {showOnlySpecSheet && <SpecSheetArea />}

      {showOnlyDashboard && <DashboardArea />}
    </MenuContainer>
  );
};

export default Menu;
