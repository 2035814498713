export interface IMakeLoginUser {
  login: string;
  password: string;
}

export interface IActions {
  makeLogin(user: IMakeLoginUser, remember?: boolean): Promise<void>;
  makeLogout(): void;
  verifySession(): Promise<void>;
  clearErrors(): void;
}

export interface IUser {
  id: number;
  fullname: string;
  login: string;
  role: {
    id: number;
    name: string;
    code: string;
  };
}

export enum Roles {
  manager,
  designer,
  factory,
  viewer,
  'sourcing-and-operations',
}

export interface IState {
  signedIn: boolean;
  detail: IUser | null;
  permissions: string[] | null;
  error: string | null;
  differentError: string | null;
  loading: boolean;
}
