import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;

export const FieldContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 80%;
  ${simpleFlex}

  div:first-child {
    margin-right: 95px;
  }

  span {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 70px;
  width: 100%;
  ${simpleFlex}
  justify-content: center;

  button:first-child {
    margin-right: 40px;
  }
`;

export const ContainerLoader = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
  min-height: 250px;

  > p {
    margin-bottom: 30px;
  }
`;

export const ContainerSuccess = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 30px;
  }

  button {
    margin-top: 130px;
  }
`;

export const ButtonsWrapp = styled.div`
  ${simpleFlex}

  button:first-child {
    margin-right: 40px;
  }
`;
