import { ChangeEvent } from 'react';
import styled from 'styled-components';

import colors from 'theme/colors';

const FONT_WEIGHT_MAP = {
  medium: 400,
  normal: 500,
  'normal-bold': 600,
};

export interface IProps {
  tag?: 'p' | 'b' | 'strong' | 'span';
  fontSize?: number;
  fontWeight?: keyof typeof FONT_WEIGHT_MAP;
  color?: keyof typeof colors;
  margin?: string;
  children?: any;
  onClick?: (event: ChangeEvent<any>) => void;
}

export const TextStyles = styled('p')<IProps>`
  display: block;
  font-size: ${({ fontSize = 16 }: IProps) => fontSize}px;
  font-weight: ${({ fontWeight = 'normal' }) => FONT_WEIGHT_MAP[fontWeight]};
  color: ${({ color = 'grayLightText' }) => colors[color]};
  margin: ${({ margin = '0' }: IProps) => margin};
  white-space: nowrap;
`;
