import React, { useEffect, useMemo } from 'react';

import { useDashboard } from 'containers/dashboard';
import { MAP_STEPS } from 'containers/dashboard/constants';

import CostumerOrder from './partials/CostumerOrder';
import Production from './partials/Production';
import Style from './partials/Style';
import CustomerOrderHistory from './partials/CustomerOrderHistory';

import { Container } from './styles';

const stepsComponents: any = {
  [MAP_STEPS.COSTUME_ORDER]: CostumerOrder,
  [MAP_STEPS.PRODUCTION]: Production,
  [MAP_STEPS.STYLE]: Style,
  [MAP_STEPS.UPLOAD_MANAGEMENT]: CustomerOrderHistory,
};

const Dashboard: React.FC = () => {
  const {
    data: { stepActive },
    actions: { changeStep },
  } = useDashboard();

  useEffect(() => {
    changeStep(MAP_STEPS.COSTUME_ORDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StepActive = stepsComponents[stepActive];

  return useMemo(
    () => (
      <Container>
        <StepActive />
      </Container>
    ),
    [StepActive],
  );
};

export default Dashboard;
