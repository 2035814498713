/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';

import { useDebounce } from 'hooks/useDebounce';

import { Select, SelectItem } from 'components/Fields';
import Button from 'components/Button';
import GridFlex from 'components/GridFlex';
import Spinner from 'components/Spinner';
import ModalRefNo from './ModalRefNo';

import { Container } from './styles';

const RefNo: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    data: { data },
    actions: { changeSpecSheetData, removeLoading },
  } = useSpecSheet();

  const refNo = data?.refNo || data?.id;

  const getRefsNo = async () => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    setLoading(true);

    const newList: number[] = await api.get(
      `/spec-sheets/list-ref-no?search=${search}`,
    );
    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getRefsNo();
  };

  const closeModal = () => {
    setIsEdit(false);
    removeLoading();
  };

  useEffect(() => {
    saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <>
      <Container>
        <Select
          label="Ref. No"
          placeholder="Click to select"
          withInputSearch
          inputSearchPlaceholder="Search Ref. No"
          inputSearchOnChange={setSearch}
          inputSearchvalue={search}
          dataTest="refNo"
          value={refNo}
          onOpen={() => !list.length && getRefsNo()}
          maxHeight="321px"
        >
          <>
            {loading ? (
              <GridFlex flex={1} justifyContent="center" alignItems="center">
                <Spinner />
              </GridFlex>
            ) : (
              <>
                {list.map(item => (
                  <SelectItem
                    onClick={() => changeSpecSheetData({ refNo: item })}
                    isSelected={false}
                    key={`items-refNo-${item}`}
                  >
                    {item}
                  </SelectItem>
                ))}
              </>
            )}
          </>
        </Select>
        <Button
          onClick={() => setIsEdit(true)}
          data-testid="ref-no-wrapper"
          width="180px"
        >
          Edit
        </Button>
      </Container>

      <ModalRefNo
        isOpen={isEdit}
        onClose={closeModal}
        initialValue={refNo}
        changeSpecSheet={changeSpecSheetData}
      />
    </>
  );
};

export default RefNo;
