/* eslint-disable react/no-array-index-key */
import React from 'react';

import Text from 'components/Text';
import SortByFilter from 'components/MoreFiltersSearch/SortyBy';
import Spinner from 'components/Spinner';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';
import { Container, Titles, ContainerFields, ContainerSpinner } from './styles';

const SortBy: React.FC = () => {
  const {
    data: { sortBy, loading },
    actions: { fillSortBy },
  } = useSpecSheetSearch();

  return (
    <Container>
      <Titles>
        <Text fontWeight="normal-bold" fontSize={18} color="black">
          Sort by
        </Text>
        <Text fontSize={16} fontWeight="medium">
          Choose the items below, by the order you want them to appear, from top
          left to bottom right
        </Text>
      </Titles>
      {loading ? (
        <ContainerSpinner>
          <Spinner size="big" />
        </ContainerSpinner>
      ) : (
        <ContainerFields>
          {[...Array(15)].map((x, i) => (
            <SortByFilter
              key={i}
              value={sortBy[i]}
              onClick={option => fillSortBy(i, option)}
            />
          ))}
        </ContainerFields>
      )}
    </Container>
  );
};

export default SortBy;
