import React, { useState } from 'react';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';
import history from 'routes/history';

import { StatusSpec } from 'components/Status';
import Button from 'components/Button';

import { dateDefault } from 'helpers/date';
import {
  TableHeader,
  TableColumn,
  TableContainer,
  TableRow,
  TableColumnSmall,
} from 'components/Table/newTableStyles';
import QuickMenu from './QuickMenu';

import { Container } from './styles';

const TableSpecs: React.FC = () => {
  const {
    data: { list },
    actions: { editArrayStatus },
  } = useSpecSheetSearch();

  const [specOptionsOpen, setSpecOptionsOpen] = useState<boolean | number>(
    false,
  );

  return (
    <Container>
      <TableContainer>
        <TableHeader>
          <TableColumn>Brand</TableColumn>
          <TableColumn>Style name</TableColumn>
          <TableColumn>Ref. No</TableColumn>
          <TableColumn>Factory</TableColumn>
          <TableColumn>Sample type</TableColumn>
          <TableColumn>Construction</TableColumn>
          <TableColumn>Season</TableColumn>
          <TableColumn>Style type</TableColumn>
          <TableColumn>Country</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn>Date</TableColumn>
          {/* <TableColumnSmall /> */}
        </TableHeader>
        {list.map(
          (
            {
              id,
              brand,
              sampleType,
              construction,
              country,
              season,
              styleType,
              refNo,
              factory,
              description,
              status,
              createdAt,
            },
            index,
          ) => (
            <div key={id}>
              {specOptionsOpen === index && (
                <QuickMenu
                  id={id}
                  refNo={refNo}
                  styleName={description}
                  status={status}
                  onClose={() => setSpecOptionsOpen(false)}
                  onChangeStatus={status => editArrayStatus(index, status)}
                />
              )}

              <TableRow
                data-testid={`row-results-${id}`}
                onClick={() => history.push(`/spec-sheet/${id}`)}
              >
                <TableColumn>{brand}</TableColumn>
                <TableColumn>{description}</TableColumn>
                <TableColumn>{refNo || id}</TableColumn>
                <TableColumn>{factory}</TableColumn>
                <TableColumn>{sampleType}</TableColumn>
                <TableColumn>{construction}</TableColumn>
                <TableColumn>{season}</TableColumn>
                <TableColumn>{styleType}</TableColumn>
                <TableColumn>{country}</TableColumn>
                <TableColumn>
                  <StatusSpec type={status?.toLowerCase() as any} />
                  {status}
                </TableColumn>
                <TableColumn>{dateDefault(createdAt)}</TableColumn>
                <TableColumnSmall>
                  <Button
                    width="112px"
                    padding="6px 20px"
                    data-testid={`button-row-result-options-${id}`}
                    onClick={e => {
                      e?.stopPropagation();
                      setSpecOptionsOpen(index);
                    }}
                  >
                    Spec options
                  </Button>
                </TableColumnSmall>
              </TableRow>
            </div>
          ),
        )}
      </TableContainer>
    </Container>
  );
};

export default TableSpecs;
