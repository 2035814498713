import React from 'react';

import { IProps, ButtonStyles } from './styles';

const Button: React.FC<IProps> = ({
  children,
  type,
  padding,
  disabled,
  ...props
}) => (
  <ButtonStyles {...props} type={type} padding={padding} disabled={disabled}>
    {children}
  </ButtonStyles>
);

Button.defaultProps = {
  type: 'submit',
  width: '100%',
};

export default Button;
