import styled from 'styled-components';

export const FullScreenGroup = styled.div`
  flex: 1;
  @media print {
    &:first-child {
      min-height: 450px;
    }
    min-height: 100vh;
    &:last-child {
      min-height: initial;
    }
  }
`;

export const WrapperTable = styled.tr`
  margin-bottom: 8px;
  page-break-inside: avoid;
  page-break-after: auto;
  display: block;
`;
