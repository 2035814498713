import { Roles } from 'containers/user/dtos';
import jwtDecode from 'jwt-decode';

import { getItem } from './storage';

interface ReturnFromDecode {
  aud: string;
}

const getRoleFromToken = () => {
  const token = getItem('@token');

  if (token) {
    const { aud } = jwtDecode<ReturnFromDecode>(token as string);

    return aud;
  }

  return Roles.viewer;
};

export const getRole = () => {
  return Roles[getRoleFromToken()];
};

export const getUserRole = (type: Roles) => {
  return Roles[type] === getRoleFromToken();
};
