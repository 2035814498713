import React, { useState, useEffect, useRef } from 'react';

import withSpecSheetSearch from 'containers/spec-sheet-search';
import { useSpecSheets } from 'containers/spec-sheets';
import withSpecSheetModal from 'containers/spec-sheet-modal';

import { useDebounce } from 'hooks/useDebounce';

import outsideClick from 'helpers/outsideClick';

import { Input } from 'components/Fields';
import Icon from 'components/Icon';
import Text from 'components/Text';

import AdvancedSearch from './AdvancedSearch';

import { Container, ContainerButtonFilter } from './styles';

const Search: React.FC = () => {
  const [isOpenAdvancedFilter, setIsOpenAdvancedFilter] = useState(false);
  const [focusChangeIcon, setFocusChangeIcon] = useState(false);

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: () => {
      removeEventClickOut();
      setFocusChangeIcon(false);
    },
  });

  const {
    data: { search },
    actions: { getAllSpecSheets, setSearch },
  } = useSpecSheets();

  const autoSave = useDebounce(search) as string;

  useEffect(() => {
    if (autoSave?.length >= 3 || !autoSave)
      getAllSpecSheets({ limit: 9, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <div data-testid="outclick-search">
      <Container data-test="search" ref={wrapperRef}>
        <Input
          name="search"
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder="Search for Ref. No or Style name"
          width="100%"
          dataTest="search"
          handleFocus={() => {
            setFocusChangeIcon(true);
            addEventClickOut();
          }}
          leftContent={
            <Icon
              icon="search"
              color="grayLightText"
              width="18px"
              height="18px"
            />
          }
          rightContent={
            <>
              {!focusChangeIcon ? (
                <Icon
                  icon="filter"
                  color="grayLightText"
                  width="18px"
                  height="18px"
                />
              ) : (
                <ContainerButtonFilter
                  data-testid="open-advanced-filters"
                  onClick={() => setIsOpenAdvancedFilter(true)}
                  width="197px"
                  padding="9px 20px"
                >
                  <Icon
                    icon="filter"
                    color="white"
                    width="13px"
                    height="13px"
                  />
                  <Text color="white" fontSize={13} fontWeight="normal">
                    Search with filters
                  </Text>
                </ContainerButtonFilter>
              )}
            </>
          }
        />
      </Container>

      <AdvancedSearch
        isOpen={isOpenAdvancedFilter}
        onClose={() => setIsOpenAdvancedFilter(false)}
      />
    </div>
  );
};

export default withSpecSheetSearch(withSpecSheetModal(Search));
