/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useRef, ReactNode } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import ScrollBar from 'components/ScrollBar';

import outsideClick from 'helpers/outsideClick';
import InputSearch from './InputSearch';

import { FieldStyle, LabelWrapper } from '../styles';

import {
  SelectWrapper,
  WrapperOptions,
  SelectItem,
  InsivibleDivClick,
} from './styles';

export interface IProps {
  placeholder?: string;
  withInputSearch?: boolean;
  inputSearchPlaceholder?: string;
  inputSearchOnChange?: (value: string) => void;
  inputSearchvalue?: string;
  error?: string;
  label?: string;
  value?: string | number | null;
  width?: string;
  dataTest: string;
  disabled?: boolean;
  auxiliaryButton?: ReactNode;
  maxHeight?: string;
  onOpen?: () => void;
}

const prefixDataTest = 'select';

const Select: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);

  const {
    value,
    label,
    placeholder,
    width,
    error,
    withInputSearch,
    inputSearchPlaceholder,
    inputSearchOnChange,
    inputSearchvalue,
    dataTest,
    disabled,
    auxiliaryButton,
    maxHeight,
    onOpen,
    children,
  } = props;

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: () => setIsFocus(false),
  });

  const handleOpenSelect = () => {
    addEventClickOut();
    if (onOpen) onOpen();
    setIsFocus(true);
  };

  const handleCloseSelect = (event: ChangeEvent<any>) => {
    event.stopPropagation();
    removeEventClickOut();
    setIsFocus(false);
  };

  const isCompleted = !!value;

  return (
    <LabelWrapper
      as="div"
      onClick={handleOpenSelect}
      width={width}
      disabled={disabled}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
      data-testid={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      <Text
        color="black"
        fontSize={18}
        fontWeight="normal-bold"
        data-test={`${prefixDataTest}-label-${dataTest}`}
        data-testid={`${prefixDataTest}-label-${dataTest}`}
      >
        {label}
      </Text>

      <SelectWrapper
        isCompleted={isCompleted || isFocus}
        isFocus={isFocus}
        data-test={`${prefixDataTest}-field-${dataTest}`}
        data-testid={`${prefixDataTest}-field-${dataTest}`}
      >
        <FieldStyle
          width={width}
          isCompleted={isCompleted}
          isFocus={isFocus}
          error={error}
        >
          <Text
            tag="strong"
            data-test={`${prefixDataTest}-value-${dataTest}`}
            data-testid={`${prefixDataTest}-value-${dataTest}`}
          >
            {value || placeholder}
          </Text>

          <Icon icon="arrow" color="grayLightText" width="15px" />

          {isFocus && <InsivibleDivClick onClick={handleCloseSelect} />}

          <WrapperOptions
            ref={wrapperRef}
            data-test={`${prefixDataTest}-options-${dataTest}`}
            data-testid={`${prefixDataTest}-options-${dataTest}`}
            maxHeight={maxHeight}
          >
            {isFocus && withInputSearch && (
              <InputSearch
                name={`select-${label}`}
                placeholder={inputSearchPlaceholder!}
                onChange={inputSearchOnChange!}
                value={inputSearchvalue}
                data-test={`${prefixDataTest}-input-search-${dataTest}`}
                data-testid={`${prefixDataTest}-input-search-${dataTest}`}
              />
            )}
            <ScrollBar
              onClick={handleCloseSelect}
              data-test={`${prefixDataTest}-scrollbar-${dataTest}`}
              data-testid={`${prefixDataTest}-scrollbar-${dataTest}`}
            >
              {children}
            </ScrollBar>
            {auxiliaryButton && auxiliaryButton}
          </WrapperOptions>
        </FieldStyle>
      </SelectWrapper>

      {error && (
        <Text
          fontSize={13}
          fontWeight="normal-bold"
          color="error"
          tag="span"
          data-test={`${prefixDataTest}-error-${dataTest}`}
        >
          {error}
        </Text>
      )}
    </LabelWrapper>
  );
};

Select.defaultProps = {
  placeholder: 'Click to select',
  error: '',
  width: '100%',
  disabled: false,
};

export { SelectItem };

export default Select;
