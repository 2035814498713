import React from 'react';

import colors from 'theme/colors';
import icons from './icons';

import { ContainerIcon } from './styles';

export interface IProps {
  icon: keyof typeof icons;
  color?: keyof typeof colors | 'none';
  width?: string;
  height?: string;
  marginLeft?: number;
  marginRight?: number;
}

const Icon: React.FC<IProps> = ({
  icon,
  width,
  height,
  color,
  marginLeft,
  marginRight,
}) => {
  const IconSvg = icons[icon];
  return (
    <ContainerIcon
      width={width}
      height={height}
      color={color}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <IconSvg />
    </ContainerIcon>
  );
};

Icon.defaultProps = {
  marginLeft: 0,
  marginRight: 0,
  width: '22px',
  height: undefined,
  color: 'white',
};

export default Icon;
