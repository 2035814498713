/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { InputChip } from 'components/Fields';

const SoloFinishEdge = () => (
  <InputChip
    type="SOLE_FINISH_EDGE"
    name="SoloFinishEdge"
    label="Sole finish/edge"
  />
);

export default SoloFinishEdge;
