import React, { useEffect } from 'react';

import withUsersManagementProvider, {
  useUsersManagement,
} from 'containers/users-management';

import Header from './partials/Header';
import List from './partials/List';

import { Container } from './styles';

const UserManagement: React.FC = () => {
  const {
    actions: { listUsers },
  } = useUsersManagement();

  useEffect(() => {
    listUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header />
      <List />
    </Container>
  );
};

export default withUsersManagementProvider(UserManagement);
