import styled from 'styled-components';

import { takeColor } from 'theme/tools';

export interface IBoxChip {
  isCompleted?: boolean;
}

export const BoxChip = styled.div<IBoxChip>`
  width: auto;
  padding: 10px 20px;
  border-radius: 41px;
  background: ${props =>
    props.isCompleted ? takeColor('grayMedium') : takeColor('black')};
  color: ${takeColor('white')};
  font-weight: 500;
  font-size: 13px;
  float: left;
  margin: 5px 5px 5px 0px;
  display: flex;
  z-index: 99999999;

  svg {
    margin-left: 5px;
  }

  * {
    fill: ${takeColor('white')} !important;
  }
`;

export const BoxDelete: any = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;
  z-index: 9999;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }

  svg {
    margin-left: 0px;
    transform: none !important;
    * {
      fill: ${takeColor('white')};
    }
  }
`;
