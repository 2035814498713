import styled from 'styled-components';

import { takeColor } from 'theme/tools';

export const StrongLabel = styled.strong`
  color: ${takeColor('black')};
  font-size: 18px;
  font-weight: 600;
`;

export const InputStyle = styled.input`
  font-size: 16px;
  width: 100%;

  &::placeholder {
    color: ${takeColor('grayLightText')};
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const WrapperLeftContent = styled.div`
  margin-right: 12px;
`;
