import styled from 'styled-components';

import { takeColor } from 'theme/tools';

export const BoxMenu = styled.div`
  width: 100% auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const BoxRef = styled.div`
  min-width: 149px;
  padding: 0 15px;
  height: 46px;
  border: 2px solid ${takeColor('white')};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: ${takeColor('white')};
  margin-bottom: 10px;
`;

export const ContainerMenu = styled.div`
  width: 149px;
  background: ${takeColor('white')};
  border: 1px solid ${takeColor('grayBorderBox')};
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 15px;
`;

export const ItemMenu = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;

  :hover {
    opacity: 0.7;
  }
`;

export const TextMenu = styled.div`
  margin-left: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${takeColor('grayMedium')};
`;
