import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { useDashboard } from 'containers/dashboard';
import { IState, IMoneyFilter } from 'containers/dashboard/dtos';

import { Input, SmallSelect } from 'components/Fields';
import Button from 'components/Button';

import Modal from 'components/Modal';
import { WrapperSelect } from '../styles';

import {
  Container,
  ButtonsModal,
  Title,
  FieldContainer,
  ContainerModal,
} from './styles';

export interface IProps {
  field: keyof IState['filters'];
  title: string;
  withMask?: boolean;
  label1?: string;
  label2?: string;
}

const MoneyModal: React.FC<IProps> = ({
  field,
  title,
  withMask = false,
  label1 = 'Value 1',
  label2 = 'Value 2',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [firstNumber, setFirstNumber] = useState(0);
  const [lastNumber, setLastNumber] = useState(0);

  const {
    data: { filters },
    actions: { addToNormalFilters, resetNormalFilter },
  } = useDashboard();

  const moneySelected = filters[field] as IMoneyFilter;

  const handleReset = () => {
    resetNormalFilter(field);
    setFirstNumber(0);
    setLastNumber(0);
    setIsOpen(false);
  };

  const handleSave = () => {
    addToNormalFilters(field, {
      firstNumber,
      lastNumber,
    });
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setFirstNumber(moneySelected?.firstNumber || 0);
      setLastNumber(moneySelected?.lastNumber || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isDisabled =
    !firstNumber ||
    firstNumber === 0 ||
    !lastNumber ||
    lastNumber === 0 ||
    Number(lastNumber) < Number(firstNumber);

  return (
    <Container>
      <WrapperSelect>
        <SmallSelect
          dataTest="select-test"
          placeholder={title}
          maxHeight="270px"
          numberSelected={moneySelected ? 1 : null}
          onOpen={() => setIsOpen(true)}
        />
      </WrapperSelect>

      <Modal
        isOpen={!!isOpen}
        onClose={() => setIsOpen(false)}
        noButtonClose
        minWidth="1280px"
        minHeight="480px"
      >
        <ContainerModal>
          <Title>{title}</Title>
          <FieldContainer>
            <Grid item md={6}>
              <Input
                isMoneyField={withMask}
                name="firstNumber"
                label={label1}
                type={withMask ? 'text' : 'number'}
                onChange={e => {
                  if (withMask) {
                    setFirstNumber(e.floatValue);
                  } else {
                    setFirstNumber(e.target.value);
                  }
                }}
                placeholder="Click to add"
                width="451px"
                value={firstNumber}
                dataTest="firstNumber"
              />
            </Grid>
            <Grid item md={6}>
              <Input
                isMoneyField={withMask}
                name="lastNumber"
                label={label2}
                type={withMask ? 'text' : 'number'}
                onChange={e => {
                  if (withMask) {
                    setLastNumber(e.floatValue);
                  } else {
                    setLastNumber(e.target.value);
                  }
                }}
                placeholder="Click to add"
                width="451px"
                value={lastNumber}
                dataTest="lastNumber"
              />
            </Grid>
          </FieldContainer>
          <ButtonsModal>
            <Button
              width="266px"
              onClick={handleReset}
              scheme={isDisabled ? 'disabled' : 'default'}
              disabled={isDisabled}
            >
              Reset filters
            </Button>
            <Button
              width="266px"
              data-testid="save-money"
              scheme={isDisabled ? 'disabled' : 'default'}
              disabled={isDisabled}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              width="266px"
              onClick={() => setIsOpen(false)}
              scheme="nobg"
              data-testid="cancell-money"
            >
              Cancel
            </Button>
          </ButtonsModal>
        </ContainerModal>
      </Modal>
    </Container>
  );
};

export default MoneyModal;
