import React from 'react';
import { useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import Text from 'components/Text';

import StyleManagerItem from './partials/StyleManagerItem';

import { ItemMenu } from '../styles';

const SpecSheetArea: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      <ItemMenu to="/spec-sheet" exact>
        <Icon icon="spec-sheet" />
        <Text fontWeight="normal-bold" fontSize={15} tag="strong" color="white">
          Spec sheet
        </Text>
      </ItemMenu>

      <StyleManagerItem />

      {!pathname.includes('spec-sheet/') && (
        <ItemMenu to="/reports" exact>
          <Icon icon="filter-big" />
          <Text
            fontWeight="normal-bold"
            fontSize={15}
            tag="strong"
            color="white"
          >
            Reports
          </Text>
        </ItemMenu>
      )}
    </>
  );
};

export default SpecSheetArea;
