import { setItem, getItem, removeItem } from './storage';

const token = '@token';
const refreshToken = '@refreshToken';

export const removeSession = (): void => {
  removeItem(token);
  removeItem(refreshToken);
};

export const isAuthenticated = (): string | null => {
  const tokenStorage = getItem(token);
  return tokenStorage;
};

export const setSession = (
  tokenValue: string,
  refreshTokenValue: string,
): void => {
  setItem(token, tokenValue);
  setItem(refreshToken, refreshTokenValue);
};
