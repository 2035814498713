import { IState } from './dtos';

export enum ERRORS_MAP {
  'BAD_REQUEST' = 'Incorrect username or password.',
  'INTERNAL_ERROR' = 'Internal server error.',
}

export const MAP_STATUS_ERROR: any = {
  400: ERRORS_MAP.BAD_REQUEST,
  500: ERRORS_MAP.INTERNAL_ERROR,
};

export const initialState: IState = {
  signedIn: false,
  detail: null,
  permissions: null,
  error: null,
  differentError: null,
  loading: false,
};
