import styled from 'styled-components';
import { mixinRespondTo, takeColor } from 'theme/tools';

export const Container = styled.div`
  margin-top: 50px;
  padding: 35px 50px;
  border-radius: 12px;
  background-color: ${takeColor('grayInputSearch')};
`;

export const Titles = styled.div`
  p:first-child {
    margin-bottom: 8px;
  }

  margin-bottom: 40px;
`;

export const ContainerFields = styled.div`
  display: grid;

  ${mixinRespondTo.sm`
    gap: 0px;
    grid-template-columns: 1fr 1fr;
  `}

  ${mixinRespondTo.md`
    gap: 0px;
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${mixinRespondTo.lg`
    gap: 0px 18px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `}
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
