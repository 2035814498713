import React, { useEffect, useState } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/Button';

import { useDashboard } from 'containers/dashboard';
import MaterialFilter from './Fields/Material';
import Customer from './Fields/Customer';
import Brand from './Fields/Brand';
import Country from './Fields/Country';
import Factory from './Fields/Factory';
import RefNo from './Fields/RefNo';
import StyleName from './Fields/StyleName';
import Season from './Fields/Season';
import ShipStatus from './Fields/ShipStatus';
import OrderStatus from './Fields/OrderStatus';
import DateModal from './Fields/DateModal';
import PoId from './Fields/PoId';
import PoNumber from './Fields/PoNumber';
import ColMatCode from './Fields/ColMatCode';
import OrderType from './Fields/OrderType';
import Color from './Fields/Color';
import MoneyModal from './Fields/MoneyModal';

import {
  Container,
  TitlesToggle,
  ContentFilters,
  ContainerButton,
  ContainerFields,
  ContainerFieldsNoOrder,
  ButtonWrapper,
} from './styles';

const Filters: React.FC = () => {
  const {
    data: { filters },
    actions: { listCustomerOrder },
  } = useDashboard();

  const [filterToggle, setFilterToggle] = useState({
    toggle: true,
    mounted: false,
  });

  const {
    multiples,
    customer,
    units,
    fobCost,
    retail,
    status,
    originalPoStart,
    originalPoCancel,
    currentPoStart,
    currentPoCancel,
    currentXfDate,
    fcrDate,
  } = filters;

  const isEmpty =
    Object.values(multiples).every(x => !x.length) &&
    !status &&
    !customer &&
    !units &&
    !fobCost &&
    !retail &&
    !originalPoStart &&
    !originalPoCancel &&
    !currentPoStart &&
    !currentPoCancel &&
    !currentXfDate &&
    !fcrDate;

  useEffect(() => {
    if (!isEmpty && !filterToggle.mounted) {
      setFilterToggle({
        toggle: false,
        mounted: true,
      });
    }
  }, [filterToggle.mounted, isEmpty]);

  return (
    <Container>
      <TitlesToggle isOpen={filterToggle.toggle}>
        <div>
          <Text fontWeight="normal-bold" fontSize={18} color="black">
            Select filters
          </Text>
          {!filterToggle.toggle && (
            <Text fontSize={16} fontWeight="medium">
              Choose the filters below
            </Text>
          )}
        </div>
        <ContainerButton
          isOpen={filterToggle.toggle}
          onClick={() =>
            setFilterToggle({ ...filterToggle, toggle: !filterToggle.toggle })
          }
        >
          <Icon
            icon="arrow-left-circle"
            color="none"
            width="38px"
            height="38px"
          />
        </ContainerButton>
      </TitlesToggle>

      {!filterToggle.toggle && (
        <ContentFilters>
          <ContainerFields>
            <Customer />
            <Brand />
            <StyleName />
            <RefNo />
            <Factory />
            <PoNumber />
            <PoId />
            <Color />
            <MaterialFilter />
            <MoneyModal
              field="units"
              title="TTL units"
              label1="Min. of units"
              label2="Max. of units"
            />
          </ContainerFields>

          <ContainerFieldsNoOrder>
            <MoneyModal
              field="fobCost"
              title="PO Price"
              withMask
              label1="Min. value"
              label2="Max. value"
            />
            <DateModal field="originalPoStart" title="Orig. PO start date" />
            <DateModal field="originalPoCancel" title="Orig. PO cancel date" />
            <DateModal field="currentPoStart" title="Current PO start date" />
            <OrderType />
          </ContainerFieldsNoOrder>

          <ContainerFieldsNoOrder>
            <DateModal
              field="currentPoCancel"
              title="Current. PO cancel date"
            />
            <DateModal field="fcrDate" title="FCR date" />
            <ShipStatus />
            <Season />
            <DateModal field="currentXfDate" title="Current XF date" />
          </ContainerFieldsNoOrder>
          <ContainerFields>
            <Country />
            <OrderStatus />
            <ColMatCode />
            <MoneyModal field="retail" title="Retail Price" withMask />
          </ContainerFields>
          <ButtonWrapper>
            <Button
              scheme={isEmpty ? 'disabled' : 'default'}
              disabled={isEmpty}
              width="266px"
              onClick={() => listCustomerOrder()}
            >
              Filter items
            </Button>
            <Button width="266px" onClick={() => listCustomerOrder(true)}>
              Reset filters
            </Button>
          </ButtonWrapper>
        </ContentFilters>
      )}
    </Container>
  );
};

export default Filters;
