import React, { useState, useEffect } from 'react';

import api from 'services/api';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';
import { useDebounce } from 'hooks/useDebounce';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import { SmallSelect, Checkbox } from 'components/Fields';

import { WrapperSelect, WrapperButtonSelect, WrappCheckbox } from '../styles';

const filterType = 'styleName';

const StyleNameFilter: React.FC = () => {
  const [search, setSearch] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    data: {
      filters: { styleName },
    },
    actions: { addToFilter, hasOnFilter, removeFilter, resetFilter },
  } = useSpecSheetSearch();

  const getStyleNames = async () => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    setLoading(true);

    const newList: string[] = await api.get(
      `/spec-sheets/list-style-names?search=${search}`,
    );
    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getStyleNames();
  };

  useEffect(() => {
    saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <WrapperSelect>
      <SmallSelect
        withInputSearch
        dataTest="select-test"
        inputSearchPlaceholder="Search"
        inputSearchOnChange={setSearch}
        inputSearchvalue={search}
        placeholder="Style name"
        numberSelected={Number(styleName?.length)}
        width="164px"
        maxHeight="270px"
        onOpen={() => !list.length && getStyleNames()}
        auxiliaryButton={
          <WrapperButtonSelect>
            <Button
              scheme="nobg"
              data-testid="reset-filter"
              onClick={() => {
                setSearch('');
                resetFilter(filterType);
              }}
              padding="9px 20px"
            >
              Reset filters
            </Button>
          </WrapperButtonSelect>
        }
      >
        <>
          {loading ? (
            <GridFlex alignItems="center" justifyContent="center">
              <Spinner />
            </GridFlex>
          ) : (
            <WrappCheckbox>
              {list.map((item, idx) => (
                <Checkbox
                  key={`${filterType}-${item}-${idx as number}`}
                  isChecked={hasOnFilter(filterType, { id: item })}
                  label={item}
                  name={item}
                  onClick={() =>
                    !hasOnFilter(filterType, { id: item })
                      ? addToFilter(filterType, { id: item })
                      : removeFilter(filterType, { id: item })
                  }
                  width="11px"
                  widthIcon="5px"
                  marginRightIcon="7px"
                  textFontSize={11}
                />
              ))}
            </WrappCheckbox>
          )}
        </>
      </SmallSelect>
    </WrapperSelect>
  );
};

export default StyleNameFilter;
