import React from 'react';

import Text from '../Text';

import { Container, SubTitlePagesStyles } from './styles';

export interface IProps {
  topContent?: any;
  title?: string;
}

const TitlePages: React.FC<IProps> = ({ topContent, title, ...props }) => {
  return (
    <Container {...props} data-test="title-page-wrapper">
      {topContent && (
        <SubTitlePagesStyles data-test="title-page-subtitle">
          {topContent}
        </SubTitlePagesStyles>
      )}

      {title && (
        <Text fontSize={30} color="black" data-test="title-page-title">
          {title}
        </Text>
      )}
    </Container>
  );
};

export default TitlePages;
