import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const CheckBoxContent = styled.div`
  ${simpleFlex};
  justify-content: center;
  background-color: ${takeColor('black')};
  position: absolute;
  transition: 0.2s all;
  width: 0;
  height: 0;
  border-radius: 50%;
  overflow: hidden;

  > div {
    display: inline-flex;
  }
`;

interface ICheckBox {
  isChecked: boolean;
  width?: string;
  marginRightIcon?: string;
}

export const CheckBox = styled.div<ICheckBox>`
  ${simpleFlex};
  background-color: transparent;
  justify-content: center;
  margin-right: ${({ marginRightIcon = '15px' }) => marginRightIcon};
  border: 2px solid ${takeColor('grayCheckbox')};
  border-radius: 50%;
  width: ${({ width = '26px' }) => width};
  min-width: ${({ width = '26px' }) => width};
  height: ${({ width = '26px' }) => width};
  overflow: hidden;
  position: relative;
  transition: 0.2s all;

  ${({ isChecked }: ICheckBox) =>
    isChecked &&
    css`
      ${CheckBoxContent} {
        width: 100%;
        height: 100%;
      }

      border-color: ${takeColor('black')};
      background-color: ${takeColor('black')};
    `}
`;

export const WrapperField = styled.div``;

export const LabelWrapper = styled.label`
  ${simpleFlex};
  width: fit-content;
  cursor: pointer;
`;
