/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';

import Modal from 'components/Modal';

import { useUsersManagement } from 'containers/users-management';
import { IUserManagement } from 'containers/users-management/dtos';

import User from './User';
import Password from './Password';

interface IProps {
  isOpen: boolean;
  isEdit: boolean | IUserManagement;
  onClose: () => void;
}

enum EType {
  USER,
  PASSWORD,
}

export enum ETypeSteps {
  DEFAULT,
  LOADING,
  ERROR,
  SUCCESS,
}

const CreateUserModal: React.FC<IProps> = ({ isOpen, onClose, isEdit }) => {
  const {
    actions: { listUsers },
  } = useUsersManagement();

  const [type, setType] = useState<EType>(EType.USER);

  useEffect(() => {
    setType(EType.USER);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} noButtonClose onClose={onClose} minWidth="1280px">
      {isOpen && type === EType.USER && (
        <User
          isEdit={isEdit}
          onClose={onClose}
          onCreate={() => {
            listUsers();
            onClose();
          }}
          onGoToPassword={() => setType(EType.PASSWORD)}
        />
      )}
      {isOpen && type === EType.PASSWORD && <Password onClose={onClose} />}
    </Modal>
  );
};

export default CreateUserModal;
