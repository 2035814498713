import React from 'react';

import withSpecSheet from 'containers/spec-sheet';
import withSpecSheetModal from 'containers/spec-sheet-modal';

import ModalSpecSheet from 'components/ModalSpecSheet';

import Header from './partials/Header';
import Steps from './partials/Steps';

import { Container } from './styles';

const SpecSheet: React.FC = () => {
  return (
    <Container>
      <ModalSpecSheet />
      <Header />
      <Steps />
    </Container>
  );
};

export default withSpecSheet(withSpecSheetModal(SpecSheet));
