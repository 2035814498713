import styled, { css } from 'styled-components';

import { takeColor } from 'theme/tools';

const rowColor = '#F7F7F7';

export interface IContainerColumn {
  width?: string;
  columnBlack?: boolean;
  isLabel?: boolean;
  height?: string;
}

export const ContainerColumn = styled.div<IContainerColumn>`
  display: flex;
  padding: 4px 5px;
  flex: 1;
  font-size: 7.26px;
  word-break: break-word;
  align-items: center;

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
      flex: initial;
    `}

  ${({ columnBlack }) =>
    columnBlack &&
    css`
      color: ${takeColor('black')};
      font-weight: bold;
    `}

  & + & {
    border-left: 1px #a8a8a8 solid;
  }

  ${({ isLabel }) =>
    isLabel &&
    css`
      background-color: ${rowColor};
      font-weight: 600;
      color: ${takeColor('grayMedium')};
      min-height: 34px;

      align-items: center;

      & + & {
        align-items: center;
      }
    `}
`;

export interface IContainerRow {
  isLabel?: boolean;
  minHeight?: string;
}

export const ContainerRow = styled.div<IContainerRow>`
  display: flex;
  min-height: ${({ minHeight = '30px' }) => minHeight};
  color: ${takeColor('black')};

  & + & {
    border-top: 1px #a8a8a8 solid;
  }

  ${({ isLabel }) =>
    isLabel &&
    css`
      background-color: ${rowColor};
      font-weight: 600;
      min-height: ${({ minHeight = '25px' }: IContainerRow) => minHeight};
      color: ${takeColor('grayMedium')};

      ${ContainerColumn} {
        align-items: center;
      }
    `}
`;

export interface IContainerTable {
  width?: string;
}

export const ContainerTable = styled.div<IContainerTable>`
  width: ${({ width }) => width};
  border: 0.6px #a8a8a8 solid;
  border-radius: 6px;
  overflow: hidden;
`;
