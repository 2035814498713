/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, memo, useEffect, useState, useRef } from 'react';
import onlyNumbers from 'helpers/onlyNumbers';

import Modal from 'components/Modal';
import GridFlex from 'components/GridFlex';
import { Input } from 'components/Fields';
import Button from 'components/Button';
import Text from 'components/Text';
import Spinner from 'components/Spinner';
import { IData } from 'containers/spec-sheet/dtos';
import Icon from 'components/Icon';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  initialValue: number | undefined;
  changeSpecSheet: (data: IData) => Promise<void>;
}

const ModalRefNo: React.FC<IProps> = ({
  onClose,
  isOpen,
  initialValue,
  changeSpecSheet,
}) => {
  const focusDiv: any = useRef();
  const [refNo, setRefNo] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | ''>(null);
  const [success, setSuccess] = useState(false);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const data = onlyNumbers(value);
    if (data < 1000000000000) setRefNo(data);
  };

  useEffect(() => {
    if (isOpen && refNo !== initialValue) {
      setRefNo(initialValue);
    }

    if (!isOpen) {
      setLoading(false);
      setError(null);
      setSuccess(false);
    }
  }, [isOpen]);

  const isCompleted = !!refNo && initialValue !== refNo;

  const confirm = async () => {
    if (!isCompleted) return;

    setLoading(true);

    try {
      await changeSpecSheet({ refNo });
      setSuccess(true);
    } catch (err: any) {
      setError(err?.data?.message[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        focusDiv?.current?.focus();
      }, 50);
    }
  }, [isOpen]);

  return (
    <Modal
      minWidth="1280px"
      minHeight="480px"
      isOpen={isOpen}
      onClose={onClose}
      noButtonClose
      title={!loading && !success && 'Edit Ref. No'}
    >
      {loading && (
        <GridFlex
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="140px 0 0"
        >
          <Text fontSize={24} color="black" margin="0 0 30px">
            Saving changes
          </Text>

          <Spinner />
        </GridFlex>
      )}

      {success && (
        <GridFlex
          margin="0 0 0"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="check-circle" color="none" width="68px" />
          <Text
            fontSize={24}
            fontWeight="normal-bold"
            color="black"
            margin="30px 0 0"
          >
            Ref. No changed with success!
          </Text>
          <Button
            data-testid="button-ok"
            width="250px"
            margin="130px 0 0"
            onClick={onClose}
          >
            Ok
          </Button>
        </GridFlex>
      )}

      {!loading && !success && (
        <GridFlex
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Input
            label="Ref. No"
            value={refNo || ''}
            name="ref-no-edit"
            onChange={handleChange}
            isCompleted={!!refNo}
            dataTest="ref-no-edit"
            width="450px"
            error={error || ''}
            inputRef={focusDiv}
          />

          <GridFlex justifyContent="center" margin="100px 0 0">
            <Button
              onClick={confirm}
              width="266px"
              data-testid="button-confirm"
              scheme={!isCompleted ? 'disabled' : 'default'}
              margin="0 40px 0 0"
            >
              Save changes
            </Button>
            <Button
              data-testid="button-cancel"
              width="266px"
              scheme="nobg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </GridFlex>
        </GridFlex>
      )}
    </Modal>
  );
};

export default memo(ModalRefNo);
