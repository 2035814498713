import React from 'react';

import { useUsersManagement } from 'containers/users-management';

import captalize from 'helpers/captalize';
import getCountryCode from 'helpers/getCountryCode';

import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Text from 'components/Text';
import Status from 'components/Status';

import { Container, ContainerSpinner, WrapList, Item } from './styles';

const List: React.FC = () => {
  const {
    data: { loading, users },
    actions: { setIsEditModal },
  } = useUsersManagement();

  return (
    <Container>
      {loading ? (
        <ContainerSpinner>
          <Spinner size="big" />
        </ContainerSpinner>
      ) : (
        <>
          <Text fontSize={18} color="black" fontWeight="normal-bold">
            Users
          </Text>
          <WrapList>
            {users?.map(({ login, role, status, countrie, ...rest }) => (
              <Item key={rest.id} width="100%" height="auto">
                <div>
                  <p>E-mail</p>
                  <Text color="black">{login}</Text>
                </div>
                <div>
                  <p>Location</p>
                  <Text color="black">
                    {getCountryCode(countrie?.description as string)}
                  </Text>
                </div>
                <div>
                  <p>Role</p>
                  <Text color="black">{role.name}</Text>
                </div>
                <div>
                  <p>Status</p>
                  <Text color="black">
                    <Status type={status} />
                    {captalize(status)}
                  </Text>
                </div>
                <Button
                  width="123px"
                  padding="8px 0px"
                  onClick={() =>
                    setIsEditModal({ login, role, status, countrie, ...rest })
                  }
                >
                  Edit
                </Button>
              </Item>
            ))}
          </WrapList>
        </>
      )}
    </Container>
  );
};

export default List;
