import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import { WrapperButton, WrapperTextIcon } from './styles';

export enum Types {
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
}

export interface IProps {
  id: number | string;
  onClick: () => void;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  loading: boolean;
  error?: boolean;
  success?: boolean;
  type: Types;
}

const ModalDeleteOrCancel: React.FC<IProps> = ({
  id,
  onClick,
  onClose,
  onCancel,
  onConfirm,
  loading,
  open,
  success,
  error,
  type,
}) => {
  const mountTitle = () => {
    let text: any = null;
    if (!success && !error) {
      text = loading ? (
        <Spinner />
      ) : (
        `Do you really want to ${
          type === Types.DELETE ? 'delete' : 'cancel'
        } the spec "${id}"?`
      );
    }

    if (success && !error) {
      text = (
        <WrapperTextIcon>
          <Icon icon="check-circle" color="none" width="38px" />
          {`Spec sheet ${
            type === Types.DELETE ? 'deleted' : 'cancelled'
          } with success!`}
        </WrapperTextIcon>
      );
    }

    if (!success && error) {
      text = (
        <WrapperTextIcon>
          <Icon icon="close-error" color="none" width="38px" />
          Something went wrong. Please, try again later
        </WrapperTextIcon>
      );
    }

    return text;
  };

  const successOrError = success || error;

  return (
    <Modal
      isOpen={open}
      title={mountTitle()}
      onClose={onClose}
      marginTitle={!loading}
      minWidth="691px"
      noButtonClose
      data-testid="modal-delete-variable"
    >
      {successOrError && (
        <WrapperButton>
          <Button
            width="266px"
            onClick={onClick}
            data-testid="modal-delete-variable-button-no"
          >
            OK
          </Button>
        </WrapperButton>
      )}

      {!successOrError && !loading && (
        <WrapperButton>
          <Button
            width="266px"
            onClick={onConfirm}
            data-testid="modal-delete-variable-button-yes"
          >
            Yes
          </Button>

          <Button
            width="266px"
            onClick={onCancel}
            scheme="nobg"
            data-testid="modal-delete-variable-button-no"
          >
            No
          </Button>
        </WrapperButton>
      )}
    </Modal>
  );
};

export default ModalDeleteOrCancel;
