import styled from 'styled-components';

export const Container = styled.div`
  .numberSelected {
    display: none;
  }
`;

export const WrapperOptions = styled.div`
  padding: 0px 25px;

  > div {
    font-size: 11px;
  }
`;
