import styled from 'styled-components';

type TDrection =
  | 'initial'
  | 'column'
  | 'column-reverse'
  | 'row'
  | 'row-reverse';
type TJustity =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type TAlign =
  | 'initial'
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline';

type TFlex = 'initial' | number;

export interface IProps {
  direction?: TDrection;
  justifyContent?: TJustity;
  alignItems?: TAlign;
  flex?: TFlex;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
}

const GridFlex = styled.div<IProps>`
  display: flex;
  flex-direction: ${({ direction = 'initial' }) => direction};
  justify-content: ${({ justifyContent = 'initial' }) => justifyContent};
  align-items: ${({ alignItems = 'initial' }) => alignItems};
  margin: ${({ margin = '0' }) => margin};
  padding: ${({ padding = '0' }) => padding};
  flex: ${({ flex = 1 }) => flex};
  height: ${({ height = 'initial' }) => height};
`;

export default GridFlex;
