const Colors = {
  grayLight: '#EDEDED',
  grayMedium: '#686868',
  grayLightText: '#8A8A8A',
  grayLightMenu: '#6C6C6C',
  grayMediumMenu: '#2E2E2E',
  grayMedium2Menu: '#454545',
  grayBorderColor: '#EEEEEE',
  grayCheckbox: '#c2c2c2',
  disableButton: '#1f1f1f33',
  grayBorderBox: '#ECECEC',
  grayScrollBar: '#E3E3E3',
  grayInputSearch: '#F5F5F5',
  grayIconUpload: '#D6D6D6',
  grayChangeText: '#8F8F8F',
  deepBlack: '#000',
  black: '#1F1F1F',
  white: '#fff',
  success: '#6FCF97',
  error: '#EE5151',
  processing: '#E7C200',
};

export default Colors;
