const fixDecimals = (num: number, precision: number) => {
  return (Math.floor(num * 100) / 100).toFixed(precision);
};

/**
 * @param {string} str: value to convert
 * @param {bool} coerce: force float return or NaN
 */
const parseFloatFromString = (str: string, coerce: boolean) => {
  const value = String(str).trim();

  if (value === '') {
    return value;
  }

  // check if the string can be converted to float as-is:
  const parsed = parseFloat(value);
  if (String(parsed) === value) {
    return fixDecimals(parsed, 2);
  }

  // remove all non-digit characters:
  const split = value.split(/[^\dE-]+/);

  for (let i = 0; i < split.length; i += 1) {
    if (split[i] === '') {
      return coerce ? fixDecimals(parseFloat(String(0)), 2) : NaN;
    }
  }

  // use the last part as decimal:
  const decimal = split.pop();

  // reconstruct the number using dot as decimal separator to insert in cell:
  return fixDecimals(parseFloat(`${split.join('')}.${decimal}`), 2);
};

export default parseFloatFromString;
