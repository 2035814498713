import React from 'react';

import Icon from 'components/Icon';

import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';

import { usePagination } from './pagination';

import { Container, WrapperArrows, WrapperItems, Item } from './styles';

const VarNavigation: React.FC = () => {
  const {
    data: { skuDetails, skuDetailActive },
    actions: { setSkuDetail },
  } = useSpecSheetSkuDetail();
  const totalCount = skuDetails.length;

  const paginationRange = usePagination({
    currentPage: skuDetailActive,
    totalCount,
  });

  const onPrevious = () => {
    if (skuDetailActive > 0) {
      setSkuDetail(skuDetailActive - 1);
    }
  };

  const onAdvance = () => {
    if (skuDetailActive !== totalCount - 1) {
      setSkuDetail(skuDetailActive + 1);
    }
  };

  const clickPage = (item: number) => {
    setSkuDetail(item);
  };

  return totalCount > 1 ? (
    <Container>
      <WrapperArrows onClick={onPrevious} data-testid="back-page">
        <Icon icon="arrow-left" color="black" width="17px" />
      </WrapperArrows>
      <WrapperItems>
        {paginationRange.map(item => (
          <Item
            data-testid={`click-page-${item}`}
            active={item - 1 === skuDetailActive}
            onClick={() => item !== '...' && clickPage(item - 1)}
          >
            {item}
          </Item>
        ))}
      </WrapperItems>
      <WrapperArrows onClick={onAdvance} data-testid="advance-page">
        <Icon icon="arrow-right" color="black" width="17px" />
      </WrapperArrows>
    </Container>
  ) : (
    <></>
  );
};

export default VarNavigation;
