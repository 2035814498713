export const LandscapeOrientation = () => (
  <style type="text/css">
    {`
      @media print {
        @page {
          size: landscape;
        }
      }
    `}
  </style>
);
