import styled from 'styled-components';
import { takeColor, mixinRespondTo } from 'theme/tools';

export const Container = styled.div`
  background-color: ${takeColor('white')};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  margin-top: 30px;
  padding: 30px 40px;
`;

export const ContainerFields = styled.div`
  display: grid;

  ${mixinRespondTo.sm`
    gap: 0px;
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${mixinRespondTo.md`
    gap: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `}

  ${mixinRespondTo.lg`
    gap: 0px 35px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `}
`;

export const WrapperSelect = styled.div`
  margin-top: 23px;
`;

export const WrapperButtonSelect = styled.div`
  margin-top: 23px;

  button {
    font-size: 11px;
  }
`;

export const WrappCheckbox = styled.div`
  > label {
    padding-bottom: 12px;
  }
`;
