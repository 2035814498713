import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const WrapperStepsSpecSheet = styled.div`
  overflow: hidden;
  transition: 0.2s all;
  width: 65%;
  margin: auto;
  height: fit-content;
`;

interface IStepItem {
  active: boolean;
}

export const StepItem = styled('div')<IStepItem>`
  ${simpleFlex};
  cursor: pointer;
  margin-top: 30px;

  @media (max-height: 700px) {
    margin-top: 15px;
  }

  * {
    color: ${takeColor('grayLightMenu')};
    transition: 0.2s all;
  }

  p {
    margin-left: 10px;
  }

  span {
    width: 14px;
    height: 14px;
    display: block;
    border: 1px solid;
    border-radius: 50%;
  }

  &:hover {
    * {
      color: ${takeColor('white')};
    }
  }

  ${({ active }: IStepItem) =>
    active &&
    css`
      * {
        color: ${takeColor('white')};
      }

      span {
        background-color: currentColor;
      }
    `};
`;

export const Container = styled.div`
  position: relative;
  margin-top: 15px;
`;

export const WrapperButton = styled.div`
  ${simpleFlex};
  border-radius: 60px;
  width: 240px;
  height: 55px;
  padding: 0 32px;
  background-color: ${takeColor('grayMediumMenu')};

  @media (max-height: 700px) {
    height: 40px;
  }

  transition: 0.2s background-color;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & + & {
    margin-top: 15px;
  }

  svg {
    fill: ${takeColor('white')};

    * {
      fill: ${takeColor('white')};
    }
  }

  strong {
    margin-left: 20px;
    line-height: 18px;
    color: ${takeColor('white')};
  }
`;
