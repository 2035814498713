import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  margin-bottom: 25px;
`;

export const FiltersWrapp = styled.div`
  ${simpleFlex}
  flex-wrap: wrap;
  justify-content: center;

  button {
    font-size: 11px;
    width: initial;
    margin-right: 7px;
    margin-bottom: 7px;
    box-shadow: initial;
    font-weight: initial;

    @media print {
      & {
        font-size: 6px;
      }
    }

    strong {
      margin-right: 5px;
    }
  }
`;

export const SortByWrapp = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${takeColor('grayMedium')};
  margin-top: 20px;

  @media print {
    & {
      margin-top: 10px;
      font-size: 8px;
    }
  }
`;
