import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const ContainerButtons = styled.div`
  display: flex;

  button {
    margin-right: 20px;
  }

  margin-bottom: 40px;
`;

export const Container = styled.div`
  margin-bottom: 50px;

  .dsg-context-menu {
    .dsg-context-menu-item:first-child {
      display: none;
    }
  }

  .row-updated .dsg-cell {
    background: #fff7e6;
  }

  .dsg-row .dsg-cell:nth-child(4) {
    font-weight: bold !important;
  }

  margin-right: 20px;

  * {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 35px;
      height: 35px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${takeColor('grayIconUpload')};
      border-radius: 25px;
      background-clip: padding-box;
      border: 13px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${takeColor('grayMedium')};
    }
  }

  .dsg-row-header .dsg-cell {
    background-color: ${takeColor('black')};
    color: white;

    > div {
      font-size: 13px;
    }
  }
`;

export const ContainerActions = styled.div`
  ${simpleFlex}

  button {
    font-size: 10px;
    margin-right: 10px;
  }
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
