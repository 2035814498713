/* eslint-disable no-useless-escape */
export const hasUpperCase = (str: string) => {
  return str.toLowerCase() !== str;
};

const regexSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

export const hasSpecialCharacter = (str: string) => {
  if (regexSpecialCharacter.test(str)) {
    return true;
  }
  return false;
};
