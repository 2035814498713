import React from 'react';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { steps } from 'containers/dashboard/constants';

import { useDashboard } from 'containers/dashboard';

import { useNav } from 'containers/nav';
import { Container, WrapperStepsSpecSheet, StepItem } from './styles';
import { ItemMenu, ItemMenuSimple } from '../styles';

const DashboardItem: React.FC = () => {
  const {
    data: { openMenu },
  } = useNav();
  const {
    data: { stepActive },
    actions,
  } = useDashboard();

  return (
    <Container>
      <>
        <ItemMenu to="/dashboard" exact $isCollapsedMenu={!openMenu}>
          <Icon icon="dashboard" />
          <Text
            fontWeight="normal-bold"
            fontSize={15}
            tag="strong"
            color="white"
          >
            Dashboard
          </Text>
        </ItemMenu>

        {openMenu && (
          <WrapperStepsSpecSheet>
            {steps.map(({ name }, index) => {
              return (
                <StepItem
                  active={stepActive === index}
                  onClick={() => actions.changeStep(index)}
                  key={name}
                >
                  <span />{' '}
                  <Text color="white" fontSize={15}>
                    {name}
                  </Text>
                </StepItem>
              );
            })}
          </WrapperStepsSpecSheet>
        )}

        <ItemMenuSimple href="#" target="_blank" $isCollapsedMenu={!openMenu}>
          <Icon icon="filter-big" />
          <Text
            fontWeight="normal-bold"
            fontSize={15}
            tag="strong"
            color="white"
          >
            Reports
          </Text>
        </ItemMenuSimple>
      </>
    </Container>
  );
};

export default DashboardItem;
