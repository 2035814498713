/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import Button from 'components/Button';
import Text from 'components/Text';

import StampColor from '../Fields/StampColor';
import SoleColor from '../Fields/SoleColor';
import SoleEdgeColor from '../Fields/SoleEdgeColor';
import HeelColor from '../Fields/HeelColor';
import SoleFinishEdge from '../Fields/SoleFinishEdge';
import Stitch from '../Fields/Stitch';
import ColMatCode from '../Fields/ColMatCode';

import Field from '../Field';
import Actions from '../Actions';
import PrototypeImage from '../PrototypeImage';
import VarNavigation from '../VarNavigation';
import LineNumberMaterial from '../LineNumberMaterial';
import Notes from '../Notes';

import { Container } from './styles';

const Content: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Notes open={open} onClose={() => handleOpen()} />
      <PrototypeImage />

      <Actions />

      <VarNavigation />

      <Grid container spacing={2}>
        <Grid item md={4}>
          <Field label="Pairs" name="pair" />
        </Grid>
        <Grid item md={4}>
          <Field label="Size" name="size" />
        </Grid>
        <Grid item md={4}>
          <Field label="Width" name="width" />
        </Grid>
      </Grid>

      <LineNumberMaterial />

      <Grid container spacing={2} className="fields-wrapper">
        <Grid item md={12}>
          <StampColor />
        </Grid>

        <Grid item md={12}>
          <SoleColor />
        </Grid>

        <Grid item md={12}>
          <SoleEdgeColor />
        </Grid>

        <Grid item md={12}>
          <Field label="Welt" name="insoleBinding" />
        </Grid>

        <Grid item md={12}>
          <HeelColor />
        </Grid>

        <Grid item md={12}>
          <SoleFinishEdge />
        </Grid>

        <Grid item md={12}>
          <Stitch />
        </Grid>

        <Grid item md={12}>
          <ColMatCode />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Field label="Variable" name="variable" />
        </Grid>
        <Grid item md={6}>
          <Field label="Hardware" name="hardware" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Button
            onClick={() => handleOpen()}
            width="100%"
            scheme="gray"
            data-test="button-add-notes"
            data-testid="button-add-notes"
          >
            <Text fontSize={15} fontWeight="normal-bold" color="white">
              Add notes
            </Text>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Content;
