import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div``;

export const PrintView = styled.div`
  padding: 5px 22px 0px 22px;
  position: relative;
`;

export const ContainerButtons = styled.div`
  ${simpleFlex}
  justify-content: center;
  padding: 35px 0px;
  position: sticky;
  bottom: -35px;
  background-color: ${takeColor('white')};

  button:first-child {
    margin-right: 35px;
  }
`;

export const TitleFornova = styled.div`
  text-align: center;
  font-size: 28px;
  color: black;
  font-weight: bold;
  margin-bottom: 15px;
`;
