import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.form`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;

export const ContainerResetPasswordButton = styled.div`
  width: 100%;
  max-width: 450px;
  display: block;
  margin-top: 37px;
`;

export const WrapperPasswordTitle = styled.div`
  ${simpleFlex};
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 70px;
`;
