/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import EditImage from 'components/EditImage';
import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import ModalLettersAndComments from 'components/ModalLettersAndComments';

import { useSpecSheet } from 'containers/spec-sheet';
import { useSpecSheets } from 'containers/spec-sheets';
import { LoadingEnum } from 'containers/spec-sheet/dtos';
import { Type } from 'containers/create-sketch/dtos';

import {
  Container,
  ContentUpload,
  ContentActions,
  WrapperButton,
  MasterImage,
  WrapperText,
} from './styles';
import ModalFileError from './partials/ModalFileError';

const validFilesTypes = ['image/png', 'image/jpeg'];

const CreateSketch: React.FC = () => {
  const [error, setError] = useState(false);
  const [isModalLetter, setIsModalLetter] = useState(false);
  const [show, setShow] = useState(false);
  const [typeModal, setTypeModal] = useState<Type>(Type.Add);
  const {
    actions: {
      uploadMasterImage,
      updateMasterImage,
      getImage,
      getImageDetails,
    },
    data: { data, loading, masterImage, imageDetails },
  } = useSpecSheet();

  const {
    actions: { changeStep },
    data: { stepActive },
  } = useSpecSheets();

  useEffect(() => {
    if (masterImage?.id) getImageDetails(masterImage?.id);
  }, [masterImage?.id]);

  const haveComments = (comments: any) => {
    const f: any = comments?.filter((f: any) => {
      return f.type === 'COMMENT';
    }).length;
    return f;
  };

  const goBack = () => {
    changeStep(stepActive - 1);
  };

  const goToPrototype = () => {
    changeStep(stepActive + 1);
  };

  const handleOpenModalError = useCallback(() => setError(true), []);

  const handleCloseModalError = useCallback(() => setError(false), []);

  const handleModalLetters = (type: Type) => {
    setTypeModal(type);
    setIsModalLetter(!isModalLetter);
  };

  const onFileChange = (e: any) => {
    const selectedFile = e?.target.files[0];
    const contains = validFilesTypes.includes(selectedFile.type);

    if (!contains) {
      handleOpenModalError();
      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);

    if (!masterImage) {
      formData.append('isMandatory', '1');
      uploadMasterImage(data?.id, formData);
    } else {
      updateMasterImage(masterImage?.id, formData);
    }

    e.target.value = '';

    handleModalLetters(Type.Add);
  };

  const handleClose = () => {
    setShow(false);
  };

  const editFile = (e: any) => {
    const formData = new FormData();

    e.canvas.toBlob((blob: Blob) => {
      const file = new File([blob], e.imageName, { type: e.imageMime });
      formData.append('file', file, e.imageName);
      updateMasterImage(masterImage?.id, formData);
    });

    return false;
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loading === LoadingEnum.SAVING;
  const isLoadingImage = loading === LoadingEnum.IMAGE;

  return isLoadingImage ? (
    <GridFlex alignItems="center" justifyContent="center" margin="100px 0">
      <Spinner size="big" />
    </GridFlex>
  ) : (
    <>
      <Container>
        <Grid container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={!masterImage ? 12 : 5}>
              <ContentUpload active={!!masterImage}>
                {!isModalLetter && (
                  <span>
                    {masterImage?.url ? (
                      <WrapperButton data-testid="create-sketch-update-master-image">
                        <Button scheme="change" padding="5px 10px">
                          {isLoading ? (
                            <Spinner size="small" />
                          ) : (
                            'Change master image'
                          )}
                        </Button>
                        <input
                          type="file"
                          onChange={onFileChange}
                          accept=".png, .jpg, .jpeg"
                          disabled={isLoading}
                          data-testid="upload-change-master-image"
                          style={{ background: 'red' }}
                        />
                      </WrapperButton>
                    ) : null}
                    {haveComments(imageDetails) ? (
                      <Button
                        scheme="change"
                        padding="5px 0px"
                        margin="15px 0px"
                        width="80%"
                        onClick={() => handleModalLetters(Type.View)}
                        data-testid="button-open-modal-letters-and-numbers"
                      >
                        {isLoading ? <Spinner size="small" /> : 'See comments'}
                      </Button>
                    ) : null}
                  </span>
                )}

                {!masterImage ? (
                  <>
                    <Icon icon="upload" width="72px" color="grayIconUpload" />

                    <GridFlex
                      direction="column"
                      alignItems="center"
                      flex="initial"
                    >
                      <GridFlex>
                        <WrapperButton data-testid="create-sketch-create-master-image">
                          <Button scheme="grayLight" width="265px">
                            {isLoading ? (
                              <Spinner size="small" />
                            ) : (
                              'Upload master image'
                            )}
                          </Button>
                          <input
                            type="file"
                            onChange={onFileChange}
                            accept=".png, .jpg, .jpeg"
                            disabled={isLoading}
                            data-testid="upload-master-image"
                          />
                        </WrapperButton>
                      </GridFlex>

                      <WrapperText>
                        <p>
                          <Text
                            fontWeight="normal-bold"
                            fontSize={13}
                            color="grayLightText"
                          >
                            Supported file formats:
                          </Text>
                          <Text fontSize={13} color="grayLightText">
                            {' '}
                            JPEG (.jpg, .jpeg) and PNG (.png)
                          </Text>
                        </p>
                      </WrapperText>
                    </GridFlex>
                  </>
                ) : (
                  <>
                    {loading ? (
                      <Spinner size="big" />
                    ) : (
                      <MasterImage src={masterImage.url} alt="Upload" />
                    )}
                  </>
                )}
              </ContentUpload>

              {!!masterImage && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Button
                      scheme="grayLight"
                      onClick={() => setShow(true)}
                      data-testid="edit-master-image-button"
                    >
                      Edit master image
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      scheme="grayLight"
                      onClick={() => handleModalLetters(Type.Add)}
                    >
                      Add letters, numbers and comments
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item md={12}>
              <ContentActions justifyContent="center">
                <Button
                  scheme="nobg"
                  onClick={goBack}
                  width="265px"
                  data-testid="button-go-back"
                >
                  <Icon
                    icon="arrow-left"
                    width="15px"
                    color="black"
                    marginRight={15}
                  />
                  Go back
                </Button>

                <Button
                  onClick={goToPrototype}
                  width="265px"
                  data-testid="button-go-prototype"
                >
                  Go to Prototype
                  <Icon
                    icon="arrow-right"
                    width="15px"
                    color="white"
                    marginLeft={15}
                  />
                </Button>
              </ContentActions>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <ModalLettersAndComments
        url={masterImage?.url}
        isOpen={isModalLetter}
        onClose={() => handleModalLetters(Type.None)}
        idImage={masterImage?.id}
        type={typeModal}
      />

      <EditImage
        editFile={editFile}
        handleClose={handleClose}
        show={show}
        url={masterImage?.url}
      />

      <ModalFileError isOpen={error} hadleClose={handleCloseModalError} />
    </>
  );
};

export default CreateSketch;
