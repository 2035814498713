import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { useDashboard } from 'containers/dashboard';
import { IState, IDateFilter } from 'containers/dashboard/dtos';

import { Input, SmallSelect } from 'components/Fields';
import Button from 'components/Button';

import Modal from 'components/Modal';
import { WrapperSelect } from '../styles';

import {
  Container,
  ButtonsModal,
  Title,
  FieldContainer,
  ContainerModal,
} from './styles';

interface IProps {
  field: keyof IState['filters'];
  title: string;
}

const DateModal: React.FC<IProps> = ({ field, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    data: { filters },
    actions: { addToNormalFilters, resetNormalFilter },
  } = useDashboard();

  const dateSelected = filters[field] as IDateFilter;

  const handleSave = () => {
    addToNormalFilters(field, { startDate, endDate });
    setIsOpen(false);
  };

  const handleReset = () => {
    resetNormalFilter(field);
    setStartDate('');
    setEndDate('');
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setStartDate(dateSelected?.startDate);
      setEndDate(dateSelected?.endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isEndDateLessThanStartDate = () => {
    const sd = new Date(startDate).getTime();
    const ed = new Date(endDate).getTime();
    return ed < sd;
  };

  const isDisabled =
    !startDate ||
    startDate === '' ||
    !endDate ||
    endDate === '' ||
    isEndDateLessThanStartDate();

  return (
    <Container>
      <WrapperSelect>
        <SmallSelect
          dataTest="select-test"
          placeholder={title}
          maxHeight="270px"
          numberSelected={dateSelected ? 1 : null}
          onOpen={() => setIsOpen(true)}
        />
      </WrapperSelect>

      <Modal
        isOpen={!!isOpen}
        onClose={() => setIsOpen(false)}
        noButtonClose
        minWidth="1280px"
        minHeight="480px"
      >
        <ContainerModal>
          <Title>{title}</Title>
          <FieldContainer>
            <Grid item md={6}>
              <Input
                name="date-start-name"
                label="Start date"
                onChange={({ target: { value } }) => setStartDate(value)}
                placeholder="Click to add"
                width="451px"
                value={startDate || ''}
                dataTest="date-start"
                type="date"
              />
            </Grid>
            <Grid item md={6}>
              <Input
                name="date-end-name"
                label="End date"
                onChange={({ target: { value } }) => setEndDate(value)}
                placeholder="Click to add"
                width="451px"
                value={endDate || ''}
                dataTest="date-end"
                type="date"
                min={startDate}
              />
            </Grid>
          </FieldContainer>
          <ButtonsModal>
            <Button
              width="266px"
              data-testid="reset-date"
              onClick={handleReset}
              scheme={isDisabled ? 'disabled' : 'default'}
              disabled={isDisabled}
            >
              Reset filters
            </Button>
            <Button
              width="266px"
              data-testid="save-date"
              onClick={handleSave}
              scheme={isDisabled ? 'disabled' : 'default'}
              disabled={isDisabled}
            >
              Save
            </Button>
            <Button
              width="266px"
              onClick={() => setIsOpen(false)}
              scheme="nobg"
              data-testid="cancell-date"
            >
              Cancel
            </Button>
          </ButtonsModal>
        </ContainerModal>
      </Modal>
    </Container>
  );
};

export default DateModal;
