import React, { useEffect, useState } from 'react';

import { useCustomerOrderHistory } from 'containers/customer-order-history';
import {
  ICustomerOrderHistoryData,
  LoadingEnum,
} from 'containers/customer-order-history/dtos';

import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Item from './Item';

import {
  Container,
  ListContainer,
  LoadingWrapper,
  ButtonsModal,
  ReasonTitle,
  ReasonText,
} from './styles';

const REFRESH_TIME = 30000; // 30 seconds in ms

const Content: React.FC = () => {
  const { data, actions } = useCustomerOrderHistory();
  const [reasonModal, setReasonModal] = useState<string | null>(null);

  const { list, loading, pagination } = data;

  const page = pagination?.page || 1;
  const pages = pagination?.pages || 1;

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  useEffect(() => {
    actions.listCustomerOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const fetchInterval = setInterval(
      actions.listCustomerOrderHistory,
      REFRESH_TIME,
    );
    return () => clearInterval(fetchInterval);
  }, [actions.listCustomerOrderHistory]);

  const isLoading = loading === LoadingEnum.DEFAULT;

  return (
    <Container>
      <Modal
        isOpen={!!reasonModal}
        onClose={() => setReasonModal(null)}
        noButtonClose
        minWidth="691px"
      >
        <ReasonTitle>Error</ReasonTitle>
        <ReasonText>{reasonModal}</ReasonText>
        <ButtonsModal>
          <Button
            onClick={() => {
              copyToClipboard(reasonModal);
              setReasonModal(null);
            }}
          >
            Copy text
          </Button>
          <Button onClick={() => setReasonModal(null)} scheme="nobg">
            Cancel
          </Button>
        </ButtonsModal>
      </Modal>

      <Container>
        {isLoading ? (
          <LoadingWrapper>
            <Spinner size="big" />
          </LoadingWrapper>
        ) : (
          <ListContainer>
            {list.map((item: ICustomerOrderHistoryData) => (
              <Item {...item} onClickFail={setReasonModal} key={item.id} />
            ))}
          </ListContainer>
        )}

        <Pagination page={page} pages={pages} setPage={actions.setPage} />
      </Container>
    </Container>
  );
};

export default Content;
