/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import searchTextHook from 'helpers/searchTextHook';

import api from 'services/api';

import { Select, SelectItem } from 'components/Fields';
import Loading from '../partials/Loading';

const Country: React.FC = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: countries,
    },
  );

  let oldIdCountry: any;

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const countrySelected = data?.country;

  const getCountries = async () => {
    setLoading(true);

    try {
      const list: any = await api.get('/countries');

      setCountries(list);
      setFilteredItems(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    if (oldIdCountry !== countrySelected?.id) {
      oldIdCountry = countrySelected?.id;
      actions.getFactories({ idCountry: countrySelected?.id, isActive: 1 });
    }
  }, [countrySelected?.id]);

  useEffect(() => {
    getCountries();
  }, []);

  const handleChange = (newCountry: any) => {
    if (countrySelected?.id !== newCountry.id) {
      setSearch('');
      actions.changeSpecSheetData({
        country: newCountry,
        factory: null,
      });
    }
  };

  return (
    <Select
      label="Country"
      value={countrySelected?.description}
      withInputSearch
      inputSearchPlaceholder="Search country"
      inputSearchOnChange={setSearch}
      inputSearchvalue={search}
      dataTest="country"
      data-testid="country"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {filteredItems.map(({ id, description }) => (
            <SelectItem
              key={`countries-${id}`}
              onClick={() => handleChange({ id, description })}
              isSelected={id === countrySelected?.id}
              data-test={`select-item-option-country-${id}`}
              data-testid="item-country"
            >
              {description}
            </SelectItem>
          ))}
        </>
      )}
    </Select>
  );
};

export default Country;
