export function getPosition(e: any) {
  const x = e.nativeEvent.offsetX;
  const y = e.nativeEvent.offsetY;

  return { x, y };
}

export function getTotalSize(e: any) {
  return { x: e?.current?.clientWidth, y: e?.current?.clientHeight };
}
