import React, { useState, useEffect } from 'react';

import api from 'services/api';
import { useDebounce } from 'hooks/useDebounce';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import Button from 'components/Button';
import Text from 'components/Text';
import { Input } from 'components/Fields';

import ModalCreateSeason from 'components/ModalCreateSeason';

import Header from '../Header';
import ModalDeleteOrIsActive, { Types } from '../ModalDeleteOrIsActive';

import { Container } from './styles';
import { List, Item, ContainerActions, SwitchTheme } from '../styles';

interface IList {
  id: number;
  description: string;
  year: number;
  isActive?: boolean;
}

const endpoint = '/seasons';

const Season: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [list, setList] = useState<IList[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModalIsActive, setOpenModalIsActive] = useState<Types | boolean>(
    false,
  );
  const [seasonSelected, setSeasonSelected] = useState<any>(false);

  const getFactories = async () => {
    setLoading(true);

    const newList: IList[] = await api.get(`${endpoint}?description=${search}`);

    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getFactories();
  };

  useEffect(() => {
    saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <Container>
      <ModalCreateSeason
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSaved={() => getFactories()}
      />

      <Header
        onCreate={() => setModalOpen(true)}
        title="Season management"
        titleCreate="Create new season"
      />

      {!!openModalIsActive && (
        <ModalDeleteOrIsActive
          type={openModalIsActive as Types}
          endpoint={endpoint}
          itemName={`${seasonSelected.description} ${seasonSelected.year}`}
          id={seasonSelected.id}
          isActive={seasonSelected.isActive}
          onClose={() => setOpenModalIsActive(false)}
          onCreated={() => {
            if (openModalIsActive === Types.DELETE) {
              setList(list.filter(i => i.id !== seasonSelected.id));
            }
            if (openModalIsActive === Types.ISACTIVE) {
              const newList = list;

              newList[seasonSelected.index] = {
                ...newList[seasonSelected.index],
                isActive: !seasonSelected.isActive,
              };

              setList(newList);
            }
            setOpenModalIsActive(false);
          }}
        />
      )}

      <Input
        name="search"
        onChange={e => setSearch(e.target.value)}
        value={search}
        placeholder="Search season"
        width="100%"
        dataTest="search"
        leftContent={
          <Icon
            icon="search"
            color="grayLightText"
            width="18px"
            height="18px"
          />
        }
      />
      <List>
        {loading ? (
          <GridFlex justifyContent="center" alignItems="center">
            <Spinner />
          </GridFlex>
        ) : (
          <>
            {list.map(({ description, year, id, isActive }, index) => (
              <Item key={`${description}-${id}`}>
                <Text fontSize={14} fontWeight="normal" color="black">
                  {description} {year || ''}
                </Text>
                <ContainerActions>
                  <SwitchTheme
                    checked={!isActive}
                    data-testid={`button-row-isActive-${id}`}
                    onClick={() => {
                      setOpenModalIsActive(Types.ISACTIVE);
                      setSeasonSelected({
                        description,
                        id,
                        isActive,
                        index,
                        year,
                      });
                    }}
                  />
                  <Button
                    width="95px"
                    padding="6px 30px"
                    data-testid={`button-row-delete-${id}`}
                    onClick={() => {
                      setOpenModalIsActive(Types.DELETE);
                      setSeasonSelected({
                        description,
                        id,
                        isActive,
                        index,
                        year,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </ContainerActions>
              </Item>
            ))}
          </>
        )}
      </List>
    </Container>
  );
};

export default Season;
