import { ReactComponent as SpecSheet } from './spec-sheet.svg';
import { ReactComponent as StyleManager } from './style-manager.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Treedots } from './treedots.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as CloseCircle } from './close-circle.svg';
import { ReactComponent as SearchPlus } from './search-plus.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Duplicate } from './Duplicar.svg';
import { ReactComponent as Delete } from './Deletar.svg';
import { ReactComponent as Cancel } from './Cancelar.svg';
import { ReactComponent as Print } from './Imprimir.svg';
import { ReactComponent as Download } from './Download.svg';
import { ReactComponent as CloseError } from './close-error.svg';
import { ReactComponent as EyeCircle } from './eye-circle.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as ArrowLeftCircle } from './arrow-left-circle.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as FilterBig } from './filter-big.svg';
import { ReactComponent as CloseBlack } from './close-black.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Xlsx } from './xlsx.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Book } from './book.svg';
import { ReactComponent as CheckPage } from './check-page.svg';
import { ReactComponent as Technical } from './technical.svg';
import { ReactComponent as MoneyPc } from './money-pc.svg';
import { ReactComponent as Money } from './money.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Import } from './import.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as WaitingCircle } from './waiting-circle.svg';

export default {
  'spec-sheet': SpecSheet,
  'style-manager': StyleManager,
  check: Check,
  close: Close,
  plus: Plus,
  copy: Copy,
  search: Search,
  treedots: Treedots,
  arrow: Arrow,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  upload: Upload,
  comment: Comment,
  trash: Trash,
  'close-circle': CloseCircle,
  'search-plus': SearchPlus,
  'check-circle': CheckCircle,
  eye: Eye,
  duplicate: Duplicate,
  delete: Delete,
  cancel: Cancel,
  print: Print,
  download: Download,
  'close-error': CloseError,
  'eye-circle': EyeCircle,
  lock: Lock,
  logo: Logo,
  'arrow-left-circle': ArrowLeftCircle,
  filter: Filter,
  'filter-big': FilterBig,
  'close-black': CloseBlack,
  dashboard: Dashboard,
  xlxs: Xlsx,
  house: House,
  settings: Settings,
  book: Book,
  'check-page': CheckPage,
  technical: Technical,
  'money-pc': MoneyPc,
  money: Money,
  refresh: Refresh,
  import: Import,
  warning: Warning,
  'waiting-circle': WaitingCircle,
};
