/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { Select, SelectItem } from 'components/Fields';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';

export enum EStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVE = 'inactive',
}

const list = [
  { name: 'Active', key: EStatus.ACTIVE },
  { name: 'Blocked', key: EStatus.BLOCKED },
  { name: 'Inactive', key: EStatus.INACTIVE },
];

interface IProps {
  setValue: (value: EStatus) => void;
  value: string | undefined;
}

const UserStatus: React.FC<IProps> = ({ setValue, value }) => {
  const selectedValue = list?.find(({ key }) => key === value)?.name;

  return (
    <Select
      placeholder="Click to select"
      label="User Status"
      dataTest="user-status"
      data-testid="user-status"
      width="450px"
      value={selectedValue}
    >
      {list ? (
        list.map(({ name, key }) => {
          const dataTest = `user-status-select-item-${key}`;

          return (
            <SelectItem
              onClick={() => setValue(key)}
              data-testid={dataTest}
              key={dataTest}
            >
              {name}
            </SelectItem>
          );
        })
      ) : (
        <GridFlex justifyContent="center" width="100%">
          <Spinner />
        </GridFlex>
      )}
    </Select>
  );
};

export default UserStatus;
