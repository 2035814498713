import styled, { css } from 'styled-components';

import { takeColor } from 'theme/tools';

interface IContainer {
  width?: string;
  height?: string;
  notBorder?: boolean;
}

export const Container = styled.div`
  border-radius: 13px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  position: relative;
  width: ${({ width }: IContainer) => width};
  height: ${({ height }: IContainer) => height};

  ${({ notBorder }: IContainer) =>
    !notBorder &&
    css`
      border: 1px solid ${takeColor('grayBorderBox')};
    `}
`;
