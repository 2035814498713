import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const WrapperStepsSpecSheet = styled.div`
  overflow: hidden;
  transition: 0.2s all;
  width: 65%;
  margin: auto;
  height: fit-content;
`;

interface IStepItem {
  active: boolean;
}

export const StepItem = styled('div')<IStepItem>`
  ${simpleFlex};
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 15px;

  @media (max-height: 700px) {
    margin-top: 15px;
  }

  * {
    color: ${takeColor('grayLightMenu')};
    transition: 0.2s all;
  }

  p {
    margin-left: 10px;
  }

  span {
    width: 14px;
    height: 14px;
    display: block;
    border: 1px solid;
    border-radius: 50%;
  }

  &:hover {
    * {
      color: ${takeColor('white')};
    }
  }

  ${({ active }: IStepItem) =>
    active &&
    css`
      * {
        color: ${takeColor('white')};
      }

      span {
        background-color: currentColor;
      }
    `};
`;

export const Container = styled.div`
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
`;
