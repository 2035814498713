import React, { useState } from 'react';

import { useUsersManagement } from 'containers/users-management';

import TitlePages from 'components/TitlePages';
import Button from 'components/Button';
import Icon from 'components/Icon';

import CreateUserModal from '../CreateUserModal';

import { Container, ContentRight } from './styles';

const Header: React.FC = () => {
  const {
    data: { isEdit },
    actions: { setIsEditModal },
  } = useUsersManagement();

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <CreateUserModal
        isOpen={!!(isOpenModal || isEdit)}
        onClose={() => {
          setIsOpenModal(false);
          setIsEditModal(false);
        }}
        isEdit={isEdit}
      />

      <Container>
        <TitlePages title="User management" topContent="ADMIN" />

        <ContentRight>
          <Button
            padding="15px 50px"
            data-testid="modal-user-create-open"
            onClick={() => setIsOpenModal(true)}
          >
            Create new user
            <Icon icon="arrow-right" marginLeft={15} width="13px" />
          </Button>
        </ContentRight>
      </Container>
    </>
  );
};

export default Header;
