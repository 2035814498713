import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

interface IPropsToggle {
  isOpen: boolean;
}

export const Container = styled.div`
  margin-top: 50px;
  margin-right: 40px;
  margin-bottom: 65px;
  border-radius: 12px;
  background-color: ${takeColor('grayInputSearch')};
  padding: 35px 20px;

  @media (min-width: 1420px) {
    padding: 35px 50px;
  }
`;

export const TitlesToggle = styled.div<IPropsToggle>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      p:first-child {
        margin-bottom: 8px;
      }
    `}

  ${simpleFlex}
  justify-content: space-between;
`;

export const ContainerButton = styled.div<IPropsToggle>`
  div {
    transition: 0.2s all;
    transform: rotate(90deg);
    cursor: pointer;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(270deg);
      `}
  }
`;

export const ContainerFields = styled.div`
  display: grid;
  gap: 0px 18px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1250px) {
    display: grid;
    gap: 0px 18px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ContainerFieldsNoOrder = styled.div`
  @media (max-width: 1420px) {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 18px;
      min-width: 170px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @media (min-width: 1420px) {
    display: grid;
    gap: 0px 18px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1250px) {
    > div {
      margin-right: 0px;
    }

    display: grid;
    gap: 0px 18px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ContentFilters = styled.div`
  margin-top: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-top: 55px;

    &:first-child {
      margin-right: 22px;
    }
  }
`;
