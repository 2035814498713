import styled, { css } from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  position: relative;
  border: 6px solid #f1f1f1;
  border-radius: 50%;
  width: 95px;
  height: 95px;
`;

export const WrapperContent = styled.div`
  ${simpleFlex}
  justify-content: center;
  background-color: ${takeColor('white')};
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

interface IHalfCircle {
  percentage: number;
}

export const WrapperFakeBorder = styled.div<IHalfCircle>`
  width: 100px;
  height: 100px;
  position: absolute;
  clip: ${({ percentage }) =>
    `rect(0px, 100px, 100px, ${percentage < 50 ? '50px' : 0});`};
  top: -6px;
  left: -6px;
`;

export const halfCircle = css`
  width: 95px;
  height: 95px;
  border: 6px solid ${takeColor('black')};
  border-radius: 50px;
  position: absolute;
  clip: rect(0px, 50px, 100px, 0px);
`;

export const HalfCircleFirst = styled.div<IHalfCircle>`
  ${halfCircle};

  transform: ${({ percentage }) => {
    const newPercentage = percentage * 2;

    const rotate =
      newPercentage < 100 ? `calc(180deg * 0.${newPercentage})` : '180deg';

    return `rotate(${rotate})`;
  }};
`;

export const HalfCircleSecond = styled.div<IHalfCircle>`
  ${halfCircle};

  transform: ${({ percentage }) => {
    const newPercentage = percentage * 2 - 100;
    const deg = 180 * Number(`0.${newPercentage}`);

    const rotate = newPercentage < 100 ? `-${180 - deg}deg` : '0';

    return `rotate(${rotate})`;
  }};
`;
