import React, { ChangeEvent, useEffect, useState, memo } from 'react';

import { Input } from 'components/Fields';
import { useDebounce } from 'hooks/useDebounce';

interface IProp {
  initValue: string | null;
  name: string;
  changeValue: (value: string) => void;
}

const LetterNumber: React.FC<IProp> = ({ initValue, name, changeValue }) => {
  const description = initValue || '';

  const [value, setValue] = useState(description);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  const autoSave = useDebounce(value);

  const saveChange = async () => {
    changeValue(value);
  };

  useEffect(() => {
    if (description !== value) saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <Input
      name={name}
      label="Letters/numbers"
      onChange={handleChange}
      dataTest={name}
      placeholder="Add text"
      width="200px"
      value={value}
    />
  );
};

export default memo(LetterNumber);
