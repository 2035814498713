import styled from 'styled-components';

import { takeColor } from 'theme/tools';
import { Selected } from 'containers/create-sketch/dtos';

export interface IProps {
  x: any;
  y: any;
  cx: any;
  cy: any;
  valueCenter: number;
  isError?: boolean;
  selected?: Selected;
  type?: string;
}

export const Container = styled.div<IProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${takeColor('black')};
  border: 1px solid ${takeColor('white')};
  position: absolute !important;
  top: ${props => `calc(${props.y}px - 6px)` || 0};
  left: ${props => `calc(${props.x}px - 6px)` || 0};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
`;

export const ContentLine = styled.div`
  position: relative;
`;

export const ContentText = styled.div`
  position: relative;
`;

export const BoxDelete = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%50%;
  background: ${takeColor('white')};
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: -7px;
  right: -7px;
  color: #8d8d8d;
  font-size: 12px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
`;

export const Text = styled.div<IProps>`
  width: 40px;
  height: 40px;
  background: ${takeColor('black')};
  border: 1px solid
    ${props => (props.isError ? takeColor('error') : takeColor('white'))};
  border-radius: 50%;
  position: absolute;
  top: -19px;
  left: -19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: ${takeColor('white')};

  :hover ${BoxDelete} {
    opacity: 1;
  }
`;

export const Input = styled.input`
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  font-size: 17px;
  color: ${takeColor('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
