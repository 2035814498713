import React from 'react';

import Box from 'components/Box';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { differenceDate } from 'helpers/date';
import { Link } from 'react-router-dom';

import { ListItem, WrapperText } from '../styles';
import { ToolTip, WrapperSpecSheet, WrapperSpecSheetOptions } from './styles';

export interface IProps {
  item: {
    id: number;
    refNo: number;
    description?: string;
    image?: string;
    createdAt: string;
  };
  onClick?: () => void;
}

const SpecSheet: React.FC<IProps> = ({ item, onClick }) => {
  const { id, refNo, description, image, createdAt } = item;

  const styleNameBigger =
    description &&
    description.length > 18 &&
    `${`${description.substring(0, 18)}...`}`;

  return (
    <ListItem
      data-test="spec-sheet-item"
      data-testid={`spec-sheet-item-id-${id}`}
      style={{ position: 'relative' }}
    >
      <WrapperSpecSheetOptions data-testid="actions-menu" onClick={onClick}>
        <Icon icon="treedots" color="grayCheckbox" width="19px" />
      </WrapperSpecSheetOptions>
      <Link to={`/spec-sheet/${id}`}>
        <Box>
          <WrapperSpecSheet>
            {image ? (
              <img src={image} alt="spec-sheet" />
            ) : (
              <Icon
                icon="copy"
                color="grayBorderBox"
                height="76px"
                width="76px"
              />
            )}
          </WrapperSpecSheet>
        </Box>

        <WrapperText data-testid="hover-text">
          {styleNameBigger && <ToolTip>{description}</ToolTip>}
          <Text
            color="black"
            fontSize={15}
            fontWeight="normal-bold"
            data-test="spec-sheet-item-text"
          >
            {styleNameBigger || description || refNo || id}
          </Text>
          <Text
            color="grayMedium"
            fontSize={12}
            data-test="spec-sheet-item-date"
          >
            {differenceDate(new Date(), createdAt)}
          </Text>
        </WrapperText>
      </Link>
    </ListItem>
  );
};

export default SpecSheet;
