import styled, { AnyStyledComponent, css } from 'styled-components';

import { takeColor } from 'theme/tools';

const styleUnLogged = `
  width: 500px;
`;

const styleLogged = `
  width: 292px;
  border-radius: 0 20px 20px 0;
`;

const styleLoggedWithMenuClosed = `
  width: 102px;
  border-radius: 0 20px 20px 0;
`;

const CommonStyles = styled.div`
  background-color: ${takeColor('black')};
`;

export const ContentWrapper: AnyStyledComponent = styled(CommonStyles)`
  ${styleUnLogged};
  position: relative;
  transition: 0.2s width, 0.2s border-radius;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
`;

interface IMenuIsOpen {
  isOpenMenu: string;
}

interface IContainer extends IMenuIsOpen {
  isLogged: string;
}

export const Container: AnyStyledComponent = styled.div`
  ${styleUnLogged};
  transition: 0.2s width;
  height: 100vh;

  ${({ isLogged, isOpenMenu }: IContainer) =>
    isLogged && isOpenMenu
      ? css`
          ${styleLogged};

          ${ContentWrapper} {
            ${styleLogged};
          }
        `
      : isLogged &&
        !isOpenMenu &&
        css`
          ${styleLoggedWithMenuClosed};

          ${ContentWrapper} {
            ${styleLoggedWithMenuClosed};
          }
        `};
`;

export const ExpandMenuContainer: AnyStyledComponent = styled.div`
  position: absolute;
  cursor: pointer;

  svg {
    transition: 0.2s all;
  }

  ${({ isOpenMenu }: IMenuIsOpen) =>
    isOpenMenu
      ? css`
          right: 25px;
        `
      : css`
          right: 43px;

          svg {
            transform: rotate(90deg) !important;
          }
        `}

  top: 120px;
`;

export const FozVersion = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  color: ${takeColor('grayMedium')};
  font-size: 13px;
`;
