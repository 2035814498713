import React from 'react';
import { Grid } from '@material-ui/core';

import { useSpecSheet } from 'containers/spec-sheet';

import { ContainerBoxTopRadius, BoxTopRadius } from './styles';

const BoxTopSketch: React.FC = () => {
  const {
    data: { data },
  } = useSpecSheet();

  return (
    <Grid container>
      <Grid item md={12}>
        <ContainerBoxTopRadius>
          <BoxTopRadius data-testid="top-ref-no">
            Ref. No <span>{data?.refNo || data?.id}</span>
          </BoxTopRadius>
          <BoxTopRadius data-testid="top-style-name">
            Style name <span>{data?.description}</span>
          </BoxTopRadius>
        </ContainerBoxTopRadius>
      </Grid>
    </Grid>
  );
};

export default BoxTopSketch;
