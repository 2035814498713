import React from 'react';

import TitlePages from 'components/TitlePages';

import Text from 'components/Text';
import { Container } from './styles';

const Header: React.FC = () => {
  return (
    <>
      <Container>
        <TitlePages title="Data management" topContent="ADMIN" />

        <Text color="grayMedium" fontSize={18} margin="50px 0 0">
          View and add new fields for the Style Manager
        </Text>
      </Container>
    </>
  );
};

export default Header;
