import React, { memo } from 'react';

import { useUser } from 'containers/user';

import Modal from 'components/Modal';
import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import Button from 'components/Button';

import { ContainerButton } from './styles';

const ErrorModal: React.FC = () => {
  const {
    data: { differentError },
    actions,
  } = useUser();

  return (
    <Modal
      isOpen={!!differentError}
      onClose={actions.clearErrors}
      minWidth="690px"
      noButtonClose
      data-testid="modal-error-login"
    >
      <GridFlex alignItems="center" justifyContent="center" direction="column">
        <Text fontSize={24} color="black" data-testid="modal-error-login-title">
          {differentError}
        </Text>
        <br />
        <Text
          fontSize={16}
          color="black"
          fontWeight="medium"
          data-testid="modal-error-login-subtitle"
        >
          Please contact the System Admin.
        </Text>

        <ContainerButton>
          <Button
            width="265px"
            onClick={actions.clearErrors}
            data-testid="modal-error-login-button"
          >
            Ok
          </Button>
        </ContainerButton>
      </GridFlex>
    </Modal>
  );
};

export default memo(ErrorModal);
