/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import api from 'services/api';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import { WrapperButton, WrapperTextIcon } from './styles';

export enum Types {
  ISACTIVE = 'ISACTIVE',
  DELETE = 'DELETE',
}

interface IProps {
  endpoint: string;
  type?: Types;
  isActive?: boolean;
  itemName?: string;
  id?: number;
  onClose: () => void;
  onCreated: () => void;
}

const ModalDeleteOrIsActive: React.FC<IProps> = ({
  onClose,
  onCreated,
  isActive,
  itemName,
  id,
  type,
  endpoint,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeModal = () => {
    setError(false);
    setLoading(false);
    setSuccess(false);
    onClose();
  };

  const closeCreated = () => {
    setError(false);
    setLoading(false);
    setSuccess(false);
    onCreated();
  };

  const mountTitle = () => {
    if (loading) return <Spinner />;

    if (success && !error)
      return (
        <WrapperTextIcon>
          <Icon icon="check-circle" color="none" width="38px" />
          {`"${itemName}" ${
            type === Types.DELETE
              ? 'deleted'
              : `${isActive ? 'disable' : 'enable'}`
          } with success!`}
        </WrapperTextIcon>
      );

    if (!success && error) {
      return (
        <WrapperTextIcon>
          <Icon icon="close-error" color="none" width="38px" />
          Something went wrong. Please, try again later
        </WrapperTextIcon>
      );
    }

    if (type === Types.DELETE) return `Do you want to delete "${itemName}"?`;

    return `Do you want to ${isActive ? 'disable' : 'enable'} "${itemName}"?`;
  };

  const makeAction = async () => {
    setLoading(true);

    try {
      if (type === Types.DELETE) await api.delete(`${endpoint}/${id}`);
      else
        await api.patch(`${endpoint}/${id}/is-active`, {
          isActive: !isActive,
        });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen
      title={mountTitle()}
      onClose={closeModal}
      minWidth="691px"
      noButtonClose
      data-testid="modal-delete"
    >
      {success || error ? (
        <WrapperButton>
          <Button
            width="266px"
            onClick={() => (success ? closeCreated() : closeModal())}
            data-testid="modal-delete-button-no"
          >
            OK
          </Button>
        </WrapperButton>
      ) : (
        <>
          {loading ? (
            <WrapperButton>
              <Button
                width="266px"
                onClick={closeModal}
                data-testid="modal-delete-button-no"
                scheme="nobg"
              >
                Cancel
              </Button>
            </WrapperButton>
          ) : (
            <WrapperButton>
              <Button
                width="266px"
                data-testid="modal-delete-button-yes"
                onClick={makeAction}
              >
                Yes
              </Button>

              <Button
                width="266px"
                onClick={closeModal}
                scheme="nobg"
                data-testid="modal-delete-button-no"
              >
                No
              </Button>
            </WrapperButton>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalDeleteOrIsActive;
