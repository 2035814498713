/* eslint-disable consistent-return */
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

export const dateDefault = (date?: string | Date) =>
  format(date ? new Date(date) : new Date(), 'MM/dd/yyyy');

export const differenceDate = (d1: any, d2: any) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const minutes = differenceInMinutes(date1, date2);
  const hours = differenceInHours(date1, date2);
  const days = differenceInDays(date1, date2);
  const months = differenceInMonths(date1, date2);
  const years = differenceInYears(date1, date2);

  if (minutes < 1) {
    return 'now';
  }

  if (minutes >= 1 && minutes < 60) {
    return `${minutes} min ago`;
  }

  if (hours >= 1 && hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  }

  if (days >= 1 && months < 1) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }

  if (months >= 1 && years < 1) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  }

  if (years >= 1) {
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  }
};
