/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useCallback, useContext, memo } from 'react';

import initialActions from './actions';

import { IState, IActions } from './dtos';

import { initialState } from './constants';

interface IData {
  data: IState;
  actions: IActions;
}

export const UsersManagementContext = React.createContext<IData>({
  data: initialState,
  actions: initialActions({ data: initialState, setData: () => {} }),
});

export const useUsersManagement = (): IData =>
  useContext(UsersManagementContext);

export default function withUsersManagementProvider(
  WrappedComponent: React.FC,
  state: IState = initialState,
  actions = initialActions,
): React.FC {
  const WithUsersManagement = (props: any) => {
    const [data, setData] = useState(state);

    const value = useCallback(
      () => ({
        data,
        actions: actions({ data, setData }),
      }),
      [data],
    );

    const dataValue: IData = value();

    return (
      <UsersManagementContext.Provider value={dataValue}>
        <WrappedComponent {...props} />
      </UsersManagementContext.Provider>
    );
  };

  return memo(WithUsersManagement);
}
