/* eslint-disable no-param-reassign */
import React from 'react';

import Text from 'components/Text';

import { ReportItem } from 'containers/spec-sheet-search/dtos';

import { Container, Colors, Group } from './styles';

interface IProps {
  items: ReportItem['items'];
}

const GroupVariables: React.FC<IProps> = ({ items }) => {
  // Group array to sku grouped
  const groupToSku: any =
    items && items.length
      ? items.reduce((obj, { color, material, value, ...item }) => {
          obj[item.idSku] = obj[item.idSku] || [];
          obj[item.idSku].push({ color, material, value });
          return obj;
        }, {})
      : [];

  const newArrayGroupedBySameSkuAndMaterials: any = [];

  // Check if variable is equal a another variable
  const checkerifIsSameVariable = (arr, target) =>
    target?.every(
      targetItem =>
        arr?.filter(
          item =>
            item.material === targetItem.material &&
            item.value === targetItem.value,
        ).length > 0,
    );

  // Pass a variable and return variabled that is equal
  const returnIfVariableIsGrouped = target => {
    const arrayGrouped: any = [];

    Object.keys(groupToSku).forEach(i => {
      if (
        checkerifIsSameVariable(groupToSku[i], target) &&
        groupToSku[i].length === target.length
      ) {
        arrayGrouped.push(...groupToSku[i]);
      }
    });

    return arrayGrouped;
  };

  // Loop in ids of variables and fill the new array with groups
  Object.keys(groupToSku).forEach(key => {
    const returnVerify = returnIfVariableIsGrouped(groupToSku[key]);

    if (
      !newArrayGroupedBySameSkuAndMaterials.some(
        item => JSON.stringify(item) === JSON.stringify(returnVerify),
      )
    ) {
      newArrayGroupedBySameSkuAndMaterials.push(returnVerify);
    }
  });

  const groupByMaterialAndValue: any = [];

  // Loop in groups and group to material and value
  newArrayGroupedBySameSkuAndMaterials.forEach(item => {
    const group_to_values = item.reduce((obj, item) => {
      obj[`${item.value || ''} ${item.material || ''}`] =
        obj[`${item.value || ''} ${item.material || ''}`] || [];
      obj[`${item.value || ''} ${item.material || ''}`].push(item);
      return obj;
    }, {});

    const groups: any = Object.keys(group_to_values).map(key => {
      return { group: key, color: group_to_values[key] };
    });

    groupByMaterialAndValue.push(groups);
  });

  return (
    <Container>
      <>
        {groupByMaterialAndValue.map(item => (
          <Group>
            {item.map(({ group, color }) => (
              <div>
                <Text fontSize={12} color="black" fontWeight="normal-bold">
                  {group}
                </Text>
                {color.map(c => (
                  <Colors>
                    <Text fontSize={12} color="black" fontWeight="normal-bold">
                      {`${c.value || ''} ${c.color || ''}`}
                    </Text>
                  </Colors>
                ))}
              </div>
            ))}
          </Group>
        ))}
      </>
    </Container>
  );
};

export default GroupVariables;
