import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import GlobalStyles from './theme/global';

import Main from './components/Main';

import history from './routes/history';

import containers from './containers';

const App: React.FC = () => {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Main />
        <GlobalStyles />
      </ThemeProvider>
    </Router>
  );
};

export default containers(App);
