import styled from 'styled-components';

import { FieldStyle } from 'components/Fields/styles';
import { mixinRespondTo, simpleFlex } from 'theme/tools';

export const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  z-index: 999;
  overflow-y: auto;
`;

export const ContainerFilter = styled.div`
  ${FieldStyle} {
    padding: 15px 35px;
    background-color: white;
  }

  input {
    font-size: 15px;
  }

  min-height: 100vh;
  flex: 1;
  padding: 0 55px;
  width: 100%;
`;

export const FakeMenu = styled.div`
  ${mixinRespondTo.md`
      width: 150px;
  `}

  ${mixinRespondTo.lg`
      width: 292px;
  `}
`;

export const BackButton = styled.div`
  cursor: pointer;
  ${simpleFlex}

  > p {
    margin-left: 18px;
  }
`;

export const ContainerActions = styled.div`
  margin-top: 50px;
  ${simpleFlex}
  justify-content: space-between;

  button {
    font-size: 13px;
  }
`;
