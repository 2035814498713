import React, { ChangeEvent, useEffect, useRef } from 'react';

import { SelectInputSearchStyles } from './styles';

export interface IProps {
  onChange: (value: string) => void;
  placeholder?: string;
  type?: 'text';
  value?: string;
  name: string;
  width?: string;
}

const InputSearch: React.FC<IProps> = ({ onChange, ...props }) => {
  const inputRef: any = useRef(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 50);
  }, []);

  return (
    <SelectInputSearchStyles
      onChange={handleChange}
      ref={inputRef}
      {...props}
    />
  );
};

export default InputSearch;
