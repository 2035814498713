import React from 'react';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';

import Text from 'components/Text';
import Spinner from 'components/Spinner';

import TableSpecs from './TableSpecs';

import { Container, NoResultsContainer, LoadingContainer } from './styles';

const ResultList: React.FC = () => {
  const {
    data: { list, loading },
  } = useSpecSheetSearch();

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <Spinner size="big" />
        </LoadingContainer>
      ) : (
        <>
          {list.length ? (
            <TableSpecs />
          ) : (
            <NoResultsContainer>
              <Text color="white" fontSize={30} fontWeight="normal">
                No results found
              </Text>
              <Text color="white" fontSize={18} fontWeight="medium">
                Sorry, we couldn&apos;t find any matches. Please try searching
                again.
              </Text>
            </NoResultsContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default ResultList;
