import React, { useState, useCallback, useMemo } from 'react';

import api from 'services/api';

import Box from 'components/Box';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import history from 'routes/history';

import { useSpecSheet } from 'containers/spec-sheet';

import { ListItem, WrapperPlus, WrapperText } from '../styles';

const NewSpecSheet: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { actions } = useSpecSheet();

  const handleCreateNewSpecSheet = useCallback(async () => {
    setLoading(true);

    try {
      const data: any = await api.post('/spec-sheets');
      history.push(`/spec-sheet/${data.id}`);
    } catch (err) {
      console.log('err: ', err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return useMemo(
    () => (
      <ListItem
        onClick={handleCreateNewSpecSheet}
        data-testid="new-spec-sheet-button"
      >
        <Box notBorder>
          <WrapperPlus>
            {loading ? (
              <Spinner size="big" />
            ) : (
              <div className="has-border">
                <Icon icon="plus" color="white" height="35px" width="35px" />
              </div>
            )}
          </WrapperPlus>
        </Box>

        <WrapperText>
          <Text
            color="black"
            fontSize={15}
            fontWeight="normal-bold"
            data-testid="new-spec-sheet-text"
          >
            Create new spec sheet
          </Text>
        </WrapperText>
      </ListItem>
    ),
    [handleCreateNewSpecSheet, loading],
  );
};

export default NewSpecSheet;
