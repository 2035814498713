/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Selected, Type } from 'containers/create-sketch/dtos';
import { useDebounce } from 'hooks/useDebounce';
import Draggable from 'react-draggable';
import {
  PointBall,
  Text,
  BoxDelete,
  Input,
  FakeClick,
  LineConnect,
} from './styles';

import Comments from './Comments';

export enum POINTS {
  POINT = 'POINT',
  COMMENT = 'COMMENT',
}

export interface IProps {
  x: number;
  y: number;
  letterY: number;
  letterX: number;
  cx: number;
  cy: number;
  text: string;
  onDelete?: () => void;
  onChange?: (e: any) => void;
  onChangeLetterPosition?({
    id,
    value,
    letterX,
    letterY,
  }: {
    id: number;
    value: string;
    letterX: number;
    letterY: number;
  }): void;
  handleSelected?: (e: any) => void;
  selected?: Selected;
  point?: any;
  changed?: boolean;
  setIdPoint?: any;
  type?: Type;
}

const Points = ({
  x,
  y,
  letterX,
  letterY,
  cx,
  cy,
  text,
  onDelete,
  selected,
  point,
  onChange,
  handleSelected,
  onChangeLetterPosition,
  setIdPoint,
  type,
}: IProps) => {
  const focusDiv: any = useRef(null);
  const divDrag: any = useRef(null);
  const valueCenter = cx / 2;

  const [isInput, setIsInput] = useState(false);
  const [newInput, setNewInput] = useState(text);
  const [linePosition, setLinePosition] = useState<any>();

  const handleDebounce = useDebounce(newInput, 1000);

  useEffect(() => {
    if (onChange && newInput !== '') onChange(handleDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDebounce]);

  const handleIsInput = (id?: number) => {
    setIsInput(true);
    setIdPoint(id);
    if (handleSelected) {
      handleSelected('change');
    }
  };

  const handleChangeLetterPosition = () => {
    if (onChangeLetterPosition && point) {
      onChangeLetterPosition({
        id: point?.id,
        value: point?.value,
        letterX: linePosition?.x,
        letterY: linePosition?.y,
      });
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
    setIsInput(false);
  };

  const removeEventClickOut = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event: any) => {
    if (focusDiv.current && !(focusDiv.current as any).contains(event.target)) {
      setIsInput(false);
      removeEventClickOut();
    }
  };

  const connect = div => {
    const length = Math.sqrt(
      (x - div.x) * (x - div.x) + (y - div.y) * (y - div.y),
    );
    const angle = Math.atan2(div.y - y, div.x - x) * (180 / Math.PI);
    setLinePosition({
      width: length,
      angle,
      x: div.x,
      y: div.y,
    });
  };

  useEffect(() => {
    connect({ x: letterX || x, y: letterY || y });
  }, []);

  useEffect(() => {
    if (isInput) {
      focusDiv.current.focus();
      document.addEventListener('mousedown', handleClickOutside);
    } else removeEventClickOut();
  }, [isInput]);

  return (
    <>
      {point?.type === POINTS.POINT && (
        <Draggable
          onDrag={(e, data) => connect(data)}
          position={{ x: linePosition?.x, y: linePosition?.y }}
          bounds=".containerRefBounds"
          nodeRef={divDrag}
          onStop={handleChangeLetterPosition}
        >
          <Text
            x={linePosition?.x}
            y={linePosition?.y}
            cx={cx}
            cy={cy}
            valueCenter={valueCenter}
            ref={divDrag}
          >
            {type !== Type.View ? (
              <>
                <FakeClick onClick={() => handleIsInput(point?.id)} />
                <BoxDelete onClick={handleDelete}>x</BoxDelete>
                {isInput ? (
                  <Input
                    type="text"
                    value={newInput}
                    onChange={(e: any) => setNewInput(e.target.value)}
                    ref={focusDiv}
                    name="text"
                    maxLength={1}
                  />
                ) : (
                  newInput
                )}
              </>
            ) : (
              text
            )}
          </Text>
        </Draggable>
      )}
      <PointBall
        x={x}
        y={y}
        cx={cx}
        cy={cy}
        valueCenter={valueCenter}
        selected={selected}
      >
        <LineConnect
          width={linePosition?.width}
          angle={linePosition?.angle}
          notComment={!!(point?.type !== POINTS.POINT)}
        />
        {point?.type !== POINTS.POINT && (
          <Comments text={text} onDelete={onDelete} />
        )}
      </PointBall>
    </>
  );
};

export default Points;
