import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 80%;
  ${simpleFlex}
  justify-content: center;

  > div:first-child {
    margin-right: 95px;
  }

  span {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  max-width: 80%;
  ${simpleFlex}
  justify-content: center;

  button:first-child {
    margin-right: 40px;
  }
`;

export const ContainerSuccess = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 30px;
  }

  button {
    margin-top: 130px;
  }
`;

export const ButtonsWrapp = styled.div`
  ${simpleFlex}

  button:first-child {
    margin-right: 40px;
  }
`;

export const ContainerLoader = styled.div`
  margin-top: 100px;
  > p {
    ${simpleFlex}
    justify-content: center;
    height: 100%;
    flex-direction: column;

    > div {
      margin-top: 25px;
    }
  }
`;

export const ContainerLoaderBrands = styled.div`
  ${simpleFlex}
  justify-content: center;
`;
