import {
  textColumn,
  intColumn,
  keyColumn,
  dateColumn,
  createTextColumn,
} from 'react-datasheet-grid';

import parseFloatFromString from 'helpers/parseFloatFromString';
import { selectColumn } from './Select';

const columnWidth = { width: '0 0 180px' };

const currencyColumn = createTextColumn<number | null>({
  alignRight: true,
  formatBlurredInput: value =>
    typeof value === 'number' && value > 0
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value)
      : '',
  formatInputOnFocus: value => (value && value > 0 ? `${value}` : ''),
  parseUserInput: value => Number(parseFloatFromString(value, true)),
});

const noCommaColumn = createTextColumn<string | null>({
  formatBlurredInput: value => (value ? String(value).replace(',', '.') : ''),
  formatInputOnFocus: value => (value ? String(value).replace(',', '.') : ''),
  parseUserInput: value => (value ? String(value).replace(',', '.') : ''),
});

export default [
  {
    ...keyColumn('customer', textColumn),
    title: 'Customer',
    disabled: true,
  },
  {
    ...keyColumn(
      'brand',
      selectColumn({
        type: 'brands',
        field: 'description',
        searchParam: 'description',
        isObject: true,
      }),
    ),
    title: 'Brand',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'styleName',
      selectColumn({
        type: '/spec-sheets/list-style-names',
        searchParam: 'search',
      }),
    ),
    title: 'Style name',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'refNumber',
      selectColumn({
        type: '/spec-sheets/list-ref-no',
        searchParam: 'search',
      }),
    ),
    title: 'Ref.Number',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'factory',
      selectColumn({
        type: `/factories`,
        field: 'description',
        searchParam: 'description',
        isObject: true,
      }),
    ),
    title: 'Factory',
    ...columnWidth,
  },
  {
    ...keyColumn('poNumber', textColumn),
    title: 'PO number',
    ...columnWidth,
  },
  {
    ...keyColumn('poId', noCommaColumn),
    title: 'PO ID',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'color',
      selectColumn({
        type: 'colors',
        field: 'color',
        searchParam: 'color',
        isObject: true,
      }),
    ),
    title: 'Color',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'material',
      selectColumn({
        type: 'materials',
        field: 'name',
        searchParam: 'name',
        isObject: true,
      }),
    ),
    title: 'Material name',
    ...columnWidth,
  },
  { ...keyColumn('units', intColumn), title: 'TTL units', ...columnWidth },
  {
    ...keyColumn('fobCost', currencyColumn),
    title: 'PO price',
    ...columnWidth,
  },
  {
    ...keyColumn('retail', currencyColumn),
    title: 'Retail price',
    ...columnWidth,
  },
  {
    ...keyColumn('originalPoStartDate', dateColumn),
    title: 'Original PO start date',
    ...columnWidth,
  },
  {
    ...keyColumn('originalPoCancelDate', dateColumn),
    title: 'Original PO cancel date',
    ...columnWidth,
  },
  {
    ...keyColumn('currentPoStartDate', dateColumn),
    title: 'Current PO start date',
    ...columnWidth,
  },
  {
    ...keyColumn('currentPoCancelDate', dateColumn),
    title: 'Current PO cancel date',
    ...columnWidth,
  },
  {
    ...keyColumn('currentXfDate', dateColumn),
    title: 'Current XF date',
    ...columnWidth,
  },
  {
    ...keyColumn('fcrDate', dateColumn),
    title: 'FCR date',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'status',
      selectColumn({
        choices: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      }),
    ),
    title: 'Ship status',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'season',
      selectColumn({
        type: 'seasons',
        field: 'description',
        searchParam: 'description',
        isObject: true,
      }),
    ),
    title: 'Season',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'country',
      selectColumn({
        type: 'countries',
        field: 'description',
        searchParam: 'description',
        isObject: true,
      }),
    ),
    title: 'Country',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'orderType',
      selectColumn({
        choices: [
          { label: 'Store', value: 'STORE' },
          { label: 'Pool', value: 'POOL' },
          { label: 'ECOM', value: 'ECOM' },
        ],
      }),
    ),
    title: 'Order type',
    ...columnWidth,
  },
  {
    ...keyColumn(
      'orderStatus',
      selectColumn({
        choices: [
          { label: 'In process', value: 'IN_PROCESS' },
          { label: 'Awaiting Shipment', value: 'AWAITING_SHIPMENT' },
          { label: 'Shipped', value: 'SHIPPED' },
          { label: 'Cancelled', value: 'CANCELLED' },
        ],
      }),
    ),
    title: 'Order Status',
    ...columnWidth,
  },
  {
    ...keyColumn('colMatCode', textColumn),
    title: 'Col/Mat Code',
    ...columnWidth,
  },
  {
    ...keyColumn('poColorPriorityComments', textColumn),
    title: 'PO Comments / Priorities',
    ...columnWidth,
    width: '0 0 600px',
  },
  {
    ...keyColumn('remarks', textColumn),
    title: 'Remarks',
    width: '0 0 600px',
  },
];
