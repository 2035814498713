import styled from 'styled-components';
import { takeColor } from 'theme/tools';

export const Container = styled.div`
  min-height: 100vh;
  flex: 1;
  padding: 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${takeColor('black')};
  position: relative;
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1;
  opacity: 0.15;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  p {
    text-align: center;
  }
`;
