import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  padding: 0 70px;
  margin-top: 75px;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  ${simpleFlex}
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const WrapperLoading = styled.div`
  ${simpleFlex};

  > div {
    margin-left: 10px;
  }
`;
