import React, { memo, useEffect, useState } from 'react';

import { Select, SelectItem } from 'components/Fields';

import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import Button from 'components/Button';

import { useDebounce } from 'hooks/useDebounce';

import api from 'services/api';

import Icon from 'components/Icon';
import { ContainerButton, ContainerSelect, IconCloseContainer } from './styles';

interface IList {
  id: number;
  color: string;
  group: string;
}

interface IProps {
  label: string;
  value: undefined | null | string;
  changeColor: (item: IList | null) => void;
  isFirst: boolean;
  onClickOpenModal: (isOpen: boolean | string) => void;
}

const SelecColor: React.FC<IProps> = ({
  label,
  value,
  changeColor,
  isFirst,
  onClickOpenModal,
}) => {
  const [search, setSearch] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState<IList[]>([]);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    setLoading(true);

    const newList: IList[] = await api.get(
      `/colors?isActive=1&color=${search}`,
    );
    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getList();
  };

  useEffect(() => {
    saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <>
      <ContainerSelect isFirst={isFirst}>
        {isFirst && (
          <IconCloseContainer onClick={() => changeColor(null)}>
            <Icon icon="close-black" color="none" width="26px" />
          </IconCloseContainer>
        )}
        <Select
          label={label}
          placeholder="Click to select"
          withInputSearch
          inputSearchPlaceholder="Search color"
          inputSearchOnChange={setSearch}
          inputSearchvalue={search}
          dataTest="color"
          value={value}
          auxiliaryButton={
            <ContainerButton>
              <Button
                data-testid="button-add-color"
                onClick={() =>
                  onClickOpenModal(!list.length && search ? search : true)
                }
              >
                Add new color
              </Button>
            </ContainerButton>
          }
        >
          <>
            {loading ? (
              <GridFlex flex={1} justifyContent="center" alignItems="center">
                <Spinner />
              </GridFlex>
            ) : (
              list.map(item => (
                <SelectItem
                  onClick={() => changeColor(item)}
                  isSelected={false}
                  key={`items-color-${item.id}`}
                >
                  {item.color}
                </SelectItem>
              ))
            )}
          </>
        </Select>
      </ContainerSelect>
    </>
  );
};

export default memo(SelecColor);
