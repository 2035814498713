import React, { useEffect, useState, useMemo, useCallback } from 'react';

import api from 'services/api';
import { useSpecSheet } from 'containers/spec-sheet';
import searchTextHook from 'helpers/searchTextHook';

import { Select, SelectItem } from 'components/Fields';
import Loading from '../partials/Loading';

const Brand: React.FC = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: brands,
    },
  );

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const brandSelected = data?.brand;

  const getBrands = async () => {
    setLoading(true);

    try {
      const list: any = await api.get('/brands?isActive=1');

      setBrands(list);
      setFilteredItems(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (newBrand: any) => {
      if (brandSelected?.id !== newBrand.id) {
        setSearch('');
        actions.changeSpecSheetData({ brand: newBrand });
      }
    },
    [brandSelected, actions, setSearch],
  );

  return useMemo(
    () => (
      <Select
        label="Brand"
        value={brandSelected?.description}
        withInputSearch
        inputSearchPlaceholder="Search brand"
        inputSearchOnChange={setSearch}
        inputSearchvalue={search}
        dataTest="brand"
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            {filteredItems.map(({ id, description }) => (
              <SelectItem
                key={`brands-${id}`}
                onClick={() => handleChange({ id, description })}
                isSelected={id === brandSelected?.id}
                data-test={`select-item-option-brand-${id}`}
              >
                {description}
              </SelectItem>
            ))}
          </>
        )}
      </Select>
    ),
    [filteredItems, brandSelected, handleChange, loading, search, setSearch],
  );
};

export default Brand;
