/* eslint-disable react/require-default-props */
import React, { memo, useEffect, useState } from 'react';

import api from 'services/api';

import { validMail } from 'helpers/valid';

import Modal from 'components/Modal';
import { WrapperTitle } from 'components/Modal/styles';
import Text from 'components/Text';
import Button from 'components/Button';
import { CountrySelect, Input } from 'components/Fields';

import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import GridFlex from 'components/GridFlex';
import {
  Container,
  ButtonsContainer,
  ContainerLoader,
  ContainerSuccess,
  ButtonsWrapp,
} from './styles';

enum ResponseType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSaved: (data: any) => void;
  nameComes?: string;
}

const ModalCreateFactory: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSaved,
  nameComes,
}) => {
  const [factoryName, setFactoryName] = useState('');
  const [factoryAddress, setFactoryAddress] = useState('');
  const [country, setCountry] = useState<number | undefined>(undefined);
  const [factoryTelNo, setFactoryTelNo] = useState('');
  const [factoryContact, setFactoryContact] = useState('');
  const [factoryEmail, setFactoryEmail] = useState('');
  const [customerVendorNo, setCustomerVenderNo] = useState('');
  const [DdsVendor, setDdsVendor] = useState('');
  const [tempMic, setTempMic] = useState('');
  const [permanentMic, setPermanentMic] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<any>(false);
  const [errorFactoryEmail, setErrorFactoryEmail] = useState(false);
  const [error, setError] = useState([]);

  const resetFields = () => {
    setFactoryAddress('');
    setCountry(undefined);
    setFactoryTelNo('');
    setFactoryContact('');
    setFactoryEmail('');
    setCustomerVenderNo('');
    setDdsVendor('');
    setTempMic('');
    setPermanentMic('');
  };

  const handleChangeName = (name: string) => {
    if (error.length) setError([]);
    setFactoryName(name);
  };

  useEffect(() => {
    if (nameComes) handleChangeName(nameComes);
    else handleChangeName('');
    resetFields();
    setSuccess(false);
    setError([]);
  }, [isOpen]);

  const createFactory = async () => {
    setLoading(true);
    try {
      const colorResponse = await api.post(`/factories`, {
        description: factoryName,
        address: factoryAddress,
        telNo: factoryTelNo,
        contact: factoryContact,
        email: factoryEmail,
        customerVendorNo,
        countrieId: country,
        ddsVendor: DdsVendor,
        tempMic,
        permanentMic,
      });
      setLoading(false);
      setSuccess(ResponseType.SUCCESS);
      onSaved(colorResponse);
    } catch (err: any) {
      if (err?.data?.message) setError(err.data.message);
      else setSuccess(ResponseType.ERROR);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!validMail(factoryEmail)) {
      setErrorFactoryEmail(true);
      return;
    }

    createFactory();
  };

  const handleChangeEmail = (email: string) => {
    if (errorFactoryEmail && validMail(factoryEmail))
      setErrorFactoryEmail(false);
    setFactoryEmail(email);
  };

  const closeAndFinish = () => {
    setSuccess(false);
    onClose();
  };

  const isCompleted =
    factoryName &&
    factoryAddress &&
    country &&
    factoryTelNo &&
    factoryContact &&
    factoryEmail &&
    customerVendorNo &&
    DdsVendor &&
    tempMic &&
    permanentMic &&
    !error[0];

  return (
    <Modal minWidth="1280px" isOpen={isOpen} onClose={onClose} noButtonClose>
      <>
        {!loading ? (
          <Container>
            {!success ? (
              <>
                <WrapperTitle marginTitle>
                  <Text fontSize={24} color="black">
                    Add new factory
                  </Text>
                </WrapperTitle>
                <GridFlex justifyContent="space-between">
                  <Input
                    name="factory-name"
                    label="Factory name"
                    onChange={({ target: { value } }) =>
                      handleChangeName(value)
                    }
                    placeholder="Click to add"
                    width="451px"
                    value={factoryName}
                    dataTest="factory-name"
                    error={error[0]}
                  />
                  <Input
                    name="factory-address"
                    label="Factory Address"
                    onChange={({ target: { value } }) =>
                      setFactoryAddress(value)
                    }
                    placeholder="Click to add"
                    width="451px"
                    value={factoryAddress}
                    dataTest="factory-address"
                  />
                </GridFlex>

                <GridFlex justifyContent="space-between" margin="40px 0">
                  <CountrySelect
                    dataTest="factory-location"
                    label="Country"
                    setValue={setCountry}
                    value={country}
                  />
                  <Input
                    name="factory-tel-no"
                    label="Factory Tel. No."
                    onChange={({ target: { value } }) => setFactoryTelNo(value)}
                    placeholder="Click to add"
                    width="451px"
                    value={factoryTelNo}
                    dataTest="factory-tel-no"
                  />
                </GridFlex>

                <GridFlex justifyContent="space-between" margin="40px 0">
                  <Input
                    name="factory-contact"
                    label="Factory contact"
                    onChange={({ target: { value } }) =>
                      setFactoryContact(value)
                    }
                    placeholder="Click to add"
                    width="451px"
                    value={factoryContact}
                    dataTest="factory-contact"
                  />
                  <Input
                    name="factory-email"
                    label="Factory e-mail"
                    onChange={({ target: { value } }) =>
                      handleChangeEmail(value)
                    }
                    placeholder="Click to add"
                    width="451px"
                    value={factoryEmail}
                    dataTest="factory-email"
                    error={errorFactoryEmail ? 'Invalid e-mail' : undefined}
                  />
                </GridFlex>

                <GridFlex justifyContent="space-between" margin="40px 0">
                  <Input
                    name="customer-vendor-no"
                    label="Customer Vendor No."
                    onChange={({ target: { value } }) =>
                      setCustomerVenderNo(value)
                    }
                    placeholder="Click to add"
                    width="451px"
                    value={customerVendorNo}
                    dataTest="customer-vendor-no"
                  />
                  <Input
                    name="dds-vendor"
                    label="DDS Vendor#"
                    onChange={({ target: { value } }) => setDdsVendor(value)}
                    placeholder="Click to add"
                    width="451px"
                    value={DdsVendor}
                    dataTest="dds-vendor"
                  />
                </GridFlex>

                <GridFlex justifyContent="space-between" margin="40px 0">
                  <Input
                    name="temp-mic"
                    label="TEMP MIC# (6%)"
                    onChange={({ target: { value } }) => setTempMic(value)}
                    placeholder="Click to add"
                    width="451px"
                    value={tempMic}
                    dataTest="temp-mic"
                  />
                  <Input
                    name="permanent-mic"
                    label="PERMANENT MIC# (15%)"
                    onChange={({ target: { value } }) => setPermanentMic(value)}
                    placeholder="Click to add"
                    width="451px"
                    value={permanentMic}
                    dataTest="permanent-mic"
                  />
                </GridFlex>

                <ButtonsContainer>
                  <Button
                    onClick={() => isCompleted && handleSubmit()}
                    width="266px"
                    data-testid="button-create"
                    scheme={!isCompleted ? 'disabled' : 'default'}
                  >
                    Add factory
                  </Button>
                  <Button
                    data-testid="button-cancel"
                    width="266px"
                    scheme="nobg"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonsContainer>
              </>
            ) : (
              <>
                {success === ResponseType.SUCCESS && (
                  <ContainerSuccess>
                    <Icon icon="check-circle" color="none" width="68px" />
                    <Text fontSize={24} fontWeight="normal-bold" color="black">
                      Factory added with success!
                    </Text>

                    <Text
                      fontSize={18}
                      fontWeight="medium"
                      color="black"
                      margin="15px 0 0"
                    >
                      The factory <b>{factoryName}</b> has been added.
                    </Text>

                    <Button
                      data-testid="button-ok"
                      width="250px"
                      onClick={() => closeAndFinish()}
                    >
                      Ok
                    </Button>
                  </ContainerSuccess>
                )}

                {success === ResponseType.ERROR && (
                  <ContainerSuccess>
                    <Icon icon="close-error" color="none" width="68px" />
                    <Text color="black" fontSize={30}>
                      Could not create this factory at the moment
                    </Text>
                    <Text
                      color="black"
                      fontSize={18}
                      fontWeight="normal"
                      margin="15px 0 0"
                    >
                      We&rsquo;re sorry for the inconvenience. Please, try again
                      later
                    </Text>

                    <ButtonsWrapp>
                      <Button width="250px" onClick={() => handleSubmit()}>
                        Try again
                      </Button>
                      <Button
                        width="250px"
                        onClick={() => closeAndFinish()}
                        scheme="nobg"
                      >
                        Back
                      </Button>
                    </ButtonsWrapp>
                  </ContainerSuccess>
                )}
              </>
            )}
          </Container>
        ) : (
          <ContainerLoader>
            <Text fontSize={24} color="black">
              Adding factory
            </Text>
            <Spinner />
          </ContainerLoader>
        )}
      </>
    </Modal>
  );
};

export default memo(ModalCreateFactory);
