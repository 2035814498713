import React, { useState, useEffect } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';

import GridFlex from 'components/GridFlex';
import Spinner from 'components/Spinner';
import Bottom from './partials/Bottom';
import Content from './partials/Content';

import { ISkuComponent } from './types';

import { Container } from './styles';

const SkuComponent: React.FC = () => {
  const [components, setComponents] = useState<ISkuComponent[]>([]);

  const {
    data: { data },
  } = useSpecSheet();

  const getComponents = async () => {
    try {
      const response: ISkuComponent[] = await api.get(
        `/spec-sheets/${data?.id}/sku-components`,
      );
      setComponents(response);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !components.length;

  return isLoading ? (
    <GridFlex alignItems="center" justifyContent="center" margin="100px 0">
      <Spinner size="big" />
    </GridFlex>
  ) : (
    <Container>
      <Content items={components} />
      <Bottom />
    </Container>
  );
};

export default SkuComponent;
