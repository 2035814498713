import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.Montserrat};
  }

  html {
    font-size: 62.5%;
  }

  html, body, #root {
    width: 100%;
    height: 100vh;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white};
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.6rem;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  @media print {
    @page {
      margin: 1cm 0cm 1cm;
      page-break-after: auto;
    }
  }
`;
