/* eslint-disable import/no-unresolved */
import GridFlex from 'components/GridFlex';
import styled from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

export interface IContentUpload {
  active?: boolean | undefined;
}

export const Container = styled.div``;

export const ContainerBoxTopRadius = styled.div`
  display: flex;
`;
export const BoxTopRadius = styled.div`
  border: 1.5px solid ${takeColor('grayChangeText')};
  box-sizing: border-box;
  border-radius: 9.3px;
  padding: 5px 7px;
  margin-right: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${takeColor('grayChangeText')};

  span {
    font-weight: 600;
  }
`;

export const ContentUpload = styled('div')<IContentUpload>`
  width: 100%;
  min-height: 400px;
  margin-top: 70px;
  margin-bottom: 40px;
  background: ${takeColor('white')};
  border: 1px solid ${takeColor('grayBorderBox')};
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  position: relative;

  span {
    display: none;
    position: absolute;
    z-index: 9999;
    transition: all 0.5s;
    cursor: pointer !important;
  }

  ::before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    transition: all 0.5s;
    display: ${props => (props?.active ? 'block' : 'none')};
  }

  &:hover {
    ::before {
      opacity: ${props => (props?.active ? 1 : 0)};
    }

    span {
      display: ${props => (props?.active ? 'block' : 'none')};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const ContentActions = styled(GridFlex)`
  width: 100%;
  margin: 80px 0px;

  button + button {
    margin-left: 40px;
  }
`;

export const WrapperButton = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;
  }
`;

export const WrapperText = styled.div`
  margin-top: 15px;

  > p {
    ${simpleFlex};

    p {
      &:last-child {
        margin-left: 3px;
      }
    }
  }
`;

export const MasterImage = styled('img').attrs(props => ({
  ...props,
}))`
  max-width: 100%;
`;
