import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  justify-content: right;
  margin-top: 15px;
  position: relative;
  height: 48px;
`;

export const Wrapp = styled.div`
  ${simpleFlex}
  background-color: ${takeColor('grayLight')};
  padding: 10px 40px;
  border-radius: 12px;
  position: sticky;
  right: -5px;

  > p {
    font-size: 11px;
    margin-right: 66px;
    color: ${takeColor('black')};
    font-weight: 600;
  }

  button {
    font-size: 10px;
    margin-right: 14px;

    &:last-child {
      margin-left: 68px;
      margin-right: 5px;
    }
  }
`;
