import GridFlex from 'components/GridFlex';
import styled from 'styled-components';

export const Container = styled(GridFlex)`
  label {
    p {
      display: none;
    }
  }
`;

export const WrapperLabel = styled.div`
  width: 100%;

  & > label > div {
    border-width: 1;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  }
`;
