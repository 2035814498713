export const initialState = {
  data: null,
  stepActive: 0,
  stepsCompleted: [],
  loading: null,
  allSpecSheets: null,
  meta: {},
  type: 'initial',
  masterImage: null,
  factories: [],
};
