import styled from 'styled-components';

import { FieldStyle } from 'components/Fields/styles';
import Button from 'components/Button';

export const Container = styled.div`
  margin-top: 80px;
  position: relative;

  ${FieldStyle} {
    padding: 15px 35px;
  }

  input {
    font-size: 15px;
  }
`;

export const ContainerButtonFilter = styled(Button)`
  position: absolute;
  right: 14px;

  > p {
    margin-left: 10px;
  }
`;
