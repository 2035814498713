import styled from 'styled-components';
import { takeColor } from 'theme/tools';

export const ModalContent = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  width: 200px;
  height: 120px;
  top: -130px;
  right: -160px;
  text-align: center;
  padding: 20px;
  word-break: break-all !important;
  z-index: 1000000;
  cursor: pointer;
`;

export const ContainerIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.11);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteAction = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: ${takeColor('white')};
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: -17px;
  right: -17px;
  color: #8d8d8d;
  font-size: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const CommentPoint = styled.div`
  position: absolute !important;
`;
