import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';

import Modal from 'components/Modal';
import GridFlex from 'components/GridFlex';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Text from 'components/Text';

import { PortraitOrientation } from 'components/PrintOrientations/Portrait';
import HeaderInfos from './HeaderInfos';
import List from './List';

import {
  Container,
  ContainerButtons,
  PrintView,
  TitleFornova,
  NoResults,
} from './styles';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const ModalReport: React.FC<IProps> = ({ isOpen, onClose }) => {
  const {
    data: { loading, resultReport },
  } = useSpecSheetSearch();

  const refPrint = useRef(null);

  const width = loading ? '690px' : '90%';
  const height = loading ? '270px' : '90%';

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => !loading && onClose()}
      minHeight={height}
      minWidth={width}
    >
      <Container>
        {loading && (
          <GridFlex
            justifyContent="center"
            alignItems="center"
            margin="60px 0 0"
          >
            <Spinner size="big" />
          </GridFlex>
        )}
        {!loading && resultReport.length > 0 && (
          <>
            <PortraitOrientation />
            <PrintView ref={refPrint}>
              <TitleFornova>SAMPLE LINE SHEET</TitleFornova>
              <HeaderInfos />
              <List />
            </PrintView>
          </>
        )}
      </Container>
      {!loading && resultReport.length > 0 && (
        <ContainerButtons>
          <ReactToPrint
            content={() => refPrint.current}
            trigger={() => (
              <Button width="265px" data-testid="print-button">
                Print
              </Button>
            )}
            documentTitle="FORNOVA GROUP"
          />
          <Button
            width="266px"
            onClick={onClose}
            scheme="nobg"
            data-testid="close-modal-print"
          >
            Cancel
          </Button>
        </ContainerButtons>
      )}

      {!loading && !resultReport.length && (
        <NoResults>
          <Text color="black" fontSize={30} fontWeight="normal">
            No results found
          </Text>
          <Text color="black" fontSize={18} fontWeight="medium">
            Sorry, we couldn&apos;t find any matches. Please try searching
            again.
          </Text>
        </NoResults>
      )}
    </Modal>
  );
};

export default ModalReport;
