import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import React from 'react';

import history from 'routes/history';

import { Container, Background, WrapperContent } from './styles';

const AccessDenied: React.FC = () => {
  return (
    <Container>
      <Background>
        <Icon icon="logo" color="deepBlack" height="90vh" width="90vw" />
      </Background>
      <WrapperContent>
        <Icon icon="lock" width="57px" height="76px" />
        <Text fontSize={30} color="white" margin="25px 0 16px 0">
          Access denied
        </Text>
        <Text
          fontSize={18}
          color="white"
          fontWeight="medium"
          margin="0 0 67px 0"
        >
          The page you&apos;re trying to access has{' '}
          <strong>restricted access</strong>.
          <br />
          Please refer to your system admin.
        </Text>
        <Button
          data-testid="go-spec-home"
          scheme="white"
          onClick={() => {
            history.push(`/`);
          }}
        >
          Go back to home
        </Button>
      </WrapperContent>
    </Container>
  );
};

export default AccessDenied;
