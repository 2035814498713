import Pagination from 'components/Pagination';
import { useDashboard } from 'containers/dashboard';
import React, { useEffect } from 'react';

import Table from '../Table';

import { Container } from './styles';

const MountTable: React.FC = () => {
  const {
    data: { pagination, loading },
    actions: {
      listCustomerOrder,
      deleteShippingOrder,
      setPage,
      createShippingOrder,
      updateCustomerOrder,
    },
  } = useDashboard();

  const page = pagination?.page || 1;
  const pages = pagination?.pages || 1;

  useEffect(() => {
    listCustomerOrder();
  }, [page]);

  return (
    <Container>
      <Table
        onDelete={deleteShippingOrder}
        onCreate={createShippingOrder}
        onUpdate={updateCustomerOrder}
        onRefresh={listCustomerOrder}
      />
      {pagination && !loading && (
        <Pagination page={page} pages={pages} setPage={setPage} />
      )}
    </Container>
  );
};

export default MountTable;
