import React from 'react';

import { useDashboard } from 'containers/dashboard';
import { MAP_STEPS } from 'containers/dashboard/constants';

import TitlePages from 'components/TitlePages';
import Button from 'components/Button';
import MountTable from './MountTable';
import Filters from './Filters';

import { ContainerTitleAndButtonUpload } from './styles';

const CostumerOrder: React.FC = () => {
  const {
    data: { loading },
    actions: { changeStep },
  } = useDashboard();

  return (
    <>
      <ContainerTitleAndButtonUpload>
        <TitlePages title="Dashboard" />
        <Button
          width="266px"
          padding="15.5px 20px"
          onClick={() => changeStep(MAP_STEPS.UPLOAD_MANAGEMENT)}
          data-testid="open-upload-excel"
          scheme={loading ? 'disabled' : 'default'}
        >
          Upload management
        </Button>
      </ContainerTitleAndButtonUpload>

      <Filters />

      <MountTable />
    </>
  );
};

export default CostumerOrder;
