/* eslint-disable consistent-return */
/* eslint-disable no-console */
import api from 'services/api';

import { saveAs } from 'file-saver';

import {
  IActions,
  TResponselistCustomerOrderHistory,
  IState,
  LoadingEnum,
  ICustomerOrderHistoryData,
} from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  const { pagination } = state;

  async function listCustomerOrderHistory() {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    let newData = state;

    const page = pagination?.page || 1;

    try {
      const response: TResponselistCustomerOrderHistory = await api.get(
        `/customer-order/import-history?page=${page}&limit=5`,
      );

      newData = {
        ...state,
        list: response.items,
        pagination: { page, pages: response.meta.totalPages },
        loading: null,
      };
    } catch (err) {
      newData = {
        ...state,
        loading: null,
      };
    }

    setData(newData);
  }

  async function setPage(page: number) {
    const newPagination = {
      page,
      pages: pagination?.pages || 0,
    };

    setData({
      ...state,
      pagination: newPagination,
    });
  }

  async function downloadSpreadsheet(customerOrderHistoryId: number) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    try {
      const response: Pick<ICustomerOrderHistoryData, 'url'> = await api.post(
        '/customer-order/download',
        {
          customerOrderHistoryId,
        },
      );

      saveAs(response.url);
    } catch (err) {
      console.error(err);
    } finally {
      setData({ ...state, loading: null });
    }
  }

  return {
    listCustomerOrderHistory,
    downloadSpreadsheet,
    setPage,
  };
};
