/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import api from 'services/api';

import { useSpecSheet } from 'containers/spec-sheet';

import { Select, SelectItem } from 'components/Fields';
import Loading from '../partials/Loading';

const StyleGroup: React.FC = () => {
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const styleGroupSelected = data?.styleGroup;

  const getAll = async () => {
    setLoading(true);

    try {
      const list: any = await api.get('/style-groups?isActive=1');
      setItems(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  const handleChange = (styleGroupNew: any) => {
    if (styleGroupSelected?.id !== styleGroupNew.id) {
      actions.changeSpecSheetData({ styleGroup: styleGroupNew });
    }
  };

  return (
    <Select
      label="Style group"
      value={styleGroupSelected?.description}
      dataTest="style-group"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {items?.map(({ id, description }) => (
            <SelectItem
              key={`style-group-${id}`}
              onClick={() => handleChange({ id, description })}
              isSelected={id === styleGroupSelected?.id}
              data-test={`select-item-option-style-group-${id}`}
            >
              {description}
            </SelectItem>
          ))}
        </>
      )}
    </Select>
  );
};

export default StyleGroup;
