export enum STORAGE_TYPES {
  LOCAL = 'local',
  SESSION = 'session',
}

export const setItem = (
  key: string,
  value: string | any,
  type = STORAGE_TYPES.LOCAL,
): void =>
  window[`${type}Storage` as keyof Storage].setItem(key, JSON.stringify(value));

export const getItem = (key: string, type = 'local'): string | null => {
  const item = window[`${type}Storage` as keyof Storage].getItem(key);

  return item && JSON.parse(item);
};

export const removeItem = (key: string, type = 'local'): void => {
  window[`${type}Storage` as keyof Storage].removeItem(key);
};
