import React, { useState } from 'react';

import api from 'services/api';

import { useUsersManagement } from 'containers/users-management';
import { IUserManagement } from 'containers/users-management/dtos';

import Text from 'components/Text';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import GridFlex from 'components/GridFlex';
import { WrapperTitle } from 'components/Modal/styles';
import NavButtons from '../NavButtons';
import PasswordField from '../PasswordField';

import { ContainerLoading, ContainerResponse } from '../styles';
import { Container } from './styles';

import { ETypeSteps } from '../index';

interface IProps {
  onClose: () => void;
}
const Password: React.FC<IProps> = ({ onClose }) => {
  const {
    data: { isEdit },
  } = useUsersManagement();

  const userEdit = isEdit as IUserManagement;

  const [type, setType] = useState<ETypeSteps>(ETypeSteps.DEFAULT);
  const [userPass, setUserPass] = useState<string>('');
  const [userPassConfirm, setUserPassConfirm] = useState<string>('');

  const [errorPass, setErrorPass] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);
  const [errorConfirm, setErrorConfirm] = useState(false);

  const isCompleted =
    userPass && userPassConfirm && !errorPass && !errorConfirm;

  const handleChangePass = (pass: string) => {
    if (errorConfirmPass && userPass !== userPassConfirm)
      setErrorConfirmPass(false);
    setUserPassConfirm(pass);
  };

  const requestToResetPassword = async () => {
    setType(ETypeSteps.LOADING);
    try {
      const data = {
        password: userPass,
      };

      await api.patch(`/users/${userEdit.id}`, data);
      setType(ETypeSteps.SUCCESS);
    } catch (err: any) {
      setType(ETypeSteps.ERROR);
    }
  };

  const handleSubmit = () => {
    if (!isCompleted) return;

    if (userPass !== userPassConfirm) {
      setErrorConfirmPass(true);
      return;
    }

    requestToResetPassword();
  };

  return (
    <>
      {type === ETypeSteps.DEFAULT && (
        <Container>
          <WrapperTitle>
            <Text color="black" fontSize={24}>
              Reset password
            </Text>
          </WrapperTitle>
          <GridFlex justifyContent="space-between" margin="40px 0">
            <PasswordField
              userPass={userPass}
              setUserPass={setUserPass}
              onCheckPass={setErrorPass}
              label="New password"
              dataTest="password"
            />

            <PasswordField
              userPass={userPassConfirm}
              setUserPass={handleChangePass}
              onCheckPass={setErrorConfirm}
              label="Verify password"
              error={errorConfirmPass ? 'Passwords must be equal' : undefined}
              dataTest="confirm-password"
            />
          </GridFlex>
        </Container>
      )}

      {type === ETypeSteps.LOADING && (
        <ContainerLoading>
          <Text color="black" fontSize={24}>
            Reseting password
          </Text>
          <Spinner size="medium" />
        </ContainerLoading>
      )}

      {type === ETypeSteps.SUCCESS && (
        <ContainerResponse>
          <Icon icon="check-circle" color="none" width="68px" />
          <Text color="black" fontSize={30}>
            Password reseted with success!
          </Text>
          <Text color="black" fontSize={18} fontWeight="normal">
            The password for the user <strong>{userEdit.fullname}</strong> has
            been reseted.
          </Text>
        </ContainerResponse>
      )}

      {type === ETypeSteps.ERROR && (
        <ContainerResponse>
          <Icon icon="close-error" color="none" width="68px" />
          <Text color="black" fontSize={30}>
            Could not reset the password at the moment
          </Text>
          <Text color="black" fontSize={18} fontWeight="normal">
            We&rsquo;re sorry for the inconvenience. Please, try again later
          </Text>
        </ContainerResponse>
      )}

      <NavButtons
        handleSubmit={handleSubmit}
        isCompleted={!!isCompleted}
        onClose={onClose}
        onCreate={onClose}
        type={type}
        isPassword
        successText={type === ETypeSteps.SUCCESS && 'Ok'}
      />
    </>
  );
};

export default Password;
