/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import api from 'services/api';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';
import searchTextHook from 'helpers/searchTextHook';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import { SmallSelect, Checkbox } from 'components/Fields';

import { WrapperSelect, WrapperButtonSelect, WrappCheckbox } from '../styles';

const filterType = 'brand';

const BrandFilter: React.FC = () => {
  const {
    data: {
      filters: { brand },
    },
    actions: { addToFilter, hasOnFilter, removeFilter, resetFilter },
  } = useSpecSheetSearch();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: list,
    },
  );

  const getBrands = async () => {
    setLoading(true);

    try {
      const newList: any = await api.get('/brands');
      setList(newList);
      setFilteredItems(newList);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WrapperSelect>
      <SmallSelect
        withInputSearch
        dataTest="select-test"
        inputSearchPlaceholder="Search"
        inputSearchOnChange={setSearch}
        inputSearchvalue={search}
        placeholder="Brand"
        numberSelected={Number(brand?.length)}
        width="164px"
        maxHeight="270px"
        onOpen={() => !list.length && getBrands()}
        auxiliaryButton={
          <WrapperButtonSelect>
            <Button
              scheme="nobg"
              data-testid="reset-filter"
              onClick={() => {
                setSearch('');
                resetFilter(filterType);
              }}
              padding="9px 20px"
            >
              Reset filters
            </Button>
          </WrapperButtonSelect>
        }
      >
        <>
          {loading ? (
            <GridFlex alignItems="center" justifyContent="center">
              <Spinner />
            </GridFlex>
          ) : (
            <WrappCheckbox>
              {filteredItems.map(item => (
                <Checkbox
                  key={`${filterType}-${item.id}-${item.description}`}
                  isChecked={hasOnFilter(filterType, item)}
                  label={item.description}
                  name={item.description}
                  onClick={() =>
                    !hasOnFilter(filterType, item)
                      ? addToFilter(filterType, item)
                      : removeFilter(filterType, item)
                  }
                  width="11px"
                  widthIcon="5px"
                  marginRightIcon="7px"
                  textFontSize={11}
                />
              ))}
            </WrappCheckbox>
          )}
        </>
      </SmallSelect>
    </WrapperSelect>
  );
};

export default BrandFilter;
