import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { boxShadow, simpleFlex, takeColor } from 'theme/tools';

export interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  width?: string;
  scheme?: keyof typeof STYLE_MAP;
  children?: any;
  onClick?: (e?: MouseEvent) => any;
  padding?: string;
  margin?: string;
  fontSize?: string;
  disabled?: boolean;
}

const STYLE_MAP = {
  default: css`
    background-color: ${takeColor('black')};
    ${boxShadow()};
  `,
  defaultWithoutShadow: css`
    background-color: ${takeColor('black')};
  `,
  disabled: css`
    background-color: ${takeColor('disableButton')};
    border: none;
    cursor: initial;
  `,
  nobg: css`
    background-color: transparent;
    color: ${takeColor('black')};
  `,
  gray: css`
    background: ${takeColor('grayMedium')};
    border-color: ${takeColor('grayMedium')};
    box-shadow: none;
  `,
  grayLight: css`
    background-color: transparent;
    border-color: ${takeColor('grayLightText')};
    color: ${takeColor('grayLightText')};
    box-shadow: none;
  `,
  change: css`
    background: ${takeColor()};
    border-color: ${takeColor()};
    color: ${takeColor('grayChangeText')};
    box-shadow: none;
    padding: 5px 10px;
  `,
  white: css`
    background: ${takeColor()};
    color: ${takeColor('black')};
    box-shadow: none;
    border: none;
    padding: 16px 46px;
    width: auto;
  `,
};

const setScheme = ({ scheme }: IProps) => STYLE_MAP[scheme || 'default'];

export const ButtonStyles = styled('button')<IProps>`
  ${simpleFlex};
  color: ${takeColor('white')};
  justify-content: center;
  width: ${({ width }: IProps) => width};
  border: 2px solid ${takeColor('black')};
  padding: ${props => props?.padding || '15px 0px'};
  margin: ${props => props?.margin || '0px 0px'};
  border-radius: 45px;
  transition: 0.2s all;
  font-size: ${props => props?.fontSize || '15px'};
  font-style: normal;
  font-weight: 600;
  font-family: 'Montserrat';

  ${setScheme};
`;
