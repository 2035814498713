import React, { useEffect, useRef, useState } from 'react';

import Icon from 'components/Icon';
import {
  CommentPoint,
  ModalContent,
  DeleteAction,
  ContainerIcon,
} from './styles';

export interface IProps {
  onDelete?: () => void;
  text: string;
}

const Comments = ({ onDelete, text }: IProps) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  const removeEventClickOut = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event: any) => {
    if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
      setOpen(false);
      removeEventClickOut();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else removeEventClickOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <CommentPoint ref={modalRef}>
        {open && (
          <ModalContent>
            <DeleteAction onClick={onDelete}>
              <Icon icon="trash" width="25px" color="black" />
            </DeleteAction>
            {text}
          </ModalContent>
        )}
        <ContainerIcon onClick={() => handleOpen()}>
          <Icon icon="comment" width="22px" color="black" />
        </ContainerIcon>
      </CommentPoint>
    </>
  );
};

export default Comments;
