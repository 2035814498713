import styled, { css } from 'styled-components';
import { styled as styledMaterial } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { simpleFlex, takeColor } from 'theme/tools';

export const List = styled.div`
  margin-top: 30px;
`;

export const Item = styled.div<{ disabled?: boolean }>`
  ${simpleFlex}
  justify-content: space-between;

  padding: 15px 0px;
  margin: 0px 35px;
  border-bottom: 1px solid ${takeColor('grayBorderColor')};

  ${({ disabled }) =>
    disabled &&
    css`
      p {
        opacity: 0.2;
      }
    `}
`;

export const ContainerActions = styled.div`
  ${simpleFlex}
  button {
    font-size: 10px;
    margin-left: 12px;
  }
`;

export const SwitchTheme = styledMaterial(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: `${takeColor('grayLight')}`,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'red',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: `${takeColor('success')}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
