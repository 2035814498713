import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Login from 'pages/Login';
import SpecSheets from 'pages/SpecSheets';
import SpecSheet from 'pages/SpecSheet';
import UserManagement from 'pages/UserManagement';
import AccessDenied from 'pages/AccessDenied';
import DataManagement from 'pages/DataManagement';
import Color from 'pages/DataManagement/partials/List/partials/Color';
import Brand from 'pages/DataManagement/partials/List/partials/Brand';
import Factory from 'pages/DataManagement/partials/List/partials/Factory';
import Material from 'pages/DataManagement/partials/List/partials/Material';
import Season from 'pages/DataManagement/partials/List/partials/Season';
import Reports from 'pages/Reports';
import Dashboard from 'pages/Dashboard';
import Home from 'pages/Home';

import Private from './Private';
import PrivateAdmin from './PrivateAdmin';
import PrivateAdminAndSourcingOperations from './PrivateAdminAndSourcingOperations';
import Public from './Public';

const Routes: React.FC = () => (
  <Switch>
    <Route component={() => <p>Page Not Found</p>} exact path="/not-found" />
    <Public component={Login} exact path="/login" />
    <Private component={Home} exact path="/" />
    <Private component={SpecSheets} exact path="/spec-sheet" />
    <Private component={SpecSheet} exact path="/spec-sheet/:id" />
    <Private component={AccessDenied} exact path="/access-denied" />
    <PrivateAdmin component={UserManagement} exact path="/users-management" />
    <PrivateAdmin component={DataManagement} exact path="/data-management" />
    <PrivateAdmin component={Color} exact path="/data-management/color" />
    <PrivateAdmin component={Brand} exact path="/data-management/brand" />
    <PrivateAdmin component={Factory} exact path="/data-management/factory" />
    <PrivateAdmin component={Material} exact path="/data-management/material" />
    <PrivateAdmin component={Season} exact path="/data-management/season" />
    <Private component={Reports} exact path="/reports" />
    <PrivateAdminAndSourcingOperations
      component={Dashboard}
      exact
      path="/dashboard"
    />
    <Redirect to="/not-found" />
  </Switch>
);

export default Routes;
