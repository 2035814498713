import React, { memo, useEffect, useState } from 'react';

import { Select, SelectItem } from 'components/Fields';

import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';
import Button from 'components/Button';

import { useDebounce } from 'hooks/useDebounce';

import api from 'services/api';

import Icon from 'components/Icon';
import {
  ContainerButton,
  ContainerSelect,
  IconCloseContainer,
} from '../styles';

interface IList {
  id: number;
  name: string;
  type: string;
}

interface IProps {
  label: string;
  value: null | string;
  changeMaterial: (item: IList | null) => void;
  isFirst: boolean;
  onClickOpenModal: (isOpen: boolean | string) => void;
}

const SelecMaterial: React.FC<IProps> = ({
  label,
  value,
  changeMaterial,
  isFirst,
  onClickOpenModal,
}) => {
  const [search, setSearch] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState<IList[]>([]);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    if (isInit || search.trim().length < 3) {
      setIsInit(false);
      return;
    }

    setLoading(true);

    const newList: IList[] = await api.get(
      `/materials?isActive=1&name=${search}`,
    );
    setList(newList);

    setLoading(false);
  };

  const autoSave = useDebounce(search);

  const saveChange = async () => {
    getList();
  };

  useEffect(() => {
    saveChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSave]);

  return (
    <>
      <ContainerSelect isFirst={isFirst}>
        {isFirst && (
          <IconCloseContainer onClick={() => changeMaterial(null)}>
            <Icon icon="close-black" color="none" width="26px" />
          </IconCloseContainer>
        )}
        <Select
          label={label}
          placeholder="Click to select"
          withInputSearch
          inputSearchPlaceholder="Search material"
          inputSearchOnChange={setSearch}
          inputSearchvalue={search}
          dataTest="material"
          value={value}
          maxHeight="321px"
          auxiliaryButton={
            <ContainerButton>
              <Button
                data-testid="button-add-material"
                onClick={() =>
                  onClickOpenModal(!list.length && search ? search : true)
                }
              >
                Add new material
              </Button>
            </ContainerButton>
          }
        >
          <>
            {loading ? (
              <GridFlex flex={1} justifyContent="center" alignItems="center">
                <Spinner />
              </GridFlex>
            ) : (
              <>
                {list.map(item => (
                  <SelectItem
                    onClick={() => changeMaterial(item)}
                    isSelected={false}
                    key={`items-material-${item.id}`}
                  >
                    {item.name}
                  </SelectItem>
                ))}
              </>
            )}
          </>
        </Select>
      </ContainerSelect>
    </>
  );
};

export default memo(SelecMaterial);
