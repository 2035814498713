import React from 'react';

import Text from 'components/Text';

import Construction from './Construction';
import Brand from './Brand';
import SampleType from './SampleType';
import Country from './Country';
import Factory from './Factory';
import Material from './Material';
import RefNo from './RefNo';
import Season from './Season';
import StyleName from './StyleName';
import StyleType from './StyleType';

import { Container, ContainerFields } from './styles';

const MoreFilters: React.FC = () => {
  return (
    <Container data-testid="more-filters">
      <Text fontSize={13} color="grayMedium" fontWeight="normal">
        Select a filter
      </Text>
      <ContainerFields>
        <Construction />
        <StyleName />
        <Factory />
        <Brand />
        <Country />
        <SampleType />
        <RefNo />
        <Season />
        <StyleType />
        <Material />
      </ContainerFields>
    </Container>
  );
};

export default MoreFilters;
