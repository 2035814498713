import React, { useEffect, useState } from 'react';

import api from 'services/api';
import { useSpecSheet } from 'containers/spec-sheet';
import searchTextHook from 'helpers/searchTextHook';

import { Select, SelectItem } from 'components/Fields';
import Loading from '../partials/Loading';

const SampleTypeSelect: React.FC = () => {
  const [sampleTypes, setSampleTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: sampleTypes,
    },
  );

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const sampleTypeSelected = data?.sampleType;

  const getSampleTypes = async () => {
    setLoading(true);

    try {
      const list: any = await api.get('/sample-types');

      setSampleTypes(list);
      setFilteredItems(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getSampleTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newSampleType: any) => {
    if (sampleTypeSelected?.id !== newSampleType.id) {
      setSearch('');
      actions.changeSpecSheetData({ sampleType: newSampleType });
    }
  };

  return (
    <Select
      label="Sample type"
      value={sampleTypeSelected?.description}
      withInputSearch
      inputSearchPlaceholder="Search sample type"
      inputSearchOnChange={setSearch}
      inputSearchvalue={search}
      dataTest="sample-type"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {filteredItems.map(({ id, description }) => (
            <SelectItem
              key={`sample-types-${id}`}
              onClick={() => handleChange({ id, description })}
              isSelected={id === sampleTypeSelected?.id}
              data-test={`select-item-option-sample-type-${id}`}
            >
              {description}
            </SelectItem>
          ))}
        </>
      )}
    </Select>
  );
};

export default SampleTypeSelect;
