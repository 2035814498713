import api from 'services/api';
import union from 'lodash.union';
import {
  parseDateFields,
  parseRows,
  parseFilters,
} from 'helpers/dashboardHelpers';

import { isObjectEmpty } from 'helpers/utils';
import { IResponseRows, IRow, IState, LoadingEnum } from './dtos';
import { initialState } from './constants';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

const endpoint = '/customer-order';

export default (params: IParams) => {
  const { setData, data: state } = params;
  const { pagination } = state;

  function resetDashboardData() {
    setData(initialState);
  }

  function changeStep(step: number) {
    setData({ ...state, stepActive: step });
  }

  async function getSavedFilters() {
    try {
      const savedFilters = (await api.get(`${endpoint}/filter`)) as any;
      return savedFilters?.value;
    } catch (error) {
      return null;
    }
  }

  async function listCustomerOrder(isReset?: boolean) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    let newData = state;

    const page = pagination?.page || 1;

    let filtersToFilter = {};
    const storedFilters: any = {};

    if (isReset) {
      try {
        await api.delete(`${endpoint}/filter`);
      } catch (error) {
        //
      }
    } else {
      const { multiples, ...others } = state.filters;

      const multipleFilters = parseFilters(multiples);
      const otherFilters = parseFilters(others);

      filtersToFilter = Object.assign(
        filtersToFilter,
        ...multipleFilters,
        ...otherFilters,
      );

      let requestedSavedFilters = {};
      if (isObjectEmpty(filtersToFilter)) {
        requestedSavedFilters = JSON.parse(await getSavedFilters());

        if (requestedSavedFilters && !isObjectEmpty(requestedSavedFilters)) {
          filtersToFilter = requestedSavedFilters;

          const parsedFilters = Object.entries(state.filters).map(
            ([key, value]: any) => {
              if (key === 'multiples') {
                const multipleValues = Object.entries(value).map(
                  ([key2, value2]: any) => {
                    if (requestedSavedFilters[key2]) {
                      const newValue2 = value2.map((v: any) => v?.id || v);
                      return {
                        [key2]: union(newValue2, requestedSavedFilters[key2]),
                      };
                    }
                    return {
                      [key2]: value2,
                    };
                  },
                );
                return {
                  [key]: multipleValues,
                };
              }

              if (requestedSavedFilters[key]) {
                return { [key]: { ...value, ...requestedSavedFilters[key] } };
              }
              return { [key]: value };
            },
          );

          parsedFilters.forEach((filterItem: any) => {
            const filterItemKey = Object.keys(filterItem)[0];

            const multipleFilter = {};
            if (filterItemKey === 'multiples') {
              filterItem[filterItemKey].forEach((multipleFilterItem: any) => {
                Object.assign(multipleFilter, { ...multipleFilterItem });
                Object.assign(storedFilters, { multiples: multipleFilter });
              });
            } else {
              Object.assign(storedFilters, { ...filterItem });
            }
          });
        }
      }
    }

    try {
      const response: IResponseRows = await api.post(endpoint, {
        meta: {
          page,
          limit: 20,
        },
        data: filtersToFilter || {},
      });

      const newFilters = () => {
        if (isReset) {
          return initialState.filters;
        }
        if (!isObjectEmpty(storedFilters)) {
          return storedFilters;
        }
        return state.filters;
      };

      newData = {
        ...state,
        list: parseDateFields(response.item),
        pagination: { page, pages: response.meta.totalPages },
        loading: null,
        filters: newFilters(),
      };
    } catch (err) {
      newData = {
        ...state,
        loading: null,
      };
    }

    setData(newData);
  }

  async function updateCustomerOrder(listToUpdate: IRow[]) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    const parsedBody = parseRows(listToUpdate);

    try {
      await api.put(`${endpoint}/update`, parsedBody);
    } catch (err) {
      // silence is gold
    } finally {
      setData({ ...state, loading: null });
    }
  }

  async function duplicateShippingOrder(id: number) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    try {
      const duplicatedItem = await api.post(
        `${endpoint}/shipping-order/${id}/duplicate`,
      );
      return duplicatedItem;
    } catch (err) {
      // silence is gold
    } finally {
      setData({ ...state, loading: null });
    }

    return null;
  }

  async function deleteShippingOrder(id: number) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    try {
      await api.delete(`${endpoint}/shipping-order/${id}`);
    } catch (_) {
      // silence is gold
    } finally {
      setData({ ...state, loading: null });
    }
  }

  async function createShippingOrder(listToCreate: IRow[]) {
    setData({ ...state, loading: LoadingEnum.DEFAULT });

    try {
      await api.post(`${endpoint}/shipping-order`, listToCreate);
    } catch (_) {
      // silence is gold
    } finally {
      setData({ ...state, loading: null });
    }
  }

  async function setPage(page: number) {
    const newPagination = {
      page,
      pages: pagination?.pages || 0,
    };

    setData({
      ...state,
      pagination: newPagination,
    });
  }

  function addToFilter(
    filterType: keyof IState['filters']['multiples'],
    value,
  ) {
    let newMultipleFilter: any[];
    if (Array.isArray(value)) {
      newMultipleFilter = [...state.filters.multiples[filterType], ...value];
    } else {
      newMultipleFilter = [...state.filters.multiples[filterType], value];
    }
    setData({
      ...state,
      filters: {
        ...state.filters,
        multiples: {
          ...state.filters.multiples,
          [filterType]: newMultipleFilter,
        },
      },
    });
  }

  function removeFilter(
    filterType: keyof IState['filters']['multiples'],
    value,
  ) {
    setData({
      ...state,
      filters: {
        ...state.filters,
        multiples: {
          ...state.filters.multiples,
          [filterType]: state.filters.multiples[filterType].filter(i =>
            value?.id
              ? value?.id !== i?.id && value?.id !== i
              : value !== i?.id && value !== i,
          ),
        },
      },
    });
  }

  function hasOnFilter(
    filterType: keyof IState['filters']['multiples'],
    value,
  ) {
    return !!state.filters.multiples[filterType].find(i =>
      value?.id
        ? value?.id === i?.id || value?.id === i
        : value === i?.id || value === i,
    );
  }

  function resetFilter(filterType: keyof IState['filters']['multiples']) {
    setData({
      ...state,
      filters: {
        ...state.filters,
        multiples: {
          ...state.filters.multiples,
          [filterType]: [],
        },
      },
    });
  }

  function addToNormalFilters(filterType: keyof IState['filters'], value) {
    setData({
      ...state,
      filters: {
        ...state.filters,
        [filterType]: value,
      },
    });
  }

  function resetNormalFilter(filterType: keyof IState['filters']) {
    setData({
      ...state,
      filters: {
        ...state.filters,
        [filterType]: null,
      },
    });
  }

  return {
    resetDashboardData,
    changeStep,
    updateCustomerOrder,
    duplicateShippingOrder,
    listCustomerOrder,
    getSavedFilters,
    setPage,
    createShippingOrder,
    deleteShippingOrder,
    addToFilter,
    hasOnFilter,
    resetFilter,
    removeFilter,
    addToNormalFilters,
    resetNormalFilter,
  };
};
