import React from 'react';
import { Grid } from '@material-ui/core';

import Content from './partials/Content';
import Search from './partials/Search';

import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Search />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Content />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
