import styled from 'styled-components';

import ShadowBlur from 'components/ShadowBlur';

import { simpleFlex } from 'theme/tools';

interface IContainer {
  isOpen: boolean;
}

export const Container = styled(ShadowBlur)<IContainer>`
  ${simpleFlex};
  justify-content: center;
  min-height: 100%;
`;

interface IWrapperContent {
  minWidth?: string;
  minHeight?: string;
}

export const WrapperContent = styled('div')<IWrapperContent>`
  padding: 35px;
  background-color: ${({ theme }) => theme?.colors?.white};
  border-radius: 10px;
  position: relative;
  width: ${({ minWidth }) => minWidth || '500px'};
  height: ${({ minHeight }) => minHeight || 'auto'};
  overflow-y: auto;
  max-width: 95%;
  max-height: 95%;
`;

export const WrapperCloseButton = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
`;

export const Content = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  width: 100%;
`;

interface IWrapperTitle {
  marginTitle?: boolean;
}

export const WrapperTitle = styled.div<IWrapperTitle>`
  ${simpleFlex};
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ marginTitle }: IWrapperTitle) => marginTitle && '70px'};
`;
