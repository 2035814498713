/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import api from 'services/api';

import { validMail } from 'helpers/valid';

import { Input, CountrySelect } from 'components/Fields';
import Icon from 'components/Icon';
import { WrapperTitle } from 'components/Modal/styles';
import GridFlex from 'components/GridFlex';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Text from 'components/Text';
import { IUserManagement } from 'containers/users-management/dtos';
import Permission from './Permission';
import UserStatus from './UserStatus';
import PasswordField from '../PasswordField';
import NavButtons from '../NavButtons';

import { ETypeSteps } from '../index';

import {
  Container,
  ContainerResetPasswordButton,
  WrapperPasswordTitle,
} from './styles';
import { ContainerLoading, ContainerResponse } from '../styles';

interface IProps {
  onClose: () => void;
  onCreate: () => void;
  onGoToPassword: () => void;
  isEdit: boolean | IUserManagement;
}

const User: React.FC<IProps> = ({
  onClose,
  onCreate,
  isEdit,
  onGoToPassword,
}) => {
  const userEdit = isEdit as IUserManagement;

  const [name, setName] = useState(userEdit?.fullname || '');
  const [login, setLogin] = useState(userEdit?.login || '');
  const [permission, setPermission] = useState<number | undefined>(
    userEdit?.role?.id || undefined,
  );
  const [userLocation, setUserLocation] = useState<number | undefined>(
    userEdit?.countrie?.id || undefined,
  );
  const [userStatus, setUserStatus] = useState<string | undefined>(
    userEdit?.status || undefined,
  );
  const [userPass, setUserPass] = useState<string>('');
  const [userPassConfirm, setUserPassConfirm] = useState<string>('');

  const [errorLogin, setErrorLogin] = useState(false);
  const [errorEmailUsed, setErrorEmailUsed] = useState(false);

  const [errorPass, setErrorPass] = useState(false);
  const [errorConfirm, setErrorConfirm] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);

  const [type, setType] = useState<ETypeSteps>(ETypeSteps.DEFAULT);

  const handleChange = setFunc => (e: any) => setFunc(e.target.value);

  const isCompleted =
    name &&
    login &&
    permission &&
    userLocation &&
    userStatus &&
    (isEdit || (userPass && userPassConfirm && !errorPass && !errorConfirm));

  const requestToEditOrCreateUser = async () => {
    setType(ETypeSteps.LOADING);
    try {
      const data = {
        fullname: name,
        login: login !== userEdit.login ? login : undefined,
        status: userStatus,
        roleId: permission,
        countrieId: userLocation,
        password: userPass || undefined,
      };

      if (isEdit) await api.patch(`/users/${userEdit.id}`, data);
      else await api.post('/users', data);
      setType(ETypeSteps.SUCCESS);
    } catch (err: any) {
      if (err?.status === 403) setErrorEmailUsed(true);
      else setErrorEmailUsed(false);
      setType(ETypeSteps.ERROR);
    }
  };

  const handleSubmit = () => {
    if (!isCompleted) return;

    if (!validMail(login)) {
      setErrorLogin(true);
      return;
    }

    if (userPass !== userPassConfirm) {
      setErrorConfirmPass(true);
      return;
    }

    requestToEditOrCreateUser();
  };

  const handleChangeEmail = (email: string) => {
    if (errorLogin && validMail(login)) setErrorLogin(false);
    setLogin(email);
  };

  const handleChangePass = (pass: string) => {
    if (errorConfirmPass && userPass !== userPassConfirm)
      setErrorConfirmPass(false);
    setUserPassConfirm(pass);
  };

  return (
    <>
      <>
        {type === ETypeSteps.DEFAULT && (
          <Container>
            <WrapperTitle marginTitle>
              <Text color="black" fontSize={24}>
                {!isEdit ? <>Create new user</> : <>Edit user</>}
              </Text>
            </WrapperTitle>
            <GridFlex justifyContent="space-between">
              <Input
                dataTest="full-name"
                data-testid="full-name"
                name="full-name"
                onChange={handleChange(setName)}
                autoFocus
                label="Full name"
                width="450px"
                value={name}
              />

              <Input
                dataTest="mail"
                data-testid="mail"
                name="mail"
                onChange={handleChange(handleChangeEmail)}
                label="E-mail"
                width="450px"
                value={login}
                error={errorLogin ? 'E-mail invalid' : undefined}
              />
            </GridFlex>

            <GridFlex justifyContent="space-between" margin="40px 0">
              <Permission value={permission} setValue={setPermission} />

              <CountrySelect
                value={userLocation}
                setValue={setUserLocation}
                dataTest="user-location"
                label="User Location"
              />
            </GridFlex>

            <GridFlex justifyContent="space-between">
              <UserStatus value={userStatus} setValue={setUserStatus} />
              {isEdit && (
                <ContainerResetPasswordButton>
                  <Button
                    scheme="gray"
                    type="button"
                    data-testid="reset-pass-button"
                    onClick={onGoToPassword}
                  >
                    Reset password
                  </Button>
                </ContainerResetPasswordButton>
              )}
            </GridFlex>

            {!isEdit && (
              <>
                <WrapperPasswordTitle>
                  <Text color="black" fontSize={24}>
                    Create password
                  </Text>
                </WrapperPasswordTitle>
                <GridFlex justifyContent="space-between" margin="40px 0">
                  <PasswordField
                    userPass={userPass}
                    setUserPass={setUserPass}
                    onCheckPass={setErrorPass}
                    label="Password"
                    dataTest="password"
                  />

                  <PasswordField
                    userPass={userPassConfirm}
                    setUserPass={handleChangePass}
                    onCheckPass={setErrorConfirm}
                    label="Verify password"
                    error={
                      errorConfirmPass ? 'Passwords must be equal' : undefined
                    }
                    dataTest="confirm-password"
                  />
                </GridFlex>
              </>
            )}
          </Container>
        )}

        {type === ETypeSteps.LOADING && (
          <ContainerLoading>
            <Text color="black" fontSize={24}>
              {isEdit ? 'Updating' : 'Creating'} user
            </Text>
            <Spinner size="medium" />
          </ContainerLoading>
        )}

        {type === ETypeSteps.SUCCESS && (
          <ContainerResponse>
            <Icon icon="check-circle" color="none" width="68px" />
            <Text color="black" fontSize={30}>
              {isEdit
                ? 'User edited with success!'
                : 'User created with success!'}
            </Text>
            <Text color="black" fontSize={18} fontWeight="normal">
              The user <strong>{name}</strong> has been{' '}
              {isEdit ? 'edited' : 'created'}.
            </Text>
          </ContainerResponse>
        )}

        {type === ETypeSteps.ERROR && (
          <ContainerResponse>
            <Icon icon="close-error" color="none" width="68px" />
            <Text color="black" fontSize={30}>
              Could not {isEdit ? 'edit' : 'create'} the user at the moment
            </Text>
            {!errorEmailUsed ? (
              <Text color="black" fontSize={18} fontWeight="normal">
                We&rsquo;re sorry for the inconvenience. Please, try again later
              </Text>
            ) : (
              <Text color="black" fontSize={18} fontWeight="normal">
                E-mail already assigned to a user.
              </Text>
            )}
          </ContainerResponse>
        )}
      </>

      <NavButtons
        handleSubmit={handleSubmit}
        isCompleted={!!isCompleted}
        onClose={onClose}
        onCreate={onCreate}
        type={type}
        isEdit={!!isEdit}
      />
    </>
  );
};

export default User;
