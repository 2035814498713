import { stringify } from 'qs';

import { LoadingEnum } from 'containers/spec-sheet/dtos';
import api from 'services/api';
import { camelize } from 'helpers/captalize';
import { IActions, IState, IGetSpecSheet, ReportItem } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

const getOnlyIdsFromFilters = filters => {
  const newObject = {};

  Object.keys(filters).forEach(key => {
    newObject[key] = filters[key].map(item => item.id || item);
  });

  return newObject;
};

const getOnlyIdsFromFiltersToString = filters => {
  const newObject = {};

  Object.keys(filters).forEach(key => {
    if (filters[key].length) {
      newObject[key] = filters[key].map(
        item => item.id.toString() || item.toString(),
      );
    }
  });

  return newObject;
};

const verifySort = sort => {
  const sortFilters = {
    'Color name': 'color',
    'Material name': 'material',
    'Proto number': 'refNo',
    'Heel height': 'heelHeight',
  };

  return sortFilters[sort] || sort;
};

export default (params: IParams): IActions => {
  const { setData, data } = params;

  async function getSpectSheetsAdvancedFilters() {
    setData({ ...data, loading: true });
    const params = getOnlyIdsFromFilters(data.filters);

    try {
      const response: IGetSpecSheet[] = await api.get(
        `/spec-sheets/advanced-filter`,
        {
          params,
          paramsSerializer: params =>
            stringify(params, { arrayFormat: 'repeat' }),
        },
      );
      setData({ ...data, list: response, loading: false });
    } catch (err: any) {
      console.log(err);
    }
  }

  function editArrayStatus(index: number, status: string) {
    const newList = data.list;

    newList[index].status = status;

    setData({
      ...data,
      list: newList,
    });
  }

  function addToFilter(filterType: keyof IState['filters'], value) {
    setData({
      ...data,
      filters: {
        ...data.filters,
        [filterType]: [...data.filters[filterType], value],
      },
    });
  }

  function removeFilter(filterType: keyof IState['filters'], value) {
    setData({
      ...data,
      filters: {
        ...data.filters,
        [filterType]: data.filters[filterType].filter(i => i.id !== value.id),
      },
    });
  }

  function hasOnFilter(filterType: keyof IState['filters'], value) {
    return !!data.filters[filterType].find(
      i => i === value || i.id === value.id,
    );
  }

  function resetFilter(filterType: keyof IState['filters']) {
    setData({
      ...data,
      filters: {
        ...data.filters,
        [filterType]: [],
      },
    });
  }

  async function sendFiltersToSave() {
    setData({ ...data, loading: LoadingEnum.SAVING });

    try {
      await api.put(`/report-line-sheet-history`, {
        value: JSON.stringify({ filters: data.filters, sortBy: data.sortBy }),
      });

      const sortByFiltered = data.sortBy
        .filter(Boolean)
        .map(sort => camelize(verifySort(sort)));

      const response: ReportItem[] = await api.post(`/reports/line-sheet`, {
        ...getOnlyIdsFromFiltersToString(data.filters),
        sortBy: sortByFiltered.length > 0 ? sortByFiltered : undefined,
      });

      setData({
        ...data,
        resultReport: response,
        loading: false,
      });
    } catch (err) {
      setData({
        ...data,
        resultReport: [],
        loading: false,
      });
    }
  }

  async function getFilters() {
    setData({ ...data, loading: true });

    const { value }: any = await api.get(`/report-line-sheet-history`);

    setData({
      ...data,
      filters: (value && value.filters) || data.filters,
      sortBy: (value && value.sortBy) || data.sortBy,
      loading: false,
    });
  }

  function fillSortBy(index, value) {
    const newSortBy = data.sortBy;

    const existsInArray = newSortBy.indexOf(value);

    if (existsInArray >= 0) {
      newSortBy[existsInArray] = undefined;
    }

    newSortBy[index] = value;

    setData({
      ...data,
      sortBy: [...newSortBy],
    });
  }

  return {
    getSpectSheetsAdvancedFilters,
    editArrayStatus,
    addToFilter,
    removeFilter,
    hasOnFilter,
    resetFilter,
    sendFiltersToSave,
    getFilters,
    fillSortBy,
  };
};
