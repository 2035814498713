import styled from 'styled-components';

export const WrapperSelect = styled.div`
  margin-top: 23px;
`;

export const WrapperButtonSelect = styled.div`
  margin-top: 23px;

  button {
    font-size: 11px;
  }
`;

export const WrappCheckbox = styled.div`
  > label {
    margin-bottom: 12px;
  }
`;
