import React from 'react';

import Routes from 'routes';
import Nav from '../Nav';

import { Container } from './styles';

const Main: React.FC = () => {
  return (
    <Container>
      <Nav />
      <Routes />
    </Container>
  );
};

export default Main;
