/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import { LoadingEnum } from 'containers/spec-sheet/dtos';
import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';

import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';

import { WrapperButton } from './styles';

const Actions: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const {
    data: { loading },
    actions: actionsSpecSheet,
  } = useSpecSheet();
  const {
    data: { skuDetailActive },
    actions,
  } = useSpecSheetSkuDetail();

  const handleDuplicate = async () => {
    actionsSpecSheet.setLoading(LoadingEnum.DUPLICATE_SKU_DETAILS);
    await actions.duplicateSkuDetail();
    actionsSpecSheet.removeLoading();
  };

  const deleteVariable = async () => {
    setOpenModal(false);
    actionsSpecSheet.setLoading(LoadingEnum.DELETE_SKU_DETAILS);
    await actions.deleteVariable();
    actionsSpecSheet.removeLoading();
  };

  const duplicateLoading = loading === LoadingEnum.DUPLICATE_SKU_DETAILS;
  const deleteLoading = loading === LoadingEnum.DELETE_SKU_DETAILS;

  return (
    <>
      <GridFlex
        direction="column"
        margin="40px 0 45px"
        data-testid="wrapper-actions"
      >
        <Text fontSize={24} color="black" data-testid="title-text">
          Sku {skuDetailActive + 1}
        </Text>

        <GridFlex margin="20px 0 0">
          <Button
            onClick={duplicateLoading ? undefined : handleDuplicate}
            width="125px"
            padding="7.5px 0"
            data-testid="action-duplicate"
          >
            {duplicateLoading ? (
              <Spinner size="very-small" />
            ) : (
              <Text fontSize={13} color="white" data-testid="text-duplicate">
                Duplicate
              </Text>
            )}
          </Button>
          <Button
            onClick={deleteLoading ? undefined : () => setOpenModal(true)}
            width="125px"
            padding="7.5px 0"
            margin="0 0 0 12px"
            data-testid="action-delete"
          >
            {deleteLoading ? (
              <Spinner size="very-small" />
            ) : (
              <Text fontSize={13} color="white" data-testid="text-delete">
                Delete
              </Text>
            )}
          </Button>
        </GridFlex>
      </GridFlex>
      <Modal
        isOpen={openModal}
        title={`Do you really want to delete "variable ${
          skuDetailActive + 1
        }"?`}
        onClose={() => setOpenModal(false)}
        minWidth="691px"
        noButtonClose
        data-test="modal-delete-variable"
      >
        <WrapperButton>
          <Button
            width="266px"
            onClick={deleteVariable}
            data-test="modal-delete-variable-button-yes"
          >
            Yes
          </Button>

          <Button
            width="266px"
            onClick={() => setOpenModal(false)}
            scheme="nobg"
            data-test="modal-delete-variable-button-no"
          >
            Cancel
          </Button>
        </WrapperButton>
      </Modal>
    </>
  );
};

export default Actions;
