import styled, { css } from 'styled-components';

import { takeColor } from 'theme/tools';

import { Selected } from 'containers/create-sketch/dtos';

export interface IProps {
  x?: any;
  y?: any;
  cx?: any;
  cy?: any;
  valueCenter?: number;
  isError?: boolean;
  selected?: Selected;
  type?: string;
  width?: any;
  angle?: any;
  notComment?: boolean;
}

export const PointBall = styled.div<IProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${takeColor('black')};
  border: 1px solid ${takeColor('white')};
  position: absolute !important;
  top: ${props => `calc(${props.y}px - 6px)` || 0};
  left: ${props => `calc(${props.x}px - 6px)` || 0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FakeClick = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const BoxDelete = styled.span`
  width: 19px;
  height: 19px;
  border-radius: 50%50%;
  background: ${takeColor('white')};
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: -7px;
  right: -7px;
  color: #8d8d8d;
  font-size: 12px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
`;

export const Text = styled.div<IProps>`
  width: 40px;
  height: 40px;
  z-index: 9999;
  top: -19px;
  left: -19px;
  background: ${takeColor('black')};
  border: 1px solid;
  position: absolute;
  color: ${props => (props.isError ? takeColor('error') : takeColor('white'))};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: ${takeColor('white')};
  cursor: all-scroll;

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

export const Input = styled.input`
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  font-size: 17px;
  color: ${takeColor('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const LineConnect = styled.div<IProps>`
  ${({ notComment, width, angle }) =>
    !notComment &&
    css`
      position: absolute;
      width: ${width}px;
      height: 2px;
      content: '';
      left: 4px;
      transform: translateX(0%) rotate(${angle}deg);
      transform-origin: 0% 0%;
      background: ${takeColor('black')};
    `}
`;
