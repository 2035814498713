import React from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/Button';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';
import { useSpecSheet } from 'containers/spec-sheet';

import { TypeEnum } from 'containers/spec-sheet-modal/dtos';

import {
  Container,
  SpecVisualize,
  WrapperHeader,
  WrapperButtons,
} from './styles';

const FinishSpecSheet: React.FC = () => {
  const { actions } = useSpecSheetModal();
  const {
    data: { data },
  } = useSpecSheet();

  const openModal = (type: TypeEnum) => {
    if (data?.id) actions.openModalAndGetSpecSheetComplete(data.id, type);
  };

  return (
    <>
      <Container>
        <WrapperHeader>
          <Icon icon="check-circle" color="none" width="68px" />
          <Text fontSize={30} fontWeight="normal-bold" color="black">
            Spec completed with success!
          </Text>
          <Text fontSize={18} color="black">
            You can access this spec sheet again through the home screen
          </Text>
        </WrapperHeader>

        <SpecVisualize>
          <Icon icon="spec-sheet" color="grayIconUpload" width="90px" />
          <Button
            scheme="grayLight"
            width="265px"
            onClick={() => openModal(TypeEnum.DEFAULT)}
          >
            Visualize spec sheet
          </Button>
        </SpecVisualize>

        <WrapperButtons>
          {/* <Button width="265px">Download</Button> */}
          <Button
            width="265px"
            data-testid="print-button"
            onClick={() => openModal(TypeEnum.PRINT)}
          >
            Print
          </Button>
        </WrapperButtons>
      </Container>
    </>
  );
};

export default FinishSpecSheet;
