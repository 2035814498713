import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.table`
  width: 100%;
`;

export const WrappItem = styled.tr`
  display: flex;
  margin-bottom: 10px;
  page-break-inside: avoid;
  page-break-after: auto;

  @media print {
    & {
      margin-bottom: 5px;
    }
  }
`;

export const RightItem = styled.div`
  background-color: ${takeColor('grayLight')};
  padding: 20px 25px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media print {
    & {
      padding: 8px 12px;
    }
  }
`;

export const LeftItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  min-width: 350px;
  max-height: 350px;
  min-height: 200px;
  border: 1px solid #a8a8a8;
  border-radius: 10px;
  box-shadow: 0px 0px 10.0381px rgba(0, 0, 0, 0.1);
  padding: 14px 18px;
  margin-right: 20px;

  @media print {
    & {
      max-width: 195px;
      min-width: 195px;
      max-height: 200px;
      min-height: 135px;
      padding: 8px 10px;
      margin-right: 10px;

      p {
        font-size: 7px !important;
      }
    }
  }

  div {
    height: 100%;
    ${simpleFlex}
    justify-content: center;
  }
`;

export const ImageShoe = styled.img`
  width: 100%;
`;

export const ItemsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const ItemsInfo = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 8px;
    text-align: right;
  }

  > div:last-child {
    > p:last-child {
      max-width: 90px;
    }
  }

  p {
    min-height: 14px;
    margin-bottom: 3px;
  }

  @media print {
    p {
      font-size: 7px !important;
      min-height: 7px;
      margin-bottom: 0px;
    }
  }
`;

export const BottomInfos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  p {
    flex: 1;
    margin-right: 10px;
    line-height: 13px;
  }

  @media print {
    margin-top: 10px;
    p {
      font-size: 7px !important;
    }
  }
`;
