/* eslint-disable no-nested-ternary */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Roles } from 'containers/user/dtos';
import { isAuthenticated } from 'helpers/auth';
import { getUserRole } from 'helpers/verifyRoles';

interface IPrivateAdminRoute extends RouteProps {
  component: React.ComponentType<RouteProps>;
}

const PrivateAdminRoute: React.FC<IPrivateAdminRoute> = ({
  component: Component,
  ...rest
}) => {
  const signedIn = !!isAuthenticated();

  return (
    <Route
      {...rest}
      render={props => {
        return signedIn ? (
          getUserRole(Roles.manager) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/access-denied" />
          )
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateAdminRoute;
