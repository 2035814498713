/* eslint-disable no-console */
import api from 'services/api';
import { IActions, IState } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  function changeStep(step: number) {
    setData({ ...state, stepActive: step });
  }

  function setSearch(search: string) {
    setData({ ...state, search });
  }

  async function getAllSpecSheets(
    params: { limit: number; page: number; search?: string },
    isMore?: boolean,
  ) {
    setData({ ...state, type: isMore ? 'loadingMore' : 'loading' });

    try {
      const data: any = await api.get(`/spec-sheets/`, {
        params: { ...params, search: state.search },
      });
      if (isMore) {
        setData({
          ...state,
          allSpecSheets: state.allSpecSheets?.concat(data.items),
          meta: data.meta,
          type: 'completed',
        });
        return;
      }
      setData({
        ...state,
        allSpecSheets: data.items,
        meta: data.meta,
        type: 'completed',
      });
    } catch (err: any) {
      setData({
        ...state,
        type: 'error',
      });
    }
  }

  return {
    changeStep,
    setSearch,
    getAllSpecSheets,
  };
};
