import React from 'react';

import Text from 'components/Text';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';

import GroupVariables from './GroupVariables';

import {
  Container,
  WrappItem,
  LeftItem,
  RightItem,
  ImageShoe,
  ItemsGroup,
  ItemsInfo,
  BottomInfos,
} from './styles';

const List: React.FC = () => {
  const {
    data: { resultReport },
  } = useSpecSheetSearch();

  return (
    <Container>
      {resultReport.length && (
        <>
          {resultReport.map(
            ({
              id,
              description,
              styleType,
              refNo,
              image,
              construction,
              categories,
              subCategories,
              brand,
              season,
              factory,
              country,
              heelHeight,
              items,
            }) => (
              <WrappItem key={`${id}-${description}-${refNo}`}>
                <LeftItem>
                  <Text color="black" fontSize={12} fontWeight="normal-bold">
                    {description && `${description}`}
                    {description && refNo && ' / '}
                    {refNo}
                  </Text>
                  <div>{image && <ImageShoe src={image} />}</div>
                  <Text color="black" fontSize={12}>
                    <strong>Construction name:</strong> {construction}
                  </Text>
                </LeftItem>
                <RightItem>
                  <ItemsGroup>
                    <GroupVariables items={items} />
                    <ItemsInfo>
                      <div>
                        <Text
                          color="black"
                          fontWeight="normal-bold"
                          fontSize={12}
                        >
                          {brand}
                        </Text>
                        <Text
                          color="black"
                          fontWeight="normal-bold"
                          fontSize={12}
                        >
                          {factory}
                        </Text>
                        {heelHeight && (
                          <Text color="black" fontSize={12}>
                            Heel height:
                          </Text>
                        )}
                      </div>
                      <div>
                        <Text
                          color="black"
                          fontWeight="normal-bold"
                          fontSize={12}
                        >
                          {season}
                        </Text>
                        <Text
                          color="black"
                          fontWeight="normal-bold"
                          fontSize={12}
                        >
                          {country}
                        </Text>
                        <Text color="black" fontSize={12}>
                          {heelHeight?.attribute}
                        </Text>
                      </div>
                    </ItemsInfo>
                  </ItemsGroup>
                  <BottomInfos>
                    <Text color="black" fontSize={12}>
                      {styleType}
                    </Text>
                    <Text color="black" fontSize={12}>
                      <strong>Category: </strong>
                      {categories?.map((st, i) =>
                        categories.length - 1 !== i ? `${st}, ` : st,
                      )}
                    </Text>
                    <Text color="black" fontSize={12}>
                      <strong>Sub category: </strong>
                      {subCategories?.map((sC, i) =>
                        subCategories.length - 1 !== i ? `${sC}, ` : sC,
                      )}
                    </Text>
                  </BottomInfos>
                </RightItem>
              </WrappItem>
            ),
          )}
        </>
      )}
    </Container>
  );
};

export default List;
