import React, { useState, memo } from 'react';
import { useFormik } from 'formik';
import { getItem } from 'helpers/storage';

import { Input, Checkbox } from 'components/Fields';
import Button from 'components/Button';
import Text from 'components/Text';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';

import { useUser } from 'containers/user';

import validationSchema from './validationSchema';

import {
  FormStyles,
  FormFields,
  ActionsWrapper,
  ButtonWrapperLoading,
  WrapperButton,
} from './styles';

const Form: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isChecked, setIsChecked] = useState(!!getItem('@loginRemember'));

  const {
    data: { loading, error },
    actions,
  } = useUser();

  const { errors, values, touched, ...formik } = useFormik({
    initialValues: {
      login: getItem('@loginRemember') || '',
      password: '',
    },
    validationSchema,
    onSubmit: async values => {
      await actions.makeLogin(values, isChecked);
    },
  });

  return (
    <>
      <FormStyles onSubmit={formik.handleSubmit}>
        <FormFields>
          <Input
            label="Username"
            name="login"
            onChange={formik.handleChange}
            value={values.login}
            placeholder="name@fornovagroup.com"
            autoFocus
            error={(touched.login && errors.login) || ''}
            isCompleted={touched.login && !errors.login}
            dataTest="login"
          />

          <Input
            label="Password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={values.password}
            placeholder="Min 8 characters"
            error={(touched.password && errors.password) || ''}
            isCompleted={touched.password && !errors.password}
            dataTest="password"
          />
        </FormFields>

        <Checkbox
          onClick={() => setIsChecked(!isChecked)}
          label="Remember me"
          isChecked={isChecked}
          name="remember"
        />

        {error && (
          <>
            <br />
            <br />
            <Text color="error" fontSize={16}>
              {error}
            </Text>
          </>
        )}

        <ActionsWrapper>
          <ButtonWrapperLoading>
            <Button>
              <Text fontSize={18} color="white">
                Login
              </Text>
            </Button>

            {loading && <Spinner size="medium" />}
          </ButtonWrapperLoading>

          <Text
            fontSize={16}
            color="grayMedium"
            onClick={() => setOpenModal(true)}
          >
            Forgot password?
          </Text>
        </ActionsWrapper>
      </FormStyles>
      <Modal
        isOpen={openModal}
        title="Forgot your password?"
        subtitle="Please, contact the System Admin and request a new password."
        onClose={() => setOpenModal(false)}
        minWidth="691px"
        noButtonClose
        data-test="modal-delete-variable"
      >
        <WrapperButton>
          <Button
            width="266px"
            onClick={() => setOpenModal(false)}
            data-test="modal-close-button"
          >
            Ok
          </Button>
        </WrapperButton>
      </Modal>
    </>
  );
};

export default memo(Form);
