import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  justify-content: center;
  position: relative;
  width: fit-content;
  border-radius: 13px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  width: 260px;
  height: auto;
  max-height: 50%;
  overflow: hidden;

  > div {
    position: relative;
    transform: scale(0.5);
  }
`;

export const ImagePrototype = styled.img`
  max-width: 243px;
  max-height: 165px;
  border-radius: 13px;
  border-radius: 13px;
`;
