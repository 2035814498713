import React, { useEffect, createRef } from 'react';

import Icon from 'components/Icon';
import {
  Container,
  ContainerInput,
  ContainerButtons,
  ButtonSave,
  ButtonCancel,
  CommentPoint,
} from './styles';

export interface IProps {
  x: any;
  y: any;
  cx: any;
  cy: any;
  valueCenter: number;
  isInputComment?: boolean;
  onChange?: (e: any) => void;
  onSave?: () => void;
  onDelete?: () => void;
  text: string;
}

const Comments = ({
  x,
  y,
  cx,
  cy,
  valueCenter,
  isInputComment,
  onChange,
  onSave,
  onDelete,
  text,
}: IProps) => {
  const focusDiv: any = createRef();

  useEffect(() => {
    focusDiv?.current?.focus();
  }, [focusDiv]);

  return (
    <>
      {isInputComment ? (
        <Container>
          <ContainerInput
            x={x}
            y={y}
            cx={cx}
            cy={cy}
            valueCenter={valueCenter}
            onChange={e => onChange && onChange(e.target.value)}
            value={text}
            ref={focusDiv}
          />
          <ContainerButtons>
            <ButtonSave onClick={onSave}>
              <Icon icon="check" width="25px" />
            </ButtonSave>
            <ButtonCancel onClick={onDelete}>
              <Icon icon="close" width="20px" />
            </ButtonCancel>
          </ContainerButtons>
        </Container>
      ) : (
        <CommentPoint>
          <Icon icon="comment" width="22px" color="black" />
        </CommentPoint>
      )}
    </>
  );
};

export default Comments;
