import React from 'react';

import { useSpecSheets } from 'containers/spec-sheets';

import Button from 'components/Button';
import Icon from 'components/Icon';

import { WrapperAction } from './styles';

const Bottom: React.FC = () => {
  const {
    actions: { changeStep },
    data: { stepActive },
  } = useSpecSheets();

  const goBack = () => changeStep(stepActive - 1);
  const goToFinish = () => changeStep(stepActive + 1);

  return (
    <WrapperAction justifyContent="center" margin="100px 0">
      <Button
        scheme="nobg"
        onClick={goBack}
        width="265px"
        data-testid="button-go-back"
      >
        <Icon icon="arrow-left" width="15px" color="black" marginRight={15} />
        Go back
      </Button>

      <Button onClick={goToFinish} width="265px" data-testid="button-go-finish">
        Finish
        <Icon icon="arrow-right" width="15px" color="white" marginLeft={15} />
      </Button>
    </WrapperAction>
  );
};

export default Bottom;
