import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  .numberSelected {
    display: none;
  }
`;

export const ContainerModal = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 80%;
  ${simpleFlex}
  justify-content: center;
  gap: 40px;

  span {
    display: none;
  }
`;

export const ButtonsModal = styled.div`
  ${simpleFlex};
  justify-content: center;

  margin-top: 80px;

  button:not(:last-child) {
    margin-right: 40px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0px 65px;
`;
