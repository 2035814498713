import React, { useState, useEffect } from 'react';

import api from 'services/api';
import history from 'routes/history';

import { useSpecSheets } from 'containers/spec-sheets';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import {
  WrapperButton,
  WrapperTextIcon,
} from 'components/ModalDeleteOrCancel/styles';

export interface IProps {
  id: number;
  refNo: number;
  styleName?: string;
  onClose: () => void;
  isOpen: boolean;
  goBackText?: string;
}

enum EType {
  DEFAULT = 0,
  LOADING = 1,
  ERROR = 2,
  SUCCESS = 3,
}

const ErrorComponent = () => (
  <WrapperTextIcon>
    <Icon icon="close-error" color="none" width="38px" />
    Something went wrong. Please, try again later
  </WrapperTextIcon>
);

const SuccessComponent = () => (
  <WrapperTextIcon>
    <Icon icon="check-circle" color="none" width="38px" />
    Spec sheet duplicated with success!
  </WrapperTextIcon>
);

const ModalDuplicate: React.FC<IProps> = ({
  id,
  refNo,
  onClose,
  isOpen,
  goBackText,
  styleName,
}) => {
  const [type, setType] = useState<EType>(EType.DEFAULT);
  const [newSpec, setNewSpec] = useState<any>(null);

  const {
    actions: { getAllSpecSheets },
  } = useSpecSheets();

  const ref = styleName || refNo || id;

  const title = {
    [EType.DEFAULT]: `Do you really want to duplicate the spec ”${ref}”?`,
    [EType.ERROR]: <ErrorComponent />,
    [EType.LOADING]: <Spinner />,
    [EType.SUCCESS]: <SuccessComponent />,
  };

  const handleDuplicate = async () => {
    setType(EType.LOADING);

    try {
      const spec = await api.post(`/spec-sheets/${id}/duplicate`);

      setNewSpec(spec);

      setType(EType.SUCCESS);
    } catch (err) {
      setType(EType.ERROR);
    }
  };

  const goToSpec = () => {
    history.push(`/spec-sheet/${newSpec.id}`);
  };

  useEffect(() => {
    if (type !== EType.DEFAULT) setType(EType.DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={title[type]}
      onClose={onClose}
      minWidth="691px"
      noButtonClose
      data-testid="modal-duplicate-variable"
    >
      {type === EType.DEFAULT && (
        <WrapperButton>
          <Button
            width="265px"
            onClick={handleDuplicate}
            data-testid="modal-duplicate-variable-button-yes"
          >
            Yes
          </Button>

          <Button
            width="265px"
            onClick={onClose}
            scheme="nobg"
            data-testid="modal-duplicate-variable-button-no"
          >
            No
          </Button>
        </WrapperButton>
      )}

      {type === EType.SUCCESS && (
        <WrapperButton>
          <Button
            width="265px"
            onClick={goToSpec}
            data-testid="modal-duplicate-variable-button-visualize"
          >
            Visualize
          </Button>

          <Button
            width="265px"
            onClick={() => {
              getAllSpecSheets({ limit: 9, page: 1 });
              onClose();
            }}
            scheme="nobg"
            data-testid="modal-duplicate-variable-button-no"
          >
            {goBackText || 'Go back to Spec home'}
          </Button>
        </WrapperButton>
      )}

      {type === EType.ERROR && (
        <WrapperButton>
          <Button
            width="265px"
            onClick={onClose}
            scheme="nobg"
            data-testid="modal-duplicate-variable-button-ok"
          >
            Ok
          </Button>
        </WrapperButton>
      )}
    </Modal>
  );
};

export default ModalDuplicate;
