import api from 'services/api';
import { IActions, IState, ICreatePoint } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  async function createPoint(data: ICreatePoint) {
    setData({ ...state, loading: true });

    try {
      await api.post(`/spec-sheets-images-details/`, data);
      setData({ ...state, loading: false });
    } catch (err) {
      setData({
        ...state,
        loading: false,
      });
    }
  }

  async function updatePoint(
    id: number,
    value: string,
    { letterY, letterX }: { letterY: number; letterX: number },
  ) {
    setData({ ...state, loading: true });

    try {
      await api.put(`/spec-sheets-images-details/${id}`, {
        value,
        letterY,
        letterX,
      });
      setData({ ...state, loading: false });
    } catch (err) {
      setData({
        ...state,
        loading: false,
      });
    }
  }

  return {
    createPoint,
    updatePoint,
  };
};
