/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import Text from 'components/Text';

import Button from 'components/Button';
import { FieldStyle, LabelWrapper } from '../styles';

import { InputStyle, WrapperLeftContent } from './styles';

export interface IProps {
  onChange: (event?: ChangeEvent<HTMLInputElement> | any) => void;
  handleFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: any) => void;
  isEdit?: (e: any) => void;
  placeholder?: string;
  error?: string;
  type?: 'text' | 'password' | 'date' | 'number';
  label?: string;
  value?: string | number;
  name: string;
  width?: string;
  autoFocus?: boolean;
  isCompleted?: boolean;
  leftContent?: any;
  rightContent?: any;
  disabled?: boolean;
  autoComplete?: boolean;
  dataTest?: string;
  inputRef?: any;
  isMoneyField?: boolean;
  min?: string | number;
}

const prefixDataTest = 'input';

const Input: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);

  const {
    onChange,
    value,
    handleBlur,
    type,
    label,
    name,
    placeholder,
    width,
    autoFocus,
    isCompleted,
    error,
    leftContent,
    disabled,
    handleFocus,
    autoComplete,
    dataTest,
    isEdit,
    inputRef,
    rightContent,
    isMoneyField,
    min,
  } = props;

  const [moneyValue, setMoneyValue] = useState<string | number>('');

  useEffect(() => {
    if (value) {
      setMoneyValue(Number(value) * 100 * 100);
    } else {
      setMoneyValue(0);
    }
  }, [value]);

  const onBlur = (e: any) => {
    setIsFocus(false);
    if (handleBlur) handleBlur(e);
  };

  const onFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isFocus) {
      if (handleFocus) handleFocus(event);
      setIsFocus(true);
    }
  };

  const inputProps = {
    id: name,
    name,
    onBlur,
    placeholder,
    onFocus,
    autoFocus,
    disabled,
    autoComplete: autoComplete ? 'on' : 'off',
    'data-test': `${prefixDataTest}-field-${dataTest}`,
    'data-testid': `${prefixDataTest}-field-${dataTest}`,
    ...(min && {
      min,
    }),
  };

  const currencyFormatter = (formatted_value: any) => {
    if (!Number(formatted_value)) return '$0.00';
    const br = { style: 'currency', currency: 'USD' };
    return new Intl.NumberFormat('en-US', br).format(formatted_value / 100);
  };

  const handleInputMoneyChange = (v: any) => {
    setMoneyValue(parseFloat(v.value) * 100);
    if (onChange) {
      onChange({ ...v, floatValue: v.floatValue / 100 });
    }
  };

  const keyDown = (e: any) => {
    if (e.code === 'Backspace' && !moneyValue) {
      e.preventDefault();
    }
    if (e.code === 'Backspace' && moneyValue < 1000) {
      e.preventDefault();
      setMoneyValue(0);
    }
  };

  return (
    <LabelWrapper
      htmlFor={name}
      width={width}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
      data-testid={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      {label && (
        <Text
          color="black"
          fontSize={18}
          fontWeight="normal-bold"
          data-test={`${prefixDataTest}-label-${dataTest}`}
          data-testid={`${prefixDataTest}-label-${dataTest}`}
        >
          {label}
        </Text>
      )}

      <FieldStyle
        width={width}
        isCompleted={isCompleted}
        isFocus={isFocus}
        error={error}
        isEdit={isEdit}
      >
        {leftContent && <WrapperLeftContent>{leftContent}</WrapperLeftContent>}
        {isMoneyField ? (
          <NumberFormat
            prefix="$ "
            decimalScale={2}
            allowNegative={false}
            value={Number(moneyValue) / 100}
            onKeyDown={keyDown}
            format={currencyFormatter}
            onValueChange={handleInputMoneyChange}
            customInput={InputStyle}
            {...inputProps}
          />
        ) : (
          <InputStyle
            value={value}
            ref={inputRef}
            type={type}
            onChange={onChange}
            {...inputProps}
          />
        )}
        {rightContent && <>{rightContent}</>}

        {isEdit && (
          <Button
            width="125px"
            padding="10px 0"
            onClick={isEdit}
            data-testid={`${prefixDataTest}-button-edit-${dataTest}`}
          >
            <Text fontSize={13} fontWeight="normal-bold" color="white">
              Edit
            </Text>
          </Button>
        )}
      </FieldStyle>

      {error && (
        <Text
          fontSize={13}
          fontWeight="normal-bold"
          color="error"
          tag="span"
          data-test={`${prefixDataTest}-error-${dataTest}`}
          data-testid={`${prefixDataTest}-error-${dataTest}`}
        >
          {error}
        </Text>
      )}
    </LabelWrapper>
  );
};

Input.defaultProps = {
  handleBlur: undefined,
  type: 'text',
  placeholder: 'Click to add',
  error: '',
  width: '100%',
  autoFocus: false,
  isCompleted: false,
  disabled: false,
  autoComplete: false,
};

export default Input;
