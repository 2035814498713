import styled, { css } from 'styled-components';
import Icon from 'components/Icon';

import { Selected } from 'containers/create-sketch/dtos';

interface MasterImageProps {
  selected: Selected;
}

export const MasterImageZoomInSizes = {
  width: 350,
  height: 365,
};

export const BoxImage = styled.div<{ removeBorder?: boolean }>`
  width: 100%;
  ${({ removeBorder }) =>
    !removeBorder &&
    css`
      border: 2px solid #ededed;
    `}
  box-sizing: border-box;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 88px;
  max-width: 600px;
  margin: 0 auto;
`;

export const BoxImagePosition = styled.div`
  position: relative;
`;
export const MasterImage = styled('img')<MasterImageProps>`
  max-width: 350px;
  max-height: 365px;
  cursor: ${props =>
    props?.selected !== '' && props?.selected !== 'change'
      ? 'crosshair'
      : 'initial'};
  z-index: 999;
`;

export const BoxButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
`;

export const BoxIcon = styled.div`
  margin-right: 10px;
`;
export const IconModifield = styled(Icon)`
  margin-right: 30px !important;
`;
