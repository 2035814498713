/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useRef } from 'react';

import ScrollBar from 'components/ScrollBar';

import outsideClick from 'helpers/outsideClick';

import Input from '../Input';

import { InsivibleDivClick, WrapperOptions } from '../Select/styles';

import { SelectWrapper } from './styles';

export interface IProps {
  placeholder?: string;
  error?: string;
  label?: string;
  value: string | number;
  width?: string;
  name: string;
  dataTest: string;
  callBackClickout?: () => any;
  onChange: (event: ChangeEvent<any>) => void;
}

const prefixDataTest = 'select-input';

const SelectInput: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);

  const {
    value,
    label,
    name,
    placeholder,
    onChange,
    width,
    error,
    dataTest,
    children,
    callBackClickout,
  } = props;

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: async () => {
      if (callBackClickout) await callBackClickout();
      setIsFocus(false);
    },
  });

  const handleOpenSelect = () => {
    addEventClickOut();
    setIsFocus(true);
  };

  const handleCloseSelect = (event: ChangeEvent<any>) => {
    event.stopPropagation();
    removeEventClickOut();
    setIsFocus(false);
  };

  const isCompleted = !!value;

  return (
    <SelectWrapper
      isCompleted={isCompleted || isFocus}
      isFocus={isFocus}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      <Input
        label={label}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        width={width}
        error={error}
        handleFocus={handleOpenSelect}
        dataTest={dataTest}
      />

      {isFocus && <InsivibleDivClick onClick={handleCloseSelect} />}

      <WrapperOptions
        ref={wrapperRef}
        isFocus={isFocus}
        data-test={`${prefixDataTest}-options-${dataTest}`}
      >
        <ScrollBar onClick={handleCloseSelect}>{children}</ScrollBar>
      </WrapperOptions>
    </SelectWrapper>
  );
};

SelectInput.defaultProps = {
  placeholder: 'Click to add',
  error: '',
  width: '100%',
};

export default SelectInput;
