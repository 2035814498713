import styled, { css } from 'styled-components';

import GridFlex from 'components/GridFlex';
import { simpleFlex } from 'theme/tools';

export const Container = styled(GridFlex)`
  margin-top: 25px;
`;

export const SeparatorOne = styled.div`
  width: 25px;
`;

export const SeparatorTwo = styled.div`
  width: 80px;
`;

export const ContainerButton = styled.div`
  margin-top: 25px;
`;

export const WrapperButton = styled.div`
  ${simpleFlex};
  flex: auto;
  justify-content: space-around;
  margin-top: 40px;

  * {
    cursor: pointer;
  }
`;

export const ContainerSelect = styled.div<{ isFirst: boolean }>`
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;

  ${({ isFirst }) =>
    isFirst &&
    css`
      > div:first-child {
        position: absolute;
        top: 47px;
        left: 15px;
      }

      strong {
        margin-left: 9px;
      }
    `}
`;

export const IconCloseContainer = styled.div`
  z-index: 1;
  cursor: pointer;
`;
