import React, { useState, ChangeEvent, useRef } from 'react';

import Text from 'components/Text';

import Button from 'components/Button';
import { FieldStyle, LabelWrapper } from '../styles';

import { WrapperInputFile } from './styles';

export interface IProps {
  onChange: (event?: ChangeEvent<HTMLInputElement> | any) => void;
  handleFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: any) => void;
  placeholder?: string;
  error?: string;
  label?: string;
  value?: any;
  name: string;
  width?: string;
  isCompleted?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  dataTest?: string;
  accept?: string;
}

const prefixDataTest = 'input';

const Input: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);

  const {
    onChange,
    value,
    handleBlur,
    label,
    name,
    placeholder,
    width,
    isCompleted,
    error,
    disabled,
    handleFocus,
    dataTest,
    accept,
  } = props;

  const onBlur = (e: any) => {
    setIsFocus(false);
    if (handleBlur) handleBlur(e);
  };

  const onFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isFocus) {
      if (handleFocus) handleFocus(event);
      setIsFocus(true);
    }
  };

  return (
    <LabelWrapper
      htmlFor={name}
      width={width}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
      data-testid={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      {label && (
        <Text
          color="black"
          fontSize={18}
          fontWeight="normal-bold"
          data-test={`${prefixDataTest}-label-${dataTest}`}
          data-testid={`${prefixDataTest}-label-${dataTest}`}
        >
          {label}
        </Text>
      )}

      <FieldStyle
        width={width}
        isCompleted={isCompleted}
        isFocus={isFocus}
        error={error}
        isChip
      >
        <WrapperInputFile>
          <input
            type="file"
            id={name}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            disabled={disabled}
            data-test={`${prefixDataTest}-field-${dataTest}`}
            data-testid={`${prefixDataTest}-field-${dataTest}`}
            ref={inputRef}
            accept={accept}
          />
          <div>
            <p>{value || placeholder}</p>

            <Button
              width="111px"
              padding="6px 0px"
              fontSize="13"
              onClick={() => (inputRef?.current as any).click()}
            >
              Upload
            </Button>
          </div>
        </WrapperInputFile>
      </FieldStyle>

      {error && (
        <Text
          fontSize={13}
          fontWeight="normal-bold"
          color="error"
          tag="span"
          data-test={`${prefixDataTest}-error-${dataTest}`}
          data-testid={`${prefixDataTest}-error-${dataTest}`}
        >
          {error}
        </Text>
      )}
    </LabelWrapper>
  );
};

Input.defaultProps = {
  handleBlur: undefined,
  placeholder: 'Click to add',
  error: '',
  width: '100%',
  isCompleted: false,
  disabled: false,
  autoComplete: false,
};

export default Input;
