import styled from 'styled-components';

import GridFlex from 'components/GridFlex';

export const Container = styled.div``;

export const WrapperAction = styled(GridFlex)`
  button + button {
    margin-left: 40px;
  }
`;
