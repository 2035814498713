import styled from 'styled-components';

import { FieldStyle } from 'components/Fields/styles';

import { simpleFlex } from 'theme/tools';

export const FormStyles = styled.form`
  margin-top: 60px;
  width: 420px;

  > label {
    &:first-child {
      margin-bottom: 30px;
    }
  }
`;

export const FormFields = styled.div`
  margin-bottom: 45px;

  ${FieldStyle} {
    padding: 20px 35px;
  }

  > label {
    &:first-child {
      margin-bottom: 30px;
    }
  }
`;

export const ActionsWrapper = styled.div`
  ${simpleFlex};
  flex-direction: column;
  margin-top: 75px;

  > p {
    &:last-child {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const ButtonWrapperLoading = styled.div`
  ${simpleFlex};
  margin-bottom: 25px;
  width: 100%;
  position: relative;

  > div {
    position: absolute;
    right: -65px;
  }
`;

export const WrapperButton = styled.div`
  ${simpleFlex};
  justify-content: center;
`;
