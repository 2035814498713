import React from 'react';
import { useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { MAP_STEPS, steps } from 'containers/spec-sheet/constants';

import { useSpecSheets } from 'containers/spec-sheets';

import {
  Container,
  WrapperButton,
  WrapperStepsSpecSheet,
  StepItem,
} from './styles';

const StyleManagerItem: React.FC = () => {
  const { pathname } = useLocation();
  const {
    data: { stepActive },
    actions,
  } = useSpecSheets();

  return (
    <Container>
      {pathname.includes('spec-sheet/') && (
        <>
          <WrapperButton>
            <Icon icon="style-manager" />
            <Text
              fontWeight="normal-bold"
              fontSize={15}
              tag="strong"
              color="white"
            >
              Style manager
            </Text>
          </WrapperButton>

          <WrapperStepsSpecSheet>
            {steps.map(({ name }, index) => {
              return (
                name !== steps[MAP_STEPS.FINISH_SPEC].name && (
                  <StepItem
                    active={
                      stepActive === index ||
                      stepActive === MAP_STEPS.FINISH_SPEC
                    }
                    onClick={() => actions.changeStep(index)}
                    key={name}
                  >
                    <span />{' '}
                    <Text color="white" fontSize={15}>
                      {name}
                    </Text>
                  </StepItem>
                )
              );
            })}
          </WrapperStepsSpecSheet>
        </>
      )}
    </Container>
  );
};

export default StyleManagerItem;
