import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.form`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;

export const ContainerLoading = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
  min-height: 250px;

  > p {
    margin-bottom: 30px;
  }
`;

export const ContainerResponse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;

  p {
    margin-top: 30px;

    &:last-child {
      margin-top: 16px;
    }
  }
`;

export const ContainerPointer = styled.div`
  cursor: pointer;
  margin-right: -12px;

  div:first-child {
    height: 0px;
  }
`;
