import styled from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  margin-top: 50px;
`;

export const ListItem = styled.div`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  width: 165px;

  &:hover {
    opacity: 0.8;
  }
`;

export const WrapperText = styled.div`
  text-align: center;
  margin-top: 15px;
`;

export const WrapperPlus = styled.div`
  ${simpleFlex};
  border-radius: 13px;
  justify-content: center;
  background-color: ${takeColor('black')};
  width: 100%;
  height: 100%;

  > div {
    border: 3px solid ${takeColor('white')};
    padding: 15px;
    border-radius: 50%;
    height: fit-content;
  }
`;
