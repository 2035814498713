import styled from 'styled-components';

import colors from 'theme/colors';
import { takeColor, simpleFlex } from 'theme/tools';

interface IPropsContainerIcon {
  color?: keyof typeof colors | 'none';
  width?: string;
  height?: string;
  marginLeft?: number;
  marginRight?: number;
}

export const ContainerIcon = styled('div')<IPropsContainerIcon>`
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  ${simpleFlex}

  * {
    fill: ${({ color }) => color !== 'none' && takeColor(color)};
  }

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;
