import styled, { css } from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

import { Container as ContainerBox } from 'components/Box/styles';

export const StrongLabel = styled.strong`
  color: ${takeColor('black')};
  font-size: 13px;
  font-weight: 600;
`;

interface ISelectWrapper {
  isCompleted?: boolean;
  isFocus?: boolean;
  isNumberSelected?: boolean;
}

interface IWrapperOptions {
  maxHeight?: string;
}

interface IFieldStyleProps {
  width?: string;
  error?: string;
  isCompleted?: boolean;
  isFocus?: boolean;
  isChip?: boolean;
  isEdit?: any;
}

const ifIsFocus = ({ isFocus }: IFieldStyleProps) =>
  isFocus &&
  css`
    border-color: ${takeColor('black')};
    background-color: ${takeColor('black')};
  `;

const ifIsCompleted = ({ isCompleted }: IFieldStyleProps) =>
  isCompleted &&
  css`
    border-color: ${takeColor('black')};
    background-color: ${takeColor('black')};
    padding: 9px 10px;

    strong {
      color: ${takeColor('white')};
    }

    input {
      color: ${takeColor('grayMedium')};
    }
  `;

const ifIsEdit = ({ isEdit }: IFieldStyleProps) =>
  isEdit &&
  css`
    padding: 6.5px 10px 6.5px 35px;

    button {
      p {
        margin-bottom: 0;
      }
    }
  `;

export const Field = styled('div')<IFieldStyleProps>`
  ${simpleFlex};
  padding: 9px 20px;
  border: 2px solid ${takeColor('grayMedium')};
  border-radius: 45px;
  font-size: 13px;
  transition: 0.2s border-color;
  width: 100%;
  max-width: ${({ width }) => width};

  ${ifIsCompleted};
  ${ifIsFocus};
  ${ifIsEdit};

  > * {
    border: 0;
  }
`;

export const WrapperOptions = styled(ContainerBox)<IWrapperOptions>`
  top: 70px;
  left: 0;
  position: absolute;
  width: 220px;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0.2s all;
  opacity: 0;
  visibility: hidden;
  cursor: initial;
  background-color: ${takeColor('white')};
  max-height: ${({ maxHeight = '235px' }: IWrapperOptions) => maxHeight};
  z-index: 9;
` as any;

export const SelectWrapper = styled('div')<ISelectWrapper>`
  position: relative;
  cursor: pointer;

  strong {
    flex: 1;
    color: ${takeColor('grayLightText')};
    transition: 0.2s all;
    margin-right: 9px;
    text-align: center;
  }

  svg {
    transition: 0.2s all;
    transform: rotate(180deg);
  }

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      svg {
        fill: ${takeColor('white')};
      }

      strong {
        color: ${takeColor('white')};
      }
    `};

  ${({ isNumberSelected }) =>
    isNumberSelected &&
    css`
      strong {
        margin-right: 5px;
      }
    `}

  ${({ isFocus }) =>
    isFocus &&
    css`
      svg {
        transform: rotate(0deg);

        fill: ${takeColor('white')};
      }

      strong {
        color: ${takeColor('white')};
      }

      ${WrapperOptions} {
        opacity: 1;
        visibility: visible;
        top: 55px;
      }
    `};
`;

export const WrapperLeftContent = styled.div`
  margin-right: 12px;
`;

interface IOption {
  isSelected?: boolean;
}

export const SelectItem = styled('div')<IOption>`
  cursor: pointer;
  color: ${takeColor('grayLightText')};
  transition: color 0.2s;
  font-size: 13px;
  padding: 7.5px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    color: ${takeColor('white')};
    font-weight: 600;
  }

  ${({ isSelected }: IOption) =>
    isSelected &&
    css`
      color: ${takeColor('white')};
      font-weight: 600;
    `}
`;

export const SelectInputSearchStyles = styled.input`
  background-color: ${takeColor('grayInputSearch')};
  border-radius: 100px;
  width: 100%;
  border: 0;
  font-size: 11px !important;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: ${takeColor('grayMedium')};

  ::placeholder {
    color: ${takeColor('grayLightText')};
    font-size: 11px;
  }
`;

export const InsivibleDivClick = styled.div`
  position: absolute;
  left: 0;
  top: -40px;
  width: 100%;
  height: 140px;
`;

export const NumberSelected = styled.div`
  padding: 2px 5px;
  background-color: ${takeColor('white')};
  color: ${takeColor('black')};
  border-radius: 100%;
  margin-right: 7px;
  font-size: 10px;
  min-width: 17px;
  text-align: center;
`;
