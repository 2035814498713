/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosResponse } from 'axios';

import { isAuthenticated, removeSession } from 'helpers/auth';
import history from 'routes/history';

const successResponse = ({ data }: AxiosResponse) => data;

const errorResponse = ({ response }: AxiosError) => {
  if (response?.status === 401) {
    removeSession();
    history.push('/login');
  }

  throw response;
};

const url = `${window.location.protocol}//${window.location.host.replace(
  'app',
  'api',
)}/v1`;

const baseURL = process.env.REACT_APP_API_URL || url;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(config => {
  const hasToken = isAuthenticated();

  if (hasToken) config.headers.Authorization = `Bearer ${hasToken}`;

  return config;
});

api.interceptors.response.use(successResponse, errorResponse);

export default api;
