/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect, useRef } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';

import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';
import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';

import outsideClick from 'helpers/outsideClick';

import { FieldStyle, LabelWrapper } from '../styles';
import {
  WrapperLeftContent,
  BoxChip,
  Chip,
  InputText,
  BoxDelete,
  BoxInput,
  BoxList,
  List,
  WrapperChip,
} from './styles';

export interface IProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: string;
  type: string;
  label?: string;
  value?: string | number;
  name: string;
  width?: string;
  autoFocus?: boolean;
  leftContent?: any;
  disabled?: boolean;
  autoComplete?: boolean;
  dataTest?: string;
}

const prefixDataTest = 'input-chip';

const InputChip: React.FC<IProps> = props => {
  const {
    data: { skuDetails, skuDetailActive, skuFieldSuggestions },
    actions: actionsSkuDetails,
  } = useSpecSheetSkuDetail();
  const { actions } = useSpecSheet();

  const [isFocus, setIsFocus] = useState(false);
  const [active, setActive] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [text, setText] = useState('');
  const [filtered, setFiltered] = useState<any>([]);
  const [isUpdated, setIsUpdated] = useState(false);

  let oldSkuDetail: null | number = null;

  const { type, label, name, width, autoFocus, error, leftContent, dataTest } =
    props;

  const skuDetail = skuDetails[skuDetailActive];

  const isCompleted = !!items?.length;

  const getTags = async () => {
    try {
      const details: any = await api.get(
        `spec-sheets-sku-details-field/${skuDetail?.id}`,
        {
          params: {
            type,
          },
        },
      );

      setItems(details);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    if (oldSkuDetail !== skuDetailActive) {
      getTags();
      oldSkuDetail = skuDetailActive;
    }
  }, [skuDetailActive]);

  useEffect(() => {
    if (isUpdated) {
      handleClickOut();
    }
  }, [isUpdated]);

  function handleClickOut() {
    addTag(text);
    setActive(false);
    setIsUpdated(false);
    setIsFocus(false);
  }

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: () => setIsUpdated(true),
  });

  const openSelect = () => {
    setActive(true);
  };

  const closeSelect = () => {
    removeEventClickOut();
    setActive(false);
  };

  async function addTag(item: any) {
    if (!item.trim()) return;

    if (items.some(i => i.value === item)) return;

    actions.setLoading();
    const newItem = await api.post(
      `spec-sheets-sku-details-field/${skuDetail?.id}`,
      {
        value: item,
        type,
      },
    );
    setItems([...items, newItem]);
    setText('');
    closeSelect();
    actionsSkuDetails.addSuggestion(item);
    actions.removeLoading();
  }

  const onBlur = () => {
    setIsFocus(false);
    closeSelect();
    addTag(text);
  };

  const onFocus = () => {
    if (!isFocus) {
      setIsFocus(true);
      addEventClickOut();
    }
  };

  const changeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);

    if (e.target.value.length) {
      openSelect();
      return;
    }

    closeSelect();
  };

  useEffect(() => {
    const filter = skuFieldSuggestions?.filter(f => {
      return f.toLowerCase().includes(text.toLowerCase());
    });
    setFiltered(filter);
  }, [text]);

  const deleteTag = async (id: any) => {
    actions.setLoading();
    const oldItems = items;
    const newItems = oldItems.filter(item => item.id !== id);
    setItems(newItems);

    try {
      await api.delete(`spec-sheets-sku-details-field/${id}`);
    } catch (err) {
      setItems(oldItems);
    } finally {
      actions.removeLoading();
    }
  };

  const keyPressTag = (e: any) => {
    const lastItem = items[items.length - 1];

    const functions = {
      Backspace: () => !text && lastItem && deleteTag(lastItem.id),
      Enter: () => addTag(text),
      Tab: onBlur,
    };

    const functionSelected = functions[e.key];
    if (functionSelected) functionSelected();
  };

  return (
    <LabelWrapper
      htmlFor={name}
      width={width}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
      data-testid={`${prefixDataTest}-wrapper-${dataTest}`}
      ref={wrapperRef}
    >
      <Text
        color="black"
        fontSize={18}
        fontWeight="normal-bold"
        data-test={`${prefixDataTest}-label-${dataTest}`}
        data-testid={`${prefixDataTest}-label-${dataTest}`}
      >
        {label}
      </Text>

      <FieldStyle
        width={width}
        isCompleted={isCompleted}
        isFocus={isFocus}
        error={error}
        isChip
      >
        {leftContent && <WrapperLeftContent>{leftContent}</WrapperLeftContent>}
        <WrapperChip isFocus={isFocus}>
          {items?.map(({ value, id }: any, index) => (
            <BoxChip key={`${id}-${index}`}>
              <Chip>
                <div style={{ whiteSpace: 'nowrap' }}>{value}</div>
                <BoxDelete onClick={() => deleteTag(id)}>
                  <Icon icon="close" width="7px" color="white" />
                </BoxDelete>
              </Chip>
            </BoxChip>
          ))}

          <InputText
            placeholder="Click to add"
            onChange={changeInput}
            id={name}
            autoFocus={autoFocus}
            value={text}
            onKeyDown={keyPressTag}
            onFocus={onFocus}
          />
        </WrapperChip>
      </FieldStyle>
      {active && (
        <BoxInput>
          <BoxList>
            {filtered?.length
              ? filtered.map((item, index) => (
                  <List
                    onClick={() => addTag(item)}
                    key={`${item}-${index}-filtered`}
                  >
                    {item}
                  </List>
                ))
              : 'no results'}
          </BoxList>
        </BoxInput>
      )}

      {error && (
        <Text
          fontSize={13}
          fontWeight="normal-bold"
          color="error"
          tag="span"
          data-test={`${prefixDataTest}-error-${dataTest}`}
          data-testid={`${prefixDataTest}-error-${dataTest}`}
        >
          {error}
        </Text>
      )}
    </LabelWrapper>
  );
};

export default InputChip;
