import styled, { css } from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

interface IFieldStyleProps {
  width?: string;
  error?: string;
  isCompleted?: boolean;
  isFocus?: boolean;
  isChip?: boolean;
  isEdit?: any;
}

const ifIsFocus = ({ isFocus }: IFieldStyleProps) =>
  isFocus &&
  css`
    border-color: ${takeColor('black')};

    input {
      color: ${takeColor('black')};
    }
  `;

const ifIsCompleted = ({ isCompleted }: IFieldStyleProps) =>
  isCompleted &&
  css`
    border-color: ${takeColor('grayMedium')};

    strong {
      color: ${takeColor('grayMedium')};
    }

    input {
      color: ${takeColor('grayMedium')};
    }
  `;

const ifIsError = ({ error }: IFieldStyleProps) =>
  error &&
  css`
    border-color: ${takeColor('error')};
  `;

const ifIsEdit = ({ isEdit }: IFieldStyleProps) =>
  isEdit &&
  css`
    padding: 6.5px 10px 6.5px 35px;

    button {
      p {
        margin-bottom: 0;
      }
    }
  `;

export const FieldStyle = styled('div')<IFieldStyleProps>`
  ${simpleFlex};
  padding: ${props => (props.isChip ? '0px 25px' : '15px 35px')};
  border: 2px solid ${takeColor('grayLight')};
  border-radius: 45px;
  font-size: 16px;
  transition: 0.2s border-color;
  width: 100%;
  max-width: ${({ width }) => width};

  ${ifIsCompleted};
  ${ifIsFocus};
  ${ifIsError};
  ${ifIsEdit};

  > * {
    border: 0;
  }
`;

interface ILabelWrapper {
  width?: string;
  disabled?: boolean;
}

export const LabelWrapper = styled.label<ILabelWrapper>`
  width: 100%;
  max-width: ${({ width }) => width};
  display: block;

  > p {
    margin-bottom: 15px;
  }

  span {
    margin-top: 10px;
    margin-left: 35px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;
