import React, { useEffect, useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import searchTextHook from 'helpers/searchTextHook';

import { Select, SelectItem } from 'components/Fields';

const Factory: React.FC = () => {
  const [factories, setFactories] = useState([]);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items: factories,
    },
  );

  const {
    data: { data, factories: allFactories },
    actions,
  } = useSpecSheet();

  const factorySelected = data?.factory;
  const countrySelected = data?.country;

  const getFactories = async (list: any) => {
    setFactories(list);
    setFilteredItems(list);
  };

  useEffect(() => {
    getFactories(allFactories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFactories]);

  const handleChange = (factoryNew: any) => {
    if (factorySelected?.id !== factoryNew.id) {
      setSearch('');
      actions.changeSpecSheetData({ factory: factoryNew });
    }
  };

  return (
    <Select
      label="Factory"
      value={factorySelected?.description}
      withInputSearch
      inputSearchPlaceholder="Search factory"
      inputSearchOnChange={setSearch}
      inputSearchvalue={search}
      dataTest="factory"
      disabled={!countrySelected?.id}
    >
      <>
        {filteredItems.map(({ id, description }) => (
          <SelectItem
            key={`factories-${id}`}
            onClick={() => handleChange({ id, description })}
            isSelected={id === factorySelected?.id}
            data-test={`select-item-option-factory-${id}`}
          >
            {description}
          </SelectItem>
        ))}
      </>
    </Select>
  );
};

export default Factory;
