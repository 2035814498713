import styled, { css } from 'styled-components';

import { takeColor } from 'theme/tools';

const ScrollBar = styled.div`
  overflow-x: hidden;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${takeColor('grayScrollBar')};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${takeColor('grayMedium')};
  }
`;

export const AllScrollBar = css`
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 35px;
    height: 35px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${takeColor('grayIconUpload')};
    border-radius: 25px;
    background-clip: padding-box;
    border: 13px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${takeColor('grayMedium')};
  }
`;

export default ScrollBar;
