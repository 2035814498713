/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { InputChip } from 'components/Fields';

const Stitch = () => {
  return (
    <InputChip type="COL_MAT_CODE" name="colMatCode" label="Col/Mat code" />
  );
};

export default Stitch;
