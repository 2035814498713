/* eslint-disable no-console */
import api from 'services/api';

import { IActions, IState, IUserManagement } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams): IActions => {
  const { setData, data } = params;

  async function listUsers() {
    setData({ ...data, loading: true });

    try {
      const users: IUserManagement[] = await api.get('/users');

      setData({ ...data, users, loading: false, isEdit: false });
    } catch (err: any) {
      setData({ ...data, error: true, loading: false, isEdit: false });
    }
  }

  function setIsEditModal(userOrBool: boolean | IUserManagement) {
    setData({ ...data, isEdit: userOrBool });
  }

  return {
    listUsers,
    setIsEditModal,
  };
};
