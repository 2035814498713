import React, { useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import { useSpecSheetModal } from 'containers/spec-sheet-modal';
import { TypeEnum } from 'containers/spec-sheet-modal/dtos';

import ModalSpecSheet from 'components/ModalSpecSheet';
import ShadowBlur from 'components/ShadowBlur';
import Icon from 'components/Icon';

import ModalDeleteOrCancel, { Types } from 'components/ModalDeleteOrCancel';
import ModalDuplicate from 'components/ModalDuplicate';

import { BoxMenu, BoxRef, ContainerMenu, ItemMenu, TextMenu } from './styles';

export interface IProps {
  isOpen: boolean;
  onClose(): void;
  refIdNo: { refId: number; refNo: number; description?: string };
}

const ModalActions: React.FC<IProps> = ({ refIdNo, onClose, isOpen }) => {
  const { refId, refNo, description } = refIdNo;
  const { actions: actionsSpec } = useSpecSheet();
  const { actions: actionsModal } = useSpecSheetModal();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);

  const [openModal, setOpenModal] = useState<boolean | Types>(false);

  const handleSpec = async () => {
    setLoading(true);
    try {
      await actionsSpec.deleteSpecSheet(refId, {
        status: {
          id: openModal === Types.DELETE ? 6 : 3,
          description: openModal === Types.DELETE ? 'Deleted' : 'Cancelled',
          key: openModal === Types.DELETE ? 'DELETED' : 'CANCELLED',
        },
      });
      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSuccess(false);
    setError(false);
    onClose();
  };

  return (
    <>
      <ModalSpecSheet />

      <ModalDeleteOrCancel
        id={description || refNo}
        onConfirm={() => handleSpec()}
        onCancel={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        onClick={() => setOpenModal(false)}
        loading={loading}
        error={error}
        success={success}
        open={!!openModal}
        type={openModal as Types}
      />

      <ModalDuplicate
        styleName={description}
        isOpen={isOpenDuplicateModal}
        onClose={() => setIsOpenDuplicateModal(false)}
        id={refId}
        refNo={refNo}
      />

      {isOpen && (
        <ShadowBlur
          data-testid="overflow-modal"
          isOpen={isOpen}
          onClick={closeModal}
        >
          <BoxMenu>
            <div>
              <BoxRef>{description || `Ref. No ${refNo}`}</BoxRef>
              <ContainerMenu>
                <ItemMenu
                  onClick={() => setIsOpenDuplicateModal(true)}
                  data-testid="actions-duplicate"
                >
                  <Icon icon="duplicate" color="grayMedium" width="19px" />
                  <TextMenu>Duplicate</TextMenu>
                </ItemMenu>
                <ItemMenu
                  data-testid="actions-delete"
                  onClick={() => setOpenModal(Types.DELETE)}
                >
                  <Icon icon="delete" color="grayMedium" width="16px" />
                  <TextMenu>Delete</TextMenu>
                </ItemMenu>
                <ItemMenu
                  data-testid="actions-cancel"
                  onClick={() => setOpenModal(Types.CANCEL)}
                >
                  <Icon
                    icon="close"
                    color="grayMedium"
                    width="14px"
                    marginRight={5}
                  />
                  <TextMenu>Cancel</TextMenu>
                </ItemMenu>
                <ItemMenu
                  data-testid="actions-print"
                  onClick={() =>
                    actionsModal.openModalAndGetSpecSheetComplete(
                      refId,
                      TypeEnum.PRINT,
                    )
                  }
                >
                  <Icon icon="print" color="grayMedium" width="19px" />
                  <TextMenu>Print</TextMenu>
                </ItemMenu>

                {/* <ItemMenu>
                  <Icon icon="download" color="grayMedium" width="19px" />
                  <TextMenu>Download</TextMenu>
                </ItemMenu> */}
              </ContainerMenu>
            </div>
          </BoxMenu>
        </ShadowBlur>
      )}
    </>
  );
};

export default ModalActions;
