import React from 'react';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';

import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import { Table, TableColumn, TableRow } from 'components/Table';

const widthColumn = '90px';
const widthColumnBig = '275px';

const TablePrototype: React.FC = () => {
  const { data } = useSpecSheetModal();

  const { specSheet, notes }: any = data;

  const outsoleDesignNo = specSheet?.outsoleDesignNo;
  const sockDesignNo = specSheet?.sockDesignNo;
  const construction = specSheet?.construction;
  const last = specSheet?.last;
  const styleGroup = specSheet?.styleGroup?.description;
  const styleType = specSheet?.styleType?.description;
  const categories = specSheet?.categories;
  const subCategories = specSheet?.subCategories;
  const originalBaseRef = specSheet?.originalBaseRef;
  const prototypeNotes = notes?.prototype?.description;

  return (
    <GridFlex direction="column" flex={1}>
      <Table width="100%">
        <TableRow isLabel minHeight="17px">
          <TableColumn>Construction</TableColumn>

          <TableColumn>Last</TableColumn>

          <TableColumn>Style group</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{construction}</TableColumn>

          <TableColumn>{last}</TableColumn>

          <TableColumn>{styleGroup}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Outsole Design</TableColumn>

          <TableColumn>Sock Design</TableColumn>

          <TableColumn>Style type</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{outsoleDesignNo}</TableColumn>

          <TableColumn>{sockDesignNo}</TableColumn>

          <TableColumn>{styleType}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Category</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>
            {categories
              ?.map(({ idCategory }) => idCategory.description)
              .join(', ')}
          </TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Sub-category</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>
            {subCategories
              ?.map(({ idSubCategory }) => idSubCategory.description)
              .join(', ')}
          </TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Notes</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{prototypeNotes}</TableColumn>
        </TableRow>
      </Table>
    </GridFlex>
  );
};

export default TablePrototype;
