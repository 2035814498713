import styled from 'styled-components';

import { simpleFlex } from 'theme/tools';

export const Container = styled.div<{ noHeader?: boolean }>`
  ${simpleFlex};
  justify-content: space-between;
  margin-top: 75px;
`;

export const WrapperLoading = styled.div`
  ${simpleFlex};

  > div {
    margin-left: 10px;
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentProgressBar = styled.div`
  width: 100px;
  height: 100px;
  margin-left: 27px;
`;
