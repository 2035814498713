import styled from 'styled-components';

export interface IProps {
  x: any;
  y: any;
  cx: any;
  cy: any;
  valueCenter: number;
  isError?: boolean;
}

export const Container = styled.div`
  position: absolute !important;
`;

export const ContainerInput = styled.textarea<IProps>`
  width: 200px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  padding: 20px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const ButtonAction = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.11);
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSave = styled(ButtonAction)`
  background: #6fcf97;
`;

export const ButtonCancel = styled(ButtonAction)`
  background: #ee5151;
`;

export const CommentPoint = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.11);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  cursor: pointer;
`;
