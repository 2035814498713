import styled from 'styled-components';

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 1;
`;

export const TextArea = styled('textarea')`
  width: 100%;
  height: 45vh;
  border: 2px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 34px;
  color: #686868;
  margin: 0px 70px;
`;

export const ContainerSaving = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Saving = styled('div')`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #8d8d8d;
  margin-right: 10px;
`;
