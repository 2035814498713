/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { useDebounce } from 'hooks/useDebounce';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { useSpecSheet } from 'containers/spec-sheet';
import { Container, TextArea, ContainerSaving, Saving } from './styles';

export enum Type {
  addNewStyle = 'ADD_NEW_STYLE',
  prototype = 'PROTOTYPE',
  skuDetails = 'SKU_DETAILS',
}

export interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  placeholder?: string;
  type: Type;
}

export const getType = (ty: string) => {
  let t = '';
  switch (ty) {
    case Type.addNewStyle:
      t = 'addNewStyle';
      break;
    case Type.prototype:
      t = 'prototype';
      break;
    case Type.skuDetails:
      t = 'skuDetails';
      break;
    default:
      t = '';
  }
  return t;
};

const Notes: React.FC<IProps> = ({
  open,
  onClose,
  title,
  placeholder,
  type,
}) => {
  const {
    data: { data },
    actions,
  } = useSpecSheet();
  const [text, setText] = useState('');
  const [initialText, setInitialText] = useState('');
  const [loading, setLoading] = useState(false);

  const handle = useDebounce(text);

  const getNotes = async () => {
    const res: any = await actions.getNote(data?.id);
    if (res[getType(type)]?.description) {
      const responseText = res[getType(type)]?.description;
      setInitialText(responseText);
      setText(responseText);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  const updateOrCreate = useCallback(async () => {
    setLoading(true);
    await actions.handleNote(data?.id, text, type);
    setLoading(false);
  }, [text]);

  useEffect(() => {
    if (text !== initialText) {
      updateOrCreate();
      setInitialText(text);
    }
  }, [handle]);

  const Loading = () => {
    return (
      <ContainerSaving>
        <Saving>SAVING</Saving> <Spinner size="small" />
      </ContainerSaving>
    );
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      minWidth="calc(100% - 160px)"
      minHeight="calc(100vh - 160px)"
      title={!loading ? title : <Loading />}
    >
      <Container>
        <TextArea
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setText(e.target.value)
          }
          value={text || ''}
        />
      </Container>
    </Modal>
  );
};

Notes.defaultProps = { placeholder: 'Add your note here' };

export default Notes;
