import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  margin-top: 20px;
  flex-direction: column;

  img {
    border-radius: 50%;
    margin-bottom: 25px;

    @media (max-height: 700px) {
      margin-bottom: 10px;
      width: 65px;
    }
  }
`;
