import styled, { css } from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  justify-content: center;
`;

export const WrapperArrows = styled.div<{ disabled: boolean }>`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
`;

export const WrapperItems = styled.div`
  ${simpleFlex}
`;

export const Item = styled.div<{ active: boolean }>`
  border: 1px solid ${takeColor('grayBorderBox')};
  border-radius: 100%;
  width: 30px;
  padding: 7px 0px;
  text-align: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${takeColor('black')};
      color: ${takeColor('white')};
    `}

  & + & {
    margin-left: 5px;
  }
`;
