import React from 'react';

import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';

const Loading: React.FC = () => (
  <GridFlex alignItems="center" justifyContent="center">
    <Spinner />
  </GridFlex>
);

export default Loading;
