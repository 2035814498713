import styled from 'styled-components';
import GridFlex from 'components/GridFlex';

export const Container = styled.div``;

export const ContentFields = styled.div`
  margin: 60px 0px;
`;

export const ContentActions = styled(GridFlex)`
  width: 100%;
  margin: 80px 0px;

  button + button {
    margin-left: 40px;
  }
`;

export const ContentButtonAddNotes = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
`;
