import { useEffect, useState, useCallback } from 'react';

export interface IItems {
  description: string;
  id: number;
  year?: number;
}

interface ISearchTextHook {
  searchInitValue?: string;
  items: IItems[];
}

let lastFilter = '';

export default ({ searchInitValue = '', items }: ISearchTextHook) => {
  const [search, setSearch] = useState(searchInitValue);
  const [filteredItems, setFilteredItems] = useState(items);

  const handleFilter = useCallback(() => {
    const parsedValue = search?.trim().toLowerCase();

    if (lastFilter !== parsedValue) {
      lastFilter = parsedValue;

      setFilteredItems(
        items.filter(
          ({ description }) =>
            description.toLowerCase().indexOf(parsedValue) === 0,
        ),
      );
    }
  }, [search, items]);

  useEffect(() => {
    handleFilter();
  }, [search, items, handleFilter]);

  return { search, setSearch, filteredItems, setFilteredItems };
};
