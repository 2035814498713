import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const BackButton = styled.div`
  ${simpleFlex};
  cursor: pointer;
`;

export const Container = styled.div`
  ${simpleFlex};
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Actions = styled.div`
  ${simpleFlex};

  > *:first-child {
    margin-right: 15px;
  }
`;
