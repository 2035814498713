import { AllScrollBar } from 'components/ScrollBar';
import styled from 'styled-components';
import { takeColor } from 'theme/tools';

export const Container = styled.div`
  padding: 30px 40px;
  background-color: ${takeColor('white')};
  position: relative;
  min-height: 450px;
  overflow: auto;

  ${AllScrollBar}
`;
