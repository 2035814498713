import React, { useEffect, useState } from 'react';

import { useSpecSheets } from 'containers/spec-sheets';
import withSpecSheetModal from 'containers/spec-sheet-modal';

import Spinner from 'components/Spinner';

import NewSpecSheet from './NewSpecSheet';
import ModalActions from './ModalActions';
import SpecSheet from './SpecSheet';
import Bottom from '../Bottom';

import { Container, ContainerSpinner } from './styles';

const List: React.FC = () => {
  const {
    data: { allSpecSheets, type },
    actions,
  } = useSpecSheets();

  const limit = 9;
  const [page, setPage] = useState(1);

  const [isOpenActions, setIsOpenActions] = useState(false);
  const [refIdActions, setRefActions] = useState({
    refId: 0,
    refNo: 0,
    description: '',
  });

  useEffect(() => {
    actions.getAllSpecSheets({ limit, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMore = () => {
    setPage(page + 1);
    actions.getAllSpecSheets({ limit, page: page + 1 }, true);
  };

  return (
    <>
      <ModalActions
        isOpen={isOpenActions}
        onClose={() => setIsOpenActions(false)}
        refIdNo={refIdActions}
      />
      <Container data-test="spec-sheet-list">
        <NewSpecSheet data-test="spec-sheet-create-button" />

        {type === 'loading' ? (
          <ContainerSpinner>
            <Spinner size="big" />
          </ContainerSpinner>
        ) : (
          <>
            {allSpecSheets?.map(item => (
              <SpecSheet
                item={item}
                key={item.id}
                onClick={() => {
                  setIsOpenActions(!isOpenActions);
                  setRefActions({
                    refId: item.id,
                    refNo: item.refNo,
                    description: item?.description || '',
                  });
                }}
              />
            ))}
          </>
        )}
      </Container>

      <Bottom getMore={getMore} />
    </>
  );
};

export default withSpecSheetModal(List);
