import { EnumSkuDetailsVariable } from 'containers/spec-sheet-sku-detail/constants';
import React, { memo } from 'react';

import List from './List';

const LineNumberMaterial: React.FC = () => {
  return (
    <>
      <List
        labelMaterial="Material"
        labelColor="Color"
        type={EnumSkuDetailsVariable.MATERIAL}
      />

      <List
        labelMaterial="Lining material"
        labelColor="Lining color"
        type={EnumSkuDetailsVariable.LINING}
      />

      <List
        labelMaterial="Sock lining material"
        labelColor="Sock lining color"
        type={EnumSkuDetailsVariable.SOCK}
      />
    </>
  );
};

export default memo(LineNumberMaterial);
