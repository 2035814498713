import React from 'react';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';

import GridFlex from 'components/GridFlex';
import { Table, TableColumn, TableRow } from 'components/Table';

import { dateDefault } from 'helpers/date';

const TableAddNewStyle: React.FC = () => {
  const { data } = useSpecSheetModal();

  const { specSheet, notes }: any = data;

  const sampleType = specSheet?.sampleType?.description;
  const refNo = specSheet?.refNo || specSheet?.id;
  const styleName = specSheet?.description;
  const season =
    specSheet?.season &&
    `${specSheet?.season?.description} ${specSheet?.season?.year}`;
  const country = specSheet?.country?.description;
  const factory = specSheet?.factory?.description;
  const brand = specSheet?.brand?.description;
  const date = dateDefault(specSheet?.createdAt);
  const group = specSheet?.group?.description;
  const id = specSheet?.id;
  const note = notes?.addNewStyle?.description;

  return (
    <GridFlex direction="column" margin="0 14px 0 0" flex={0}>
      <Table width="160px">
        <TableRow isLabel minHeight="17px">
          <TableColumn>Sample type</TableColumn>
          <TableColumn>Date</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{sampleType}</TableColumn>
          <TableColumn>{date}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Style name</TableColumn>
          <TableColumn>Ref. No</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn columnBlack>{styleName}</TableColumn>
          <TableColumn columnBlack>{refNo}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Season</TableColumn>
          <TableColumn>Brand</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn columnBlack>{season}</TableColumn>
          <TableColumn>{brand}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Country</TableColumn>
          <TableColumn>Factory</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{country}</TableColumn>
          <TableColumn columnBlack>{factory}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Group</TableColumn>
          <TableColumn>Spec ID</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{group}</TableColumn>
          <TableColumn>{id}</TableColumn>
        </TableRow>

        <TableRow isLabel minHeight="17px">
          <TableColumn>Notes</TableColumn>
        </TableRow>
        <TableRow minHeight="17px">
          <TableColumn>{note}</TableColumn>
        </TableRow>
      </Table>
    </GridFlex>
  );
};

export default TableAddNewStyle;
