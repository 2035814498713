import React from 'react';

import { Container } from './styles';

const UnLogged: React.FC = () => (
  <Container>
    <img src="/images/logo.svg" alt="Fornova" />
  </Container>
);

export default UnLogged;
