import styled from 'styled-components';

import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div``;

export const TitlePagesStyles = styled.h1`
  font-size: 30px;
  color: ${takeColor('black')};
  font-weight: 500;
`;

export const SubTitlePagesStyles = styled.h2`
  font-size: 16px;
  color: ${takeColor('grayLightText')};
  height: 30px;
  ${simpleFlex};
  letter-spacing: 2px;
`;
