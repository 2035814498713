import React, { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Text from 'components/Text';
import Modal from 'components/Modal';
import Button from 'components/Button';

import { getUserRole } from 'helpers/verifyRoles';

import { Roles } from 'containers/user/dtos';
import { useUser } from 'containers/user';
import { useNav } from 'containers/nav';
import { Container, WrapperButton, SettingsMenu } from './styles';

const LittleMenu: React.FC = () => {
  const { actions } = useUser();
  const {
    data: { openMenu },
  } = useNav();
  const [modalLogout, setModalLogout] = useState(false);
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);

  const closeModalLogout = useCallback(() => setModalLogout(false), []);

  const { pathname } = useLocation();

  const showOnlyHome = pathname === '/';

  return (
    <>
      <Container isMenuCollapsed={!openMenu}>
        {getUserRole(Roles.manager) && !showOnlyHome && (
          <>
            <Text
              color="white"
              fontWeight="normal-bold"
              data-testid="settings-link"
              onClick={() => setOpenSettingsMenu(!openSettingsMenu)}
            >
              Settings
            </Text>

            <SettingsMenu open={openSettingsMenu} isMenuCollapsed={!openMenu}>
              <NavLink to="/users-management">
                <Text fontWeight="normal-bold" data-test="user-link">
                  User management
                </Text>
              </NavLink>

              <NavLink to="/data-management">
                <Text fontWeight="normal-bold" data-test="data-management-link">
                  Data management
                </Text>
              </NavLink>
            </SettingsMenu>
          </>
        )}

        <Text
          color="white"
          fontSize={15}
          fontWeight="normal-bold"
          onClick={() => setModalLogout(true)}
          data-test="logout-link"
        >
          Log out
        </Text>
      </Container>
      <Modal
        isOpen={modalLogout}
        title="Do you really want do log out?"
        onClose={closeModalLogout}
        minWidth="691px"
        noButtonClose
        data-test="modal-logout"
      >
        <WrapperButton>
          <Button
            width="266px"
            onClick={actions.makeLogout}
            data-test="modal-logout-button-yes"
          >
            Yes
          </Button>

          <Button
            width="266px"
            onClick={closeModalLogout}
            scheme="nobg"
            data-test="modal-logout-button-no"
          >
            Cancel
          </Button>
        </WrapperButton>
      </Modal>
    </>
  );
};

export default LittleMenu;
