/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from 'components/Fields/Input';

import { useSpecSheet } from 'containers/spec-sheet';
import { useDebounce } from 'hooks/useDebounce';

const SockDesignNo: React.FC = () => {
  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const sockDesignNo = data?.sockDesignNo || '';

  const [value, setValue] = useState(sockDesignNo);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  const saveChange = async () =>
    actions.changeSpecSheetData({ sockDesignNo: value.trim() });

  const autoSave = useDebounce(value);

  const handleSave = () => {
    if (sockDesignNo !== value) saveChange();
  };

  useEffect(() => {
    handleSave();
  }, [autoSave]);

  const isCompleted = !!value;

  return (
    <Input
      label="Sock Design No."
      value={value}
      name="sock-design-no"
      onChange={handleChange}
      dataTest="sock-design-no"
      isCompleted={isCompleted}
      handleBlur={() => handleSave()}
      placeholder="Click to add"
    />
  );
};

export default SockDesignNo;
