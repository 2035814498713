import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  margin-top: 75px;
`;

export const ContainerActions = styled.div`
  ${simpleFlex}
  justify-content: space-between;
`;

export const BackButton = styled.div`
  cursor: pointer;
  ${simpleFlex}

  > p {
    margin-left: 18px;
  }
`;

export const ContainerTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 60px;
`;
