/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, createRef } from 'react';
import { Grid } from '@material-ui/core';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { getPosition, getTotalSize } from 'helpers/positionElement';
import { useCreateSketch } from 'containers/create-sketch';
import { useSpecSheet } from 'containers/spec-sheet';
import { Type, Selected } from 'containers/create-sketch/dtos';

import { ContainerSaving, Saving } from 'components/Saving';
import Spinner from 'components/Spinner';
import html2canvas from 'html2canvas';
import Points, { POINTS } from '../Points';
import PointsAdd from '../PointsAdd';

import {
  BoxImage,
  MasterImage,
  BoxImagePosition,
  BoxButtons,
  BoxIcon,
  IconModifield,
  MasterImageZoomInSizes,
} from './styles';

export interface IProps {
  url?: string;
  isOpen: boolean;
  onClose: () => void;
  idImage: any;
  type: Type;
}

export interface IItem {
  x: any;
  y: any;
  letterY: any;
  letterX: any;
  value: string;
  type: 'POINT' | 'COMMENT';
  idSpecSheetImage: number;
}

export interface IPoint {
  x: number;
  y: number;
  letterY: number;
  letterX: number;
  cx: number;
  cy: number;
  id?: number | undefined;
}

const ModalLettersAndComments = ({
  url,
  isOpen,
  onClose,
  idImage,
  type,
}: IProps) => {
  const regex = new RegExp(/^[a-zA-Z0-9]$/);
  const refImage: any = createRef();

  const boxImageWithPoints: any = createRef();

  const [selected, setSelected] = useState<Selected>('');
  const [input, setInput] = useState('');
  const [point, setPoint] = useState<IPoint>();
  const [isNew, setIsNew] = useState(false);
  const [isNewComment, setIsNewComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [changed, setChanged] = useState(false);
  const [idPoint, setIdPoint] = useState(0);
  const [isSavingOnS3, setIsSavingOnS3] = useState(false);

  const titleAdd = 'Add letters, numbers and comments';
  const titleView = 'See comments';
  const title = type === 'add' ? titleAdd : titleView;

  const {
    actions: { createPoint, updatePoint },
  } = useCreateSketch();

  const {
    actions: {
      getImageDetails,
      deleteImagePoint,
      uploadMasterImage,
      updateMasterImage,
    },
    data: { imageDetails, data, masterImageWithPoints },
  } = useSpecSheet();

  useEffect(() => {
    if (idImage) getImageDetails(idImage);
  }, [idImage]);

  const addPoint = (
    p: { x: number; y: number },
    t: { x: number; y: number },
  ) => {
    setInput('');
    setIsNew(true);
    setIsNewComment(true);
    const item: any = {
      x: p?.x,
      y: p?.y,
      cx: t?.x,
      cy: t?.y,
      letterY: p?.y,
      letterX: p?.x,
    };
    setPoint(item);
  };

  const createNewPoint = useCallback(async () => {
    if (selected === 'letters' || selected === 'change') {
      if (input === '' || !regex.test(input)) return;
    }
    setLoading(true);
    const item: IItem = {
      x: point?.x,
      y: point?.y,
      letterY: point?.letterY,
      letterX: point?.letterX,
      value: input,
      type:
        selected === 'letters' || selected === 'change' ? 'POINT' : 'COMMENT',
      idSpecSheetImage: idImage,
    };
    if (selected === 'letters' || selected === 'comments') {
      await createPoint(item);
    }

    if (selected === 'change') {
      const pointOnImage = imageDetails?.find(
        pointImage => pointImage.id === idPoint,
      );
      await updatePoint(idPoint, input, {
        letterX: pointOnImage.letterX,
        letterY: pointOnImage.letterY,
      });
      setChanged(false);
    }

    setLoading(false);
    setIsNew(false);
    setIsNewComment(false);
    getImageDetails(idImage);
    setChanged(false);
  }, [createPoint, idImage, input, point?.x, point?.y]);

  const updateOnlyLetterPosition = async ({
    id,
    value,
    letterY,
    letterX,
  }: {
    id: number;
    value: string;
    letterX: number;
    letterY: number;
  }) => {
    const pointOnImage = imageDetails?.find(pointImage => pointImage.id === id);
    if (
      letterY !== pointOnImage?.letterY ||
      letterX !== pointOnImage?.letterX
    ) {
      setLoading(true);
      await updatePoint(id, value, {
        letterX,
        letterY,
      });
      setLoading(false);
      setIsNew(false);
      setIsNewComment(false);
      getImageDetails(idImage);
      setChanged(false);
    }
  };

  useEffect(() => {
    if (selected === 'comments') return;
    createNewPoint();
  }, [input]);

  const deletePoint = (id?: any) => {
    if (isNew) {
      setIsNew(false);
      setIsNewComment(false);
      setInput('');
    }
    deleteImagePoint(id);
  };

  const handleSelected = (s: Selected) => {
    setSelected(s);
  };

  const handleAddLetters = () => {
    setIsNew(false);
    setIsNewComment(false);
    if (selected === 'letters') {
      setSelected('');
      return;
    }
    handleSelected('letters');
  };

  const handleAddComments = () => {
    setIsNew(false);
    setIsNewComment(false);
    if (selected === 'comments') {
      setSelected('');
      return;
    }
    handleSelected('comments');
  };

  const handleInput = (i: string) => {
    setInput(i);
    setChanged(true);
    if (!regex.test(i)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const handlePosition = (e: any) => {
    if (selected === '' || selected === 'change') return;
    addPoint(getPosition(e), getTotalSize(e));
  };

  const Loading = () => {
    return (
      <ContainerSaving>
        <Saving>SAVING </Saving> <Spinner size="small" />
      </ContainerSaving>
    );
  };

  const handleClose = () => {
    onClose();
    setInput('');
    setIsNew(false);
    setIsNewComment(false);
    setSelected('');
  };

  const prepareImageToS3 = () => {
    if (!isSavingOnS3) {
      setInput('');
      setIsNew(false);
      setIsNewComment(false);
      setSelected('');
      setIsSavingOnS3(true);
    }
  };

  const saveImageS3 = async () => {
    const canvas = await html2canvas(boxImageWithPoints.current as any, {
      allowTaint: false,
      useCORS: true,
    });

    const formData = new FormData();

    canvas.toBlob(blob => {
      formData.append('file', blob as any, 'master-image-with-points.png');

      formData.append('isMandatory', '0');
      formData.append('withPoints', '1');

      if (masterImageWithPoints?.id) {
        updateMasterImage(masterImageWithPoints?.id, formData, true);
      } else {
        uploadMasterImage(data?.id, formData, true);
      }

      setIsSavingOnS3(false);
      handleClose();
    });
  };

  useEffect(() => {
    if (isSavingOnS3) saveImageS3();
  }, [isSavingOnS3]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => (type === Type.Add ? prepareImageToS3() : handleClose())}
      minWidth="calc(100% - 160px)"
      minHeight="calc(100vh - 160px)"
      title={isSavingOnS3 || loading ? <Loading /> : title}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item justifyContent="center" alignItems="center">
          <BoxImage ref={boxImageWithPoints} removeBorder={isSavingOnS3}>
            <BoxImagePosition className="containerRefBounds">
              {isNew && (
                <PointsAdd
                  key={`${point?.x}-${point?.y}-${isOpen}-${point?.id}`}
                  x={point!.x}
                  y={point!.y}
                  cx={MasterImageZoomInSizes.width}
                  cy={MasterImageZoomInSizes.height}
                  text={input}
                  onDelete={() => deletePoint(point?.id)}
                  onChange={handleInput}
                  onSaveComment={() => !loading && createNewPoint()}
                  isInput={isNew}
                  isInputComment={isNewComment}
                  isError={isError}
                  selected={selected}
                />
              )}
              {imageDetails?.map((point: any) => (
                <>
                  {(!isSavingOnS3 || point?.type !== POINTS.COMMENT) && (
                    <Points
                      key={`${point?.x}${point?.y}${isOpen}`}
                      x={point?.x}
                      y={point?.y}
                      letterY={point?.letterY}
                      letterX={point?.letterX}
                      cx={MasterImageZoomInSizes.width}
                      cy={MasterImageZoomInSizes.height}
                      text={point.value}
                      onDelete={() => deletePoint(point?.id)}
                      selected={selected}
                      point={point}
                      onChange={handleInput}
                      handleSelected={handleSelected}
                      setIdPoint={e => setIdPoint(e)}
                      type={type}
                      onChangeLetterPosition={updateOnlyLetterPosition}
                    />
                  )}
                </>
              ))}
              <MasterImage
                src={`${url}?not-from-cache-please`}
                selected={selected}
                onClick={(e: any) => !loading && handlePosition(e)}
                ref={refImage}
                crossOrigin="anonymous"
              />
            </BoxImagePosition>
          </BoxImage>
        </Grid>
      </Grid>

      {type === Type.Add && !isSavingOnS3 && (
        <Grid container justifyContent="center">
          <Grid item xs={10} md={5}>
            <BoxButtons>
              <Button
                width="230px"
                onClick={() => !loading && handleAddLetters()}
                data-testid="button-handle-letters"
                scheme={selected === 'letters' || loading ? 'gray' : 'default'}
              >
                <BoxIcon>
                  <IconModifield icon="plus" width="15px" />
                </BoxIcon>
                Add letters, numbers
              </Button>
              <Button
                width="230px"
                onClick={() => !loading && handleAddComments()}
                data-testid="button-handle-comments"
                scheme={selected === 'comments' || loading ? 'gray' : 'default'}
              >
                <BoxIcon>
                  <IconModifield icon="plus" width="15px" />
                </BoxIcon>
                Add comments
              </Button>
            </BoxButtons>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};

export default ModalLettersAndComments;
