/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { useDebounce } from 'hooks/useDebounce';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';
import { Container, TextArea, ContainerSaving, Saving } from './styles';

export interface IProps {
  open: boolean;
  onClose: () => void;
  placeholder?: string;
}

const Notes: React.FC<IProps> = ({ open, onClose, placeholder }) => {
  const {
    data: { skuDetails, skuDetailActive },
    actions,
  } = useSpecSheetSkuDetail();

  const skuDetail = skuDetails[skuDetailActive];

  const [text, setText] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handle = useDebounce(text);

  const getNotes = async () => {
    setText(skuDetail?.note || '');
  };

  useEffect(() => {
    getNotes();
  }, [skuDetail]);

  const updateOrCreate = useCallback(async () => {
    setLoading(true);
    await actions.updateSkuDetail({ note: text });
    setLoading(false);
  }, [text]);

  useEffect(() => {
    if (text === null) return;
    updateOrCreate();
  }, [handle]);

  const Loading = () => {
    return (
      <ContainerSaving>
        <Saving>SAVING</Saving> <Spinner size="small" />
      </ContainerSaving>
    );
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      minWidth="calc(100% - 160px)"
      minHeight="calc(100vh - 160px)"
      title={!loading ? 'Add notes' : <Loading />}
    >
      <Container>
        <TextArea
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setText(e.target.value)
          }
          value={text || ''}
        />
      </Container>
    </Modal>
  );
};

Notes.defaultProps = { placeholder: 'Add your note here' };

export default Notes;
