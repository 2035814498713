import React from 'react';

import withCustomerOrderHistory from 'containers/customer-order-history';
import TitlePages from 'components/TitlePages';

import Content from './Content';

import { Container } from './styles';
import Header from './Header';

const CustomerOrderHistory: React.FC = () => {
  return (
    <Container>
      <Header />
      <TitlePages title="Upload management" />

      <Content />
    </Container>
  );
};

export default withCustomerOrderHistory(CustomerOrderHistory);
