/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
import { useMemo, useState, useEffect } from 'react';
import {
  Column,
  ContextMenuComponentProps,
  DynamicDataSheetGrid,
} from 'react-datasheet-grid';
import { ContextMenu } from 'react-datasheet-grid/dist/components/ContextMenu';
import 'react-datasheet-grid/dist/style.css';
import { v4 as uuidv4 } from 'uuid';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { useDashboard } from 'containers/dashboard';
import { IRow } from 'containers/dashboard/dtos';

import { Operation } from 'react-datasheet-grid/dist/types';
import columnsList from './columns';

import { Container, ContainerButtons, ContainerSpinner } from './styles';

enum OPERATION_TYPES {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

interface IProps {
  onCreate(data: IRow[]): void;
  onDelete(id: number): void;
  onUpdate(data: IRow[]): void;
  onRefresh(isReset?: boolean): Promise<void>;
}

const Table = ({ onCreate, onDelete, onUpdate, onRefresh }: IProps) => {
  const {
    data: { loading, list },
  } = useDashboard();

  const [data, setData] = useState<IRow[]>([]);
  const [prevData, setPrevData] = useState<IRow[]>([]);
  const [columns, setColumns] = useState<Partial<Column<any, any, any>>[]>([]);

  const [hasModifiedItems, setHasModifiedItems] = useState(false);

  const addedItems = useMemo(() => new Set(), []);
  const duplicatedItems = useMemo(() => new Set(), []);
  const updatedItems = useMemo(() => new Set(), []);
  const deletedItems = useMemo(() => new Set(), []);

  const operationRow = (operation: Operation, newValue: IRow[]) =>
    ({
      [OPERATION_TYPES.CREATE]: () => {
        const hasDuplicated = newValue.find(value => value.duplicated) as IRow;

        if (hasDuplicated) {
          delete hasDuplicated.id;
          duplicatedItems.add(hasDuplicated);
        }

        setData(newValue);
      },

      [OPERATION_TYPES.UPDATE]: () => {
        const currentRow = newValue[operation.fromRowIndex];
        const newData = [...data];
        newData[operation.fromRowIndex] = currentRow;

        const hasAddedRowToUpdate = Array.from(addedItems).find(
          value => (value as IRow).operationId === currentRow.operationId,
        );
        const hasDuplicatedRowToUpdate = Array.from(duplicatedItems).find(
          value => (value as IRow).operationId === currentRow.operationId,
        );

        if (hasAddedRowToUpdate) {
          addedItems.delete(hasAddedRowToUpdate);
          addedItems.add(currentRow);
          setData(newData);
          return;
        }

        if (hasDuplicatedRowToUpdate) {
          duplicatedItems.delete(hasDuplicatedRowToUpdate);
          duplicatedItems.add(currentRow);
          setData(newData);
          return;
        }

        // UPDATE:
        const updatedRow = newValue.find(value => value.id === currentRow.id);

        const hasToUpdateRow = Array.from(updatedItems).find(
          value => (value as IRow).id === currentRow.id,
        );

        if (updatedRow) {
          setData(newData);
          updatedItems.delete(hasToUpdateRow);
          updatedItems.add(updatedRow);
          return;
        }
        if (hasToUpdateRow) {
          updatedItems.delete(hasToUpdateRow);
          updatedItems.add(updatedRow);
        }
      },

      [OPERATION_TYPES.DELETE]: () => {
        const currentRow = data[operation.fromRowIndex];
        const hasToDelete = Array.from(addedItems).find(
          value => (value as IRow).operationId === currentRow.operationId,
        );

        if (hasToDelete) {
          addedItems.delete(hasToDelete);
        }

        const hasDuplicatedToDelete = Array.from(duplicatedItems).find(
          duplicatedItem =>
            duplicatedItem === currentRow.id && currentRow.duplicated,
        );
        if (hasDuplicatedToDelete) {
          duplicatedItems.delete(hasDuplicatedToDelete);
        }

        if (!currentRow.duplicated) {
          deletedItems.add(currentRow.id);
        }

        setData(newValue);
      },
    }[operation.type]());

  const handleChange = (newValue: IRow[], operations: Operation[]) => {
    const operation = operations[0];
    if (!operation) return;

    operationRow(operation, newValue);
    setHasModifiedItems(true);
  };

  const rowClassName = ({ rowData }) => {
    const hasUpdatedRow = Array.from(updatedItems).find(
      uRow => (uRow as IRow).id === rowData.id,
    );

    if (hasUpdatedRow) {
      return 'row-updated';
    }

    if (!rowData.id) {
      return 'row-updated';
    }

    return '';
  };

  const handleCancel = () => {
    // remove new entries
    const newData = prevData;

    for (let i = newData.length - 1; i >= 0; i -= 1) {
      if (newData[i].operationId) {
        newData.splice(i, 1);
      }
    }

    setData(newData);
    addedItems.clear();
    updatedItems.clear();
    duplicatedItems.clear();
    deletedItems.clear();
    setHasModifiedItems(false);
  };

  const handleSave = async () => {
    // delete
    const itemsToDelete = Array.from(deletedItems) as number[];

    await Promise.all(
      itemsToDelete.map(async id => {
        await onDelete(id);
      }),
    );

    // duplicate

    const itemsToDuplicate = Array.from(duplicatedItems) as IRow[];

    if (itemsToDuplicate.length > 0) {
      await onCreate(itemsToDuplicate);
    }

    // add
    const itemsToAdd = Array.from(addedItems) as IRow[];
    if (itemsToAdd.length > 0) {
      await onCreate(itemsToAdd);
    }

    // update
    const itemsToUpdate = Array.from(updatedItems) as IRow[];

    if (itemsToUpdate.length > 0) {
      await onUpdate(itemsToUpdate);
    }

    // reset helpers states
    addedItems.clear();
    updatedItems.clear();
    duplicatedItems.clear();
    deletedItems.clear();
    setHasModifiedItems(false);
    await onRefresh();
  };

  const addNewEntry = () => {
    setHasModifiedItems(true);
    const newArray = data;

    const newRow = {
      customer: "Dillard's",
      created: true,
      operationId: uuidv4(),
    };

    newArray.unshift({ ...newRow });
    setData([...newArray]);
    addedItems.add(newRow);
  };

  useEffect(() => {
    if (list) {
      setData(list);
      setPrevData(list);
    }
  }, [list]);

  useEffect(() => {
    setColumns(columnsList);
  }, []);

  function ContextMenuCustomComponent(props: ContextMenuComponentProps) {
    const { items, close, cursorIndex } = props;

    const isDuplicated = data[cursorIndex?.row].duplicated;

    useEffect(() => {
      if (
        items.some(
          e => ('fromRow' in e && e.fromRow) || ('toRow' in e && e.toRow),
        ) ||
        cursorIndex?.row === -1 ||
        isDuplicated
      ) {
        close();
      }
    }, [close, cursorIndex?.row, isDuplicated, items]);

    return <ContextMenu {...props} />;
  }

  return (
    <Container>
      {loading ? (
        <ContainerSpinner>
          <Spinner size="big" />
        </ContainerSpinner>
      ) : (
        <>
          <ContainerButtons>
            <Button padding="6px 0px" width="150px" onClick={addNewEntry}>
              Add new entry
            </Button>

            {hasModifiedItems && (
              <>
                <Button padding="6px 0px" width="150px" onClick={handleSave}>
                  Save
                </Button>

                <Button
                  padding="6px 0px"
                  width="150px"
                  scheme="nobg"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </ContainerButtons>
          <DynamicDataSheetGrid
            value={data}
            onChange={handleChange}
            columns={columns}
            rowClassName={rowClassName}
            addRowsComponent={() => <></>}
            rowHeight={50}
            height={600}
            contextMenuComponent={props => (
              <ContextMenuCustomComponent {...props} />
            )}
            duplicateRow={({ rowData }) => ({
              ...rowData,
              operationId: uuidv4(),
              duplicated: true,
            })}
          />
        </>
      )}
    </Container>
  );
};

export default Table;
