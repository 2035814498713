import React from 'react';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';

import GridFlex from 'components/GridFlex';
import Text from 'components/Text';
import { ImagePrototype } from 'components/ImageWithPoints/styles';

import { NotesContainer, Comment, ImageContainer } from './styles';

const CreateSketch: React.FC = () => {
  const {
    data: { imageWithPoints, image },
  } = useSpecSheetModal();

  return (
    <GridFlex direction="column" margin="0 14px 0 0" flex={1}>
      <ImageContainer>
        {imageWithPoints && <ImagePrototype src={imageWithPoints.url} />}
      </ImageContainer>

      {image?.comments && image?.comments.length > 0 && (
        <NotesContainer>
          {image?.comments.map(({ value, id }) => (
            <Comment key={id}>
              <Text fontSize={7.27}>{value}</Text>
            </Comment>
          ))}
        </NotesContainer>
      )}
    </GridFlex>
  );
};

export default CreateSketch;
