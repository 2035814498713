import React from 'react';
import ReactTooltip from 'react-tooltip';

import {
  ICustomerOrderHistoryData,
  StatusEnum,
} from 'containers/customer-order-history/dtos';

import Button from 'components/Button';
import Text from 'components/Text';
import { IProps as IPropsText } from 'components/Text/styles';

import { dateDefault } from 'helpers/date';
import Icon from 'components/Icon';

import { useCustomerOrderHistory } from 'containers/customer-order-history';
import {
  StatusWrapper,
  WrapperItem,
  WrapperText,
  ReasonWrapper,
  IconContainer,
} from './styles';

const labelTextProps = {
  fontSize: 12,
};

const valueTextProps: IPropsText = {
  fontSize: 16,
  color: 'black',
};

const MAP_STATUS_TEXT = {
  [StatusEnum.SUCCESS]: 'Success',
  [StatusEnum.ERROR]: 'Fail',
  [StatusEnum.PROCESSING]: 'Processing',
};

interface IProps extends ICustomerOrderHistoryData {
  onClickFail(reason: string): void;
}

const Item: React.FC<IProps> = ({
  id,
  createdAt,
  name,
  brand,
  status,
  userName,
  reason,
  onClickFail,
}) => {
  const { actions: actionsCustomerOrderHistory } = useCustomerOrderHistory();

  const handleDownload = async (customerOrderHistoryId: number) => {
    await actionsCustomerOrderHistory.downloadSpreadsheet(
      customerOrderHistoryId,
    );
  };

  const itemId = `customer-order-history-item-${id}`;

  const isLongName = name.length > 27;
  const isLongBrand = brand.length > 12;

  return (
    <WrapperItem key={itemId}>
      <WrapperText>
        <Text {...labelTextProps}>File</Text>
        <Text {...valueTextProps} data-for={`${itemId}-name`} data-tip={name}>
          {isLongName ? `${name.substring(0, 21)}...` : name}
        </Text>
        {isLongName && <ReactTooltip id={`${itemId}-name`} />}
      </WrapperText>

      <WrapperText>
        <Text {...labelTextProps}>Brand</Text>
        <Text {...valueTextProps} data-for={`${itemId}-brand`} data-tip={brand}>
          {isLongBrand ? `${brand.substring(0, 12)}...` : brand}
        </Text>
        {isLongBrand && <ReactTooltip id={`${itemId}-brand`} />}
      </WrapperText>

      <WrapperText>
        <Text {...labelTextProps}>Uploaded</Text>
        <Text {...valueTextProps}>{dateDefault(createdAt)}</Text>
      </WrapperText>

      <WrapperText>
        <Text {...labelTextProps}>User</Text>
        <Text {...valueTextProps}>{userName}</Text>
      </WrapperText>

      <WrapperText>
        <Text {...labelTextProps}>Status</Text>
        <ReasonWrapper>
          <StatusWrapper status={status}>
            <Text {...valueTextProps}>{MAP_STATUS_TEXT[status]}</Text>
          </StatusWrapper>
          {status === StatusEnum.ERROR && reason && (
            <IconContainer onClick={() => onClickFail(reason)}>
              <Icon icon="warning" color="none" />
            </IconContainer>
          )}
        </ReasonWrapper>
      </WrapperText>

      <Button padding="8px 0px" onClick={() => handleDownload(id)}>
        <Text color="white" fontSize={13}>
          Download
        </Text>
      </Button>
    </WrapperItem>
  );
};

export default Item;
