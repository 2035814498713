import React, { useEffect } from 'react';

import Icon from 'components/Icon';
import Text from 'components/Text';

import {
  Container,
  WrapperContent,
  WrapperCloseButton,
  Content,
  WrapperTitle,
} from './styles';

export interface IProps {
  onClose: () => void;
  title?: any;
  subtitle?: string;
  minWidth?: string;
  minHeight?: string;
  marginTitle?: boolean;
  isOpen: boolean;
  noButtonClose?: boolean;
}

const Modal: React.FC<IProps> = ({
  onClose,
  title,
  subtitle = '',
  minWidth,
  minHeight,
  children,
  isOpen,
  marginTitle,
  noButtonClose,
  ...props
}) => {
  useEffect(() => {
    if (isOpen) {
      window.onkeyup = (event: any) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
    } else {
      window.onkeyup = null;
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    return () => {
      if (window.onkeyup) {
        window.onkeyup = null;
      }
    };
  }, []);

  return (
    <Container isOpen={isOpen} {...props}>
      <WrapperContent minWidth={minWidth} minHeight={minHeight}>
        {onClose && (
          <WrapperCloseButton onClick={onClose} data-testid="close-modal">
            {!noButtonClose && <Icon color="black" icon="close" width="21px" />}
          </WrapperCloseButton>
        )}
        <Content>
          {title && (
            <WrapperTitle marginTitle={marginTitle}>
              <Text color="black" fontSize={24}>
                {title}
              </Text>
              {subtitle && (
                <Text
                  color="black"
                  fontSize={16}
                  fontWeight="medium"
                  margin="6px 0 0 0"
                >
                  {subtitle}
                </Text>
              )}
            </WrapperTitle>
          )}
          {children}
        </Content>
      </WrapperContent>
    </Container>
  );
};

Modal.defaultProps = {
  marginTitle: true,
};

export default Modal;
