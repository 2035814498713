import styled, { css } from 'styled-components';

import { scrollStyle, takeColor } from 'theme/tools';

export const StrongLabel = styled.strong`
  color: ${takeColor('black')};
  font-size: 18px;
  font-weight: 600;
`;

export const InputStyle = styled.input`
  font-size: 16px;
  width: 100%;

  &::placeholder {
    color: ${takeColor('grayLightText')};
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const WrapperLeftContent = styled.div`
  margin-right: 12px;
`;

export const BoxChip = styled.div`
  padding: 10px 15px;
  background: ${takeColor('grayMedium')};
  border-radius: 41px;
  color: ${takeColor('white')};
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const Chip = styled.div`
  display: flex;
`;

interface IWrapperChip {
  isFocus: boolean;
}

export const WrapperChip = styled.div<IWrapperChip>`
  max-height: 100px;
  overflow-y: scroll;
  margin-right: 5px;

  ${scrollStyle}

  ${({ isFocus }: any) =>
    isFocus &&
    css`
      ${BoxChip} {
        background-color: ${takeColor('black')};
      }
    `};
`;

export const InputText = styled.input`
  width: auto;
  height: 50px;
  border: none;

  ::placeholder {
    font-size: 16px;
  }
`;

export const BoxDelete: any = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;
  z-index: 5;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

export const BoxInput = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BoxList = styled.div`
  width: 95%;
  min-height: 100px;
  position: absolute;
  background: ${takeColor('white')};
  border: 1px solid ${takeColor('grayBorderBox')};
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  top: 20px;
  padding: 20px;
  max-height: 150px;
  overflow-y: auto;
`;

export const List = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 0px;
  cursor: pointer;

  :hover {
    font-weight: 600;
    color: ${takeColor('black')};
  }
`;
