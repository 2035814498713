import styled, { css } from 'styled-components';

import { TextStyles } from 'components/Text/styles';
import { simpleFlex, takeColor } from 'theme/tools';

interface IMenuCollapsed {
  isMenuCollapsed: boolean;
}

interface ISettingsMenu extends IMenuCollapsed {
  open?: boolean;
}

export const Container = styled.div<IMenuCollapsed>`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  /* margin-left: 60px; */
  transition: 0.2s all;
  align-items: center;

  > ${TextStyles} {
    margin-top: 15px;
    cursor: pointer;
  }

  * {
    display: flex;
    transition: 0.2s opacity;
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    font-size: 15px;
  }

  ${({ isMenuCollapsed }) =>
    isMenuCollapsed &&
    css`
      p {
        font-size: 12px;
      }

      /* margin-left: 25px; */
      margin-bottom: 65px;
    `}
`;

export const WrapperButton = styled.div`
  ${simpleFlex};
  flex: auto;
  justify-content: space-around;
  margin-top: 40px;

  * {
    cursor: pointer;
  }
`;

export const SettingsMenu = styled.div<ISettingsMenu>`
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  overflow: hidden;

  .active {
    p {
      color: ${takeColor('white')};
    }
  }

  ${TextStyles} {
    margin-top: 15px;
    white-space: break-spaces;
    text-align: center;

    @media (max-height: 700px) {
      margin-top: 5px;
    }

    cursor: pointer;

    &:hover {
      color: ${takeColor('white')};
      opacity: 1;
    }
  }
  /*
  ${({ isMenuCollapsed }) =>
    isMenuCollapsed
      ? css`
          margin-left: -15px;
        `
      : css`
          margin-left: 20px;
        `} */

  ${({ open }) =>
    open
      ? css`
          max-height: 120px;
        `
      : css`
          max-height: 0px;
        `}
`;
