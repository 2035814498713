import React, { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser } from 'containers/user';
import { getItem } from 'helpers/storage';
import { useNav } from 'containers/nav';
import Icon from 'components/Icon';
import pgk from '../../../package.json';
import UnLogged from './partials/UnLogged';
import Logged from './partials/Logged';

import {
  Container,
  ContentWrapper,
  ExpandMenuContainer,
  FozVersion,
} from './styles';

const Nav: React.FC = () => {
  const { data } = useUser();
  const {
    data: { openMenu },
    actions: { openNav },
  } = useNav();
  const { pathname } = useLocation();

  const showOnlyDashboard = pathname.includes('dashboard');

  const isAccessDenied = ['/access-denied'].includes(pathname);

  const isLogged = getItem('@token') && data.signedIn;

  const handleOpenNav = useCallback(() => {
    if (!showOnlyDashboard && !openMenu) openNav(true);
  }, [showOnlyDashboard, openNav, openMenu]);

  useEffect(() => {
    handleOpenNav();
  }, [handleOpenNav]);

  return !isAccessDenied ? (
    <Container isLogged={isLogged} isOpenMenu={openMenu}>
      <ContentWrapper>
        {isLogged ? (
          <>
            {showOnlyDashboard && (
              <ExpandMenuContainer
                isOpenMenu={openMenu}
                onClick={() => openNav(!openMenu)}
              >
                <Icon icon="arrow-left" />
              </ExpandMenuContainer>
            )}

            <Logged />
          </>
        ) : (
          <UnLogged />
        )}
        <FozVersion>FOS Version {pgk.version}</FozVersion>
      </ContentWrapper>
    </Container>
  ) : null;
};

export default memo(Nav);
