import React from 'react';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';

import { Table, TableColumn, TableRow } from 'components/Table';
import { WrapperTable } from './styles';

const columnWidthBig = '70px';

type Line = {
  name: string;
  letterNumber: string;
  color: string;
};

const TableSkuDetails: React.FC = () => {
  const { data } = useSpecSheetModal();

  const { skuDetails }: any = data;

  return (
    <table width="100%">
      {skuDetails?.map(
        (
          {
            id,
            pair,
            size,
            variable,
            hardware,
            variables,
            fields,
            note,
            insoleBinding,
          },
          index,
        ) => {
          let newVariables = variables;

          const newLine: {
            material?: Line;
            lining?: Line;
            sock?: Line;
          }[] = [{}];

          newVariables?.material?.forEach((element, index) => {
            if (!newLine[index]) {
              newLine[index] = {};
            }
            newLine[index].material = {
              name: element.material?.name,
              letterNumber: element.letterNumber,
              color: element.color?.color,
            };
          });

          newVariables?.lining?.forEach((element, index) => {
            if (!newLine[index]) {
              newLine[index] = {};
            }
            newLine[index].lining = {
              name: element.material?.name,
              letterNumber: element.letterNumber,
              color: element.color?.color,
            };
          });

          newVariables?.sock?.forEach((element, index) => {
            if (!newLine[index]) {
              newLine[index] = {};
            }
            newLine[index].sock = {
              name: element.material?.name,
              letterNumber: element.letterNumber,
              color: element.color?.color,
            };
          });

          if (!newVariables?.length) newVariables = [{}];

          return (
            <WrapperTable key={`sku-details-${id}`}>
              <Table width="100%">
                <TableRow isLabel>
                  <TableColumn width="30px">Pairs</TableColumn>

                  <TableColumn width="30px">Size</TableColumn>

                  <TableColumn width={columnWidthBig}>Material</TableColumn>

                  <TableColumn width={columnWidthBig}>Color</TableColumn>

                  <TableColumn width={columnWidthBig}>
                    Lining material
                  </TableColumn>

                  <TableColumn width={columnWidthBig}>Lining color</TableColumn>

                  <TableColumn width={columnWidthBig}>
                    Sock lin material
                  </TableColumn>

                  <TableColumn width={columnWidthBig}>
                    Sock lin color
                  </TableColumn>

                  <TableColumn width={columnWidthBig}>Stamp color</TableColumn>

                  <TableColumn width={columnWidthBig}>Sole color</TableColumn>

                  <TableColumn width={columnWidthBig}>
                    Sole edge color
                  </TableColumn>

                  <TableColumn width="45px">Welt</TableColumn>

                  <TableColumn width={columnWidthBig}>Heel color</TableColumn>

                  <TableColumn width={columnWidthBig}>
                    Sole finish/ edge
                  </TableColumn>

                  <TableColumn width={columnWidthBig}>Stitch</TableColumn>

                  <TableColumn width="55px">Col/Mat code</TableColumn>
                </TableRow>

                {newLine.map((line, index) => {
                  const isFirst = index === 0;
                  const isMaterial = !!line.material;
                  const isLining = !!line.lining;
                  const isSock = !!line.sock;

                  return (
                    <TableRow minHeight="17px">
                      <TableColumn width="30px" height="20px">
                        {isFirst && pair}
                      </TableColumn>

                      <TableColumn width="30px" height="20px">
                        {isFirst && size}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isMaterial &&
                          `${line.material?.letterNumber || ''} ${
                            line.material?.name || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isMaterial &&
                          `${line.material?.letterNumber || ''} ${
                            line.material?.color || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isLining &&
                          `${line.lining?.letterNumber || ''} ${
                            line.lining?.name || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isLining &&
                          `${line.lining?.letterNumber || ''} ${
                            line.lining?.color || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isSock &&
                          `${line.sock?.letterNumber || ''} ${
                            line.sock?.name || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isSock &&
                          `${line.sock?.letterNumber || ''} ${
                            line.sock?.color || ''
                          }`}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.stampColor
                            .map(({ value }) => value)
                            .join(', ')}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.soleColor.map(({ value }) => value).join(', ')}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.soloEdgeColor
                            .map(({ value }) => value)
                            .join(', ')}
                      </TableColumn>

                      <TableColumn width="45px" height="20px">
                        {isFirst && insoleBinding}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.heelColor.map(({ value }) => value).join(', ')}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.soloFinishColor
                            .map(({ value }) => value)
                            .join(', ')}
                      </TableColumn>

                      <TableColumn width={columnWidthBig} height="20px">
                        {isFirst &&
                          fields.stitch.map(({ value }) => value).join(', ')}
                      </TableColumn>

                      <TableColumn width="55px" height="20px">
                        {isFirst &&
                          fields.colMatCode
                            .map(({ value }) => value)
                            .join(', ')}
                      </TableColumn>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableColumn isLabel>Variable</TableColumn>
                  <TableColumn isLabel>Hardware</TableColumn>
                  <TableColumn isLabel>Notes</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{variable}</TableColumn>
                  <TableColumn>{hardware}</TableColumn>
                  <TableColumn>{note}</TableColumn>
                </TableRow>
              </Table>
            </WrapperTable>
          );
        },
      )}
    </table>
  );
};

export default TableSkuDetails;
