import React, { memo } from 'react';

import user from './user';
import specSheets from './spec-sheets';
import dashboard from './dashboard';
import nav from './nav';

const listContainers = [user, specSheets, dashboard, nav];

export default function containers(WrappedComponent: React.FC): React.FC {
  const WithContainers = (props: any) => {
    let CompleteComponent: any = null;

    listContainers.forEach(withContainer => {
      CompleteComponent = withContainer(CompleteComponent || WrappedComponent);
    });

    return <CompleteComponent {...props} />;
  };

  return memo(WithContainers);
}
