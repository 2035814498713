import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const NotesContainer = styled.div`
  padding: 7px 10px;
  border-radius: 6px;
  font-size: 6.27px;
  line-height: 17px;
  border: 1px solid #a8a8a8;
  color: ${takeColor('black')};
  margin-top: 12px;
`;

export const Comment = styled.div`
  word-wrap: break-word;
  margin-bottom: 5px;
`;

export const ImageContainer = styled.div`
  padding: 0px 2px;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  ${simpleFlex}
  justify-content: center;
`;
