import styled from 'styled-components';

import { simpleFlex } from 'theme/tools';

export const Container = styled.div<{ noHeader?: boolean }>`
  ${simpleFlex};
  justify-content: space-between;
  margin-top: 75px;
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
