export interface IActions {
  changeStep(step: number): void;
  listCustomerOrder(isReset?: boolean): Promise<void>;
  getSavedFilters(): Promise<any>;
  updateCustomerOrder(listToUpdate: IRow[]): Promise<void>;
  duplicateShippingOrder(id: number): Promise<any>;
  deleteShippingOrder(id: number): Promise<void>;
  createShippingOrder(listToCreate: IRow[]): Promise<void>;
  setPage(page: number): void;
  addToFilter(
    filterType: keyof IState['filters']['multiples'],
    value: any,
  ): void;
  removeFilter(
    filterType: keyof IState['filters']['multiples'],
    value: any,
  ): void;
  hasOnFilter(
    filterType: keyof IState['filters']['multiples'],
    value: any,
  ): boolean;
  resetFilter(filterType: keyof IState['filters']['multiples']): void;
  addToNormalFilters(filterType: keyof IState['filters'], value: any): void;
  resetNormalFilter(filterType: keyof IState['filters']): void;
  resetDashboardData(): void;
}

export interface IState {
  stepActive: number;
  stepsCompleted: number[];
  list: IRow[] | null;
  pagination: IPagination | null;
  loading: LoadingEnum | null;
  filters: {
    multiples: {
      brands: IFilterItem[];
      styleNames: IFilterItem[];
      refNumbers: IFilterItem[];
      factories: IFilterItem[];
      poNumbers: IFilterItem[];
      poIDs: IFilterItem[];
      colors: IFilterItem[];
      materials: IFilterItem[];
      seasons: IFilterItem[];
      countries: IFilterItem[];
      colMatCode: IFilterItem[];
      orderType: IFilterItem[];
      orderStatus: IFilterItem[];
      status: IFilterItem[];
    };
    customer: string | null;
    units: IMoneyFilter | null;
    fobCost: IMoneyFilter | null;
    retail: IMoneyFilter | null;
    status: string | null;
    originalPoStart: IDateFilter | null;
    originalPoCancel: IDateFilter | null;
    currentPoStart: IDateFilter | null;
    currentPoCancel: IDateFilter | null;
    currentXfDate: IDateFilter | null;
    fcrDate: IDateFilter | null;
  };
}

export interface IPagination {
  page: number;
  pages: number;
}

export enum LoadingEnum {
  DEFAULT = 'default',
}

export interface IRow {
  id?: number;
  styleName?: string;
  fobCost?: number;
  retail?: number;
  units?: number;
  orderType?: string;
  poColorPriorityComments?: string;
  status?: string;
  orderStatus?: string;
  colMatCode?: string;
  remarks?: string;
  poId?: string;
  customer?: string;
  mic?: string;
  dept?: string;
  poNumber?: string;
  refNumber?: number;
  createdAt?: string | Date | null;
  fcrDate?: string | Date | null;
  factoryFirstShipDate?: string | Date | null;
  factoryPenaltyDate?: string | Date | null;
  currentPoStart?: string | Date | null;
  currentPoStartDate?: string | Date | null;
  originalPoCancel?: string | Date | null;
  originalPoCancelDate?: string | Date | null;
  scheduledXfDate?: string | Date | null;
  currentXfDate?: string | Date | null;
  dateTransmitted?: string | Date | null;
  currentPoCancel?: string | Date | null;
  currentPoCancelDate?: string | Date | null;
  originalPoStart?: string | Date | null;
  originalPoStartDate?: string | Date | null;
  idPurchaseOrder?: number;
  idShippingOrder?: number;
  duplicated?: boolean;
  created?: boolean;
  operationId?: string;
  factory?: {
    id: number;
    name: string;
  };
  season?: {
    id: number;
    name: string;
  };
  brand?: {
    id: number;
    name: string;
  };
  country?: {
    id: number;
    name: string;
  };
  material?: {
    id: number;
    name: string;
  };
  color?: {
    id: number;
    name: string;
  };
}

export interface IResponseRows {
  item: IRow[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface IDateFilter {
  startDate: string;
  endDate: string;
}

export interface IMoneyFilter {
  firstNumber: number;
  lastNumber: number;
}

interface IFilterItem {
  id: string;
}
