import React, { memo, useCallback, useEffect, useState } from 'react';

import { EnumSkuDetailsVariable } from 'containers/spec-sheet-sku-detail/constants';

import api from 'services/api';
import { useSpecSheetSkuDetail } from 'containers/spec-sheet-sku-detail';
import { useSpecSheet } from 'containers/spec-sheet';

import ModalCreateMaterial from 'components/ModalCreateMaterial';
import ModalCreateColor from 'components/ModalCreateColor';
import Base from './Base';

import { Container } from './styles';
import { ILine } from './enum';

interface IProps {
  labelMaterial: string;
  labelColor: string;
  type: EnumSkuDetailsVariable;
}

const emptyLine = {
  id: null,
  idMaterial: null,
  idColor: null,
  letterNumber: null,
  color: null,
  material: null,
};

const List: React.FC<IProps> = ({ labelColor, labelMaterial, type }) => {
  const { actions } = useSpecSheet();

  const [lineList, setLineList] = useState<(ILine & { key?: number })[] | null>(
    null,
  );
  const [createModalColor, setCreateModalColor] = useState<boolean | string>(
    false,
  );
  const [createModalMaterial, setCreateModalMaterial] = useState<
    boolean | string
  >(false);
  const [line, setLine] = useState<any>();

  const {
    data: { skuDetailActive, skuDetails },
  } = useSpecSheetSkuDetail();
  const skuDetail = skuDetails[skuDetailActive];

  const getList = async () => {
    const list: ILine[] = await api.get(
      `/spec-sheets-sku-details-variable/${skuDetail?.id}?type=${type}`,
    );

    if (list.length) {
      setLineList(list);
      return;
    }

    setLineList([{ ...emptyLine, key: Date.now() }]);
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuDetail?.id]);

  const createLine = useCallback(async () => {
    return api.post<ILine>(
      `/spec-sheets-sku-details-variable/${skuDetail?.id}`,
      {
        type,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuDetail?.id]);

  const updateLineData = useCallback(
    async (newData: ILine) => {
      return api.put<ILine>(
        `/spec-sheets-sku-details-variable/${skuDetail?.id}`,
        newData,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [skuDetail?.id],
  );

  const updateLine = useCallback(
    async (data: ILine, index: number) => {
      actions.setLoading();

      try {
        const newData = { ...data };

        if (!data.id) {
          const newLine: any = await createLine();
          newData.id = newLine.id;
        }

        await updateLineData(newData);

        const list: any = lineList;

        list[index] = { ...newData };

        const newList = [...list];

        setLineList(newList);
      } catch (err) {
        console.log('err: ', err);
      }

      actions.removeLoading();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineList],
  );

  const newLine = () => {
    const list: any = lineList;
    setLineList([...list, { ...emptyLine, key: Date.now() }]);
  };

  const deleteLine = useCallback(
    async (lineId: number) => {
      return api.delete<ILine>(
        `/spec-sheets-sku-details-variable/${skuDetail?.id}`,
        { data: { id: lineId } },
      );
    },
    [skuDetail?.id],
  );

  const removeLine = useCallback(
    async (lineId: ILine['id'], index: number) => {
      try {
        if (lineId) {
          await deleteLine(lineId);
        }
        const list: any = lineList;

        list?.splice(index, 1);

        setLineList([...list]);
      } catch (err) {
        console.log('err: ', err);
      }
    },
    [deleteLine, lineList],
  );

  return (
    <Container>
      {lineList?.map((item, index) => (
        <Base
          key={`base-${labelMaterial}-${item.id}-${skuDetailActive}-${item?.key}`}
          item={item}
          labelColor={labelColor}
          labelMaterial={labelMaterial}
          updateLine={updateLine}
          index={index}
          newLine={newLine}
          removeLine={removeLine}
          isFirst={index === 0}
          onClickOpenModalColor={data => {
            setLine({ ...item, index });
            setCreateModalColor(data);
          }}
          onClickOpenModalMaterial={data => {
            setLine({ ...item, index });
            setCreateModalMaterial(data);
          }}
        />
      ))}

      <ModalCreateColor
        isOpen={!!createModalColor}
        onClose={() => setCreateModalColor(false)}
        onSaved={data =>
          updateLine(
            {
              ...line,
              idColor: data ? data.id : null,
              color: data ? { color: data.color } : null,
            },
            line.index,
          )
        }
        nameComes={typeof createModalColor === 'string' ? createModalColor : ''}
      />

      <ModalCreateMaterial
        isOpen={!!createModalMaterial}
        onClose={() => setCreateModalMaterial(false)}
        onSaved={data =>
          updateLine(
            {
              ...line,
              idMaterial: data ? data.id : null,
              material: data ? { name: data.name } : null,
            },
            line.index,
          )
        }
        nameComes={
          typeof createModalMaterial === 'string' ? createModalMaterial : ''
        }
      />
    </Container>
  );
};

export default memo(List);
