import { LoadingEnum } from './dtos';

export const initialState = {
  data: null,
  stepActive: 0,
  stepsCompleted: [],
  loading: LoadingEnum.DEFAULT,
  allSpecSheets: null,
  meta: {},
  type: 'initial',
  masterImage: null,
  masterImageWithPoints: null,
  factories: [],
  imageDetails: [],
  styleGroups: [],
  styleTypes: [],
  categories: [],
  errorSpecSheet: false,
};

export const steps = [
  { name: 'Add new style' },
  { name: 'Create sketch' },
  { name: 'Prototype' },
  { name: 'Sku component' },
  { name: 'Sku details' },
  { name: 'Finish spec' },
];

export enum MAP_STEPS {
  'ADD_NEW_STYLE' = 0,
  'CREATE_SKETCH' = 1,
  'PROTOTYPE' = 2,
  'SKU_COMPONENT' = 3,
  'SKU_DETAILS' = 4,
  'FINISH_SPEC' = 5,
}
