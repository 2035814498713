/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, ChangeEvent, useRef } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import ScrollBar from 'components/ScrollBar';

import outsideClick from 'helpers/outsideClick';
import InputSearch from './InputSearch';

import Chip from './partials/Chip';

import { FieldStyle, LabelWrapper } from '../styles';

import {
  SelectWrapper,
  WrapperOptions,
  SelectItem,
  InsivibleDivClick,
  BoxChips,
  BoxIcon,
} from './styles';

export interface IItem {
  id: number;
  description: string;
}

export interface IProps {
  placeholder?: string;
  withInputSearch?: boolean;
  inputSearchPlaceholder?: string;
  inputSearchOnChange?: (value: string) => any;
  onDelete?: (item: any) => any;
  inputSearchvalue?: string;
  error?: string;
  label?: string;
  value?: IItem[];
  width?: string;
  dataTest?: string;
  disabled?: boolean;
}

const prefixDataTest = 'select';

const Select: React.FC<IProps> = props => {
  const [isFocus, setIsFocus] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const {
    value,
    label,
    placeholder,
    width,
    error,
    withInputSearch,
    inputSearchPlaceholder,
    inputSearchOnChange,
    onDelete,
    inputSearchvalue,
    dataTest,
    disabled,
    children,
  } = props;

  const wrapperRef = useRef(null);
  const { addEventClickOut, removeEventClickOut } = outsideClick({
    ref: wrapperRef,
    callback: () => {
      setIsFocus(false);
      setIsScrolling(false);
    },
  });

  const handleScrolling = () => {
    addEventClickOut();
    setIsScrolling(true);
  };

  const handleOpenSelect = () => {
    addEventClickOut();
    setIsFocus(true);
  };

  const handleCloseSelect = (event: ChangeEvent<any>) => {
    event.stopPropagation();
    removeEventClickOut();
    setIsFocus(false);
  };

  const handleDelete = (e: any) => {
    if (onDelete) {
      onDelete(e);
    }
    setIsFocus(false);
  };

  const isCompleted = !!value;

  return (
    <LabelWrapper
      as="div"
      width={width}
      disabled={disabled}
      data-test={`${prefixDataTest}-wrapper-${dataTest}`}
    >
      <Text
        onClick={handleOpenSelect}
        color="black"
        fontSize={18}
        fontWeight="normal-bold"
        data-test={`${prefixDataTest}-label-${dataTest}`}
      >
        {label}
      </Text>

      <SelectWrapper
        isCompleted={isCompleted || isFocus}
        isFocus={isFocus}
        data-test={`${prefixDataTest}-field-${dataTest}`}
        onClick={() => !value?.length && handleOpenSelect()}
        isValue={!!value?.length}
      >
        <FieldStyle
          width={width}
          isCompleted={!!value?.length}
          isFocus={isFocus}
          error={error}
          isChip={!!value?.length}
        >
          <Text tag="strong" data-test={`${prefixDataTest}-value-${dataTest}`}>
            <BoxChips onScrollCapture={handleScrolling}>
              {value?.length
                ? value?.map((item: any) => (
                    <Chip
                      key={item?.description}
                      id={item?.id}
                      description={item?.description}
                      onDelete={() => handleDelete!(item)}
                      isCompleted={!(isScrolling || isFocus)}
                    />
                  ))
                : placeholder}
            </BoxChips>
          </Text>

          <BoxIcon onClick={handleOpenSelect}>
            <Icon icon="arrow" color="grayLightText" width="15px" />
          </BoxIcon>

          {isFocus && <InsivibleDivClick onClick={handleCloseSelect} />}

          <WrapperOptions
            ref={wrapperRef}
            data-test={`${prefixDataTest}-options-${dataTest}`}
          >
            {isFocus && withInputSearch && (
              <InputSearch
                name={`select-${label}`}
                placeholder={inputSearchPlaceholder}
                onChange={inputSearchOnChange!}
                value={inputSearchvalue}
                data-test={`${prefixDataTest}-input-search-${dataTest}`}
              />
            )}
            <ScrollBar
              onClick={handleCloseSelect}
              data-test={`${prefixDataTest}-scrollbar-${dataTest}`}
            >
              {children}
            </ScrollBar>
          </WrapperOptions>
        </FieldStyle>
      </SelectWrapper>

      {error && (
        <Text
          fontSize={13}
          fontWeight="normal-bold"
          color="error"
          tag="span"
          data-test={`${prefixDataTest}-error-${dataTest}`}
        >
          {error}
        </Text>
      )}
    </LabelWrapper>
  );
};

Select.defaultProps = {
  placeholder: 'Click to select',
  error: '',
  width: '100%',
  disabled: false,
};

export { SelectItem };

export default Select;
