import styled from 'styled-components';

import { simpleFlex } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex};
  justify-content: center;
  flex-direction: column;

  margin: 65px 0;
`;

export const WrapperLoading = styled.div`
  padding: 0 0 5px;
`;

export const ActionLoadMore = styled.div`
  padding: 3px 5px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
