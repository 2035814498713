import styled, { css } from 'styled-components';

import {
  WrapperOptions,
  SelectWrapper as SelectWrapperStyle,
} from '../Select/styles';

export const SelectWrapper = styled(SelectWrapperStyle)`
  ${WrapperOptions} {
    top: 90px;
  }

  ${({ isFocus }) =>
    isFocus &&
    css`
      ${WrapperOptions} {
        top: 110px;
      }
    `};
`;
