/* eslint-disable react/no-array-index-key */
import React from 'react';

import { usePagination } from 'hooks/usePagination';

import Icon from 'components/Icon';

import { Container, WrapperArrows, WrapperItems, Item } from './styles';

interface IProps {
  page: number;
  pages: number;
  setPage: (page: number) => void;
}

const Pagination: React.FC<IProps> = ({ page, pages, setPage }) => {
  const paginationRange = usePagination({ totalCount: pages, page });

  const onPrevious = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const clickPage = (item: number) => {
    setPage(item);
  };

  const isFirstPage = page === 1;
  const isLastPage = page === pages;

  return pages > 1 ? (
    <Container>
      <WrapperArrows
        onClick={onPrevious}
        data-testid="back-page"
        disabled={isFirstPage}
      >
        <Icon
          icon="arrow-left"
          color={isFirstPage ? 'grayBorderColor' : 'black'}
          width="17px"
        />
      </WrapperArrows>

      <WrapperItems>
        {paginationRange.map((item, index) => (
          <Item
            data-testid={`click-page-${item}`}
            active={item === page}
            onClick={() => item !== '...' && clickPage(item)}
            key={`pagination-item-${index}`}
          >
            {item}
          </Item>
        ))}
      </WrapperItems>

      <WrapperArrows
        onClick={onNext}
        data-testid="next-page"
        disabled={isLastPage}
      >
        <Icon
          icon="arrow-right"
          color={isLastPage ? 'grayBorderColor' : 'black'}
          width="17px"
        />
      </WrapperArrows>
    </Container>
  ) : null;
};

export default Pagination;
