import React, { useEffect, createRef, useState } from 'react';
import { Selected } from 'containers/create-sketch/dtos';
import { useDebounce } from 'hooks/useDebounce';
import {
  Container,
  ContentLine,
  ContentText,
  Text,
  BoxDelete,
  Input,
} from './styles';

import Comments from './Comments';

export interface IProps {
  x: number;
  y: number;
  cx: number;
  cy: number;
  text: string;
  onDelete?: () => void;
  onSaveComment?: () => void;
  onChange?: (e: any) => void;
  isInput?: boolean;
  isInputComment?: boolean;
  isError?: boolean;
  selected?: Selected;
  point?: any;
}

const Points = ({
  x,
  y,
  cx,
  cy,
  text,
  onDelete,
  onSaveComment,
  onChange,
  isInput,
  isInputComment,
  isError,
  selected,
  point,
}: IProps) => {
  const focusDiv: any = createRef();
  const valueCenter = cx / 2;

  const [input, setInput] = useState(text);

  const handleDebounce = useDebounce(input, 1000);

  useEffect(() => {
    if (onChange && input !== '') onChange(handleDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDebounce]);

  useEffect(() => {
    focusDiv?.current?.focus();
  }, [focusDiv]);

  return (
    <Container
      x={x}
      y={y}
      cx={cx}
      cy={cy}
      valueCenter={valueCenter}
      selected={selected}
    >
      {selected === 'letters' || point?.type === 'POINT' ? (
        <ContentLine>
          <ContentText>
            <Text
              x={x}
              y={y}
              cx={cx}
              cy={cy}
              valueCenter={valueCenter}
              isError={isError}
            >
              <BoxDelete onClick={onDelete}>x</BoxDelete>
              {isInput ? (
                <Input
                  type="text"
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  ref={focusDiv}
                  name="text"
                  maxLength={1}
                />
              ) : (
                input
              )}
            </Text>
          </ContentText>
        </ContentLine>
      ) : (
        <Comments
          x={x}
          y={y}
          cx={cx}
          cy={cy}
          valueCenter={valueCenter}
          text={text}
          onChange={onChange}
          onDelete={onDelete}
          onSave={onSaveComment}
          isInputComment={isInputComment}
        />
      )}
    </Container>
  );
};

export default Points;
