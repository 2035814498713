/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';

import { Select, SelectItem } from 'components/Fields';
import Loading from '../partials/Loading';

const SpecSheetStatus: React.FC = () => {
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const statusSelected = data?.status;

  const getStatus = async () => {
    setLoading(true);

    try {
      const list: any = await api.get('/spec-sheet-status');

      setStatus(list);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const handleChange = (newStatus: any) => {
    actions.changeSpecSheetData({ status: newStatus });
  };

  return (
    <Select
      label="Spec sheet status"
      value={statusSelected?.description}
      dataTest="country"
      data-testid="country"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {status.map(({ id, description }) => (
            <SelectItem
              key={`status-${id}`}
              onClick={() => handleChange({ id, description })}
              isSelected={id === statusSelected?.id}
              data-test={`select-item-option-status-${id}`}
              data-testid="item-status"
            >
              {description}
            </SelectItem>
          ))}
        </>
      )}
    </Select>
  );
};

export default SpecSheetStatus;
