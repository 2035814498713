/* eslint-disable consistent-return */
/* eslint-disable no-console */
import api from 'services/api';

import { IActions, IState, TypeEnum } from './dtos';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

const specSheetPrefix = '/spec-sheets';

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  async function openModalAndGetSpecSheetComplete(
    idSpecSheet: number,
    type: TypeEnum = TypeEnum.DEFAULT,
  ) {
    const newState = { ...state, type, loading: true };

    setData(newState);

    try {
      const data: any = await api.get(
        `${specSheetPrefix}/${idSpecSheet}/complete`,
      );

      setData({ ...newState, ...data, loading: false });
    } catch (err) {
      console.log(err);

      setData({ ...newState, loading: false });
    }
  }

  function closeModal() {
    setData({ ...state, type: null });
  }

  return {
    openModalAndGetSpecSheetComplete,
    closeModal,
  };
};
