import React from 'react';

import history from 'routes/history';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';

import {
  Container,
  ContainerActions,
  BackButton,
  ContainerTitle,
} from './styles';

interface IProps {
  title: string;
  titleCreate: string;
  onCreate: () => void;
}

const Header: React.FC<IProps> = ({ onCreate, title, titleCreate }) => {
  return (
    <Container>
      <ContainerActions>
        <BackButton
          onClick={() => history.goBack()}
          data-testid="goBack-data-management"
        >
          <Icon icon="arrow-left-circle" color="none" width="38px" />
          <Text fontSize={18} color="grayMedium" fontWeight="normal">
            Go back to Data management
          </Text>
        </BackButton>
        <Button onClick={onCreate} width="265px" data-testid="create-new-item">
          {titleCreate}
          <Icon icon="arrow-right" marginLeft={15} width="13px" />
        </Button>
      </ContainerActions>
      <ContainerTitle>
        <Text fontSize={30} color="black" fontWeight="normal">
          {title}
        </Text>
      </ContainerTitle>
    </Container>
  );
};

export default Header;
