export const initialState = {
  idSpecSheet: null,
  skuDetails: [],
  skuDetailActive: 0,
  skuFieldSuggestions: [],
};

export enum EnumSkuDetailsVariable {
  MATERIAL = 'MATERIAL',
  LINING = 'LINING',
  SOCK = 'SOCK',
}
