/* eslint-disable consistent-return */
/* eslint-disable no-console */
import api from 'services/api';

import { getType } from 'components/Notes';

import {
  IActions,
  IData,
  IState,
  IUpdateSkuComponent,
  LoadingEnum,
} from './dtos';
import { initialState } from './constants';

interface IParams {
  data: IState;
  setData(data: IState): void;
}

export default (params: IParams): IActions => {
  const { setData, data: state } = params;

  const { data: specSheet } = state;

  function setSpecSheet(data: IData) {
    setData({ ...initialState, data });
  }

  async function changeSpecSheetData(data: IData) {
    setData({ ...state, loading: LoadingEnum.SAVING });

    await api.put(`/spec-sheets/${state.data?.id}`, data);

    setData({
      ...state,
      data: {
        ...state.data,
        ...data,
      },
      loading: null,
    });
  }

  async function deleteSpecSheet(id: number, data?: IData) {
    await api.put(`/spec-sheets/${id}`, data);
    setData({
      ...state,
      data: {
        ...state.data,
        ...data,
      },
    });
  }

  async function getFactories(data: any) {
    const factories: any = await api.get(
      `/factories/?idCountrie=${data.idCountry}&isActive=${data.isActive}`,
    );
    setData({ ...state, factories });
  }

  async function uploadMasterImage(id: any, file: any, withPoints = false) {
    setData({ ...state, loading: LoadingEnum.SAVING });
    try {
      const data: any = await api.post(`/spec-sheets/${id}/image`, file);
      setData({
        ...state,
        loading: null,
        masterImage: !withPoints ? data : state.masterImage,
        masterImageWithPoints: withPoints ? data : state.masterImageWithPoints,
      });
    } catch (err) {
      setData({ ...state, loading: null });
    }
  }

  async function updateMasterImage(id: any, file: any, withPoints = false) {
    setData({ ...state, loading: LoadingEnum.SAVING });
    try {
      const data: any = await api.put(`/spec-sheets-images/${id}`, file);
      setData({
        ...state,
        loading: null,
        masterImage: !withPoints ? data : state.masterImage,
        masterImageWithPoints: withPoints ? data : state.masterImageWithPoints,
      });
    } catch (err) {
      setData({ ...state, loading: null });
    }
  }

  async function getDetails(id: number) {
    if (id !== state.data?.id) {
      try {
        const data: any = await api.get(`/spec-sheets/${id}`);

        setData({
          ...state,
          loading: null,
          data: { ...data },
        });
      } catch (err) {
        setData({ ...state, loading: null });
      }
    }
  }

  async function getImage() {
    if (!state.masterImage) {
      setData({ ...state, loading: LoadingEnum.IMAGE });

      try {
        const masterImage: any = await api.get(
          `/spec-sheets/${state.data?.id}/image`,
        );

        setData({
          ...state,
          loading: null,
          masterImage: masterImage.image,
          masterImageWithPoints: masterImage.imageWithPoints,
        });
      } catch (err) {
        setData({
          ...state,
          loading: null,
        });
      }
    }
  }

  async function getImageDetails(id: number) {
    try {
      const details: any = await api.get(`/spec-sheets-images/${id}/details`);
      setData({
        ...state,
        imageDetails: details,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteImagePoint(id: number) {
    try {
      await api.delete(`/spec-sheets-images-details/${id}`);
      const items: any = state.imageDetails?.filter((f: any) => f.id !== id);
      setData({
        ...state,
        imageDetails: items,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function getStyleGroups() {
    try {
      const items: any = await api.get(`/style-groups/`);
      setData({
        ...state,
        styleGroups: items,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function getStyleTypes() {
    try {
      const items: any = await api.get(`/style-types/`);
      setData({
        ...state,
        styleTypes: items,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function updateSkuComponent(body: IUpdateSkuComponent) {
    setData({ ...state, loading: LoadingEnum.SAVING });

    try {
      await api.put(`/spec-sheets/${state?.data?.id}/sku-components`, body);
    } catch (err) {
      console.log('err: ', err);
    }

    setData({ ...state, loading: null });
  }

  async function addCategory(idCategory: number) {
    setData({ ...state, loading: LoadingEnum.SAVING });
    try {
      await api.patch(`/spec-sheets/${specSheet?.id}/add-category`, {
        idCategory,
      });

      const categories = specSheet?.categories
        ? [...specSheet?.categories, idCategory]
        : [idCategory];

      setData({
        ...state,
        data: {
          ...specSheet,
          categories,
        },
        loading: null,
      });
    } catch (err) {
      console.log('err: ', err);
      setData({ ...state, loading: null });
    }
  }

  async function addSubCategory(idSubCategory: number) {
    setData({ ...state, loading: LoadingEnum.SAVING });

    try {
      await api.patch(`/spec-sheets/${specSheet?.id}/add-sub-category`, {
        idSubCategory,
      });

      const subCategories = specSheet?.subCategories
        ? [...specSheet?.subCategories, idSubCategory]
        : [idSubCategory];

      setData({
        ...state,
        data: {
          ...specSheet,
          subCategories,
        },
        loading: null,
      });
    } catch (err) {
      console.log('err: ', err);
      setData({ ...state, loading: null });
    }
  }

  async function removeCategory(idCategory: any) {
    setData({ ...state, loading: LoadingEnum.SAVING });

    try {
      await api.delete(`/spec-sheets/${specSheet?.id}/remove-category`, {
        data: { idCategory },
      });

      let categories = specSheet?.categories;

      if (categories)
        categories = categories.filter((id: number) => id !== idCategory);

      setData({
        ...state,
        data: {
          ...specSheet,
          categories,
        },
        loading: null,
      });
    } catch (err) {
      console.log('err: ', err);
      setData({ ...state, loading: null });
    }
  }

  async function removeSubCategory(idSubCategory: any) {
    setData({ ...state, loading: LoadingEnum.SAVING });

    try {
      await api.delete(`/spec-sheets/${specSheet?.id}/remove-sub-category`, {
        data: { idSubCategory },
      });

      let subCategories = specSheet?.subCategories;

      if (subCategories)
        subCategories = subCategories.filter(
          (id: number) => id !== idSubCategory,
        );

      setData({
        ...state,
        data: {
          ...specSheet,
          subCategories,
        },
        loading: null,
      });
    } catch (err) {
      console.log('err: ', err);
      setData({ ...state, loading: null });
    }
  }

  async function getNote(id: any) {
    try {
      const res: any = await api.get(`/spec-sheets/${id}/notes`);
      return res;
    } catch (err) {
      console.log('err: ', err);
    }
  }

  async function handleNote(id: any, description: any, type: any) {
    setData({ ...state, loading: LoadingEnum.SAVING });
    try {
      const res: any = await api.get(`/spec-sheets/${id}/notes`);
      if (res[getType(type)]) {
        await api.put(`/spec-sheets/${id}/notes`, {
          id: res[getType(type)].id,
          description,
        });
      } else {
        await api.post(`/spec-sheets/${id}/notes`, {
          description,
          type,
        });
      }
    } catch (err) {
      console.log('err: ', err);
    }
    setData({ ...state, loading: null });
  }

  function setLoading(loading = LoadingEnum.SAVING) {
    setData({ ...state, loading });
  }

  function removeLoading() {
    setData({ ...state, loading: null });
  }

  return {
    setSpecSheet,
    changeSpecSheetData,
    getFactories,
    uploadMasterImage,
    updateMasterImage,
    getDetails,
    getImage,
    getImageDetails,
    deleteImagePoint,
    getStyleGroups,
    getStyleTypes,
    updateSkuComponent,
    addCategory,
    removeCategory,
    addSubCategory,
    removeSubCategory,
    getNote,
    handleNote,
    setLoading,
    removeLoading,
    deleteSpecSheet,
  };
};
