import React from 'react';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';

import Text from 'components/Text';
import Spinner from 'components/Spinner';
import BrandFilter from 'components/MoreFiltersSearch/Brand';
import CountryFilter from 'components/MoreFiltersSearch/Country';
import SeasonFilter from 'components/MoreFiltersSearch/Season';
import SampleType from 'components/MoreFiltersSearch/SampleType';
import FactoryFilter from 'components/MoreFiltersSearch/Factory';
import StyleType from 'components/MoreFiltersSearch/StyleType';
import Material from 'components/MoreFiltersSearch/Material';
import StatusFilter from 'components/MoreFiltersSearch/Status';
import CategoryFilter from 'components/MoreFiltersSearch/Category';
import SubCategoryFilter from 'components/MoreFiltersSearch/SubCategory';

import { Container, Titles, ContainerFields, ContainerSpinner } from './styles';

const Filters: React.FC = () => {
  const {
    data: { loading },
  } = useSpecSheetSearch();

  return (
    <Container>
      <Titles>
        <Text fontWeight="normal-bold" fontSize={18} color="black">
          Select filters
        </Text>
        <Text fontSize={16} fontWeight="medium">
          Choose the filters below
        </Text>
      </Titles>
      {loading ? (
        <ContainerSpinner>
          <Spinner size="big" />
        </ContainerSpinner>
      ) : (
        <ContainerFields>
          <BrandFilter />
          <CountryFilter />
          <SeasonFilter />
          <SampleType />
          <FactoryFilter />
          <StatusFilter />
          <StyleType />
          <CategoryFilter />
          <SubCategoryFilter />
          <Material name="Material name" />
        </ContainerFields>
      )}
    </Container>
  );
};

export default Filters;
