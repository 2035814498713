import React, { memo, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Modal from 'components/Modal';

import GridFlex from 'components/GridFlex';
import SelectMaterial from './SelectMaterial';
import SelectColor from './SelectColor';
import LetterNumber from './LetterNumber';

import { ILine } from '../enum';

import { Container, SeparatorOne, SeparatorTwo, WrapperButton } from './styles';

interface IProps {
  labelMaterial: string;
  labelColor: string;
  item: ILine;
  index: number;
  isFirst: boolean;
  updateLine: (item: ILine, index: number) => Promise<void>;
  removeLine: (lineId: ILine['id'], index: number) => Promise<void>;
  newLine: () => void;
  onClickOpenModalColor: (isOpen: boolean | string) => void;
  onClickOpenModalMaterial: (isOpen: boolean | string) => void;
}

const Base: React.FC<IProps> = ({
  labelMaterial,
  labelColor,
  item,
  index,
  updateLine,
  newLine,
  removeLine,
  isFirst,
  onClickOpenModalColor,
  onClickOpenModalMaterial,
}) => {
  const [modalDelete, setModalDelete] = useState(false);
  const name = `${labelMaterial}-letters-numbers-${index}`;

  const changeValue = (value: string) => {
    updateLine({ ...item, letterNumber: value }, index);
  };

  const changeMaterial = (data: any) => {
    updateLine(
      {
        ...item,
        idMaterial: data ? data.id : null,
        material: data ? { name: data.name } : null,
      },
      index,
    );
  };

  const changeColor = (data: any) => {
    updateLine(
      {
        ...item,
        idColor: data ? data.id : null,
        color: data ? { color: data.color } : null,
      },
      index,
    );
  };

  return (
    <>
      <Container justifyContent="space-between" alignItems="flex-end">
        <GridFlex>
          <LetterNumber
            name={name}
            initValue={item.letterNumber}
            changeValue={changeValue}
          />

          <SeparatorTwo />

          <SelectMaterial
            label={labelMaterial}
            changeMaterial={changeMaterial}
            value={item.material?.name || null}
            isFirst={isFirst && !!item.material?.name}
            onClickOpenModal={onClickOpenModalMaterial}
          />

          <SeparatorTwo />

          <SelectColor
            label={labelColor}
            changeColor={changeColor}
            value={item.color?.color || null}
            isFirst={isFirst && !!item.color?.color}
            onClickOpenModal={onClickOpenModalColor}
          />
        </GridFlex>

        <SeparatorOne />

        {isFirst ? (
          <Button width="145px" onClick={newLine}>
            <Icon icon="plus" width="17px" />

            <Text color="white" margin="0 0 0 10px" fontWeight="normal-bold">
              Add
            </Text>
          </Button>
        ) : (
          <Button
            width="145px"
            onClick={() => {
              setModalDelete(true);
            }}
            scheme="gray"
          >
            <Text color="white" fontWeight="normal-bold">
              Delete row
            </Text>
          </Button>
        )}
      </Container>
      <Modal
        isOpen={modalDelete}
        title="Do you really want to delete this row?"
        onClose={() => setModalDelete(false)}
        minWidth="691px"
        noButtonClose
        data-test="modal-delete-line"
      >
        <WrapperButton>
          <Button
            width="266px"
            onClick={() => removeLine(item.id, index)}
            data-test="modal-delete-line-button-yes"
          >
            Yes
          </Button>

          <Button
            width="266px"
            onClick={() => setModalDelete(false)}
            scheme="nobg"
            data-test="modal-delete-line-button-no"
          >
            Cancel
          </Button>
        </WrapperButton>
      </Modal>
    </>
  );
};

export default memo(Base);
