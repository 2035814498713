import styled from 'styled-components';
import { simpleFlex } from 'theme/tools';

export const WrapperButton = styled.div`
  ${simpleFlex};
  flex: auto;
  justify-content: space-around;
  margin-top: 40px;

  * {
    cursor: pointer;
  }
`;

export const WrapperTextIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
