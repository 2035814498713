import { css } from 'styled-components';

import { breakpoints } from '../variables';

export { default as boxShadow } from './boxShadow';
export { default as takeColor } from './takeColor';
export { default as scrollStyle } from './scroll';

const responsiveFunc = (bp: string) => {
  return (...args: any[]) => css`
    @media (min-width: ${bp}) {
      ${css`
        ${args}
      `};
    }
  `;
};

export const mixinRespondTo = {
  xxs: responsiveFunc(breakpoints.xxs),
  xs: responsiveFunc(breakpoints.xs),
  sm: responsiveFunc(breakpoints.sm),
  md: responsiveFunc(breakpoints.md),
  lg: responsiveFunc(breakpoints.lg),
  xg: responsiveFunc(breakpoints.xg),
  xxg: responsiveFunc(breakpoints.xxg),
};

export const simpleFlex = `
  display: flex;
  align-items: center;
`;
