import React from 'react';

import { useSpecSheets } from 'containers/spec-sheets';

import Text from 'components/Text';
import Spinner from 'components/Spinner';

import { Container, ActionLoadMore, WrapperLoading } from './styles';

interface IProps {
  getMore: () => void;
}

const Bottom: React.FC<IProps> = ({ getMore }) => {
  const {
    data: { meta, type },
  } = useSpecSheets();

  const isLastPage = meta.currentPage === meta.totalPages;

  return (
    <Container data-test="spec-sheet-list-bottom">
      {!isLastPage && (
        <>
          {type === 'loadingMore' ? (
            <WrapperLoading>
              <Spinner size="small" />
            </WrapperLoading>
          ) : (
            <ActionLoadMore onClick={getMore}>
              <Text
                color="grayMedium"
                fontWeight="normal-bold"
                data-test="spec-sheet-list-bottom-text-one"
              >
                Click here to load more
              </Text>
            </ActionLoadMore>
          )}
        </>
      )}

      {meta.totalItems && (
        <Text
          color="grayLightText"
          fontSize={13}
          data-test="spec-sheet-list-bottom-text-two"
        >
          Total of {meta.totalItems} itens
        </Text>
      )}
    </Container>
  );
};

export default Bottom;
