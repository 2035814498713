import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { SelectChip, SelectItem } from 'components/Fields';

import { useSpecSheet } from 'containers/spec-sheet';

import api from 'services/api';

import searchTextHook from 'helpers/searchTextHook';
import Loading from '../partials/Loading';

export interface IItem {
  id: number;
  description: string;
}

let valueLength: null | number = null;

const SubCategory: React.FC = () => {
  const {
    data: { data },
    actions,
  } = useSpecSheet();

  const [chips, setChips] = useState<IItem[]>([]);
  const [items, setItems] = useState<IItem[]>([]);
  const [loading, setLoading] = useState(false);
  const { search, setSearch, setFilteredItems, filteredItems } = searchTextHook(
    {
      items,
    },
  );

  const values = data?.subCategories || [];

  const returnChips = (list = items) => {
    const mounted: any = [];

    values.map((id: number) =>
      mounted.push(list.find((item: any) => item.id === id)),
    );

    return mounted;
  };

  const handleCategories = async () => {
    setLoading(true);

    const list: IItem[] = await api.get('/sub-categories');
    setItems(list);
    setFilteredItems(list);

    const mounted: any = returnChips(list);

    setChips(mounted);

    setLoading(false);
  };

  const handleChange = useCallback(
    async (chip: any) => {
      setLoading(true);

      actions.addSubCategory(chip?.id);

      setLoading(false);
      setSearch('');
    },
    [setLoading, actions],
  );

  const mountChips = () => {
    const mounted = returnChips();
    setChips(mounted);
  };

  const filterItemsSelecteds = () => {
    const newFilteredItems = items.filter(({ id }) => !values.includes(id));
    setFilteredItems(newFilteredItems);
  };

  useEffect(() => {
    if (valueLength !== values.length) {
      mountChips();
      filterItemsSelecteds();

      valueLength = values.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, items]);

  useEffect(() => {
    handleCategories();
    filterItemsSelecteds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(
    async (item: any) => {
      actions.removeSubCategory(item.id);

      const filtersItems: any = chips.filter((f: any) => f.id !== item.id);

      setChips(filtersItems);
    },
    [actions, chips],
  );

  return useMemo(
    () => (
      <SelectChip
        label="Sub-category"
        value={chips}
        withInputSearch
        inputSearchPlaceholder="Search sub-category"
        inputSearchOnChange={setSearch}
        inputSearchvalue={search}
        dataTest="sub-category"
        onDelete={handleDelete}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            {filteredItems
              ?.filter(({ id }) => !values.includes(id))
              .map(item => (
                <SelectItem
                  key={`sub-categories-${item.id}`}
                  onClick={() => handleChange(item)}
                  data-testid={`select-item-option-sub-categorie-${item.id}`}
                  isSelected={values.includes(item.id)}
                >
                  {item.description}
                </SelectItem>
              ))}
          </>
        )}
      </SelectChip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chips,
      filteredItems,
      loading,
      setSearch,
      search,
      handleDelete,
      handleChange,
    ],
  );
};

export default memo(SubCategory);
