/* eslint-disable react/require-default-props */
import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import api from 'services/api';

import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
import { Input } from 'components/Fields';

import { IMaterial } from 'containers/spec-sheet-sku-detail/dtos';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import {
  Container,
  FieldContainer,
  ButtonsContainer,
  ContainerLoader,
  ContainerSuccess,
  ErrorContainer,
  ButtonsWrapp,
} from './styles';

enum ResponseType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSaved: (data: any) => void;
  nameComes?: string;
}

const ModalCreateMaterial: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSaved,
  nameComes,
}) => {
  const [materialName, setMaterialName] = useState('');
  const [materialType, setMaterialType] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<any>(false);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (nameComes) setMaterialName(nameComes);
    else setMaterialName('');
    setMaterialType('');
    setSuccess(false);
    setError([]);
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      setError([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialName, materialType]);

  const createMaterial = async (data: IMaterial) => {
    setLoading(true);
    try {
      const materialResponse = await api.post(`/materials`, data);
      setLoading(false);
      setSuccess(ResponseType.SUCCESS);
      onSaved(materialResponse);
    } catch (err: any) {
      if (err?.data?.message) setError(err.data.message);
      else setSuccess(ResponseType.ERROR);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const closeAndFinish = () => {
    setSuccess(false);
    onClose();
  };

  const isCompleted = materialName && materialType && !error[0];

  return (
    <Modal
      minWidth="1280px"
      minHeight="480px"
      isOpen={isOpen}
      onClose={onClose}
      noButtonClose
    >
      <>
        {!loading ? (
          <Container>
            {!success ? (
              <>
                <Text fontSize={24} color="black">
                  Create new material
                </Text>
                <FieldContainer>
                  <Grid item md={6}>
                    <Input
                      name="material-name"
                      label="Material name"
                      onChange={({ target: { value } }) =>
                        setMaterialName(value)
                      }
                      placeholder="Click to add"
                      width="451px"
                      value={materialName}
                      dataTest="material-name"
                      error={error[0]}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      name="material-type"
                      label="Material Type"
                      onChange={({ target: { value } }) =>
                        setMaterialType(value)
                      }
                      placeholder="Click to add"
                      width="451px"
                      value={materialType}
                      dataTest="material-type"
                      error={error[0]}
                    />
                  </Grid>
                </FieldContainer>
                {!!error && (
                  <ErrorContainer>
                    <Text
                      fontSize={13}
                      fontWeight="normal-bold"
                      color="error"
                      tag="span"
                    >
                      {error[0]}
                    </Text>
                  </ErrorContainer>
                )}
                <ButtonsContainer error={!!error}>
                  <Button
                    onClick={() =>
                      isCompleted &&
                      createMaterial({ name: materialName, type: materialType })
                    }
                    width="266px"
                    data-testid="button-create"
                    scheme={!isCompleted ? 'disabled' : 'default'}
                  >
                    Create
                  </Button>
                  <Button
                    data-testid="button-cancel"
                    width="266px"
                    scheme="nobg"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonsContainer>
              </>
            ) : (
              <>
                {success === ResponseType.SUCCESS && (
                  <ContainerSuccess>
                    <Icon icon="check-circle" color="none" width="68px" />
                    <Text fontSize={24} fontWeight="normal-bold" color="black">
                      Material created with success!
                    </Text>

                    <Text
                      fontSize={18}
                      fontWeight="medium"
                      color="black"
                      margin="15px 0 0"
                    >
                      The material{' '}
                      <b>
                        {materialName} - {materialType}
                      </b>{' '}
                      has been created.
                    </Text>

                    <Button
                      data-testid="button-ok"
                      width="250px"
                      onClick={() => closeAndFinish()}
                    >
                      Ok
                    </Button>
                  </ContainerSuccess>
                )}

                {success === ResponseType.ERROR && (
                  <ContainerSuccess>
                    <Icon icon="close-error" color="none" width="68px" />
                    <Text color="black" fontSize={30}>
                      Could not create this material at the moment
                    </Text>
                    <Text
                      color="black"
                      fontSize={18}
                      fontWeight="normal"
                      margin="15px 0 0"
                    >
                      We&rsquo;re sorry for the inconvenience. Please, try again
                      later
                    </Text>

                    <ButtonsWrapp>
                      <Button
                        width="250px"
                        onClick={() =>
                          createMaterial({
                            name: materialName,
                            type: materialType,
                          })
                        }
                      >
                        Try again
                      </Button>
                      <Button
                        width="250px"
                        onClick={() => closeAndFinish()}
                        scheme="nobg"
                      >
                        Back
                      </Button>
                    </ButtonsWrapp>
                  </ContainerSuccess>
                )}
              </>
            )}
          </Container>
        ) : (
          <ContainerLoader>
            <Text fontSize={24} color="black">
              Creating material
              <Spinner />
            </Text>
          </ContainerLoader>
        )}
      </>
    </Modal>
  );
};

export default memo(ModalCreateMaterial);
