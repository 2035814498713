import { IRow } from 'containers/dashboard/dtos';

export const parseDateFields = (data: IRow[]) => {
  const newData = data;

  return newData.map(
    ({
      factoryFirstShipDate,
      factoryPenaltyDate,
      fcrDate,
      scheduledXfDate,
      currentPoStart,
      currentPoStartDate,
      originalPoCancel,
      originalPoCancelDate,
      currentPoCancel,
      currentPoCancelDate,
      dateTransmitted,
      currentXfDate,
      originalPoStart,
      originalPoStartDate,
      ...rest
    }) => {
      return {
        factoryFirstShipDate: factoryFirstShipDate
          ? new Date(factoryFirstShipDate)
          : null,
        factoryPenaltyDate: factoryPenaltyDate
          ? new Date(factoryPenaltyDate)
          : null,
        fcrDate: fcrDate ? new Date(fcrDate) : null,
        scheduledXfDate: scheduledXfDate ? new Date(scheduledXfDate) : null,
        currentPoStart: currentPoStart ? new Date(currentPoStart) : null,
        currentPoStartDate: currentPoStartDate
          ? new Date(currentPoStartDate)
          : null,
        originalPoCancel: originalPoCancel ? new Date(originalPoCancel) : null,
        originalPoCancelDate: originalPoCancelDate
          ? new Date(originalPoCancelDate)
          : null,
        currentPoCancel: currentPoCancel ? new Date(currentPoCancel) : null,
        currentPoCancelDate: currentPoCancelDate
          ? new Date(currentPoCancelDate)
          : null,
        dateTransmitted: dateTransmitted ? new Date(dateTransmitted) : null,
        currentXfDate: currentXfDate ? new Date(currentXfDate) : null,
        originalPoStart: originalPoStart ? new Date(originalPoStart) : null,
        originalPoStartDate: originalPoStartDate
          ? new Date(originalPoStartDate)
          : null,
        ...rest,
      };
    },
  );
};

export const parseRows = (data: IRow[]) => {
  const newData = data;

  return newData.map(
    ({
      id,
      styleName,
      fobCost,
      retail,
      units,
      orderType,
      poColorPriorityComments,
      status,
      orderStatus,
      colMatCode,
      idPurchaseOrder,
      color,
      material,
      mic,
      dept,
      poId,
      poNumber,
      refNumber,
      season,
      brand,
      country,
      factory,
      factoryFirstShipDate,
      currentPoStart,
      currentPoStartDate,
      factoryPenaltyDate,
      scheduledXfDate,
      fcrDate,
      currentPoCancel,
      currentPoCancelDate,
      currentXfDate,
      originalPoCancel,
      originalPoCancelDate,
      dateTransmitted,
      originalPoStart,
      originalPoStartDate,
      remarks,
    }) => {
      return {
        id,
        styleName,
        fobCost,
        retail,
        units,
        orderType,
        poColorPriorityComments,
        status,
        orderStatus,
        colMatCode,
        mic,
        dept,
        poId,
        poNumber,
        refNumber,
        idPurchaseOrder,
        remarks,
        color: {
          id: color?.id || null,
        },
        material: {
          id: material?.id || null,
        },
        country: {
          id: country?.id || null,
        },
        brand: {
          id: brand?.id || null,
        },
        season: {
          id: season?.id || null,
        },
        factory: {
          id: factory?.id || null,
        },
        factoryFirstShipDate: factoryFirstShipDate
          ? new Date(factoryFirstShipDate).toISOString()
          : null,
        currentPoStart: currentPoStart
          ? new Date(currentPoStart).toISOString()
          : null,
        currentPoStartDate: currentPoStartDate
          ? new Date(currentPoStartDate).toISOString()
          : null,
        factoryPenaltyDate: factoryPenaltyDate
          ? new Date(factoryPenaltyDate).toISOString()
          : null,
        scheduledXfDate: scheduledXfDate
          ? new Date(scheduledXfDate).toISOString()
          : null,
        fcrDate: fcrDate ? new Date(fcrDate).toISOString() : null,
        currentPoCancel: currentPoCancel
          ? new Date(currentPoCancel).toISOString()
          : null,
        currentPoCancelDate: currentPoCancelDate
          ? new Date(currentPoCancelDate).toISOString()
          : null,
        currentXfDate: currentXfDate
          ? new Date(currentXfDate).toISOString()
          : null,
        originalPoCancel: originalPoCancel
          ? new Date(originalPoCancel).toISOString()
          : null,
        originalPoCancelDate: originalPoCancelDate
          ? new Date(originalPoCancelDate).toISOString()
          : null,
        dateTransmitted: dateTransmitted
          ? new Date(dateTransmitted).toISOString()
          : null,
        originalPoStart: originalPoStart
          ? new Date(originalPoStart).toISOString()
          : null,
        originalPoStartDate: originalPoStartDate
          ? new Date(originalPoStartDate).toISOString()
          : null,
      };
    },
  );
};

export const parseFilters = (filterList: any) =>
  Object.entries(filterList)
    .filter(([, value]) =>
      Array.isArray(value) ? value.length > 0 : value !== null && value !== '',
    )
    .map(([key, value]: any) => ({
      [key]: Array.isArray(value)
        ? value.map((v: any) => (v?.id ? v.id : v))
        : value,
    }));
