/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import Button from 'components/Button';
import GridFlex from 'components/GridFlex';

import { ETypeSteps } from '../index';

interface IProps {
  onClose: () => void;
  onCreate: () => void;
  handleSubmit: () => void;
  type: ETypeSteps;
  isCompleted: boolean;
  successText?: string | boolean;
  isEdit?: boolean;
  isPassword?: boolean;
}

const NavButtons: React.FC<IProps> = ({
  onClose,
  onCreate,
  handleSubmit,
  type,
  isCompleted,
  successText,
  isEdit,
  isPassword,
}) => {
  const isNotLoading = type !== ETypeSteps.LOADING;
  const isError = type === ETypeSteps.ERROR;
  const isSuccess = type === ETypeSteps.SUCCESS;

  const createOrPass = isPassword ? 'Reset' : 'Create';
  const editOrCreate = isEdit ? 'Save' : createOrPass;

  return (
    <GridFlex justifyContent="center" margin="100px 0 0">
      {isNotLoading && (
        <Button
          width="265px"
          data-testid="confirm-button-user-nav"
          scheme={isCompleted ? 'default' : 'disabled'}
          onClick={() => (!isSuccess ? handleSubmit() : onCreate())}
        >
          {!isError ? (
            <>
              {!successText ? (
                <>{isSuccess ? 'Ok' : editOrCreate}</>
              ) : (
                successText
              )}
            </>
          ) : (
            <>Try again</>
          )}
        </Button>
      )}

      {!isSuccess && (
        <Button
          scheme="nobg"
          width="265px"
          data-testid="cancel-modal-user"
          margin={isNotLoading ? '0 0 0 40px' : ''}
          onClick={onClose}
        >
          {!isError ? 'Cancel' : 'Back to User management'}
        </Button>
      )}
    </GridFlex>
  );
};

export default NavButtons;
