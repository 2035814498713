import styled from 'styled-components';
import { IStatusUser } from 'containers/users-management/dtos';
import { takeColor } from 'theme/tools';

const StatusColors = {
  active: `${takeColor('success')}`,
  blocked: `${takeColor('error')}`,
  inactive: `${takeColor('grayLightText')}`,
};

const StatusSpecColor = {
  active: `${takeColor('success')}`,
  deleted: `${takeColor('error')}`,
  inactive: `${takeColor('grayMedium')}`,
};

export default styled.div<{ type?: IStatusUser }>`
  padding: 5px;
  display: inline-block;
  border-radius: 100%;

  background-color: ${({ type }) =>
    type ? StatusColors[type] : StatusColors.active};
`;

export const StatusSpec = styled.div<{ type?: keyof typeof StatusSpecColor }>`
  padding: 5px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 7px;
  position: relative;
  top: 1px;

  background-color: ${({ type }) =>
    type && (StatusSpecColor[type] || StatusSpecColor.inactive)};
`;
