const mapCountries = {
  'United States': 'USA',
  China: 'CHN',
  Brazil: 'BRA',
  Mexico: 'MEX',
  Vietnam: 'VNM',
  Cambodia: 'KHM',
  Ethiopia: 'ETH',
  'Dominican Republic': 'DOM',
  Portugal: 'PRT',
};

export default (countrie: string) => mapCountries[countrie] || '';
