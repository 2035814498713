import styled from 'styled-components';

export const Container = styled.div`
  > div {
    margin-bottom: 25px;

    > p {
      width: 100%;
    }
  }

  .fields-wrapper {
    > div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
