import styled from 'styled-components';
import { takeColor, simpleFlex } from 'theme/tools';

export const Container = styled.div`
  border: 2px solid ${takeColor('white')};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  margin-top: 30px;
  overflow: hidden;
`;

export const LoadingContainer = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
`;

export const NoResultsContainer = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
  min-height: 400px;

  > p:first-child {
    margin-bottom: 16px;
  }
`;
