import styled from 'styled-components';
import { takeColor } from 'theme/tools';

export const ContainerBoxTopRadius = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const BoxTopRadius = styled.div`
  border: 1.5px solid ${takeColor('grayChangeText')};
  box-sizing: border-box;
  border-radius: 9.3px;
  padding: 5px 7px;
  margin-right: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${takeColor('grayChangeText')};

  span {
    font-weight: 600;
  }
`;
