import React, { useEffect, useState } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import { LoadingEnum } from 'containers/spec-sheet/dtos';

import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { POINTS } from 'components/Points';
import ModalLettersAndComments from 'components/ModalLettersAndComments';

import { Type } from 'containers/create-sketch/dtos';
import { Container, ImagePrototype, ContainerButtons } from './styles';

const PrototypeImage: React.FC = () => {
  const {
    data: { masterImage, imageDetails, loading, masterImageWithPoints },
    actions: { getImage, getImageDetails },
  } = useSpecSheet();

  const [isZoom, setZoom] = useState(false);
  const [isCommentsModal, setCommentsModal] = useState(false);

  const isLoading = loading === LoadingEnum.IMAGE;

  const pointsOrNoPoints = isZoom
    ? masterImageWithPoints?.url
    : masterImage?.url;

  useEffect(() => {
    if (!masterImage) getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (masterImage && imageDetails && !imageDetails.length)
      getImageDetails(masterImage.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterImage]);

  return (
    masterImage && (
      <>
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <>
            <Container isZoomIn={isZoom}>
              <div>
                <ImagePrototype
                  data-testid="image-prototype"
                  src={pointsOrNoPoints}
                  isZoomIn={isZoom}
                />
              </div>

              <ContainerButtons>
                {masterImageWithPoints && (
                  <Button
                    scheme="change"
                    width="145px"
                    onClick={() => setZoom(!isZoom)}
                    data-testid="button-zoom"
                  >
                    <Icon
                      icon="search-plus"
                      width="15px"
                      color="grayChangeText"
                    />
                    Zoom {isZoom ? 'out' : 'in'}
                  </Button>
                )}
                {imageDetails?.length &&
                imageDetails.findIndex(
                  point => point.type === POINTS.COMMENT,
                ) >= 0 ? (
                  <Button
                    scheme="change"
                    data-testid="see-comments"
                    width="145px"
                    onClick={() => setCommentsModal(true)}
                  >
                    See comments
                  </Button>
                ) : (
                  <></>
                )}
              </ContainerButtons>
            </Container>

            <ModalLettersAndComments
              url={masterImage?.url}
              isOpen={isCommentsModal}
              onClose={() => setCommentsModal(false)}
              idImage={masterImage?.id}
              type={Type.View}
            />
          </>
        )}
      </>
    )
  );
};

export default PrototypeImage;
