import styled from 'styled-components';

export const ContainerSaving = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Saving = styled('div')`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #8d8d8d;
  margin-right: 10px;
`;
