import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const TableContainer = styled.div`
  position: absolute;
  padding-right: 35px;
  padding-bottom: 35px;
`;

export const TableHeader = styled.div`
  background-color: ${takeColor('black')};
  border-radius: 12px;
  color: ${takeColor('white')};
  ${simpleFlex}
  min-height: 57px;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 38px;
`;

export const TableColumn = styled.div`
  min-width: 185px;
  max-width: 185px;
`;

export const TableColumnSmall = styled.div`
  min-width: 115px;

  button {
    font-size: 10px;
  }
`;

export const TableRow = styled.div`
  border-radius: 12px;
  border: 2px solid ${takeColor('grayBorderBox')};
  ${simpleFlex}
  min-height: 46px;
  font-size: 11px;
  font-weight: 600;
  padding: 0px 38px;
  color: ${takeColor('grayMedium')};

  margin-top: 15px;
  cursor: pointer;
`;
