import styled from 'styled-components';
import { simpleFlex, takeColor } from 'theme/tools';

export const Container = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
`;

export const WrapperHeader = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 29px;
  }

  > p:last-child {
    margin-top: 16px;
  }
`;

export const SpecVisualize = styled.div`
  ${simpleFlex}
  justify-content: center;
  flex-direction: column;
  max-width: 761px;
  min-height: 356px;
  width: 100%;
  margin-top: 64px;
  background: ${takeColor('white')};
  border: 1px solid ${takeColor('grayBorderBox')};
  border-radius: 13px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);

  button {
    margin-top: 36px;
  }
`;

export const WrapperButtons = styled.div`
  ${simpleFlex}
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 80px;
`;
