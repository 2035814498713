import React, { useEffect, useState } from 'react';

import withSpecSheetSearch, {
  useSpecSheetSearch,
} from 'containers/spec-sheet-search';
import { LoadingEnum } from 'containers/spec-sheet/dtos';

import TitlePages from 'components/TitlePages';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import Icon from 'components/Icon';
import Filters from './partials/Filters';
import SortBy from './partials/SortBy';
import ReportGenerator from './partials/ReportGenerator';

import { Container, ButtonContainer, WrapperLoading } from './styles';

const IsSaving = () => (
  <WrapperLoading>
    SAVING{' '}
    <div>
      <Spinner size="small" />
    </div>
  </WrapperLoading>
);

const Reports: React.FC = () => {
  const {
    data: { loading },
    actions: { sendFiltersToSave, getFilters },
  } = useSpecSheetSearch();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loading === LoadingEnum.SAVING;

  const openReport = () => {
    sendFiltersToSave();
    setIsOpen(true);
  };

  return (
    <Container>
      <TitlePages
        title="SAMPLE LINE SHEET"
        topContent={isLoading ? <IsSaving /> : `REPORTS`}
      />

      <Filters />
      <SortBy />
      <ReportGenerator isOpen={isOpen} onClose={() => setIsOpen(false)} />

      <ButtonContainer>
        <Button
          width="265px"
          data-testid="generate-report"
          onClick={() => !loading && openReport()}
        >
          Generate report
          <Icon icon="arrow-right" height="8px" />
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default withSpecSheetSearch(Reports);
