import React, { useState } from 'react';

import { useSpecSheetSearch } from 'containers/spec-sheet-search';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/Button';

import Spinner from 'components/Spinner';
import MoreFiltersSearch from 'components/MoreFiltersSearch';
import ResultList from '../ResultList';

import {
  Container,
  ContainerFilter,
  FakeMenu,
  BackButton,
  ContainerActions,
} from './styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdvancedSearch: React.FC<IProps> = ({ isOpen, onClose }) => {
  const {
    data: { filters, loading },
    actions: { getSpectSheetsAdvancedFilters },
  } = useSpecSheetSearch();

  const [haveSearched, setHaveSearched] = useState(false);

  const isEmpty = Object.values(filters).every((x: any) => !x.length);

  const searchClick = () => {
    getSpectSheetsAdvancedFilters();
    setHaveSearched(true);
  };

  return (
    <>
      {isOpen && (
        <>
          <Container data-testid="search-container">
            <FakeMenu />
            <ContainerFilter>
              <ContainerActions>
                <BackButton
                  onClick={onClose}
                  data-testid="close-advanced-filters"
                >
                  <Icon icon="arrow-left-circle" color="none" width="38px" />
                  <Text fontSize={18} color="white" fontWeight="normal">
                    Go back to Spec Home
                  </Text>
                </BackButton>
                <Button
                  width="197px"
                  padding="7.5px 28px"
                  onClick={() => !loading && !isEmpty && searchClick()}
                >
                  {loading ? <Spinner size="very-small" /> : 'Search'}
                </Button>
              </ContainerActions>
              <MoreFiltersSearch />
              {haveSearched && <ResultList />}
            </ContainerFilter>
          </Container>
        </>
      )}
    </>
  );
};

export default AdvancedSearch;
