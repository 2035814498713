import styled, { css } from 'styled-components';

import { Container as BoxContainer } from 'components/Box/styles';
import { takeColor } from 'theme/tools';

export const Container = styled.div`
  margin: 40px 0 40px;
`;

interface IItem {
  isDisable: boolean;
}

export const Item = styled(BoxContainer)<IItem>`
  & + & {
    margin-top: 30px;
  }

  ${({ isDisable }: IItem) =>
    isDisable &&
    css`
      pointer-events: none;
      box-shadow: none;
      border: 2px solid ${takeColor('grayLight')};

      * {
        opacity: 0.5;
      }
    `}
`;
