/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';

import { Select, SelectItem } from 'components/Fields';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';

import api from 'services/api';

interface IRole {
  id: number;
  name: string;
  code: string;
}

interface IProps {
  setValue: (value: number) => void;
  value: number | undefined;
}

const Permission: React.FC<IProps> = ({ setValue, value }) => {
  const [list, setList] = useState<IRole[] | null>(null);

  const handleRoles = async () => {
    const newList: IRole[] = await api.get('/roles');
    setList(newList);
  };

  useEffect(() => {
    handleRoles();
  }, []);

  const selectedValue = list?.find(({ id }) => id === value)?.name;

  return (
    <Select
      placeholder="Click to select"
      label="Role"
      dataTest="permission"
      data-testid="permission"
      width="450px"
      value={selectedValue === 'Manager' ? 'Admin' : selectedValue}
    >
      {list ? (
        list.map(({ id, name }) => {
          const dataTest = `permission-select-item-${id}`;

          return (
            <SelectItem
              onClick={() => setValue(id)}
              data-testid={dataTest}
              key={dataTest}
            >
              {name === 'Manager' ? 'Admin' : name}
            </SelectItem>
          );
        })
      ) : (
        <GridFlex justifyContent="center" width="100%">
          <Spinner />
        </GridFlex>
      )}
    </Select>
  );
};

export default Permission;
