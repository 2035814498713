import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;

  div > p:first-child {
    margin-bottom: 3px;
    margin-right: 3px;
  }

  @media print {
    div > p:first-child {
      margin-bottom: 2px;
      margin-right: 3px;
    }
  }

  @media print {
    & {
      p {
        font-size: 7px !important;
      }
    }
  }
`;

export const Colors = styled.div`
  margin-left: 18px;
  margin-top: 2px;

  @media print {
    & {
      margin-left: 15px;
      margin-top: 0px;
    }
  }
`;

export const Group = styled.div`
  display: flex;
  margin-bottom: 15px;

  @media print {
    & {
      margin-bottom: 4px;
    }
  }
`;
