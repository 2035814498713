import React, { useEffect, memo } from 'react';

import { useSpecSheet } from 'containers/spec-sheet';
import withSkuDetail, {
  useSpecSheetSkuDetail,
} from 'containers/spec-sheet-sku-detail';

import GridFlex from 'components/GridFlex';
import Spinner from 'components/Spinner';

import Bottom from './partials/Bottom';
import Content from './partials/Content';

import { Container } from './styles';

const SkuComponent: React.FC = () => {
  const {
    data: { data },
  } = useSpecSheet();
  const {
    data: { skuDetails },
    actions,
  } = useSpecSheetSkuDetail();

  const loadSkuDetails = async () => {
    await actions.getSkuDetailsAndCreate(Number(data?.id));
  };

  useEffect(() => {
    loadSkuDetails();

    return () => {
      actions.setSkuDetail(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !skuDetails.length;

  return isLoading ? (
    <GridFlex alignItems="center" justifyContent="center" margin="100px 0">
      <Spinner size="big" />
    </GridFlex>
  ) : (
    <Container>
      <Content />
      <Bottom />
    </Container>
  );
};

export default memo(withSkuDetail(SkuComponent));
