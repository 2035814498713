import React, { useMemo } from 'react';

import Text from 'components/Text';

import { useUser } from 'containers/user';

import { Container } from './styles';

const Perfil: React.FC = () => {
  const {
    data: { detail },
  } = useUser();

  return useMemo(
    () => (
      <Container>
        <img src="/images/perfil.png" alt="perfil" />

        <Text fontSize={22} fontWeight="normal-bold" color="white">
          {detail?.fullname}
        </Text>

        <Text fontSize={15} fontWeight="normal">
          {detail?.role.name}
        </Text>
      </Container>
    ),
    [detail],
  );
};

export default Perfil;
