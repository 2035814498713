import styled, { css } from 'styled-components';

interface IProps {
  isOpen: boolean;
}

const ShadowBlur = styled.div<IProps>`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  transition: all 0.2s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `};
`;

export default ShadowBlur;
