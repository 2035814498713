import Text from 'components/Text';
import React from 'react';

import {
  Container,
  WrapperContent,
  WrapperFakeBorder,
  HalfCircleFirst,
  HalfCircleSecond,
} from './styles';

export interface IProps {
  percentage: number;
}

const ProgressBar: React.FC<IProps> = ({ percentage }) => {
  return (
    <Container data-testid="progress-circle-bar">
      <WrapperContent>
        <Text
          fontWeight="normal"
          fontSize={18}
          color="black"
          data-testid="progress-circle-bar-percentage"
        >
          {percentage}%
        </Text>
      </WrapperContent>

      <WrapperFakeBorder percentage={percentage}>
        <HalfCircleFirst percentage={percentage} />
        {percentage > 50 && <HalfCircleSecond percentage={percentage} />}
      </WrapperFakeBorder>
    </Container>
  );
};

export default ProgressBar;
