/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';

import { Select, SelectItem } from 'components/Fields';
import Spinner from 'components/Spinner';
import GridFlex from 'components/GridFlex';

import api from 'services/api';

interface ICountries {
  id: number;
  description: string;
}

interface IProps {
  setValue: (value: number) => void;
  value: number | undefined;
  dataTest: string;
  label: string;
}

const CountrySelect: React.FC<IProps> = ({
  setValue,
  value,
  dataTest,
  label,
}) => {
  const [list, setList] = useState<ICountries[] | null>(null);

  const handleRoles = async () => {
    const newList: ICountries[] = await api.get('/countries');
    setList(newList);
  };

  useEffect(() => {
    handleRoles();
  }, []);

  const selectedValue = list?.find(({ id }) => id === value)?.description;

  return (
    <Select
      placeholder="Click to select"
      label={label}
      dataTest={dataTest}
      data-testid={dataTest}
      width="450px"
      value={selectedValue}
    >
      {list ? (
        list.map(({ id, description }) => {
          const mountDataTestSelect = `${dataTest}-select-item-${id}`;

          return (
            <SelectItem
              onClick={() => setValue(id)}
              data-testid={mountDataTestSelect}
              key={mountDataTestSelect}
            >
              {description}
            </SelectItem>
          );
        })
      ) : (
        <GridFlex justifyContent="center" width="100%">
          <Spinner />
        </GridFlex>
      )}
    </Select>
  );
};

export default CountrySelect;
