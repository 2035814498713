import { useDebounce } from 'hooks/useDebounce';
import React, {
  useLayoutEffect,
  useRef,
  useEffect,
  useState,
  RefObject,
} from 'react';
import { CellProps, Column } from 'react-datasheet-grid';
import { CellComponent } from 'react-datasheet-grid/dist/types';
import Select from 'react-select';
import api from 'services/api';

type SelectOptions = {
  type?: string;
  searchParam?: string;
  field?: string;
  disabled?: boolean;
  isObject?: boolean;
  choices?: {
    label: string;
    value: string;
  }[];
};

const SelectComponent = React.memo(
  ({
    active,
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }: CellProps<any, SelectOptions>) => {
    const ref: RefObject<any> = useRef<Select>(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<Array<any>>([]);

    const debounce = useDebounce(search);

    const isNullablePropsDatabaseField =
      rowData?.id !== undefined &&
      !rowData?.id &&
      rowData?.name !== undefined &&
      !rowData?.name;

    const selectedValue = isNullablePropsDatabaseField
      ? null
      : {
          label: rowData?.name || rowData,
          value: rowData?.id || rowData,
        };

    const returnSelectedLabel = () => {
      if (columnData.choices?.length) {
        return columnData.choices?.find(({ value }) => value === rowData);
      }

      return false;
    };

    const getChoices = async () => {
      setLoading(true);

      const newList: Array<any> = await api.get(
        `${columnData.type}?${columnData.searchParam}=${search}`,
      );

      setList(
        newList.map(op => ({
          value: columnData.field ? op.id : op,
          label: columnData.field
            ? `${op[columnData.field]} ${op.year || ''}`
            : op,
        })),
      );

      setLoading(false);
    };

    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus();
      } else {
        ref.current?.blur();
      }
    }, [focus]);

    useEffect(() => {
      if (focus && !columnData.choices?.length) getChoices();
    }, [debounce]);

    return (
      <Select
        ref={ref}
        styles={{
          container: provided => ({
            ...provided,
            flex: 1,
            alignSelf: 'stretch',
            pointerEvents: focus ? undefined : 'none',
          }),
          control: provided => ({
            ...provided,
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            background: 'none',
          }),
          indicatorSeparator: provided => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          placeholder: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
        }}
        isDisabled={columnData.disabled}
        value={returnSelectedLabel() || selectedValue}
        menuPortalTarget={document.body}
        menuIsOpen={focus}
        onChange={({ value, label }: any) => {
          if (columnData.isObject) {
            setRowData({ id: value, name: label });
          } else {
            setRowData(value);
          }
          setTimeout(stopEditing, 0);
        }}
        onMenuClose={() => stopEditing({ nextRow: false })}
        options={columnData.choices || list}
        filterOption={() => true}
        inputValue={search}
        onInputChange={setSearch}
        isLoading={loading}
        defaultValue={selectedValue}
      />
    );
  },
);

export const selectColumn = (
  options: SelectOptions,
): Column<string | null, SelectOptions> => ({
  component: SelectComponent as CellComponent<string | null, SelectOptions>,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: data => {
    return JSON.stringify(data.rowData);
  },
  pasteValue: data => {
    return JSON.parse(data.value);
  },
});
