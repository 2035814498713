/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { useSpecSheetModal } from 'containers/spec-sheet-modal';
import { TypeEnum } from 'containers/spec-sheet-modal/dtos';

import Modal from 'components/Modal';
import GridFlex from 'components/GridFlex';

import Spinner from 'components/Spinner';
import Button from 'components/Button';
import { LandscapeOrientation } from 'components/PrintOrientations/LandScape';
import TableAddNewStyle from './TableAddNewStyle';
import CreateSketch from './CreateSketch';
import TableSkuComponents from './TableSkuComponents';
import TablePrototype from './TablePrototype';
import TableSkuDetails from './TableSkuDetails';

import { Container, ContainerButtons, PrintView, TitleFornova } from './styles';

const ModalSpecSheet: React.FC = () => {
  const {
    data: { type, loading, specSheet },
    actions: { closeModal },
  } = useSpecSheetModal();
  const refPrint = useRef(null);

  const width = loading ? '690px' : '90%';
  const height = loading ? '270px' : '90%';

  return (
    <Modal
      isOpen={!!type}
      onClose={closeModal}
      minHeight={height}
      minWidth={width}
    >
      <Container>
        {loading && (
          <GridFlex
            justifyContent="center"
            alignItems="center"
            margin="60px 0 0"
          >
            <Spinner size="big" />
          </GridFlex>
        )}
        {!loading && (
          <>
            <LandscapeOrientation />
            <PrintView ref={refPrint}>
              <TitleFornova>FORNOVA GROUP</TitleFornova>
              <GridFlex>
                <TableAddNewStyle />
                <CreateSketch />
                <TableSkuComponents />
                <TablePrototype />
              </GridFlex>

              <TableSkuDetails />
            </PrintView>
          </>
        )}
      </Container>
      {type === TypeEnum.PRINT && !loading && (
        <ContainerButtons>
          <ReactToPrint
            content={() => refPrint.current}
            trigger={() => (
              <Button width="265px" data-testid="print-button">
                Print
              </Button>
            )}
            documentTitle={`${specSheet?.description || ''} / ${
              specSheet?.refNo || ''
            } / Spec ID ${specSheet?.id || ''}`}
          />
          <Button
            width="266px"
            onClick={closeModal}
            scheme="nobg"
            data-testid="close-modal-print"
          >
            Cancel
          </Button>
        </ContainerButtons>
      )}
    </Modal>
  );
};

export default ModalSpecSheet;
